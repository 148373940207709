import React, { useState, useContext } from 'react'
import { Typography, Button } from '@uhc-tempo/components'
import Config from '../../../../config'
import RallyRedirects from '../../../../constants/rally-redirects'
import Constants from '../../../../constants/constants'
import UseBaseService from "../../../../hooks/useBaseService"
import { ProfileContext } from "../../../../contexts/profileContext"
import getCookieItem from "../../../../tools/getCookieItem"
import clickTrack from '../../../../hooks/clickTrack'

const HealthTiles = (props) => {
  const { image, title, btnText, description, url, offerKey, offerStatus, nbaBlacklistDaysTellMeMore } = props
  const { consumer_url, aem_content_url, legacy_url, rally_deeplink } = Config
  const { find_provider } = RallyRedirects || {}
  const { memberactionupdate_endpoint } = Constants
  // const memberaction_update_endpoint =
  //   `${memberactionupdate_endpoint}&offerKey=${offerKey}&offerStatus=${offerStatus}&blacklistDays=${nbaBlacklistDaysTellMeMore}`
  const { profile, blockActionForSuperUser } = useContext(ProfileContext) || {}
  const { loggedinMember } = profile || {}
  const { coverages, firstName, lastName, dateOfBirth } = loggedinMember || {}
  const { medical } = coverages || {}
  const { policyNumber, memberID } = medical || {}

  const loginLang = getCookieItem("LOGINLOCALE")
  const selectedLang = getCookieItem("lang")

  //US4949442, US4988117: Don't change rally_connect_lang value
  let rally_connect_lang = "en-US"
  if (loginLang === "es" || selectedLang === "es") {
    rally_connect_lang = "es"
  }

  const [maData, setMaData] = useState({
    hasError: false, loading: false
  })
  const [formData, setFormData] = useState({})
  let postObj = {}
  if (memberactionupdate_endpoint.indexOf('offline') < 0) {
    postObj = {
      "offerKey": offerKey,
      "offerStatus": offerStatus,
      "blacklistDays": nbaBlacklistDaysTellMeMore
    }
  }
  else {
    postObj = {
      "firstName": firstName,
      "lastName": lastName,
      "memberId": memberID,
      "groupNumber": policyNumber,
      "dob": dateOfBirth,
      "offers": [{
        "offerStatus": offerStatus,
        "offerKey": offerKey,
        "blacklistDays": nbaBlacklistDaysTellMeMore
      }]
    }
  }

  const updateAction = () => {
    UseBaseService("updateOffer", "POST", "dmz", memberactionupdate_endpoint, postObj,
      response => {
        const { APIError, data } = response || ''
        if (APIError) {
          console.log("update memberactions api error: check api call!")
          setMaData({ hasError: true, loading: true })
        } else {
          try {
            if (data) {
              setMaData({ hasError: false, loading: true })
            }
          } catch (error) {
            setMaData({ hasError: true, loading: false })
          }
        }
      })
    // else if (memberactionupdate_endpoint.indexOf('offline') > 0) {
    //   const fetchMemberactions = async () => {
    //     const response = await fetch(memberactionupdate_endpoint);
    //     const memberActionsResponse = await response.json();
    //     return memberActionsResponse;
    //   }
    //   fetchMemberactions().then(Response => {
    //     const { data, Error } = Response || {};
    //     if (Error) {
    //       console.log("update memberactions api error: check api call!")
    //       setMaData({ hasError: true, loading: true })
    //     } else {
    //       try {
    //         if (data) {
    //           setMaData({ hasError: false, loading: true })
    //         }
    //       } catch (error) {
    //         setMaData({ hasError: true, loading: false })
    //       }
    //     }

    //   })
    // }
  }

  let urlformat = url
  let urlParam = urlformat && urlformat.match(/^https:\/\/[^\s]+\.?(my)?uhc\.com$/gm) ?
  urlformat.match(/^https:\/\/[^\s]+\.?(my)?uhc\.com$/gm) : ''
  urlParam = urlformat && urlformat.replace(urlParam[0], '') ?
    urlformat.replace(urlParam[0], '') : ''

  let urlNew = ''
  if (url?.includes("ssoOutBound") || url?.includes("sso/outbound")) {
    urlNew = `${legacy_url}` + urlParam
  }
  else if (url?.includes("ifp")) {
    urlNew = `${consumer_url}` + urlParam
  }
  else if (url?.includes("content/myuhc")) {
    urlNew = `${aem_content_url}` + urlParam
  }
  else if (url?.includes("connect.werally")) {
    let formatRallyUrl = url
    let rallyUrlParam = formatRallyUrl && formatRallyUrl.match(/^https:\/\/[^.\s]+\.werally\.com$/gm) ?
    formatRallyUrl.match(/^https:\/\/[^.\s]+\.werally\.com$/gm) : ''
    rallyUrlParam = formatRallyUrl && formatRallyUrl.replace(rallyUrlParam[0], '') ?
      formatRallyUrl.replace(rallyUrlParam[0], '') : ''
    urlNew = `${rally_deeplink}` + rallyUrlParam
  }
  else {
    urlNew = url || ''
  }

  let handleSubmit
  const envCheck = window.location.hostname
  if (envCheck.includes('int')) {
      handleSubmit = (e) => {
        if ( e !== null && e !== undefined) {
          e.preventDefault()
        }
          window.location.href = urlNew
      }
  } else {
      handleSubmit = (e) => {
          blockActionForSuperUser([], () => {
              updateAction()
              window.location.href = urlNew
          })
      }
  }

  const handleChange = (event) => {
    setFormData(event.target.value)
  }

  return (
    <>
      {!image || image === " " ?
        <>
          {/* <BrandIcon icon={IconWellness} size="small" styleVariation="TWO_COLOR_LIGHT_CIRCLE" /> */}
          <img id='healthTile' src={`${aem_content_url}/content/dam/myuhc/images/icons/icon_wellness.svg`} style={{ width: 75 }}
            alt='' />
        </> :
        <>
          <img src={`${aem_content_url}${image}`} style={{ width: 75 }}
            alt='' />
        </>
      }
      <Typography.H3 style={{ fontSize: '1.25rem' }}>{title}</Typography.H3>
      <Typography.Paragraph>  {description} </Typography.Paragraph>

      <Button
        type="submit"
        value={formData}
        onChange={handleChange}
        onClick={()=>{handleSubmit(); 
          clickTrack(btnText, "main-body", "button", "internal", "")}}
        buttonType="secondary-one"
        id="learn-more">
        {btnText}
      </Button>

    </>
  )

}

export default HealthTiles
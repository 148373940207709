const getPlanYear = startDate => {
    const currentDate = new Date()
    const CSToffSet = -360
    const offset= CSToffSet*60*1000;

    const currentYear = new Date(currentDate.getTime()+offset).getFullYear()
    console.log('current year', currentYear)
    const effectiveDate = new Date(startDate+'T00:00:00');
    const effectiveYear = effectiveDate.getFullYear();
    console.log('effective year >>', effectiveYear)

    let planYear = "current-year"
    if (effectiveDate) {
        if (effectiveYear === currentYear) {
            planYear = "current-year"
        }
        else if (effectiveYear > currentYear) {
            planYear = "future-year"
        }
    }
    console.log('plan year >> ', planYear)
    return planYear;

}

export default getPlanYear

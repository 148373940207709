import React,{useEffect,useState} from 'react';
import styles from './Messagebox.module.css'
import MessageboxQuery from './MessageboxQuery';
import UseGraphqlApi from '../../hooks/useGraphqlApi';
import Modal from 'react-bootstrap/Modal';
import { Button } from '@uhc-tempo/components'
import CloseButton from 'react-bootstrap/CloseButton';
import Config from '../../config'
import { Markup } from 'interweave';
import modalTrack from '../../hooks/modalTrack';
import clickTrack from '../../hooks/clickTrack';




export const Messagebox = () => {

  const messageQuery = MessageboxQuery()
  const [messageData, setMessageData] = useState([]);
  const { aem_content_url,consumer_url } = Config || {}
  const [loading, setLoading] = useState(true);
  const [hasError, setError] = useState(false);

  const [showpopup, setShowPopup] = useState(true)
  const handleClose = () => setShowPopup(false);
  
  
  useEffect(() => {
    if (localStorage.getItem("firstLoad")) {
      setShowPopup(false)
    }
    else {
      localStorage.setItem("firstLoad", 'set');
      setTimeout(()=>{ modalTrack("PFNY Modal") },2000)
    }
}, [])

  useEffect(() => {
    UseGraphqlApi(messageQuery, MessageboxQueryHandler)
}, [])

// const response1 ={
//     "data": {
//       "actionCardList": {
//         "items": [
//           {
//             "_path": "/content/dam/iex/future-year/effective/dashboard/modeloverlay",
//             "cardTitle": "We make it easy to stay with a plan you can rely on",
//             "description": {
//               "html": null
//             },
//             "url": "uhc.com/ifp/benefits/prepare-for-next-year ",
//             "linkText": "See plan changes ",
//             "urlType": "default",
//             "image": {
//               "__typename": "ImageRef"
//             },
//             "startDate": "1969-12-30",
//             "endDate": "9999-12-29"
//           }
//         ]
//       }
//     }
//   }

  // useEffect(()=>{
  // MessageboxQueryHandler(response1)
  // })
const MessageboxQueryHandler = (response) => {
  if (response && !response.isError) {
      if (response.data) {
          if (response.data.actionCardList.items.length > 0) {
           setMessageData(response.data?.actionCardList?.items)
      }
  } else {
      setError(true)
  }
  setLoading(false)
}
}

const { url, linkText, type, description, image, cardTitle } = messageData[0] || {}

const onCloseButton = () =>{
  window.location.href = '/ifp/dashboard'
}


  

  return (
    <>
    {showpopup?
      (<Modal show={showpopup} onHide={handleClose} dialogClassName={styles.modal700w}>
      <Modal.Body className={styles.modalbody}>
      <CloseButton className={styles.closebutton} onClick={handleClose}/>
      {image && image._path ?  <div className={styles.imageBox} > <img src={`${aem_content_url}${image._path}`}/></div>:''}
      <div className={styles.MessageBox1}> 
       {cardTitle ? <div> <h1 className={styles.textBox}> {cardTitle}</h1></div> : ''}
       {description && description.html ? (
       <div><h2 className={styles.subText}> <Markup
                                                allowAttributes={true}
                                                content={description && description.html}
                                            /> </h2></div>):''}
       { linkText ? <div><Button className={styles.button} href={`${consumer_url}${url}`}  
       onClick={()=> clickTrack(linkText, "main-body", "button", "internal", `${consumer_url}${url}`)}>
       {linkText}</Button></div> : null }
       </div>
        </Modal.Body>
       
      </Modal> ):''} 
    </>
  );
}



  // return (
  //   <>
  //     {/* <div className={styles.overlay}>    
  //     <div className={styles.MessageBox}>  
  //       <div className={styles.imageBox} >
  //       <img src="/messageBox.png" style={{paddingLeft: '140px'}}>
  //       </img>
  //       <button className={styles.closebutton} onClick={onCloseButton}>x</button>
        
  //       </div>  */}
        
      
  //      {/* <h1 className={styles.textBox}>{cardTitle}</h1>
  //      <h2 className={styles.subText}>Explore what is new with next year's plan, see benefits, and compare costs for 2024</h2>
  //      <button className={styles.button} href={url}>{linkText}</button>
  //      </div>  
      
  //      </div> */}

    
  // );
  //      </>
    
  // );


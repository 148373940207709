import Axios from "axios"
import ContentFragmentFormatter from './UseContentFragmentFormatter'

const UseContentFragments = (endpoint, callback) => {
    Axios.get(endpoint)
        .then(response => {
            const { data } = response
            let content = { hasError: false, data: [] }
            try {
                const formattedData = ContentFragmentFormatter(data)
                content.data = formattedData
                callback(content)
            } catch (error) {
                //additional logging for errors
                console.log(error)
            }
        })
        .catch(err => {
            //additional logging for errors
            callback({ hasError: false, data: [] })
        })
}

export default UseContentFragments

const CONTENT_RALLY_INSTANCE = true
const configs = {
  int: {
    consumer_url: "https://member.int.uhc.com",
    logger_url: "https://logger.int.myuhc.com",
    common_url: "https://common.int.myuhc.com",
    dashboard_url: "https://dashboard.int.myuhc.com",
    claims_url: "https://claims.int.myuhc.com",
    benefits_url: "https://benefits.int.myuhc.com",
    accountprofile_url: "https://accountprofile.int.myuhc.com",
    provider_url: "https://provider.int.myuhc.com",
    wellness_url: "https://wellness.int.myuhc.com",
    pharmacy_url: "https://pharmacy.int.myuhc.com",
    payment_url: "https://payment.int.myuhc.com",
    activate_url: "https://activate.int.myuhc.com",
    stargate_obapi_url: "https://gateway-stage.optum.com",
    obapi_base_uri: "/api/stage",
    sheild_base_uri: "/api/test",
    sheild_base2_uri: "/api/testalphauat",
    bills_base_uri: "/api/stage",
    b360_globalapiservice_call: "false",
    aem_content_url: CONTENT_RALLY_INSTANCE ? `https://cms.int.myuhc.com` : `https://test3.myuhc.com`,
    oidc_url: "https://member.int.uhc.com",
    rally_url: "https://member.int.uhc.com/internal-redirect?deepLink=https://connect.int.werally.in",
    rally_vc_url: "https://member.int.uhc.com/internal-redirect?deepLink=https://virtualcare.int.werally.in",
    legacy_url: "https://member.int.uhc.com/myuhc",
    legacy_en_url: "https://member.int.uhc.com",
    myuhc_20_url: "https://test.int.myuhc.com",
    myuhc_20_es_url: "https://member.int.myuhc.com",
    IEX_widget_url:
      "https://member-widgets-stage-iex.optum.com/widgetfactory.js",
    hsid_session_url: "https://stage-accounts.myuhc.com",
    rally_deeplink: "https://connect.werally.com",
    aem_test: "https://test3.myuhc.com",
    ping_authorization_url:
      "https://stage-sso.uhc.com/ext/as/authorization.oauth2",
    ping_adapter_id: "systest3myuhcdirect",
    client_id: "systest-myuhc",
    offline_token: "https://common.int.myuhc.com/getImplicitToken"
  },
  beta: {
    consumer_url: "https://member.beta.uhc.com",
    consumer_pr_url: "https://cmspr-beta.int.myuhc.com/pr",
    logger_url: "https://logger-beta.int.myuhc.com",
    common_url: "https://common.int.myuhc.com",
    dashboard_url: "https://dashboard-beta.int.myuhc.com",
    claims_url: "https://claims-beta.int.myuhc.com",
    benefits_url: "https://benefits-beta.int.myuhc.com",
    accountprofile_url: "https://accountprofile-beta.int.myuhc.com",
    provider_url: "https://provider-beta.int.myuhc.com",
    wellness_url: "https://wellness-beta.int.myuhc.com",
    pharmacy_url: "https://pharmacy-beta.int.myuhc.com",
    payment_url: "https://payment-beta.int.myuhc.com",
    pr_url: "https://cmspr-beta.int.myuhc.com",
    activate_url: "https://activate-beta.int.myuhc.com",
    stargate_obapi_url: "https://gateway-stage.optum.com",
    obapi_base_uri: "/api/stage",
    sheild_base_uri: "/api/test",
    sheild_base2_uri: "/api/testalphauat",
    bills_base_uri: "/api/stage",
    b360_globalapiservice_call: "false",
    account_validation_base_uri:"/api/stage",
    aem_content_url: CONTENT_RALLY_INSTANCE ? `https://cmsbeta.int.myuhc.com` : `https://cms.int.myuhc.com`,
    oidc_url: "https://oidc.int.myuhc.com",
    legacy_url: "https://member.int.uhc.com/myuhc",
    legacy_en_url: "https://member.int.uhc.com",
    myuhc_20_url: "https://test.int.myuhc.com",
    myuhc_20_es_url: "https://member.int.myuhc.com",
    IEX_widget_url:
      "https://member-widgets-stage-iex.optum.com/widgetfactory.js",
    hsid_session_url: "https://stage-accounts.myuhc.com",
    rally_deeplink: "https://connect.werally.com",
    aem_test: "https://test3.myuhc.com",
    rally_url:
      "https://member.int.uhc.com/internal-redirect?deepLink=https://connect.int.werally.in",
    rally_vc_url: "https://member.int.uhc.com/internal-redirect?deepLink=https://virtualcare.int.werally.in",
    ping_authorization_url:
      "https://stage-sso.uhc.com/ext/as/authorization.oauth2",
    ping_adapter_id: "systest3myuhcdirect",
    client_id: "myuhc-member-nonprod",
    offline_token: "https://common.int.myuhc.com/getImplicitToken"
  },
  uat: {
    consumer_url: "https://member.uat.uhc.com",
    logger_url: "https://logger.uat.myuhc.com",
    common_url: "https://common.uat.myuhc.com",
    dashboard_url: "https://dashboard.uat.myuhc.com",
    claims_url: "https://claims.uat.myuhc.com",
    benefits_url: "https://benefits.uat.myuhc.com",
    accountprofile_url: "https://accountprofile.uat.myuhc.com",
    provider_url: "https://provider-offlinestg.myuhc.com",
    wellness_url: "https://wellness.uat.myuhc.com",
    payment_url: "https://payment.uat.myuhc.com",
    pharmacy_url: "https://pharmacy.uat.myuhc.com",
    stargate_obapi_url: "https://gateway.optum.com",
    sheild_base_uri: "/api",
    sheild_base2_uri: "/api",
    obapi_base_uri: "/api/beta",
    bills_base_uri: "/api",
    b360_globalapiservice_call: "true",
    account_validation_base_uri:"/api",
    aem_content_url: CONTENT_RALLY_INSTANCE ? `https://cms.uat.myuhc.com` : `https://test3.myuhc.com`,
    oidc_url: "https://member.uat.uhc.com",
    rally_url: "https://member.uat.uhc.com/internal-redirect?deepLink=https://connect.bluesteel.werally.in",
    rally_vc_url: "https://member.uat.uhc.com/internal-redirect?deepLink=https://virtualcare.bluesteel.werally.in",
    legacy_url: "https://member.uat.uhc.com/myuhc",
    legacy_en_url: "https://member.uat.uhc.com",
    myuhc_20_url: "https://stg.uat.myuhc.com",
    myuhc_20_es_url: "https://member.uat.myuhc.com",
    activate_url: "https://activate.uat.myuhc.com",
    IEX_widget_url:
      "https://member-widgets-stage-iex.optum.com/widgetfactory.js",
    hsid_session_url: "https://accounts.myuhc.com",
    rally_deeplink: "https://connect.bluesteel.werally.in",
    aem_test: "https://stg.myuhc.com",
    ping_authorization_url: "https://sso.uhc.com/ext/as/authorization.oauth2",
    ping_adapter_id: "uatmyuhc",
    client_id: "myuhc2-prod",
    offline_token: "https://common.uat.myuhc.com/getImplicitToken"
  },

  prd: {
    consumer_url: "https://member.uhc.com",
    logger_url: "https://logger.member.myuhc.com",
    common_url: "https://common.member.myuhc.com",
    dashboard_url: "https://dashboard.member.myuhc.com",
    claims_url: "https://claims.member.myuhc.com",
    benefits_url: "https://benefits.member.myuhc.com",
    accountprofile_url: "https://accountprofile.member.myuhc.com",
    provider_url: "https://provider.member.myuhc.com",
    wellness_url: "https://wellness.member.myuhc.com",
    payment_url: "https://payment.member.myuhc.com",
    pharmacy_url: "https://pharmacy.member.myuhc.com",
    stargate_obapi_url: "https://gateway.optum.com",
    activate_url: "https://activate.member.myuhc.com",
    obapi_base_uri: "/api",
    sheild_base_uri: "/api",
    sheild_base2_uri: "/api",
    bills_base_uri: "/api",
    b360_globalapiservice_call: "false",
    account_validation_base_uri:"/api",
    aem_content_url: CONTENT_RALLY_INSTANCE ? `https://cms.member.myuhc.com` : `https://prd.myuhc.com`,
    oidc_url: "https://member.uhc.com",
    rally_url: "https://member.uhc.com/internal-redirect?deepLink=https://connect.werally.com",
    rally_vc_url: "https://member.uhc.com/internal-redirect?deepLink=https://virtualcare.werally.com",
    legacy_url: "https://member.uhc.com/myuhc",
    legacy_en_url: "https://member.uhc.com",
    myuhc_20_url: "https://prod.member.myuhc.com",
    myuhc_20_es_url: "https://member.myuhc.com",
    IEX_widget_url: "https://member-widgets-iex.optum.com/widgetfactory.js",
    hsid_session_url: "https://accounts.myuhc.com",
    rally_deeplink: "https://connect.werally.com",
    aem_test: "https://prd.myuhc.com",
    ping_authorization_url: "https://sso.uhc.com/ext/as/authorization.oauth2",
    ping_adapter_id: "prdmyuhc",
    client_id: "myuhc2-prod",
    offline_token: "https://common.member.myuhc.com/getImplicitToken"
  },

  perf: {
    consumer_url: "https://member.perftest.uhc.com",
    logger_url: "https://logger.member.myuhc.com",
    common_url: "https://common.perftest.myuhc.com",
    dashboard_url: "https://dashboard.perftest.myuhc.com",
    claims_url: "https://claims.perftest.myuhc.com",
    benefits_url: "https://benefits.perftest.myuhc.com",
    accountprofile_url: "https://accountprofile.perftest.myuhc.com",
    provider_url: "https://provider.perftest.myuhc.com",
    wellness_url: "https://wellness.perftest.myuhc.com",
    payment_url: "https://payment.perftest.myuhc.com",
    pharmacy_url: "https://pharmacy.perftest.myuhc.com",
    stargate_obapi_url: "https://gateway.optum.com",
    activate_url: "https://activate.perftest.myuhc.com",
    obapi_base_uri: "/api",
    sheild_base_uri: "/api",
    sheild_base2_uri: "/api",
    bills_base_uri: "/api",
    b360_globalapiservice_call: "false",
    aem_content_url: CONTENT_RALLY_INSTANCE ? `https://cms.member.myuhc.com` : `https://prd.myuhc.com`,
    oidc_url: "https://member.uhc.com",
    rally_url: "https://member.uhc.com/internal-redirect?deepLink=https://connect.werally.com",
    rally_vc_url: "https://member.uhc.com/internal-redirect?deepLink=https://virtualcare.werally.com",
    legacy_url: "https://member.uhc.com/myuhc",
    legacy_en_url: "https://member.uhc.com",
    myuhc_20_url: "https://prod.perftest.myuhc.com",
    myuhc_20_es_url: "https://member.myuhc.com",
    IEX_widget_url: "https://member-widgets-iex.optum.com/widgetfactory.js",
    hsid_session_url: "https://accounts.myuhc.com",
    rally_deeplink: "https://connect.werally.com",
    aem_test: "https://prd.myuhc.com",
    ping_authorization_url: "https://sso.uhc.com/ext/as/authorization.oauth2",
    ping_adapter_id: "prdmyuhc",
    client_id: "myuhc2-prod",
    offline_token: "https://common.member.myuhc.com/getImplicitToken"
  },
  localhost: {
    consumer_url: "https://member.int.uhc.com",
    legacy_url: "https://systest3.myuhc.com",
    logger_url: "",
    common_url: "",
    stargate_obapi_url: "",
    sheild_base_uri: "",
    sheild_base2_uri: "",
    obapi_base_uri: "/API",
    bills_base_uri: "/api",
    b360_globalapiservice_call: "false",
    aem_content_url: "",
    oidc_url: "",
    rally_url: "https://member.uhc.com/internal-redirect?deepLink=https://connect.werally.com",
    rally_vc_url: "https://member.uhc.com/internal-redirect?deepLink=https://virtualcare.werally.com",
    aem_test: "https://test3.myuhc.com",
    rally_deeplink: "https://connect.werally.com",
    offline_token: "https://common.int.myuhc.com/getImplicitToken"
  },
};
const config = () => {
  if (window.location.hostname.indexOf("uat") > -1) {
    return { ...configs.uat };
  } else if (window.location.hostname.indexOf("beta") > -1) {
    return { ...configs.beta };
  } else if (window.location.hostname.indexOf("int") > -1) {
    return { ...configs.int };
  }
  else if (window.location.hostname.indexOf("perf") > -1) {
    return { ...configs.perf };
  } else if (window.location.hostname.indexOf("localhost") > -1) {
    return { ...configs.localhost };
  } else {
    return { ...configs.prd };
  }
};

export default config();
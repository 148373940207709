import React, { useState, useEffect, useContext } from 'react'
import { Button, Link, Typography } from '@uhc-tempo/components'
import styles from './banner.module.css'
import UseGraphqlApi from '../../../../hooks/useGraphqlApi'
import MedicaidQuery from './medicaidQuery'
import { Loader } from "@uhc/pattern-library"
import Config from '../../../../config'
import RallyRedirects from '../../../../constants/rally-redirects'
import { PrimaryCareContext } from "../../../../contexts/primarycareContext"
import getCookieItem from "../../../../tools/getCookieItem";
import getFilteredContentList from "../../../../tools/getFilteredContentList";
import { Markup } from 'interweave'
import clickTrack from '../../../../hooks/clickTrack'

const loginLang = getCookieItem("LOGINLOCALE");
const selectedlang = getCookieItem("lang");

//US4949442, US4988117: Don't change rally_connect_lang value
let rally_connect_lang = "en-US"
if (loginLang === "es" || selectedlang === "es") {
  rally_connect_lang = "es"
}

const MedicaidBanner = ({ depSeqNbr }) => {
  const { legacy_en_url, rally_url, aem_content_url } = Config || {}
  const { view_pcp, find_provider } = RallyRedirects || {}
  const [open, setOpen] = useState(true)
  // GraphQl query implementation starts
  const [bannerContent, setBannerContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasError, setError] = useState(false);
  const bannerContentQuery = MedicaidQuery()
  const domain = window.location.origin + window.location.pathname
  useEffect(() => {
    UseGraphqlApi(bannerContentQuery, postBannersHandler);
  }, []);

  const postBannersHandler = (response) => {
    if (response && !response.isError) {
      if (response.data) {
        console.log("Medicaid Banner",response.data)
        if (response.data.bannerList.items.length > 0) {
          const listWithDates = getFilteredContentList(response.data.bannerList.items)
          if (listWithDates.length > 0) {
            //console.log('listwithdates', listWithDates);
            setBannerContent(
              listWithDates[0]
            )
          }
          else {
            setBannerContent(
              response.data.bannerByPath && response.data.bannerByPath.item ?
                response.data.bannerByPath.item : null
            );
          }
        }
        else if (response.data.bannerByPath.item) {
          setBannerContent(
            response.data.bannerByPath && response.data.bannerByPath.item ?
              response.data.bannerByPath.item : null
          );
        }
      }
    } else {
      setError(true);
    }
    setLoading(false);
  };
  const { urlType, closeLink, buttonLink, type, description, image, title, buttonTitle } = bannerContent || {}
  // GraphQl query implementation ends
  //  ****************** Banner url function **********************
  const { primarycare } = useContext(PrimaryCareContext) || {}
  const { pcpData, isPrimarycareSet } = primarycare || {}
  let url = ''
  function renderUrl(urlType) {
    if (urlType === "pcpUrl") {
      isPrimarycareSet && pcpData !== null && pcpData.primarycareData !== null && pcpData.primarycareData !== undefined && depSeqNbr !== null &&
        pcpData.primarycareData.map((pcpdata, i) => {
          if ((depSeqNbr === pcpdata.depSeqNbr) && pcpdata.providerID && pcpdata.providerID.id && pcpdata.pcpError === false) {
            url = `${view_pcp}/${pcpdata.providerID.id}?partnerId=true&locale=${rally_connect_lang}`
          }
        })
    }
    else if (urlType === "pageUrl" || urlType === "default") {
      url = buttonLink
    }
    else if (urlType === "legacyUrl") {
      url = `${legacy_en_url}` + buttonLink
    }
    else if (urlType === "rallyUrl") {
      url = `${rally_url}` + buttonLink
    }
    else {
      url = `${find_provider}`
    }
    return url
  }
  // console.log(renderUrl(urlType))
  //  ****************** Banner url function **********************
  return (
    <>
      {loading && hasError ?
        <Loader Blocking /> :
        open && (title || (description && description.html)) ?
          <div className={type === 'info' ? styles.wrapInfo : type === 'alert' ? styles.wrapAlert :
            type === 'success' ? styles.wrapSuccess : type === 'error' ? styles.wrapError : styles.wrapInfo}>
            <div className={styles.container}>
              <div className={styles.flexWrap}>

                <div className={styles.mobileFlex}>
                  {image && image._path ? <img src={`${aem_content_url}${image._path}`} alt="" className={styles.icon} style={{ width: 34, marginRight: 20 }} /> :
                    null}
                  <div id="markup-margin" className={styles.mobileOnly}>
                    {title ? <h3> {title} </h3> : null}
                    {description && description.html ?
                      <Typography.Paragraph style={{ fontSize: 16 }}>
                        <Markup allowAttributes={true} content={description.html} />
                      </Typography.Paragraph> : null}
                  </div>
                </div>

                <div id="markup-margin" className={styles.desktopOnly} >
                  {title ? <h3>{title} </h3> : null}
                  {description && description.html ? <Typography.Paragraph className={styles.bannerBody}>
                    <Markup allowAttributes={true} content={description.html} />
                  </Typography.Paragraph> : null}
                </div>

              </div>
              <div style={{ marginBottom: 10, marginLeft: 'auto', whiteSpace: 'nowrap' }}>
                {type === 'info' || type === 'alert' ?
                  <>
                    {buttonTitle ? 
                    <Button
                      role="button"
                      buttonType={type === 'info' ? "primary-one" : type === 'alert' ? "primary-three" : "primary-one"}
                      href={renderUrl(urlType)}
                      id="see-resources-btn"
                      target={urlType === 'pageUrl' ? '_blank' : ''}
                      newTabWindow={urlType === 'pageUrl'}
                      onClick = {() => 
                        clickTrack(
                        buttonTitle, 
                        "main-body",
                        "button",
                        urlType === 'pageUrl' ? 'external' : 'internal',
                        domain + renderUrl(urlType)
                        )}
                    >
                      {buttonTitle}
                    </Button> :
                      <Link
                        herf={renderUrl(urlType)}
                        id="see-resources-btn"
                        style={{ marginLeft: 20 }}
                        target={urlType === 'pageUrl' ? '_blank' : ''}
                        onClick={()=>{
                          clickTrack(buttonTitle, "main-body", "text", urlType === 'pageUrl' ? 'external ' : 'internal', domain + renderUrl(urlType))
                       }}
                      >
                        {buttonTitle}
                      </Link>}
                  </>
                  : null}
                {closeLink ? (
                  <Button
                    onClick={() => setOpen(false)}
                    style={{
                      backgroundColor: 'transparent',
                      border: 'none',
                      color: '#5a5a5a'
                    }}
                  >
                    <span className="sr-only">close banner</span>
                    <span aria-hidden="true"> X </span>
                  </Button>
                ) : null}
              </div>
            </div>
          </div> : null}
    </>
  )
}

export default MedicaidBanner

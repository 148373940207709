const PrimaryCareFormatter = ({
   pcpResponse
} = {}) => {
   try {
      const { member } = pcpResponse
      let primarycareRecord;
      let primarycareList = [];
      let primarycareData;

      const response = member && member.filter(
         data => data.depSeqNbr
      )

      for (const data of response) {

         const depSeqNbr = data && data.depSeqNbr ? data.depSeqNbr : null
         const pcpFirstname = data && data.primaryCare && data.primaryCare[0] && data.primaryCare[0].primaryCareFirstName ?
            data.primaryCare[0].primaryCareFirstName : ""
         const pcpLastname = data && data.primaryCare && data.primaryCare[0] && data.primaryCare[0].primaryCareLastName ?
            data.primaryCare[0].primaryCareLastName : ""
         const pcpMiddlename = data && data.primaryCare && data.primaryCare[0] && data.primaryCare[0].primaryCareMiddleName ?
            data.primaryCare[0].primaryCareMiddleName : ""
         const pcpName = `${pcpFirstname} ${pcpMiddlename} ${pcpLastname}`
         //adding provider_id for change pcp sso
         const providerID =
            data && data.primaryCare && data.primaryCare[0] && data.primaryCare[0].providerID
               ? data.primaryCare[0].providerID
               : ""

         const pcpErrorCode = data && data.error && data.error.responseError &&
            data.error.responseError.map(errCode => errCode !== null ? errCode.errorCode : '')
         const pcpError = pcpErrorCode && pcpErrorCode !== null ? true : false

         primarycareRecord = {
            depSeqNbr,
            pcpName,
            providerID,
            pcpError,
         };

         primarycareList.push(primarycareRecord);
         primarycareData = primarycareList
      }

      return { primarycareData }
   } catch (error) {
      console.log(error)
   }
   return null
}

export default PrimaryCareFormatter

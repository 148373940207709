import Axios from "axios"
import info from "../../logger/info"
import error from "../../logger/error"

const UseContentFragment = (endpoint, callback) => {
   let responseCode = 'NULL'
   const startTime = new Date()
   Axios.get(endpoint)
      //Axios.get ('/API/dashboardfragments.json')
      .then(response => {
         const { data } = response
         if (response.status) {
            responseCode = response.status
         }
         //console.log(data)
         try {
            const getCardElements = itm => {
               const { elements } = itm
               return elements ? elements : null
            }
            const items = data[":items"]
            const {
               welcomemessage,
               viewprintidcard,
               plansummarycard,
               recentclaim,
               seedentalclaims,
               journeydescription,
               journeycards,
               actioncards
            } = items
            const welcomeTitle = welcomemessage && welcomemessage.text
            const journeyTitle = journeydescription && journeydescription.text
            const journeyCardElem = journeycards.items.map(data =>
               getCardElements(data)
            )
            const actionCardElem = actioncards.items.map(data =>
               getCardElements(data)
            )
            const viewidcardElem = viewprintidcard && viewprintidcard.elements
            const recentclaimElem = recentclaim && recentclaim.elements
            const seeclaimElem = seedentalclaims && seedentalclaims.elements
            const planSummary = plansummarycard && plansummarycard.elements
            info({ 'responseCode': responseCode, 'function': 'UseContentFragment', 'endpoint': endpoint, 'status': 'success', 'duration': Date.now() - startTime.getTime() })
            callback({
               hasError: false,
               journeyTitle,
               welcomeTitle,
               journeyCards: journeyCardElem,
               actionCards: actionCardElem,
               viewidcardElem,
               recentclaimElem,
               seeclaimElem,
               planSummary
            })
         } catch (err1) {
            //additional logging for errors
            console.log(err1)
            //const logObj = { 'function': 'UseContentFragment','endpoint': endpoint, 'status': 'error', 'error': err.name ? err.name : 'undefined error', 'duration': Date.now() - startTime.getTime() }
            //console.log(logObj)
            info({ 'function': 'UseContentFragment', 'status': 'error', 'endpoint': endpoint, 'error': err1.name ? err1.name : 'undefined error', 'duration': Date.now() - startTime.getTime() })
            error({ 'function': 'UseContentFragment', 'status': 'error', 'endpoint': endpoint, 'error': err1.name ? err1.name : 'undefined error', 'duration': Date.now() - startTime.getTime() })
            callback({ hasError: true })
         }
      }).catch(err => {
         if (err.response) {
            responseCode = err.response.status
         }
         //additional logging for errors
         const logObj = {
            responseCode: responseCode,
            function: "UseContentFragment",
            status: "error in catch block",
            endpoint: endpoint,
            error: err.name,
            duration: Date.now() - startTime.getTime()
         }
         info(logObj)
         error(logObj)
         //callback({ hasError: true })
      })
}

export default UseContentFragment
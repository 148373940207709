import React, { useState, useEffect } from 'react';
import { Accumulator } from '@uhc/pattern-library';
import { UtilityIcon, Typography, PopupTip } from '@uhc-tempo/components';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Error from '@material-ui/icons/Error';
// import StopCircle from '@material-ui/icons/RemoveCircleOutline';
import { Loader } from '@uhc/pattern-library';
import '../../../index.css';
import '../../../../../shared-components/reusableComponents/grid.css';
import ApiCallError from '../../../components/apiCallError';
import { Markup } from 'interweave';
import Constants from "../../../../../../constants/contentEndpoints";
import UseContentFragments from "../../../../../../hooks/contentfragment/useContentFragments"
import modalTrack from '../../../../../../hooks/modalTrack';
// /* eslint-disable */

const MedicalClaimsAccounts = props => {
  const pointer = 12;
  const {
    dependentSeqNbr,
    apiFailErrorMsg,
    apiErrorMessage,
    familybenefits,
    isPlandetailSet,
    medicalTab,
    indDedMet,
    indDedNotMet,
    familyDedMet,
    familyDedNotMet,
    oopMaxTooltip,
    oopRxMaxTooltip,
    pharmacyAndMedicalOopToolTip
  } = props;

  const dollarFormatter = v => v.toLocaleString('en-US', { style: 'currency', currency: 'USD' });

  const moopIndicator = JSON.parse(sessionStorage.getItem("isRxMoopIndicator"));
  
  const [hmoTierPlan, setHMOTierPlan] = useState({ isLoading: false });
  const hmoTierPlan_endpoint = Constants.HMOTIERPLAN_CONTENT;

  useEffect(() => {
    UseContentFragments(hmoTierPlan_endpoint, (response) => {
      try {
        if (response.APIError) {
          console.log("error");
        } else {
          const data = response.data.data;
          // console.log(data)
          if (data) {
            setHMOTierPlan({
              hmoMedicalPlan: data["hmoMedicalPlan"],
              tier1Deductible: data["tier1Deductible"],
              tier2Deductible: data["tier2Deductible"],
              tierIndDedMet: data["indDedMet"],
              tierIndDedNotMet: data["indDedNotMet"],
              tierFamilyDedMet: data["familyDedMet"],
              tierFamilyDedNotMet: data["familyDedNotMet"],
              outofPocMaxTitle: data["outofPocMaxTitle"],
              oopMaxTooltip: data["oopMaxTooltip"],
              isLoading: true,
            });
          }
        }
      } catch (error) {
        console.log("Error while calling HMO Tier plan content fragment");
      }
    });

    //creating aria-label for individual Tier-1 deductibles
    const indtier1deds = document.querySelectorAll('button[id^="popup-tip_ind-tier1-ded"]')
    if (indtier1deds) {
      for (const indtier1ded of indtier1deds) {
        indtier1ded.ariaLabel = 'More info on ' + document.querySelector('p[id^="ind-tier1-ded-title"]').innerHTML;
      }
    }

    //creating aria-label for individual Tier-2
    const indtier2deds = document.querySelectorAll('button[id^="popup-tip_ind-tier2-ded"]');
    if (indtier2deds) {
      for (const indtier2ded of indtier2deds) {
        indtier2ded.ariaLabel = 'More info on ' + document.querySelector('p[id^="ind-tier2-ded-title"]').innerHTML;
      }
    }

    //creating aria-label for individual out-of-pocket max
    const indoopmaxs = document.querySelectorAll('button[id^="popup-tip_ind-oop-max"]');
    if (indoopmaxs) {
      for (const indoopmax of indoopmaxs) {
        indoopmax.ariaLabel = 'More info on ' + document.querySelector('p[id^="ind-oop-max-title"]').innerHTML;
      }
    }

    //creating aria-label for family's Tier-1 deductibles
    const famtier1deds = document.querySelectorAll('button[id^="popup-tip_fmy-tier1-ded"]')
    if (famtier1deds) {
      for (const famtier1ded of famtier1deds) {
        famtier1ded.ariaLabel = "More info on Family Tier 1 Deductible";
      }
    }
    //creating aria-label for family's Tier 2/ Family's deductibles
    const famtier2deds = document.querySelectorAll('button[id^="popup-tip_fmy-tier2-ded"]')
    if (famtier2deds) {
      for (const famtier2ded of famtier2deds) {
        famtier2ded.ariaLabel = 'More info on ' + document.querySelector('p[id^="fmy-tier2-ded-title"]').innerHTML;
      }
    }
    //creating aria-label for family's out-of-pocket max
    const famoopmaxs = document.querySelectorAll('button[id^=popup-tip_fmy-oop-max]');
    if (famoopmaxs) {
      for (const famoopmax of famoopmaxs) {
        famoopmax.ariaLabel = "More info on Family Out-of-pocket Max";
      }
    }

  }, [hmoTierPlan_endpoint]);

  const { hmoMedicalPlan, tier1Deductible, tier2Deductible, tierIndDedMet, tierIndDedNotMet,
    tierFamilyDedMet, tierFamilyDedNotMet, outofPocMaxTitle } = hmoTierPlan;

    const isRxDualDeductible = sessionStorage.getItem('isRxDualDeductible')
    const isRxAccount = sessionStorage.getItem('isRxAccount');
    let hasPrescriptionTab = false
    if(isRxDualDeductible === "true" && isRxAccount === "true") {
      hasPrescriptionTab = true
    }

  return (
    <>
      {!isPlandetailSet ? (
        <>
          <Loader Blocking />
        </>
      ) : familybenefits ? (
        familybenefits.map(data => {
          const hasTier1Plan = data ?.medicalTier1AccountSummary ? !!Object.keys(data ?.medicalTier1AccountSummary).length : 0;

          if (data !== null && dependentSeqNbr === data.dependentSeqNbr) {
            return (
              <>
              <div>
                  {!hasTier1Plan ?
                    medicalTab && medicalTab.description &&
                    <Typography.Paragraph id="markup-margin">
                      <Markup allowAttributes={true} content={medicalTab.description} />
                    </Typography.Paragraph> : hmoMedicalPlan && hmoMedicalPlan.description ?
                      <Typography.Paragraph id="markup-margin">
                        <Markup allowAttributes={true} content={hmoMedicalPlan.description} />
                  </Typography.Paragraph> :
                      <Typography.Paragraph>
                        Please note that the dental and vision coverage for members through 
                        the age of 18 are included in these deductible and maximum out-of-pocket amounts.
                      </Typography.Paragraph>}
                  {hasTier1Plan && (
                    <>
                      {tier1Deductible && tier1Deductible.title &&
                        <Typography.H3 headingStyle={4} style={{ margin: '20px 0 10px 0' }}>
                          {tier1Deductible.title}
                        </Typography.H3>}
                      {tier1Deductible && tier1Deductible.description &&
                        <Typography.Paragraph id="markup-margin">
                          <Markup allowAttributes={true} content={tier1Deductible.description}></Markup>
                        </Typography.Paragraph>}
                    </>
                  )}
                </div>
                <div className="row" style={{ display: 'flex', padding: 0, margin: '-10px' }}>
                  <div className="col-xs-12 col-sm-12 flex-item">
                    <ul className="row">
                      {hasTier1Plan && (
                        <>
                          {/*------------Individual Tier 1 deductible ----------- */}
                          <li className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                            <Accumulator
                              progressBarProps={{
                                style: { height: '4px' }, title: `${dollarFormatter(
                                  data.medicalTier1AccountSummary &&
                                    data.medicalTier1AccountSummary.indDedSpentAmount
                                    ? Number(data.medicalTier1AccountSummary.indDedSpentAmount)
                                    : data.medicalTier1AccountSummary &&
                                      data.medicalTier1AccountSummary.indDeductibleInitialAmount
                                      ? Number(
                                        data.medicalTier1AccountSummary.indDeductibleInitialAmount
                                      )
                                      : '0.00'
                                )}  spent out of ${dollarFormatter(
                                  data.medicalTier1AccountSummary
                                    ? Number(data.medicalTier1AccountSummary.indDeductibleMaxAmount)
                                    : '0.00'
                                )}`
                              }}
                              progressBarBackgroundProps={{
                                style: { height: '4px' },
                              }}
                              min={0}
                              max={
                                data.medicalTier1AccountSummary
                                  ? Number(data.medicalTier1AccountSummary.indDeductibleMaxAmount)
                                  : '0.00'
                              }
                              value={
                                data.medicalTier1AccountSummary &&
                                  data.medicalTier1AccountSummary.indDedSpentAmount
                                  ? Number(data.medicalTier1AccountSummary.indDedSpentAmount)
                                  : data.medicalTier1AccountSummary &&
                                    data.medicalTier1AccountSummary.indDeductibleInitialAmount
                                    ? Number(
                                      data.medicalTier1AccountSummary.indDeductibleInitialAmount
                                    )
                                    : '0.00'
                              }
                              labelFormatter={dollarFormatter}
                            >
                              <Typography.Paragraph style={{ fontSize: 14, margin: 0 }} id="ind-tier1-ded-title">
                                {data.firstName}'s Tier 1 Deductible
                              </Typography.Paragraph>
                              <Accumulator.Title aria-hidden={true} style={{ marginTop: 8 }}>{`${dollarFormatter(
                                data.medicalTier1AccountSummary &&
                                  data.medicalTier1AccountSummary.indDedSpentAmount
                                  ? Number(data.medicalTier1AccountSummary.indDedSpentAmount)
                                  : data.medicalTier1AccountSummary &&
                                    data.medicalTier1AccountSummary.indDeductibleInitialAmount
                                    ? Number(
                                      data.medicalTier1AccountSummary.indDeductibleInitialAmount
                                    )
                                    : '0.00'
                              )}  Spent`}</Accumulator.Title>
                            </Accumulator>
                            {data.medicalTier1AccountSummary && (
                              <div className="meet-dedctible-container">
                                {Number(data.medicalTier1AccountSummary.indDedSpentAmount) >=
                                  Number(data.medicalTier1AccountSummary.indDeductibleMaxAmount) &&
                                  data.medicalTier1AccountSummary.indDeductibleMaxAmount !==
                                  '0.00' ? (
                                    <div className="expandable-section not-met">
                                      <UtilityIcon icon={CheckCircle} />
                                      {tierIndDedMet && tierIndDedMet.title ? (
                                        <span className="tds-header__h6 paragraph">
                                          <Markup content={tierIndDedMet.title}></Markup>
                                        </span>
                                      ) : (
                                          <span className="tds-header__h6 paragraph">
                                            You have met your individual tier 1 deductible
                                      </span>
                                        )}
                                        <div
                                        onClick = {()=> 
                                          modalTrack("You have met your individual tier 1 deductible")}>
                                      <PopupTip icon="question" placement="top" iconVariant="outlined" pointer={parseInt(12, 3)}
                                        contentId="ind-tier1-ded-1"
                                        aria-label={tierIndDedMet && tierIndDedMet.description ? tierIndDedMet.description ?.replace(/(<([^>]+)>)/gi, "") : "You may now owe your coinsurance. Coinsurance is your share of the cost for a covered service."}
                                          >
                                        {tierIndDedMet && tierIndDedMet.description ? (
                                          <Typography.Paragraph className="tds-margin-none">
                                            <Markup allowAttributes={true} content={tierIndDedMet.description}></Markup>
                                          </Typography.Paragraph>
                                        ) : (
                                            <Typography.Paragraph className="tds-margin-none">
                                              You may now owe your coinsurance. Coinsurance is your
                                              share of the cost for a covered service.
                                        </Typography.Paragraph>
                                          )}
                                      </PopupTip>
                                      </div>
                                    </div>
                                  ) :  (
                                      <div className="expandable-section not-met">
                                        <UtilityIcon icon={Error} className="blue-utility" />
                                        {tierIndDedNotMet && tierIndDedNotMet.title ? (
                                          <span className="tds-header__h6 paragraph">
                                            <Markup content={tierIndDedNotMet.title}></Markup>
                                          </span>
                                        ) : (
                                            <span className="tds-header__h6 paragraph">
                                              You have not met your individual tier 1 deductible
                                      </span>
                                          )}
                                          <div
                                           onClick = {()=> 
                                            modalTrack("You have not met your individual tier 1 deductible")}>
                                        <PopupTip
                                          contentId="ind-tier1-ded-2"
                                          icon="question"
                                          placement="top"
                                          iconVariant="outlined"
                                          pointer={parseInt(pointer, 3)}
                                          aria-label={tierIndDedNotMet && tierIndDedNotMet.description ? tierIndDedNotMet.description ?.replace(/(<([^>]+)>)/gi, "") : "This means you have deductibles left to pay for covered Network services for this plan year. Then, the plan will start to pay its share of the costs."}
                                        >
                                          {tierIndDedNotMet && tierIndDedNotMet.description ? (
                                            <Typography.Paragraph className="tds-margin-none">
                                              <Markup allowAttributes={true} content={tierIndDedNotMet.description}></Markup>
                                            </Typography.Paragraph>
                                          ) : (
                                              <Typography.Paragraph className="tds-margin-none">
                                                This means you have deductibles left to pay for covered
                                                Network services for this plan year. Then, the plan will
                                                start to pay its share of the costs.
                                        </Typography.Paragraph>
                                            )}
                                        </PopupTip>
                                        </div>
                                      </div>
                                    ) }
                              </div>
                            )}
                          </li>

                          {/*----------Family Tier 1 Deductible ------- */}
                          <li className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                            <Accumulator
                              progressBarProps={{
                                style: { height: '4px' }, title: `${dollarFormatter(
                                  data.medicalTier1AccountSummary &&
                                    data.medicalTier1AccountSummary.familyDedSpentAmount
                                    ? Number(data.medicalTier1AccountSummary.familyDedSpentAmount)
                                    : data.medicalTier1AccountSummary &&
                                      data.medicalTier1AccountSummary.familyDeductibleInitialAmount
                                      ? Number(
                                        data.medicalTier1AccountSummary.familyDeductibleInitialAmount
                                      )
                                      : '0.00'
                                )} spent out of ${dollarFormatter(
                                  data.medicalTier1AccountSummary
                                    ? Number(
                                      data.medicalTier1AccountSummary.familyDeductibleMaxAmount
                                    )
                                    : '0.00'
                                )}`
                              }}
                              progressBarBackgroundProps={{
                                style: { height: '4px' },
                              }}
                              min={0}
                              max={
                                data.medicalTier1AccountSummary
                                  ? Number(
                                    data.medicalTier1AccountSummary.familyDeductibleMaxAmount
                                  )
                                  : '0.00'
                              }
                              value={
                                data.medicalTier1AccountSummary &&
                                  data.medicalTier1AccountSummary.familyDedSpentAmount
                                  ? Number(data.medicalTier1AccountSummary.familyDedSpentAmount)
                                  : data.medicalTier1AccountSummary &&
                                    data.medicalTier1AccountSummary.familyDeductibleInitialAmount
                                    ? Number(
                                      data.medicalTier1AccountSummary.familyDeductibleInitialAmount
                                    )
                                    : '0.00'
                              }
                              labelFormatter={dollarFormatter}
                            >
                              <Typography.Paragraph style={{ fontSize: 14, margin: 0 }}>
                                Family Tier 1 Deductible
                              </Typography.Paragraph>
                              <Accumulator.Title aria-hidden={true} style={{ marginTop: 8 }}>{`${dollarFormatter(
                                data.medicalTier1AccountSummary &&
                                  data.medicalTier1AccountSummary.familyDedSpentAmount
                                  ? Number(data.medicalTier1AccountSummary.familyDedSpentAmount)
                                  : data.medicalTier1AccountSummary &&
                                    data.medicalTier1AccountSummary.familyDeductibleInitialAmount
                                    ? Number(
                                      data.medicalTier1AccountSummary.familyDeductibleInitialAmount
                                    )
                                    : '0.00'
                              )} Spent`}</Accumulator.Title>
                            </Accumulator>
                            {data.medicalTier1AccountSummary && (
                              <div className="meet-dedctible-container">
                                {Number(data.medicalTier1AccountSummary.familyDedSpentAmount) >=
                                  Number(
                                    data.medicalTier1AccountSummary.familyDeductibleMaxAmount
                                  ) &&
                                  data.medicalTier1AccountSummary.familyDeductibleMaxAmount !==
                                  '0.00' ? (
                                    <div className="expandable-section not-met">
                                      <UtilityIcon icon={CheckCircle} />
                                      {tierFamilyDedMet && tierFamilyDedMet.title ? (
                                        <span className="tds-header__h6 paragraph">
                                          <Markup content={tierFamilyDedMet.title}></Markup>
                                        </span>
                                      ) : (
                                          <span className="tds-header__h6 paragraph">
                                            You have met your family tier 1 deductible
                                      </span>
                                        )}
                                        <div
                                        onClick = {()=> 
                                          modalTrack("You have met your family tier 1 deductible")}>
                                      <PopupTip
                                        contentId="fmy-tier1-ded-1"
                                        icon="question"
                                        placement="top"
                                        iconVariant="outlined"
                                        pointer={parseInt(pointer, 3)}
                                        aria-label={tierFamilyDedMet && tierFamilyDedMet.description ? tierFamilyDedMet.description ?.replace(/(<([^>]+)>)/gi, "") : "You may now owe your coinsurance. Coinsurance is your share of the cost for a covered service" }
                                      >
                                        {tierFamilyDedMet && tierFamilyDedMet.description ? (
                                          <Typography.Paragraph className="tds-margin-none">
                                            <Markup allowAttributes={true} content={tierFamilyDedMet.description}></Markup>
                                          </Typography.Paragraph>
                                        ) : (
                                            <Typography.Paragraph className="tds-margin-none">
                                              You may now owe your coinsurance. Coinsurance is your
                                              share of the cost for a covered service.
                                        </Typography.Paragraph>
                                          )}
                                      </PopupTip>
                                    </div>
                                    </div>
                                  ) :  (
                                      <div className="expandable-section not-met">
                                        <UtilityIcon icon={Error} className="blue-utility" />
                                        {tierFamilyDedNotMet && tierFamilyDedNotMet.title ? (
                                          <span className="tds-header__h6 paragraph">
                                            <Markup content={tierFamilyDedNotMet.title}></Markup>
                                          </span>
                                        ) : (
                                            <span className="tds-header__h6 paragraph">
                                              You have not met your family tier 1 deductible
                                            </span>
                                          )}
                                          <div
                                          onClick = {()=> 
                                            modalTrack("You have not met your family tier 1 deductible")}>
                                        <PopupTip
                                          contentId="fmy-tier1-ded-2"
                                          icon="question"
                                          placement="top"
                                          iconVariant="outlined"
                                          pointer={parseInt(pointer, 3)}
                                          aria-label={tierFamilyDedNotMet && tierFamilyDedNotMet.description ? tierFamilyDedNotMet.description?.replace(/(<([^>]+)>)/gi, "") : "This means you have deductibles left to pay for covered Network services for this plan year. Then, the plan will start to pay its share of the costs."}
                                        >
                                          {tierFamilyDedNotMet && tierFamilyDedNotMet.description ? (
                                            <Typography.Paragraph className="tds-margin-none">
                                              <Markup allowAttributes={true} content={tierFamilyDedNotMet.description}></Markup>
                                            </Typography.Paragraph>
                                          ) : (
                                              <Typography.Paragraph className="tds-margin-none">
                                                This means you have deductibles left to pay for covered
                                                Network services for this plan year. Then, the plan will
                                                start to pay its share of the costs.
                                        </Typography.Paragraph>
                                            )}
                                        </PopupTip>
                                      </div>
                                      </div>
                                    )}
                              </div>
                            )}
                          </li>

                          <div style={{ padding: '0 20px' }}>
                            {tier2Deductible && tier2Deductible.title &&
                              < Typography.H3 headingStyle={4} style={{ margin: '20px 0 10px 0' }}>
                                {tier2Deductible.title}
                              </Typography.H3>}
                            {tier2Deductible && tier2Deductible.description &&
                              <Typography.Paragraph id="markup-margin">
                                <Markup allowAttributes={true} content={tier2Deductible.description}></Markup>
                              </Typography.Paragraph>}
                          </div>
                        </>
                      )}

                      {/*------------Individual deductible ----------- */}
                      <li className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                        <Accumulator
                          progressBarProps={{
                            style: { height: '4px' }, title: `${dollarFormatter(
                              data.medicalAccountSummary &&
                                data.medicalAccountSummary.indDedSpentAmount
                                ? Number(data.medicalAccountSummary.indDedSpentAmount)
                                : data.medicalAccountSummary &&
                                  data.medicalAccountSummary.indDeductibleInitialAmount
                                  ? Number(data.medicalAccountSummary.indDeductibleInitialAmount)
                                  : '0.00'
                            )}  spent out of ${dollarFormatter(
                              data.medicalAccountSummary
                                ? Number(data.medicalAccountSummary.indDeductibleMaxAmount)
                                : '0.00'
                            )}`
                          }}
                          progressBarBackgroundProps={{
                            style: { height: '4px' },
                          }}
                          min={0}
                          max={
                            data.medicalAccountSummary
                              ? Number(data.medicalAccountSummary.indDeductibleMaxAmount)
                              : '0.00'
                          }
                          value={
                            data.medicalAccountSummary &&
                              data.medicalAccountSummary.indDedSpentAmount
                              ? Number(data.medicalAccountSummary.indDedSpentAmount)
                              : data.medicalAccountSummary &&
                                data.medicalAccountSummary.indDeductibleInitialAmount
                                ? Number(data.medicalAccountSummary.indDeductibleInitialAmount)
                                : '0.00'
                          }
                          labelFormatter={dollarFormatter}
                        >
                          <Typography.Paragraph style={{ fontSize: 14, margin: 0 }} id="ind-tier2-ded-title">
                            {`${data.firstName}'s ${hasTier1Plan ? 'Tier 2 ' : ''}Deductible`}
                          </Typography.Paragraph>
                          <Accumulator.Title aria-hidden={true} style={{ marginTop: 8 }}>{`${dollarFormatter(
                            data.medicalAccountSummary &&
                              data.medicalAccountSummary.indDedSpentAmount
                              ? Number(data.medicalAccountSummary.indDedSpentAmount)
                              : data.medicalAccountSummary &&
                                data.medicalAccountSummary.indDeductibleInitialAmount
                                ? Number(data.medicalAccountSummary.indDeductibleInitialAmount)
                                : '0.00'
                          )}  Spent`}</Accumulator.Title>
                        </Accumulator>
                        {data.medicalAccountSummary && (
                          <div className="meet-dedctible-container">
                            {Number(data.medicalAccountSummary.indDedSpentAmount) >=
                              Number(data.medicalAccountSummary.indDeductibleMaxAmount) &&
                              data.medicalAccountSummary.indDeductibleMaxAmount !== '0.00' ? (
                                <div className="expandable-section not-met">
                                  <UtilityIcon icon={CheckCircle} />
                                  <span className="tds-header__h6 paragraph">
                                    {`You have met your individual ${
                                      hasTier1Plan ? 'tier 2 ' : ''
                                      }deductible`}
                                  </span>
                                  <div
                                  onClick = {()=> 
                                    modalTrack(`You have met your individual ${
                                      hasTier1Plan ? 'tier 2 ' : ''
                                      }deductible`)}>
                                  <PopupTip icon="question" placement="top" iconVariant="outlined" pointer={parseInt(12, 3)}
                                    contentId="ind-tier2-ded-1"
                                    aria-label={!hasTier1Plan && hasPrescriptionTab && indDedMet && indDedMet.description ? indDedMet.description?.replace(/(<([^>]+)>)/gi, "") : 
                                    !hasTier1Plan && !hasPrescriptionTab ? 'This is the amount you must pay each year for your covered medical services from in-network providers and medications filled at an in-network pharmacy that are subject to the Annual Deductible before your plan pays its share.'
                                    :'You may now owe your coinsurance. Coinsurance is your share of the cost for a covered service.'} >
                                    {!hasTier1Plan && hasPrescriptionTab && indDedMet && indDedMet.description ? (
                                      <Typography.Paragraph className="tds-margin-none">
                                        <Markup allowAttributes={true} content={indDedMet.description}></Markup>
                                      </Typography.Paragraph>
                                    ) 
                                    : !hasPrescriptionTab && !hasTier1Plan ? (
                                      <Typography.Paragraph className="tds-margin-none">
                                          This is the amount you must pay each year for your covered medical services 
                                          from in-network providers and medications filled at an in-network pharmacy that are 
                                          subject to the Annual Deductible before your plan pays its share.
                                    </Typography.Paragraph>
                                    ) : 
                                    (
                                        <Typography.Paragraph className="tds-margin-none">
                                          You may now owe your coinsurance. Coinsurance is your share of
                                          the cost for a covered service.
                                    </Typography.Paragraph>
                                      )}
                                  </PopupTip>
                                </div>
                                </div>
                              ) :  (
                                  <div className="expandable-section not-met">
                                    <UtilityIcon icon={Error} className="blue-utility" />
                                    <span className="tds-header__h6 paragraph">
                                      {`You have not met your individual ${
                                        hasTier1Plan ? 'tier 2 ' : ''
                                        }deductible`}
                                    </span>
                                    <div
                                    onClick = {()=> 
                                      modalTrack(`You have not met your individual ${
                                        hasTier1Plan ? 'tier 2 ' : ''
                                        }deductible`)}>
                                    <PopupTip
                                      contentId="ind-tier2-ded-2"
                                      icon="question"
                                      placement="top"
                                      iconVariant="outlined"
                                      pointer={parseInt(pointer, 3)}
                                      aria-label={!hasTier1Plan && hasPrescriptionTab && indDedNotMet && indDedNotMet.description ? indDedNotMet.description?.replace(/(<([^>]+)>)/gi, "") : 
                                    !hasTier1Plan && !hasPrescriptionTab ? 'This is the amount you must pay each year for your covered medical services from in-network providers and medications filled at an in-network pharmacy that are subject to the Annual Deductible before your plan pays its share.'
                                    :'This means you have deductibles left to pay for covered Network services for this plan year. Then, the plan will start to pay its share of the costs.'} >
                                    {!hasTier1Plan && hasPrescriptionTab && indDedNotMet && indDedNotMet.description ? (
                                        <Typography.Paragraph className="tds-margin-none">
                                          <Markup allowAttributes={true} content={indDedNotMet.description}></Markup>
                                        </Typography.Paragraph>
                                    ) 
                                    : !hasPrescriptionTab && !hasTier1Plan ? (
                                      <Typography.Paragraph className="tds-margin-none">
                                          This is the amount you must pay each year for your covered medical services 
                                          from in-network providers and medications filled at an in-network pharmacy that are 
                                          subject to the Annual Deductible before your plan pays its share.
                                    </Typography.Paragraph>
                                    ) : 
                                    (
                                          <Typography.Paragraph className="tds-margin-none">
                                            This means you have deductibles left to pay for covered
                                            Network services for this plan year. Then, the plan will start
                                            to pay its share of the costs.
                                            </Typography.Paragraph> 
                                          )}
                                    </PopupTip>
                                  </div>
                                  </div>
                                ) }
                          </div>
                        )}
                      </li>
                      {/*----------Family Deductible ------- */}
                      <li className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                        <Accumulator
                          progressBarProps={{
                            style: { height: '4px' }, title: `${dollarFormatter(
                              data.medicalAccountSummary &&
                                data.medicalAccountSummary.familyDedSpentAmount
                                ? Number(data.medicalAccountSummary.familyDedSpentAmount)
                                : data.medicalAccountSummary &&
                                  data.medicalAccountSummary.familyDeductibleInitialAmount
                                  ? Number(data.medicalAccountSummary.familyDeductibleInitialAmount)
                                  : '0.00'
                            )} spent out of ${dollarFormatter(
                              data.medicalAccountSummary
                                ? Number(data.medicalAccountSummary.familyDeductibleMaxAmount)
                                : '0.00'
                            )}`
                          }}
                          progressBarBackgroundProps={{
                            style: { height: '4px' },
                          }}
                          min={0}
                          max={
                            data.medicalAccountSummary
                              ? Number(data.medicalAccountSummary.familyDeductibleMaxAmount)
                              : '0.00'
                          }
                          value={
                            data.medicalAccountSummary &&
                              data.medicalAccountSummary.familyDedSpentAmount
                              ? Number(data.medicalAccountSummary.familyDedSpentAmount)
                              : data.medicalAccountSummary &&
                                data.medicalAccountSummary.familyDeductibleInitialAmount
                                ? Number(data.medicalAccountSummary.familyDeductibleInitialAmount)
                                : '0.00'
                          }
                          labelFormatter={dollarFormatter}
                        >
                          <Typography.Paragraph style={{ fontSize: 14, margin: 0 }} id="fmy-tier2-ded-title">
                            {`Family ${hasTier1Plan ? 'Tier 2 ' : ''}Deductible`}
                          </Typography.Paragraph>
                          <Accumulator.Title aria-hidden={true} style={{ marginTop: 8 }}>{`${dollarFormatter(
                            data.medicalAccountSummary &&
                              data.medicalAccountSummary.familyDedSpentAmount
                              ? Number(data.medicalAccountSummary.familyDedSpentAmount)
                              : data.medicalAccountSummary &&
                                data.medicalAccountSummary.familyDeductibleInitialAmount
                                ? Number(data.medicalAccountSummary.familyDeductibleInitialAmount)
                                : '0.00'
                          )} Spent`}</Accumulator.Title>
                        </Accumulator>
                        {data.medicalAccountSummary && (
                          <div className="meet-dedctible-container">
                            {Number(data.medicalAccountSummary.familyDedSpentAmount) >=
                              Number(data.medicalAccountSummary.familyDeductibleMaxAmount) &&
                              data.medicalAccountSummary.familyDeductibleMaxAmount !== '0.00' ? (
                                <div className="expandable-section not-met">
                                  <UtilityIcon icon={CheckCircle} />
                                  <span className="tds-header__h6 paragraph">
                                    {`You have met your family ${
                                      hasTier1Plan ? 'tier 2 ' : ''
                                      }deductible`}
                                  </span>
                                  <div
                                  onClick = {()=> 
                                      modalTrack(`You have met your family ${
                                        hasTier1Plan ? 'tier 2 ' : ''
                                        }deductible`)}>
                                  <PopupTip
                                    contentId="fmy-tier2-ded-1"
                                    icon="question"
                                    placement="top"
                                    iconVariant="outlined"
                                    pointer={parseInt(pointer, 3)}
                                    aria-label={!hasTier1Plan && hasPrescriptionTab && familyDedMet && familyDedMet.description ? familyDedMet.description?.replace(/(<([^>]+)>)/gi, "") : 
                                    !hasTier1Plan && !hasPrescriptionTab ? 'This is the amount you must pay each year for your covered medical services from in-network providers and medications filled at an in-network pharmacy that are subject to the Annual Deductible before your plan pays its share.'
                                    :'You may now owe your coinsurance. Coinsurance is your share of the cost for a covered service.'} >
                                    {!hasTier1Plan && hasPrescriptionTab && familyDedMet && familyDedMet.description ? (
                                      <Typography.Paragraph className="tds-margin-none">
                                        <Markup allowAttributes={true} content={familyDedMet.description}></Markup>
                                      </Typography.Paragraph>
                                    ) 
                                    : !hasPrescriptionTab && !hasTier1Plan ? (
                                      <Typography.Paragraph className="tds-margin-none">
                                          This is the amount you must pay each year for your covered medical services 
                                          from in-network providers and medications filled at an in-network pharmacy that are 
                                          subject to the Annual Deductible before your plan pays its share.
                                    </Typography.Paragraph>
                                    ) : (
                                        <Typography.Paragraph className="tds-margin-none">
                                          You may now owe your coinsurance. Coinsurance is your share of
                                          the cost for a covered service.
                                    </Typography.Paragraph>
                                      )}
                                  </PopupTip>
                                </div>
                                </div>
                              ) : (
                                  <div className="expandable-section not-met">
                                    <UtilityIcon icon={Error} className="blue-utility" />
                                    <span className="tds-header__h6 paragraph">
                                      {`You have not met your family ${
                                        hasTier1Plan ? 'tier 2 ' : ''
                                        }deductible`}
                                    </span>
                                    <div 
                                     onClick = {()=> 
                                      modalTrack(`You have not met your family ${
                                        hasTier1Plan ? 'tier 2 ' : ''
                                        }deductible`)}>
                                    <PopupTip
                                      contentId="fmy-tier2-ded-2"
                                      icon="question"
                                      placement="top"
                                      iconVariant="outlined"
                                      pointer={parseInt(pointer, 3)}
                                      aria-label={!hasTier1Plan && hasPrescriptionTab && familyDedNotMet && familyDedNotMet.description ? familyDedNotMet.description?.replace(/(<([^>]+)>)/gi, "") : 
                                    !hasTier1Plan && !hasPrescriptionTab ? 'This is the amount you must pay each year for your covered medical services from in-network providers and medications filled at an in-network pharmacy that are subject to the Annual Deductible before your plan pays its share.'
                                    :'This means you have deductibles left to pay for covered Network services for this plan year. Then, the plan will start to pay its share of the costs.'} >
                                      {!hasTier1Plan && hasPrescriptionTab && familyDedNotMet && familyDedNotMet.description ? (
                                        <Typography.Paragraph className="tds-margin-none">
                                          <Markup allowAttributes={true} content={familyDedNotMet.description}></Markup>
                                        </Typography.Paragraph>
                                    ) 
                                    : !hasPrescriptionTab && !hasTier1Plan ? (
                                      <Typography.Paragraph className="tds-margin-none">
                                          This is the amount you must pay each year for your covered medical services 
                                          from in-network providers and medications filled at an in-network pharmacy that are 
                                          subject to the Annual Deductible before your plan pays its share.
                                    </Typography.Paragraph>) 
                                    : (
                                          <Typography.Paragraph className="tds-margin-none">
                                            This means you have deductibles left to pay for covered
                                            Network services for this plan year. Then, the plan will start
                                            to pay its share of the costs.
                                            </Typography.Paragraph>
                                        )}
                                    </PopupTip>
                                  </div>
                                  </div>
                                ) }
                          </div>
                        )}
                      </li>

                      {/* Out of pocket max */}
                      <div style={{ padding: '0 20px', width: '100%' }}>
                        {outofPocMaxTitle && <Typography.H3 headingStyle={4} style={{ margin: '20px 0 10px 0' }}>
                          {outofPocMaxTitle.title}
                        </Typography.H3>}
                      </div>
                      {/*------------Individual Out-of-pocket Max ------- */}
                      <li className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                        <Accumulator
                          progressBarProps={{
                            style: { height: '4px' }, title: `${dollarFormatter(
                              data.medicalAccountSummary &&
                                data.medicalAccountSummary.indOopSpentAmount
                                ? Number(data.medicalAccountSummary.indOopSpentAmount)
                                : data.medicalAccountSummary &&
                                  data.medicalAccountSummary.individualOopInitialAmount
                                  ? Number(data.medicalAccountSummary.individualOopInitialAmount)
                                  : '0.00'
                            )} spent out of ${dollarFormatter(
                              data.medicalAccountSummary
                                ? Number(data.medicalAccountSummary.individualOopMaxAmount)
                                : '0.00'
                            )}`
                          }}
                          progressBarBackgroundProps={{
                            style: { height: '4px' },
                          }}
                          min={0}
                          max={
                            data.medicalAccountSummary
                              ? Number(data.medicalAccountSummary.individualOopMaxAmount)
                              : '0.00'
                          }
                          value={
                            data.medicalAccountSummary &&
                              data.medicalAccountSummary.indOopSpentAmount
                              ? Number(data.medicalAccountSummary.indOopSpentAmount)
                              : data.medicalAccountSummary &&
                                data.medicalAccountSummary.individualOopInitialAmount
                                ? Number(data.medicalAccountSummary.individualOopInitialAmount)
                                : '0.00'
                          }
                          labelFormatter={dollarFormatter}
                        >
                          <Typography.Paragraph style={{ fontSize: 14, margin: 0 }} id="ind-oop-max-title">
                            {`${data.firstName}'s Out-of-pocket Max`}
                          </Typography.Paragraph>
                          <Accumulator.Title aria-hidden={true} style={{ marginTop: 8 }}>{`${dollarFormatter(
                            data.medicalAccountSummary &&
                              data.medicalAccountSummary.indOopSpentAmount
                              ? Number(data.medicalAccountSummary.indOopSpentAmount)
                              : data.medicalAccountSummary &&
                                data.medicalAccountSummary.individualOopInitialAmount
                                ? Number(data.medicalAccountSummary.individualOopInitialAmount)
                                : '0.00'
                          )} Spent`}</Accumulator.Title>
                        </Accumulator>
                        {data.medicalAccountSummary && (
                          <div className="meet-dedctible-container">
                            {Number(data.medicalAccountSummary.indOopSpentAmount) >=
                              Number(data.medicalAccountSummary.individualOopMaxAmount) &&
                              data.medicalAccountSummary.individualOopMaxAmount !== '0.00' ? (
                                <div className="expandable-section not-met">
                                  <UtilityIcon icon={CheckCircle} />
                                  <span className="tds-header__h6 paragraph">
                                    You have met your individual out-of-pocket max
                                </span>
                                <div
                                onClick = {()=> 
                                  modalTrack("You have met your individual out-of-pocket max")}>
                                  <PopupTip icon="question" placement="top"
                                    contentId="ind-oop-max-1"
                                    aria-label={oopMaxTooltip && oopMaxTooltip.description ? oopMaxTooltip.description?.replace(/(<([^>]+)>)/gi, "") : "This is the most you pay for covered services from network providers during the year. Your deductible, copays and coinsurance for covered network services count toward this amount. After you reach your out-of-pocket maximum, the plan will pay 100% of your costs for the rest of the year. Plan premiums and costs for certain services do not count toward your out-of-pocket maximum amounts."}
                                  >
                                    {oopMaxTooltip && oopMaxTooltip.description && hasPrescriptionTab && moopIndicator === true ? (
                                      <Typography.Paragraph className="tds-margin-none">
                                        <Markup allowAttributes={true} content={oopMaxTooltip.description}></Markup>
                                      </Typography.Paragraph>
                                    ) : ( pharmacyAndMedicalOopToolTip && pharmacyAndMedicalOopToolTip.description ?
                                        <Typography.Paragraph className="tds-margin-none">
                                        <Markup allowAttributes={true} content={pharmacyAndMedicalOopToolTip.description}></Markup>
                                    </Typography.Paragraph> :'This is the most you pay for covered services from in-network providers during the year. Your deductible, copays and coinsurance for covered in-network services count toward this amount. Once you reach your maximum out-of-pocket, your plan will pay 100% of your costs for covered services for the rest of the year. Plan premiums and costs for certain services do not count toward your maximum out-of-pocket amounts.'
                                      )}
                                  </PopupTip>
                                </div>
                                </div>
                              ) :  (
                                  <div className="expandable-section not-met">
                                    <UtilityIcon icon={Error} className="blue-utility" />
                                    <span className="tds-header__h6 paragraph">
                                      You have not met your individual out-of-pocket max
                                </span>
                                    <div 
                                    onClick = {()=> 
                                      modalTrack("You have not met your individual out-of-pocket max")}>
                                    <PopupTip
                                      contentId="ind-oop-max-2"
                                      icon="question"
                                      placement="top"
                                      iconVariant="outlined"
                                      pointer={parseInt(pointer, 3)}
                                      aria-label={oopMaxTooltip && oopMaxTooltip.description ? oopMaxTooltip.description ?.replace(/(<([^>]+)>)/gi, "") : "This is the most you pay for covered services from network providers during the year. Your deductible, copays and coinsurance for covered network services count toward this amount. After you reach your out-of-pocket maximum, the plan will pay 100% of your costs for the rest of the year. Plan premiums and costs for certain services do not count toward your out-of-pocket maximum amounts." }
                                    >
                                      {oopMaxTooltip && oopMaxTooltip.description && hasPrescriptionTab && moopIndicator === true? (
                                        <Typography.Paragraph className="tds-margin-none">
                                          <Markup allowAttributes={true} content={oopRxMaxTooltip.description} />
                                        </Typography.Paragraph>
                                      ) : (pharmacyAndMedicalOopToolTip && pharmacyAndMedicalOopToolTip.description ?
                                          <Typography.Paragraph className="tds-margin-none">
                                          <Markup allowAttributes={true} content={pharmacyAndMedicalOopToolTip.description}></Markup>
                                    </Typography.Paragraph>
                                        :'This is the most you pay for covered services from in-network providers during the year. Your deductible, copays and coinsurance for covered in-network services count toward this amount. Once you reach your maximum out-of-pocket, your plan will pay 100% of your costs for covered services for the rest of the year. Plan premiums and costs for certain services do not count toward your maximum out-of-pocket amounts.')}
                                    </PopupTip>
                                  </div>
                                  </div>
                                ) }
                          </div>
                        )}
                      </li>
                      {/*------------Family Out-of-pocket Max ------- */}
                      <li className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                        <Accumulator
                          progressBarProps={{
                            style: { height: '4px' }, title: `${dollarFormatter(
                              data.medicalAccountSummary &&
                                data.medicalAccountSummary.familyOopSpentAmount
                                ? Number(data.medicalAccountSummary.familyOopSpentAmount)
                                : data.medicalAccountSummary &&
                                  data.medicalAccountSummary.familyOopInitialAmount
                                  ? Number(data.medicalAccountSummary.familyOopInitialAmount)
                                  : '0.00'
                            )} spent out of ${dollarFormatter(
                              data.medicalAccountSummary
                                ? Number(data.medicalAccountSummary.familyOopMaxAmount)
                                : '0.00'
                            )}`
                          }}
                          progressBarBackgroundProps={{
                            style: { height: '4px' },
                          }}
                          min={0}
                          max={
                            data.medicalAccountSummary
                              ? Number(data.medicalAccountSummary.familyOopMaxAmount)
                              : '0.00'
                          }
                          value={
                            data.medicalAccountSummary &&
                              data.medicalAccountSummary.familyOopSpentAmount
                              ? Number(data.medicalAccountSummary.familyOopSpentAmount)
                              : data.medicalAccountSummary &&
                                data.medicalAccountSummary.familyOopInitialAmount
                                ? Number(data.medicalAccountSummary.familyOopInitialAmount)
                                : '0.00'
                          }
                          labelFormatter={dollarFormatter}
                        >
                          <Typography.Paragraph style={{ fontSize: 14, margin: 0 }}>
                            {`Family Out-of-pocket Max`}
                          </Typography.Paragraph>
                          <Accumulator.Title aria-hidden={true} style={{ marginTop: 8 }}>{`${dollarFormatter(
                            data.medicalAccountSummary &&
                              data.medicalAccountSummary.familyOopSpentAmount
                              ? Number(data.medicalAccountSummary.familyOopSpentAmount)
                              : data.medicalAccountSummary &&
                                data.medicalAccountSummary.familyOopInitialAmount
                                ? Number(data.medicalAccountSummary.familyOopInitialAmount)
                                : '0.00'
                          )} Spent`}</Accumulator.Title>
                        </Accumulator>
                        {data.medicalAccountSummary && (
                          <div className="meet-dedctible-container">
                            {Number(data.medicalAccountSummary.familyOopSpentAmount) >=
                              Number(data.medicalAccountSummary.familyOopMaxAmount) &&
                              data.medicalAccountSummary.familyOopMaxAmount !== '0.00' ? (
                                <div className="expandable-section not-met">
                                  <UtilityIcon icon={CheckCircle} />
                                  <span className="tds-header__h6 paragraph">
                                    You have met your family out-of-pocket max
                                </span>
                                <div
                                onClick = {()=> 
                                  modalTrack("You have met your family out-of-pocket max")}>
                                  <PopupTip
                                    contentId="fmy-oop-max-1"
                                    icon="question"
                                    placement="top"
                                    iconVariant="outlined"
                                    pointer={parseInt(pointer, 3)}
                                    aria-label={oopMaxTooltip && oopMaxTooltip.description ? oopMaxTooltip.description ?.replace(/(<([^>]+)>)/gi, "") : "This is the most you pay for covered services from network providers during the year. Your deductible, copays and coinsurance for covered network services count toward this amount. After you reach your out-of-pocket maximum, the plan will pay 100% of your costs for the rest of the year. Plan premiums and costs for certain services do not count toward your out-of-pocket maximum amounts."}
                                  >
                                    {oopMaxTooltip && oopMaxTooltip.description && hasPrescriptionTab && moopIndicator === true ? (
                                      <Typography.Paragraph className="tds-margin-none">
                                        <Markup allowAttributes={true} content={oopMaxTooltip.description}></Markup>
                                      </Typography.Paragraph>
                                    ) : (pharmacyAndMedicalOopToolTip && pharmacyAndMedicalOopToolTip.description ?
                                        <Typography.Paragraph className="tds-margin-none">
                                           <Markup allowAttributes={true} content={pharmacyAndMedicalOopToolTip.description}></Markup>
                                    </Typography.Paragraph>
                                      :'This is the most you pay for covered services from in-network providers during the year. Your deductible, copays and coinsurance for covered in-network services count toward this amount. Once you reach your maximum out-of-pocket, your plan will pay 100% of your costs for covered services for the rest of the year. Plan premiums and costs for certain services do not count toward your maximum out-of-pocket amounts.')}
                                  </PopupTip>
                                </div>
                                </div>
                              ) :  (
                                  <div className="expandable-section not-met">
                                    <UtilityIcon icon={Error} className="blue-utility" />
                                    <span className="tds-header__h6 paragraph">
                                      You have not met your family out-of-pocket max
                                </span>
                                <div
                                onClick = {()=> 
                                  modalTrack("You have not met your family out-of-pocket max")}>
                                    <PopupTip
                                      contentId="fmy-oop-max-2"
                                      icon="question"
                                      placement="top"
                                      iconVariant="outlined"
                                      pointer={parseInt(pointer, 3)}
                                      aria-label={oopMaxTooltip && oopMaxTooltip.description ? oopMaxTooltip.description ?.replace(/(<([^>]+)>)/gi, "") : "This is the most you pay for covered services from network providers during the year. Your deductible, copays and coinsurance for covered network services count toward this amount. After you reach your out-of-pocket maximum, the plan will pay 100% of your costs for the rest of the year. Plan premiums and costs for certain services do not count toward your out-of-pocket maximum amounts."}
                                    >
                                      {oopMaxTooltip && oopMaxTooltip.description && hasPrescriptionTab && moopIndicator === true  ? (
                                        <Typography.Paragraph className="tds-margin-none">
                                        <Markup allowAttributes={true} content={oopRxMaxTooltip.description} />
                                        </Typography.Paragraph>
                                      ) : (pharmacyAndMedicalOopToolTip && pharmacyAndMedicalOopToolTip.description ?
                                          <Typography.Paragraph className="tds-margin-none">
                                           <Markup allowAttributes={true} content={pharmacyAndMedicalOopToolTip.description}></Markup>
                                    </Typography.Paragraph>
                                        :'This is the most you pay for covered services from in-network providers during the year. Your deductible, copays and coinsurance for covered in-network services count toward this amount. Once you reach your maximum out-of-pocket, your plan will pay 100% of your costs for covered services for the rest of the year. Plan premiums and costs for certain services do not count toward your maximum out-of-pocket amounts.')}
                                    </PopupTip>
                                  </div>
                                  </div>
                                )}
                          </div>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            );
          } else if (data === null) {
            return apiFailErrorMsg && apiFailErrorMsg.title && apiFailErrorMsg.title[0] ? (
              <ApiCallError text={apiFailErrorMsg.title[0]} />
            ) : apiErrorMessage && apiErrorMessage.title && apiErrorMessage.title[0] ? (
              <ApiCallError text={apiErrorMessage.title[0]} />
            ) : (
                  <ApiCallError text="Sorry, we're experiencing technical difficulties. Please try again later." />
                );
          }
        })
      ) : null}
      {/* {hasError ? (
        <>
          apiFailErrorMsg && apiFailErrorMsg.title && apiFailErrorMsg.title[0] ?
          <ApiCallError text={apiFailErrorMsg.title[0]} />
          : apiErrorMessage && apiErrorMessage.title && apiErrorMessage.title[0] ?
          <ApiCallError text={apiErrorMessage.title[0]} />
          :{' '}
          <ApiCallError text="Sorry, we're experiencing technical difficulties. Please try again later." />
        </>
      ) : null} */}
    </>
  );
};

export default MedicalClaimsAccounts;


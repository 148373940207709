import React from 'react';
import moment from 'moment';
import { Typography, Button } from '@uhc-tempo/components';
import config from '../../../../../config';
import styles from '../preeffective-components/rewards.module.css';
import clickTrack from '../../../../../hooks/clickTrack';

const { aem_content_url } = config;

const Rewards = ({
  effectiveRewards,
  effectiveRewardsButton,
  reimbursementImage,
  selectedDateOfBirth,
  selectedMedical,
  isNMuser,
  isNJuser
}) => {
  const { coverageStatus = '', startDate = '' } = selectedMedical;

  if (coverageStatus?.toLowerCase() === 'termed') return null;

  const reimbursementHref = reimbursementImage?.href ? `${aem_content_url}${reimbursementImage.href}` : '#';
  // const twentyTwentyThreePlan = moment(startDate).isBetween('2023-01-01', '2023-12-31', 'year', '[]')
  const incentiveLink = effectiveRewardsButton?.href ? `${effectiveRewardsButton.href}` : '#';
  const age = moment().diff(moment(selectedDateOfBirth), 'years');
  const showReward = age >= 18 && coverageStatus.toLowerCase() === 'active' && (!isNMuser || !isNJuser);

  return (
    <>
      {showReward ? (
        <div className={styles.section}>
          <div className={styles.wrapper}>
            <div className={styles.container}>
              <div className={styles.leftCol}>
                <img src={reimbursementHref} alt="coin dropping into a hand" />
              </div>
              <div className={styles.middleCol}>
                <Typography.H3 headingStyle={4}>
                  {effectiveRewards?.title?.[0]}
                </Typography.H3>
                <Typography.Paragraph>
                  {effectiveRewards?.title?.[1]}
                </Typography.Paragraph>
                <Button
                  id="more-info"
                  buttonType="primary-one"
                  href={incentiveLink}
                  newTabWindow= {incentiveLink ? incentiveLink.includes("BIW") : null}
                  onClick={()=> clickTrack(effectiveRewardsButton?.title, "main-body", "button", "internal", incentiveLink)}
                >
                  {effectiveRewardsButton?.title}
                </Button>
              </div>
              <div className={styles.rightCol}>
                <ul className={styles.ulist}>
                  <li>{effectiveRewards?.title?.[2]}</li>
                  <li>{effectiveRewards?.title?.[3]}</li>
                  <li>{effectiveRewards?.title?.[4]}</li>
                  <li>{effectiveRewards?.title?.[5]}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Rewards;

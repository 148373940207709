import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import '@uhc-tempo/components/tempo.css'
import { datadogRum } from "@datadog/browser-rum";
import getCookieItem from "./tools/getCookieItem";

const getEnvironment = (docHost) => {
   const allowedIntHost = 'member.int.uhc'
   const allowedBetaHost = 'member.beta.uhc'
   const allowedUatHost = 'member.uat.uhc'
   const allowedProdHost = 'member.uhc'
   if (docHost.indexOf(allowedIntHost) > -1) {
     return "int";
   } else if (docHost.indexOf(allowedBetaHost) > -1) {
      return "beta";
    } else if (docHost.indexOf(allowedUatHost) > -1) {
     return "stage";
   } else if (docHost.indexOf(allowedProdHost) > -1) {
      return "prod";
   }
   return "";
 };
 
datadogRum.init({
   applicationId: 'ef284655-2318-4eb5-bab8-82069711e16e',
   clientToken: 'pubd033fd444cd5e1450af55b555f0a493b',
   site: 'datadoghq.com',
   env: getEnvironment(window.location.host),
   service: 'myuhc2.5-dashboard',
   sessionSampleRate: 100,
   sessionReplaySampleRate: 20,
   trackUserInteractions: true,
   trackResources: true,
   trackLongTasks: true,
   defaultPrivacyLevel: 'mask-user-input',
});

const canarystatus = getCookieItem("X-MYUHC-Canary");
console.log("dashboard canary Status is: " + canarystatus);
datadogRum.addRumGlobalContext('canary_status_myuhc', canarystatus);

const getStartTime = () => {
   return new Date()
}
// render micro frontend function
window.renderdashboardapp = (containerId, history) => {
   window.dashboardappStartTime = getStartTime()
   ReactDOM.render(
      <App history={history} />,
      document.getElementById(containerId)
   )
   serviceWorker.unregister()
}

// unmount micro frontend function
window.unmountdashboardapp = containerId => {
   ReactDOM.unmountComponentAtNode(document.getElementById(containerId))
}

// Mount to root if it is not a micro frontend
if (!document.getElementById("dashboardapp-container")) {
   ReactDOM.render(<App />, document.getElementById("root"))
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

import React, { useContext, useState, useEffect } from "react";
import { Typography, Button, Link, UtilityIcon } from "@uhc-tempo/components";
import Create from "@material-ui/icons/Create";
import { Loader } from "@uhc/pattern-library";
import UseGraphqlApi from "../../../../../hooks/useGraphqlApi";
import Config from "../../../../../config";
import GetCookieItem from "../../../../../tools/getCookieItem";
import RallyRedirects from '../../../../../constants/rally-redirects';
import { PrimaryCareContext } from "../../../../../contexts/primarycareContext";
import PCP_Query from "../../components/effective-components/pcpQuery.js";
import VirtualCareQuery from "../../components/effective-components/virtualCareQuery";
import GenericError from "../../../../shared-components/error";
import getFilteredContentList from "../../../../../tools/getFilteredContentList";
import styles from './notification.module.css';
import clickTrack from "../../../../../hooks/clickTrack";

const { legacy_en_url, rally_url, rally_vc_url } = Config;
const { view_pcp } = RallyRedirects || {}
const loginLang = GetCookieItem("LOGINLOCALE")
const selectedLang = GetCookieItem("lang")

  //US4949442, US4988117: Don't change rally_connect_lang value
let rally_connect_lang = "en-US"
if(loginLang === "es" || selectedLang === "es"){
    rally_connect_lang = "es"
}
const ChangeProvider = (props) => {
  const {
    // providerUrl,
    dependentSeqNbr
  } = props;

  const { primarycare } = useContext(PrimaryCareContext) || {};
  const { pcpData, isPrimarycareSet } = primarycare || {};

  const [loading, setLoading] = useState(true);
  const [PCP_Error, set_PCP_Error] = useState(false);

  const [pcpState, setPcpState] = useState([]);
  const get_PCP_Data = PCP_Query();
  const domain = window.location.origin + window.location.pathname

  useEffect(() => {
    UseGraphqlApi(get_PCP_Data, PCP_QueryHandler);
  }, []);

  const PCP_QueryHandler = (response) => {
    if (response && !response.isError) {
      if (response.data) {
        if (response.data.listTileList.items.length > 0) {
          const listWithDates = getFilteredContentList(response.data ?.listTileList ?.items)
          if (listWithDates.length > 0) {
            setPcpState(
              listWithDates[0]
            );
          }
          else {
            setPcpState(
              response.data.listTileByPath && response.data.listTileByPath.item ?
                response.data.listTileByPath.item : ''
            )
          }
        }
        else if (response.data.listTileByPath.item) {
          setPcpState(
            response.data.listTileByPath && response.data.listTileByPath.item ?
              response.data.listTileByPath.item : ''
          )
        }
      }
    } else {
      set_PCP_Error(true);
    }
    setLoading(false);
  };
  const { linkText: pcpLinkText, url: pcpUrl, urlType: pcpUrlType } = pcpState || {}
  let newPcpUrl = ''
  function renderPcpUrl(pcpUrlType) {
    if (pcpUrlType === "pcpUrl") {
      isPrimarycareSet && pcpData !== null && pcpData.primarycareData !== null && pcpData.primarycareData !== undefined && dependentSeqNbr !== null &&
        pcpData.primarycareData.map((pcpdata, i) => {
          if ((dependentSeqNbr === pcpdata.depSeqNbr) && pcpdata.providerID && pcpdata.providerID.id && pcpdata.pcpError === false) {
            newPcpUrl = `${view_pcp}/${pcpdata.providerID.id}?partnerId=true&locale=${rally_connect_lang}`
          }
        })
    }
    if (pcpUrlType === "rallyUrl") {
      newPcpUrl = `${rally_url}` + pcpUrl
    }
    else if (pcpUrlType === "rallySSO") {
      newPcpUrl = `${rally_vc_url}` + pcpUrl
    }
    else if (pcpUrlType === "pageUrl" || pcpUrlType === "default") {
      newPcpUrl = pcpUrl
    }
    else if (pcpUrlType === "legacyUrl") {
      //pcpUrl is coming from AEM - /care/edit-pcp
      //legacy_en_url is in config = https://member.uat.uhc.com
      newPcpUrl = `${legacy_en_url}${pcpUrl}?locale=${rally_connect_lang}`
    }

    return newPcpUrl
  }
  //  ****************** Banner url function **********************
  return (
    <>
      {loading ? (
        <Loader Blocking />
      ) : (
          <>
            {PCP_Error ? (
              <GenericError></GenericError>
            ) : (
                <>
                  {pcpLinkText ?

                    <div className={styles.changeProvLink}>
                      <Link
                        // href={pcpState.url && pcpState.url}
                        href={renderPcpUrl(pcpUrlType)}
                        noIcon={true}
                        className="replace-provider-link"
                        onClick={()=>
                          clickTrack(pcpLinkText, "main-body", "text", 'internal', domain + renderPcpUrl(pcpUrlType))}
                      >
                        <>
                          <span className="link-text">{pcpLinkText}</span>
                          < UtilityIcon icon={Create} />
                        </>
                      </Link>
                    </div> : null}
                </>)}
          </>
        )}
    </>
  );
};

export default ChangeProvider;

import React, { createContext, useState, useEffect } from "react"
import UseBaseService from "../hooks/useBaseService"
import Constants from "../constants/constants"
import PrimarycareFormatter from "../hooks/primarycareFormatter"
const { primarycare_endpoint } = Constants
export const PrimaryCareContext = createContext()

const PrimaryCareContextProvider = ({ children }) => {
   const [primarycare, setPrimarycare] = useState({
      isPrimarycare: false,
      hasError: false
   })

   useEffect(() => {
      //const dependentCode = "00";
      // if (sessionStorage.getItem("PCP")) {
      //    setPrimarycare(JSON.parse(sessionStorage.getItem("PCP")))
      // }
      // else {
      // if (primarycare_endpoint.indexOf('offline') < 0) {
      UseBaseService("primarycare", "GET", "dmz", primarycare_endpoint, "", response => {
         try {
            const { data, APIError } = response
            if (APIError) {
               setPrimarycare({ isPrimarycareSet: false, hasError: true })
            }
            else if (data) {
               const resData = data && data.data ? data.data : data ? data : ''
               const member = resData && resData.bodyObj ? resData.bodyObj : ''
               const pcpData = PrimarycareFormatter(member)

               //set session in state
               sessionStorage.setItem(
                  "PCP",
                  JSON.stringify({
                     pcpData,
                     isPrimarycareSet: true
                  })
               )
               setPrimarycare({
                  pcpData,
                  isPrimarycareSet: true
               })
            }

         } catch (error) {
            setPrimarycare({ hasError: true, isPrimarycare: false })
         }
      })
      // }
      // else if (primarycare_endpoint.indexOf('offline') > 0) {
      //    const fetchPrimaryCare = async () => {
      //       const response = await fetch(primarycare_endpoint);
      //       const primaryCareResponse = await response.json();
      //       return primaryCareResponse;
      //    }

      //    fetchPrimaryCare().then(primaryCareResponse => {
      //       const { data, Error } = primaryCareResponse || {};
      //       if (Error) {
      //          console.log("PrimaryCare API Error: Check api call!")
      //          setPrimarycare({ hasError: true, isPlandetailSet: false })
      //       } else {
      //          try {
      //             if (data && data.bodyObj) {
      //                const member = data.bodyObj
      //                const pcpData = PrimarycareFormatter(member)
      //                //set session in state
      //                sessionStorage.setItem(
      //                   "PCP",
      //                   JSON.stringify({
      //                      pcpData,
      //                      isPrimarycareSet: true
      //                   })
      //                )
      //                setPrimarycare({
      //                   pcpData,
      //                   isPrimarycareSet: true
      //                })
      //             }
      //          } catch (error) {
      //             setPrimarycare({ hasError: true, isPrimarycare: false })
      //          }

      //       }
      //    })

      // }

   }, [])

   return (
      <PrimaryCareContext.Provider value={{ primarycare }}>
         {children}
      </PrimaryCareContext.Provider>
   )
}

export default PrimaryCareContextProvider


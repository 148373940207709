import React from 'react'

const ExternalIcon = ({ iconColor }) => {

    return (

        <svg

            class="MuiSvgIcon-root tds-utility-icon tds-link__icon tds-link__icon--external MuiSvgIcon-fontSizeSmall uhc-tempo-button__icon"

            focusable="false"

            height="20" 

            width="20"

            viewBox="0 0 24 24"

            color="#186ECF"

            aria-hidden="true"

            role="img"

        >

            <path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"></path>

        </svg>

    )

}

export default ExternalIcon
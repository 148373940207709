import React from "react";
import { Section, Flex } from "@uhc/pattern-library"
// import { IdCard2 } from "@uhc/icons";
import '../../dashboard/index.css'
import { Typography, Button } from "@uhc-tempo/components";
import config from "../../../../config";
import clickTrack from "../../../../hooks/clickTrack"
const DashBoardMemberInfo = ({ memberName, covStatus, welcomeTitle, viewIDCardLinkName, viewIDCardLink }) => {
  const { aem_content_url } = config
  const domain = window.location.origin + window.location.pathname
  return (
    <>
      <Section border style={{ padding: "20px 0" }}>
        <Flex
          flexDirection="column"
          flexDirectionLg="row"
          alignItemsLg="center"
          flexDirectionMd="row"
          alignItemsMd="center"
          justifyContentMd="space-between"
          justifyContentLg="space-between"
          flexDirectionSm="column"
          justifyContentSm="center"
        >
          <Flex>
            <div className="tempo-v1" >
              <Typography.H1 style={{ margin: "1.3rem 0px" }}>
                Hello, {memberName}
                <p style={{ fontSize: 22, fontFamily: '"UHC Sans Bold", Arial, sans-serif', margin: 0 }}>{welcomeTitle}</p>
              </Typography.H1>
            </div>
          </Flex>
          {covStatus !== "termed" && (
            <Flex className="desktop" >
              <Button
                buttonType="secondary-one"
                style={{ display: 'flex', alignItems: 'center' }}
                href={viewIDCardLink}
                onClick = {()=> 
                clickTrack(
                viewIDCardLinkName || "View & Print Dental ID card", 
                "main-body", 
                "button",
                "internal", 
                domain + viewIDCardLink)}
                >
                {viewIDCardLinkName || "View & Print Dental ID card"}
                <img src={`${aem_content_url}/content/dam/myuhc/consumer/assets/myuhc/id_card.png`} alt='' style={{ maxWidth: 24, marginLeft: 5 }} />
              </Button>
            </Flex>)}
          {covStatus !== "termed" && (
            <Flex className="mobile idButtonMobile">
              <Button
                buttonType="secondary-one"
                style={{ display: 'flex', alignItems: 'center' }}
                href={'/dental-only/accounts#/viewidcard' || viewIDCardLink}
                onClick = {()=> 
                  clickTrack(
                  viewIDCardLinkName || "View & Print Dental ID card", 
                  "main-body", 
                  "button",
                  "internal", 
                  domain + '/dental-only/accounts#/viewidcard' || domain + viewIDCardLink)}
                >
                {viewIDCardLinkName || "View & Print Dental ID card"}
                <img src={`${aem_content_url}/content/dam/myuhc/consumer/assets/myuhc/id_card.png`} alt='' style={{ maxWidth: 24, marginLeft: 5 }} />
              </Button>
            </Flex>)}
        </Flex>
      </Section>
    </>
  )
}

export default DashBoardMemberInfo;
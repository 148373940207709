import React from "react";
import { Card } from "@uhc/pattern-library";
import { Typography } from "@uhc-tempo/components"
import styled from "styled-components";
import config from "../../config"
import { Button } from "@uhc-tempo/components";
import clickTrack from "../../hooks/clickTrack";

const ActionDashCard = styled(Card)`
  border: 0;
`;
const { aem_content_url } = config
const ActionCard = props => {
  const { titleAction, textAction, linkName, clicked, imgPath, covStatus } = props;
  let title = titleAction + " " + linkName
  return (
    <ActionDashCard span={12} spanSm={12} spamLg={4} spanMd={covStatus === 'termed' ? 6 : 4}
      style={{ border: "none !important", padding: "25px 45px", textAlign: "center" }} >
      <img src={`${imgPath}`} alt='' style={{ width: '80px', height: '80px', margin: 'auto' }} />
      <Typography.H2 className="tds-header__h6">{titleAction}</Typography.H2>
      <p>
        <Typography.Paragraph>{textAction}</Typography.Paragraph>
      </p>
      <Button buttonType='secondary-one'
        aria-label={title}
        style={{ width: 240 }}
        onClick={()=> { clicked()
          clickTrack(linkName, "main-body", "button", "internal", "")}}>
        {linkName}
      </Button>
    </ActionDashCard>
  )
}
ActionDashCard.defaultProps = {
  imgPath: `${aem_content_url}/content/dam/myuhc/consumer/assets/myuhc/oral-icon.svg`
}

export default ActionCard;

const PlandetailDataFormatter = ({ eligibility, error } = {}) => {
   let accountSummary = {};
   let medicalTier1AccountSummary = {};
   let medicalAccountSummary = {};
   let dentalAccountSummary = {};
   let pharmacyAccountSummary = {};

   try {
      const { benefitSet: benefitSetVal } = eligibility || {}

      for (const benefitSet of benefitSetVal) {
         const { coverageTypeCode: { code: coverageTypeCode } } = benefitSet || {}

         if (coverageTypeCode && coverageTypeCode.toUpperCase() === 'M') {
            /*'Medical Covergae' Block */
            const planBenefitSetObj = benefitSet.planBenefitSet && benefitSet.planBenefitSet[0] ? benefitSet.planBenefitSet[0] : {}
            const { planBenefitLevel: planBenefitLevelArr } = planBenefitSetObj || {}
            for (const planBenefitLevel of planBenefitLevelArr) {
               const { networkStatusCode: { code: networkStatusCode } } = planBenefitLevel || {}
               if (networkStatusCode.toUpperCase() === 'TIER-1') {

                  const { planLevel } = planBenefitLevel || {}
                  const { deductible: deductibleObj, oop: outOfPocketObj } = planLevel || {}
                  const { individual: individualDeductibleObj, family: familyDeductibleObj } = deductibleObj || {}
                  const { individual: individualOopObj, family: familyOopObj } = outOfPocketObj || {}

                  const planIndividualRemainingDeductibleAmount = individualDeductibleObj && individualDeductibleObj.planIndividualRemainingDeductibleAmount ?
                     individualDeductibleObj.planIndividualRemainingDeductibleAmount : "0.00"
                  const indDeductibleMaxAmount = individualDeductibleObj && individualDeductibleObj.deductibleAmount ?
                     individualDeductibleObj.deductibleAmount : "0.00"
                  const indDeductibleInitialAmount = Number(indDeductibleMaxAmount) - Number(planIndividualRemainingDeductibleAmount)
                  const indDedSpentAmount = individualDeductibleObj && individualDeductibleObj.ytdAmount ? individualDeductibleObj.ytdAmount : "0.00"

                  const planFamilyRemainingDeductibleAmount = familyDeductibleObj && familyDeductibleObj.planFamilyRemainingDeductibleAmount ?
                     familyDeductibleObj.planFamilyRemainingDeductibleAmount : "0.00"
                  const familyDeductibleMaxAmount = familyDeductibleObj && familyDeductibleObj.deductibleAmount ?
                     familyDeductibleObj.deductibleAmount : {}
                  const familyDeductibleInitialAmount = Number(familyDeductibleMaxAmount) - Number(planFamilyRemainingDeductibleAmount)
                  const familyDedSpentAmount = familyDeductibleObj && familyDeductibleObj.ytdAmount ? familyDeductibleObj.ytdAmount : "0.00"

                  const planIndividualOOPRemaining = individualOopObj && individualOopObj.planIndividualOOPRemaining ?
                     individualOopObj.planIndividualOOPRemaining : "0.00"
                  const individualOopMaxAmount = individualOopObj && individualOopObj.planIndividualOOP ?
                     individualOopObj.planIndividualOOP : "0.00"
                  const individualOopInitialAmount = Number(individualOopMaxAmount) - Number(planIndividualOOPRemaining)
                  const indOopSpentAmount = individualOopObj && individualOopObj.oopYTDAmount ? individualOopObj.oopYTDAmount : "0.00"

                  const planFamilyOOPRemaining = familyOopObj && familyOopObj.planFamilyOOPRemaining ?
                     familyOopObj.planFamilyOOPRemaining : "0.00"
                  const familyOopMaxAmount = familyOopObj && familyOopObj.planFamilyOOP ? familyOopObj.planFamilyOOP : "0.00"
                  const familyOopInitialAmount = Number(familyOopMaxAmount) - Number(planFamilyOOPRemaining)
                  const familyOopSpentAmount = familyOopObj && familyOopObj.oopYTDAmount ? familyOopObj.oopYTDAmount : "0.00"

                  medicalTier1AccountSummary = {
                     ...medicalTier1AccountSummary,
                     indDeductibleInitialAmount,
                     indDeductibleMaxAmount,
                     indDedSpentAmount,
                     familyDeductibleInitialAmount,
                     familyDeductibleMaxAmount,
                     familyDedSpentAmount,
                     indOopSpentAmount,
                     individualOopInitialAmount,
                     individualOopMaxAmount,
                     familyOopSpentAmount,
                     familyOopInitialAmount,
                     familyOopMaxAmount,
                  }
               }
               if (networkStatusCode.toUpperCase() === 'IN-NETWORK') {

                  const { planLevel } = planBenefitLevel || {}
                  const { deductible: deductibleObj, oop: outOfPocketObj } = planLevel || {}
                  const { individual: individualDeductibleObj, family: familyDeductibleObj } = deductibleObj || {}
                  const { individual: individualOopObj, family: familyOopObj } = outOfPocketObj || {}

                  const planIndividualRemainingDeductibleAmount = individualDeductibleObj && individualDeductibleObj.planIndividualRemainingDeductibleAmount ?
                     individualDeductibleObj.planIndividualRemainingDeductibleAmount : "0.00"
                  const indDeductibleMaxAmount = individualDeductibleObj && individualDeductibleObj.deductibleAmount ?
                     individualDeductibleObj.deductibleAmount : "0.00"
                  const indDeductibleInitialAmount = Number(indDeductibleMaxAmount) - Number(planIndividualRemainingDeductibleAmount)
                  const indDedSpentAmount = individualDeductibleObj && individualDeductibleObj.ytdAmount ? individualDeductibleObj.ytdAmount : "0.00"

                  const planFamilyRemainingDeductibleAmount = familyDeductibleObj && familyDeductibleObj.planFamilyRemainingDeductibleAmount ?
                     familyDeductibleObj.planFamilyRemainingDeductibleAmount : "0.00"
                  const familyDeductibleMaxAmount = familyDeductibleObj && familyDeductibleObj.deductibleAmount ?
                     familyDeductibleObj.deductibleAmount : "0.00"
                  const familyDeductibleInitialAmount = Number(familyDeductibleMaxAmount) - Number(planFamilyRemainingDeductibleAmount)
                  const familyDedSpentAmount = familyDeductibleObj && familyDeductibleObj.ytdAmount ? familyDeductibleObj.ytdAmount : "0.00"

                  const planIndividualOOPRemaining = individualOopObj && individualOopObj.planIndividualOOPRemaining ?
                     individualOopObj.planIndividualOOPRemaining : "0.00"
                  const individualOopMaxAmount = individualOopObj && individualOopObj.planIndividualOOP ?
                     individualOopObj.planIndividualOOP : "0.00"
                  const individualOopInitialAmount = Number(individualOopMaxAmount) - Number(planIndividualOOPRemaining)
                  const indOopSpentAmount = individualOopObj && individualOopObj.oopYTDAmount ? individualOopObj.oopYTDAmount : "0.00"

                  const planFamilyOOPRemaining = familyOopObj && familyOopObj.planFamilyOOPRemaining ?
                     familyOopObj.planFamilyOOPRemaining : "0.00"
                  const familyOopMaxAmount = familyOopObj && familyOopObj.planFamilyOOP ? familyOopObj.planFamilyOOP : "0.00"
                  const familyOopInitialAmount = Number(familyOopMaxAmount) - Number(planFamilyOOPRemaining)
                  const familyOopSpentAmount = familyOopObj && familyOopObj.oopYTDAmount ? familyOopObj.oopYTDAmount : "0.00"

                  medicalAccountSummary = {
                     ...medicalAccountSummary,
                     indDeductibleInitialAmount,
                     indDeductibleMaxAmount,
                     indDedSpentAmount,
                     familyDeductibleInitialAmount,
                     familyDeductibleMaxAmount,
                     familyDedSpentAmount,
                     indOopSpentAmount,
                     individualOopInitialAmount,
                     individualOopMaxAmount,
                     familyOopSpentAmount,
                     familyOopInitialAmount,
                     familyOopMaxAmount,
                  }
               }

            }
         }

         if (coverageTypeCode && coverageTypeCode.toUpperCase() === 'D') {
            /*'Dental Covergae' Block */
            const planBenefitSetObj = benefitSet.planBenefitSet && benefitSet.planBenefitSet[0] ?
               benefitSet.planBenefitSet[0] : {}
            const { planBenefitLevel: planBenefitLevelArr } = planBenefitSetObj || {}
            for (const planBenefitLevel of planBenefitLevelArr) {
               const { networkStatusCode: { code: networkStatusCode } } = planBenefitLevel || {}
               if (networkStatusCode.toUpperCase() === 'IN-NETWORK') {

                  const { planLevel } = planBenefitLevel || {}
                  const { deductible: deductibleObj, oop: outOfPocketObj } = planLevel || {}
                  const { individual: individualDeductibleObj, family: familyDeductibleObj } = deductibleObj || {}
                  const { individual: individualOopObj, family: familyOopObj } = outOfPocketObj || {}

                  const planIndividualRemainingDeductibleAmount = individualDeductibleObj && individualDeductibleObj.planIndividualRemainingDeductibleAmount ?
                     individualDeductibleObj.planIndividualRemainingDeductibleAmount : "0.00"
                  const indDeductibleMaxAmount = individualDeductibleObj && individualDeductibleObj.deductibleAmount ?
                     individualDeductibleObj.deductibleAmount : "0.00"
                  const indDeductibleInitialAmount = Number(indDeductibleMaxAmount) - Number(planIndividualRemainingDeductibleAmount)
                  const indDedSpentAmount = individualDeductibleObj && individualDeductibleObj.ytdAmount ? individualDeductibleObj.ytdAmount : "0.00"

                  const planIndividualOOPRemaining = individualOopObj && individualOopObj.planIndividualOOPRemaining ?
                     individualOopObj.planIndividualOOPRemaining : "0.00"
                  const indOopMaxAmount = individualOopObj && individualOopObj.planIndividualOOP ?
                     individualOopObj.planIndividualOOP : "0.00"
                  const indOopInitialAmount = Number(indOopMaxAmount) - Number(planIndividualOOPRemaining)
                  const familyDedSpentAmount = familyDeductibleObj && familyDeductibleObj.ytdAmount ? familyDeductibleObj.ytdAmount : "0.00"

                  const planFamilyRemainingDeductibleAmount = familyDeductibleObj && familyDeductibleObj.planFamilyRemainingDeductibleAmount ?
                     familyDeductibleObj.planFamilyRemainingDeductibleAmount : "0.00"
                  const familyDeductibleMaxAmount = familyDeductibleObj && familyDeductibleObj.deductibleAmount ?
                     familyDeductibleObj.deductibleAmount : "0.00"
                  const familyDeductibleInitialAmount = Number(familyDeductibleMaxAmount) - Number(planFamilyRemainingDeductibleAmount);
                  const indOopSpentAmount = individualOopObj && individualOopObj.oopYTDAmount ? individualOopObj.oopYTDAmount : "0.00"

                  const planFamilyOOPRemaining = familyOopObj && familyOopObj.planFamilyOOPRemaining ?
                     familyOopObj.planFamilyOOPRemaining : "0.00"
                  const familyOopMaxAmount = familyOopObj && familyOopObj.planFamilyOOP ? familyOopObj.planFamilyOOP : "0.00"
                  const familyOopInitialAmount = Number(familyOopMaxAmount) - Number(planFamilyOOPRemaining);
                  const familyOopSpentAmount = familyOopObj && familyOopObj.oopYTDAmount ? familyOopObj.oopYTDAmount : "0.00"

                  dentalAccountSummary = {
                     ...dentalAccountSummary,
                     indDeductibleMaxAmount,
                     indDeductibleInitialAmount,
                     indDedSpentAmount,
                     familyDeductibleMaxAmount,
                     familyDeductibleInitialAmount,
                     familyDedSpentAmount,
                     indOopSpentAmount,
                     indOopMaxAmount,
                     indOopInitialAmount,
                     familyOopSpentAmount,
                     familyOopMaxAmount,
                     familyOopInitialAmount
                  }
               }
            }
         }
         if (coverageTypeCode && coverageTypeCode.toUpperCase() == 'RX') {
            /*'Pharmacy Covergae' Block */
            const planBenefitSetObj = benefitSet.planBenefitSet && benefitSet.planBenefitSet[0] || {}
            const { planBenefitLevel: planBenefitLevelArr } = planBenefitSetObj || {}
            for (const planBenefitLevel of planBenefitLevelArr) {
               const { networkStatusCode: { code: networkStatusCode } } = planBenefitLevel || {}
               if (networkStatusCode.toUpperCase() == 'IN-NETWORK') {

                  const { planLevel } = planBenefitLevel || {}
                  const { deductible: deductibleObj, oop: outOfPocketObj } = planLevel || {}
                  const { individual: individualDeductibleObj, family: familyDeductibleObj } = deductibleObj || {}
                  const { individual: individualOopObj, family: familyOopObj } = outOfPocketObj || {}

                  const planIndividualRemainingDeductibleAmount = individualDeductibleObj && individualDeductibleObj.planIndividualRemainingDeductibleAmount || "0.00"
                  const indDeductibleMaxAmount = individualDeductibleObj && individualDeductibleObj.deductibleAmount || "0.00"
                  const indDeductibleInitialAmount = Number(indDeductibleMaxAmount) - Number(planIndividualRemainingDeductibleAmount)
                  const indDedSpentAmount = individualDeductibleObj && individualDeductibleObj.ytdAmount ? individualDeductibleObj.ytdAmount : "0.00"

                  const planIndividualOOPRemaining = individualOopObj && individualOopObj.planIndividualOOPRemaining || "0.00"
                  const indOopMaxAmount = individualOopObj && individualOopObj.planIndividualOOP || "0.00"
                  const indOopInitialAmount = Number(indOopMaxAmount) - Number(planIndividualOOPRemaining)
                  const familyDedSpentAmount = familyDeductibleObj && familyDeductibleObj.ytdAmount ? familyDeductibleObj.ytdAmount : "0.00"

                  const planFamilyRemainingDeductibleAmount = familyDeductibleObj && familyDeductibleObj.planFamilyRemainingDeductibleAmount || "0.00"
                  const familyDeductibleMaxAmount = familyDeductibleObj && familyDeductibleObj.deductibleAmount || "0.00"
                  const familyDeductibleInitialAmount = Number(familyDeductibleMaxAmount) - Number(planFamilyRemainingDeductibleAmount);
                  const indOopSpentAmount = individualOopObj && individualOopObj.oopYTDAmount ? individualOopObj.oopYTDAmount : "0.00"

                  const planFamilyOOPRemaining = familyOopObj && familyOopObj.planFamilyOOPRemaining || "0.00"
                  const familyOopMaxAmount = familyOopObj && familyOopObj.planFamilyOOP || "0.00"
                  const familyOopInitialAmount = Number(familyOopMaxAmount) - Number(planFamilyOOPRemaining);
                  const familyOopSpentAmount = familyOopObj && familyOopObj.oopYTDAmount ? familyOopObj.oopYTDAmount : "0.00"

                  pharmacyAccountSummary = {
                     ...pharmacyAccountSummary,
                     indDeductibleMaxAmount,
                     indDeductibleInitialAmount,
                     indDedSpentAmount,
                     familyDeductibleMaxAmount,
                     familyDeductibleInitialAmount,
                     familyDedSpentAmount,
                     indOopSpentAmount,
                     indOopMaxAmount,
                     indOopInitialAmount,
                     familyOopSpentAmount,
                     familyOopMaxAmount,
                     familyOopInitialAmount
                  }
               }
            }
         }

         accountSummary = {
            ...accountSummary,
            medicalTier1AccountSummary,
            medicalAccountSummary,
            dentalAccountSummary,
            pharmacyAccountSummary,
         }
      }

   } catch (error) {
   }

   return accountSummary;
}
export default PlandetailDataFormatter;

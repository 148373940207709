import { useEffect, useState, useContext } from 'react'
import UseBaseService from '../hooks/useBaseService';
import Constants from "../constants/constants";
import { ProfileContext } from "../contexts/profileContext"


const AccountBalanceApi = () => {

    const { profile } = useContext(ProfileContext) || {};
    const { loggedinMember } = profile || {};
    const { isSubscriber } = loggedinMember || {};
    const { accountbalance_endpoint } = Constants;
    const [HSAData, setHSAData] = useState({
    });
    useEffect(() => {
        if (sessionStorage.getItem("HSA")) {
            setHSAData(JSON.parse(sessionStorage.getItem("HSA")))
        }
        else if (isSubscriber) {
            UseBaseService("HSA", "GET", "dmz", accountbalance_endpoint, "", response => {
                try {
                    const { data } = response
                    if (data) {
                        const { account } = data.data ? data.data : data
                        const { accountPlanYear } = account[0] || {}
                        const { accountBalance } = accountPlanYear[0] || {}
                        const { totalBalance } = accountBalance || {}
                        sessionStorage.setItem(
                            "HSA",
                            JSON.stringify({
                                totalBalance
                            })
                        )
                        setHSAData({ totalBalance })
                    }
                } catch (error) {
                    setHSAData({ error: true })
                }
            })
        }
    }, [isSubscriber, accountbalance_endpoint])
    return HSAData
}

export default AccountBalanceApi
import React from "react";
import { Typography, BrandIcon } from "@uhc-tempo/components";
import { IconAlert } from "@uhc-tempo/icons";
import styles from "./banner.module.css";

const Banner = ({ bannerText = ''}) => {
  return (
    <>
      <div className={styles.alertContainer}>
        <div className={styles.contentWrapper}>
          <div className={styles.icon}>
            <BrandIcon
              icon={IconAlert}
              size="EXTRA_SMALL"
              styleVariation="ONE_TONE_DARK_CIRCLE"
            />
          </div>
          <div style={{ textAlign: "left" }}>
            <Typography.Paragraph useBold className={styles.pStyled}>
              {bannerText}
            </Typography.Paragraph>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
const getCookieItem = item => {
   //get item
   const documentCookies = document.cookie.split(";")
   // const cookieFound = documentCookies.find(data => data.indexOf(item) > -1)
   const cookieFound = documentCookies.find((data) => data.match(new RegExp(`\\b${item}\\b`)));
   const cookie = cookieFound && cookieFound.split("=")
   return cookie && cookie[1] ? cookie[1] : ""
}

export default getCookieItem

import React, { useEffect, useState } from 'react'
import { Card, Section, Flex } from '@uhc/pattern-library'
import { Button, BrandIcon } from '@uhc-tempo/components'
import { IconPillBot, IconSavings, IconProv, Icon_$0 } from '@uhc-tempo/icons'
import Config from '../../../../../config'
import PlanInfoMobile from './planInfoMobile'
import RallyRedirects from '../../../../../constants/rally-redirects';
import AccountBalance from '../../../../../hooks/accountBalanceApi';
import clickTrack from '../../../../../hooks/clickTrack'

const { find_provider } = RallyRedirects;
const domain = window.location.origin + window.location.pathname
const { aem_content_url } = Config
const PlanInfoEffective = (props) => {
  const { dashboardviewFor, providerUrl, findProvider, viewBenefits, mngPrpLink, mngRxLink, hsaLink, situsState, activeCoverages, isGalileo, isNMPayExempt } = props

  const activeRX = activeCoverages && activeCoverages.find(data =>
    data && data.typeCode && data.typeCode.coverageTypeCode === "RX"
  )

  //changes to add check for health savings account based on profile API response
  const activeMedicalCoverage = activeCoverages && activeCoverages.find(data => data.typeCode && data.typeCode.coverageTypeCode && (data.typeCode.coverageTypeCode.toUpperCase() === 'M' || data.typeCode.coverageTypeCode.toUpperCase() === 'MC' || data.typeCode.coverageTypeCode.toUpperCase() === 'ME' || data.typeCode.coverageTypeCode.toUpperCase() === 'MH' || data.typeCode.coverageTypeCode.toUpperCase() === 'MS'))
  const { prodServiceTypeCode } = activeMedicalCoverage || {}
  const { code: prodServiceCode } = prodServiceTypeCode || {}
  const isHSAEligible = prodServiceCode && (prodServiceCode === '3' || prodServiceCode === '7' || prodServiceCode === 'Y') ? true : false
  const HSAData = AccountBalance()
  const { totalBalance } = HSAData || {}
  const hsaStates = ["TN", "MD", "AZ", "MI", "NC", "OK", "TX", "KS", "MO", "MS", "OH", "CO", "NJ", "NM", "SC", "WI"]
  const isHSAEligState = hsaStates.includes(situsState)
  const [fullySubdizedWithNoDue, setFullySubsidizedWithNoDue] = useState(false);
  const [totalDueAmount, setTotalDueAmount] = useState(false);


  useEffect(() => {
    const timer = setTimeout(() => {
      const totalDueAmount = sessionStorage.getItem("totalDueAmount");
      const subdizedAndNoDue = sessionStorage.getItem("subdizedAndNoDue") === 'true'? true : false;
      setFullySubsidizedWithNoDue(subdizedAndNoDue);
      setTotalDueAmount(totalDueAmount);
    }, 3000);
    return () => clearTimeout(timer);
  }, [fullySubdizedWithNoDue,totalDueAmount]);


  return (
    <>
      <div className="desktop">
        <Section style={{ position: 'relative', top: -100 }}>
          <Card>
            <Card.Content style={{ padding: '40px 0' }}>
              <Flex>
                {!isGalileo ?
                  <>
                    <div className="active-plan-tile" id="find-provider">
                      <Button noIcon={true} className="link-button uhc-tempo-link btn-active-styled"
                        href={find_provider}
                        onClick={()=> clickTrack(findProvider.bodyTitle, "main-body", "button", "internal", domain + find_provider )}
                      >
                        <div style={{ marginRight: 10 }}>
                          {findProvider && findProvider.imgPath ? <img src={`${aem_content_url}${findProvider.imgPath}`} alt=''
                            style={{ height: 70, margin: 'auto' }} /> :
                            <BrandIcon icon={IconProv} size="SMALL" styleVariation="TWO_TONE_DARK_CIRCLE" />}
                        </div>
                        <span className="link-text">{findProvider.bodyTitle}</span>
                      </Button>
                    </div>
                  </> :
                  <>
                    <div className="active-plan-tile" id="view-benefits">
                      <Button noIcon={true} className="link-button uhc-tempo-link btn-active-styled"
                        href={viewBenefits.href}
                        onClick={()=> clickTrack(viewBenefits.bodyTitle, "main-body", "button", "internal", domain + viewBenefits.href )}
                      >
                        <div style={{ marginRight: 10 }}>
                          {viewBenefits && viewBenefits.imgPath ? <img src={`${aem_content_url}${viewBenefits.imgPath}`} alt=''
                            style={{ height: 70, margin: 'auto' }} /> :
                            <BrandIcon icon={IconProv} size="SMALL" styleVariation="TWO_TONE_DARK_CIRCLE" />}
                        </div>
                        <span className="link-text">{viewBenefits.bodyTitle}</span>
                      </Button>
                    </div>
                  </>}
                {dashboardviewFor.relationship === "SUBSCRIBER" && !fullySubdizedWithNoDue && !isNMPayExempt ?
                  <div className="active-plan-tile" id="premium-payment">
                    {mngPrpLink ? <Button noIcon={true} className="link-button uhc-tempo-link btn-active-styled"
                      href={mngPrpLink.href}
                      onClick={()=> clickTrack(mngPrpLink.bodyTitle, "main-body", "button", "internal", domain + mngPrpLink.href )}
                      >
                      <div style={{ marginRight: 10 }}>
                        {mngPrpLink && mngPrpLink.imgPath ? <img src={`${aem_content_url}${mngPrpLink.imgPath}`} alt=''
                          style={{ height: 70, margin: 'auto' }} /> :
                          <BrandIcon icon={Icon_$0} size="SMALL" styleVariation="TWO_TONE_DARK_CIRCLE" />}
                      </div>
                      <span className="link-text">{mngPrpLink.bodyTitle}{fullySubdizedWithNoDue}</span>
                    </Button> : null}
                  </div> : null}
                {activeRX ? <div className="active-plan-tile" id="manage-prescription">
                  {mngRxLink ? <Button noIcon={true} className="link-button uhc-tempo-link btn-active-styled"
                    href={mngRxLink.href}
                    onClick={()=> clickTrack(mngRxLink.bodyTitle, "main-body", "button", "internal", domain + mngRxLink.href )}
                    >
                    <div style={{ marginRight: 10 }}>
                      {mngRxLink && mngRxLink.imgPath ? <img src={`${aem_content_url}${mngRxLink.imgPath}`} alt=''
                        style={{ height: 70, margin: 'auto' }} /> :
                        <BrandIcon icon={IconPillBot} size="SMALL" styleVariation="TWO_TONE_DARK_CIRCLE" />}
                    </div>
                    <span className="link-text">{mngRxLink.bodyTitle}</span>
                  </Button> : null}
                </div> : null}
                {dashboardviewFor.relationship === "SUBSCRIBER" &&
                  isHSAEligState && isHSAEligible ?
                  <div className="active-plan-tile" id="hsa">
                    {hsaLink ? <Button noIcon={true} className="link-button uhc-tempo-link btn-active-styled"
                      href={hsaLink.href + '#/hsa'}
                      onClick={()=> clickTrack(hsaLink.bodyTitle, "main-body", "button", "internal", domain + hsaLink.href + '#/hsa' )}
                    >
                      <div style={{ marginRight: 10 }}>
                        {hsaLink && hsaLink.imgPath ? <img src={`${aem_content_url}${hsaLink.imgPath}`} alt=''
                          style={{ height: 70, margin: 'auto' }} /> :
                          <BrandIcon icon={IconSavings} size="SMALL" styleVariation="TWO_TONE_DARK_CIRCLE" />}
                      </div>
                      {totalBalance ? <span className="link-text">{hsaLink.bodyTitle} (${totalBalance})</span> :
                        <span className="link-text">{hsaLink.bodyTitle}</span>}
                    </Button> : null}
                  </div> : null}
              </Flex>
            </Card.Content>
          </Card>
        </Section>
      </div>

      <PlanInfoMobile
        providerUrl={providerUrl}
        dashboardviewFor={dashboardviewFor}
        findProvider={findProvider}
        viewBenefits={viewBenefits}
        mngPrpLink={mngPrpLink}
        mngRxLink={mngRxLink}
        hsaLink={hsaLink}
        situsState={situsState}
        activeRX={activeRX}
        isHSAEligState={isHSAEligState}
        isHSAEligible={isHSAEligible}
        totalBalance={totalBalance}
        isGalileo={isGalileo}
        isNMPayExempt = {isNMPayExempt}
      />
    </>
  )
}

export default PlanInfoEffective
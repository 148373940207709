import Axios from "axios";
import config from '../config'
import error from "../logger/error";
import info from "../logger/info";

const UseOfflineToken = (callback) => {
  let endpoint = config.offline_token;
  const startTime = new Date()
  Axios.get(endpoint)
    .then((response) => {
      const { data } = response;
      try {
        info({ 'function':'UseOfflineToken','responseCode': response.status, 'status': 'Success','data': data, 'apiUrl': endpoint, 'duration': Date.now() - startTime.getTime() })
        callback(data);
      } catch (err) {
        error({ 'function':'UseOfflineToken','error': err,'responseCode': response.status, 'status': 'Error', 'apiUrl': endpoint, 'error': "Error in the response catch block", 'duration': Date.now() - startTime.getTime() })
      }
    })
    .catch((err) => {
      error({ 'function':'UseOfflineToken','error': err, 'status': 'Error', 'apiUrl': endpoint, 'error': "Error in the catch block", 'duration': Date.now() - startTime.getTime() })
    });
};

export default UseOfflineToken;

import React, { useContext, useState, useEffect } from "react"
import { Card, Typography, Flex } from "@uhc/pattern-library"
import { ProfileContext } from "../../contexts/profileContext"
import UsePlanDetail from "../../hooks/usePlanDetail"

const PlanSummaryCard = ({ children, planSummaryTitle, planMemberNo, planGroupNo, planPersonsCvrd }) => {

  const { profile } = useContext(ProfileContext) || {}
  const { loggedinMember } = profile || {}
  const { coverages } = loggedinMember || {}
  const { dental } = coverages || {}
  const loggedinfirstName = loggedinMember ? loggedinMember['firstName'] : ''
  const isSubscriber = loggedinMember ? loggedinMember['isSubscriber'] : ''
  const covstartDate = dental ? dental['startDate'] : ''
  let startDate = new Date(covstartDate ? covstartDate.replace(/-/g, '/') : '')
  startDate = startDate ? ((startDate.getMonth() + 1) + '/' + startDate.getDate() + '/' + startDate.getFullYear()).replace(/(^|\D)(\d)(?!\d)/g, '$10$2') : ''
  const covendDate = dental ? dental['endDate'] : ''
  let endDate = new Date(covendDate ? covendDate.replace(/-/g, '/') : '')
  endDate = endDate ? ((endDate.getMonth() + 1) + '/' + endDate.getDate() + '/' + endDate.getFullYear()).replace(/(^|\D)(\d)(?!\d)/g, '$10$2') : ''
  const employerGroupNumber = dental ? dental['groupNumber'] : ''
  const coverageStatus = dental ? dental['coverageStatus'] : ''
  const memberId = dental ? dental['memberID'] : ''
  let dependents = profile['dependents']
  const hasActiveCoverage = (dependent) => dependent && dependent.activeCoverages && dependent.activeCoverages.length > 0;
  let activeCoverageDependents = dependents ? dependents.filter(hasActiveCoverage) : null;
  const personsCovered = (activeCoverageDependents ? activeCoverageDependents.map(name => name === null ? '' : name.firstName).join(", ").replace(/, ([^,]*)$/, ' and $1') : '')

  const [planData, setPlanData] = useState({
    error: false,
    loading: false,
    dentalPlanName: ""
  });
  useEffect(() => {
    if (sessionStorage.getItem("PlanName")) {
      setPlanData(JSON.parse(sessionStorage.getItem("PlanName")))
    }
    else {
      UsePlanDetail(data => {
        try {
          const { hasError, brandingLongName } = data;
          if (hasError) {
            setPlanData({ error: true });
          } else {
            sessionStorage.setItem(
              "PlanName",
              JSON.stringify({
                error: false,
                loading: true,
                dentalPlanName: brandingLongName
              })
            )
            setPlanData({
              error: false,
              loading: true,
              dentalPlanName: brandingLongName
            });
          }
        } catch (error) { setPlanData({ hasError: true, loading: true }) }
      });
    }
  }, []);
  const { loading, dentalPlanName } = planData;
  return (
    <>
      <Card span={12} spanLg={4}>
        <Card.Header>
          <Card.Title>
            <Typography fontWeight="bold" style={{ margin: '0', fontSize: '19px' }}>
              {planSummaryTitle || "Plan Summary"}
            </Typography>
          </Card.Title>
        </Card.Header>
        <Card.Content style={{ minHeight: '200px' }}>
          <Typography>
            {coverageStatus === "active" ? "Coverage started on " : ""}
            {coverageStatus === "pre-effective"
              ? "Coverage will start on "
              : ""}
            {coverageStatus === "termed" ? "Coverage from " : ""}
          </Typography>
          <Typography>
            {coverageStatus === "termed" && startDate && endDate ?
              `${startDate} - ${endDate}`
              : startDate ? `${startDate}` : null}
          </Typography>
          {loading ?
            <Card.Title style={{ display: 'initial' }}>
              <p style={{ marginTop: 5 }}>
                <Typography fontWeight="bold">{dentalPlanName}</Typography>
              </p>
            </Card.Title> : <p></p>}

          <Flex flexDirection="row">
            <Flex flex={1}>
              <dl style={{ margin: 0 }}>
                <dt style={{ color: 'rgb(74, 74, 74)', fontWeight: 500, fontSize: 14 }}>
                  {planMemberNo || "Member Number:"} </dt>
                <dd style={{ fontSize: 21, margin: 0 }}>
                  {memberId ? memberId : ""} </dd>
              </dl>
            </Flex>
            <Flex flex={1}>
              <dl style={{ margin: '0' }}>
                <dt style={{ color: 'rgb(74, 74, 74)', fontWeight: 500, fontSize: 14 }}>
                  {planGroupNo || "Group/Acct#:"}</dt>
                <dd style={{ fontSize: 21, margin: 0 }}>{employerGroupNumber}
                </dd>
              </dl>
            </Flex>
          </Flex>
          {isSubscriber && activeCoverageDependents.length === 0 ? (
            <Flex flex={1}>
              <dl style={{ marginBottom: 0 }}>
                <dt style={{ color: 'rgb(74, 74, 74)', fontWeight: 500, fontSize: 14 }}>
                  {planPersonsCvrd || "Persons Covered:"}</dt>
                <dd style={{ margin: 0, fontSize: 21 }}>
                  {loggedinfirstName}</dd>
              </dl>
            </Flex>) : ''}
          {isSubscriber && activeCoverageDependents.length > 1 ? (
            <Flex flex={1}>
              <dl style={{ marginBottom: 0 }}>
                <dt style={{ color: 'rgb(74, 74, 74)', fontWeight: 500, fontSize: 14 }}>
                  {planPersonsCvrd || "Persons Covered:"}</dt>
                <dd style={{ margin: 0, fontSize: 21 }}>
                  {loggedinfirstName}, {personsCovered}</dd>
              </dl>
            </Flex>) : ''}
          {isSubscriber && activeCoverageDependents.length === 1 ? (
            <Flex flex={1}>
              <dl style={{ marginBottom: 0 }}>
                <dt style={{ color: 'rgb(74, 74, 74)', fontWeight: 500, fontSize: 14 }}>
                  {planPersonsCvrd || "Persons Covered:"}</dt>
                <dd style={{ margin: 0, fontSize: 21 }}>
                  {loggedinfirstName} and {personsCovered}</dd>
              </dl>
            </Flex>) : ''}

        </Card.Content>
        <Card.Content>
          {children}
        </Card.Content>
      </Card>
    </>
  )
}

export default PlanSummaryCard

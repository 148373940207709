import React from 'react';
import { Typography, BrandIcon, Button } from '@uhc-tempo/components';
import { IconAlert } from '@uhc-tempo/icons';
import Referral from './referral';
import RallyRedirects from '../../../../../constants/rally-redirects';
import styles from './notificationVariation.module.css';
import clickTrack from '../../../../../hooks/clickTrack';

const { find_provider } = RallyRedirects;
const domain = window.location.origin + window.location.pathname

const NotificationVariation = ({ viewReferrals, seqNumbr, selectedMedical, isKelseySeybold }) => (
  <>
    <div className={styles.referralCard}>
      <div className={styles.cardContainerLeft}>
        <div className={styles.providerWrapper}>
          <div className={styles.leftSection}>
            <div className={styles.referralItemOne}>
              <div className={styles.icon}>
                <BrandIcon
                  icon={IconAlert}
                  size="EXTRA_SMALL"
                  styleVariation="ONE_TONE_DARK_CIRCLE"
                  className={styles.icondesktop}
                />
                <BrandIcon icon={IconAlert} size="EXTRA_SMALL" className={styles.iconmobile} />
              </div>
              <Typography.Paragraph style={{ margin: 0 }}>
                You have one month after your plan start date to choose a primary care provider for you and the members
                on your plan. If you do not choose, we will match you with a quality provider near you.
              </Typography.Paragraph>
            </div>
            <div className={styles.changeProvLink}>
              <Button href={find_provider} id="choose-prov-1" text="Choose provider" buttonType="primary-one" 
              onClick={()=> 
                clickTrack(
                  "Choose provider", 
                  "main-body", 
                  "button",
                  "internal", 
                  domain + find_provider)}
                  />
            </div>
          </div>
        </div>
      </div>
     {!isKelseySeybold ?
      <Referral viewReferrals={viewReferrals} seqNumbr={seqNumbr} selectedMedical={selectedMedical} /> : null}
    </div>
  </>
);

export default NotificationVariation;

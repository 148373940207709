import React, { useState, useEffect, useContext } from "react"
import Constants from "../../../../../constants/contentEndpoints"
import UseContentFragments from "../../../../../hooks/contentfragment/useContentFragments"
import MemberInfo from "../../components/memberInfo"
import HealthResources from "../../components/healthResources"
import PremiumPayments from '../../components/premiumPayments'
import ClaimsAccounts from '../../components/effective-components/claimsAccount-redesign/claimsAccountsNew'
// import ClaimsAccounts from '../../components/effective-components/claimsAccounts'
import config from "../../../../../config"
import { ProfileContext } from "../../../../../contexts/profileContext"
import pageLoadAnalytics from "../../../../../hooks/pageLoadAnalytics"
import '../../index'
import '../termed-components/index.css'
import PaymentContacts from "../../../../../constants/paymentContacts";
import GetCookieItem from "../../../../../tools/getCookieItem";
import Banner from '../banner'
import pageTrackAnalytics from "../../../../../hooks/pageTrackAnalytics"


const DashboardI28Termed = (props) => {
  const {isNMPayExempt} = props
  const {isNMuser} = props
  const { aem_content_url } = config;
  const { profile } = useContext(ProfileContext) || {};
  const { loggedinMember, dependents } = profile || {};
  const dependentsArr = dependents || {};
  const {
    firstName: memberName,
    isSubscriber,
    coverages,
    activeCoverages,
  } = loggedinMember || {};
  const { medical, dental, vision } = coverages ? coverages : "";
  const { coverageStatus } = medical ? medical : "";
  const medicalMemberID = medical ? medical["memberID"] : "";
  const { coverageStatus: dentalCoverageStatus } = dental;
  const { coverageStatus: visionCoverageStatus } = vision;
  const situsState = medical ? medical["situsState"] : "";
  const contact = PaymentContacts(situsState) || {};

  const contentfragement_endpoint = Constants.DASHBOARDI28TERMED_CONTENT;
  const [dashboardI28, setDashboardI28] = useState({
    isLoading: false,
  });
  useEffect(() => {
    UseContentFragments(contentfragement_endpoint, (response) => {
      try {
        if (response.APIError) {
          console.log("error");
        } else {
           const data = response.data.data;
          //console.log(data)
          if (data) {
            setDashboardI28({
              medicalTab: data["medical"],
              planInfoTermed: data["planInfoTermed"],
              reviewDocuments: data["reviewDocuments"],
              makePayment: data["makePayment"],
              healthResourceTermed: data["healthResourceTermed"],
              shopForPlanButton: data["shopForPlanButton"],
              prpMedicalPlanTermed: data["prpMedicalPlanTermed"],
              claimAccountsTermed: data["claimAccountsTermed"],
              termedTitles: data["termedTitles"],
              apiErrorMessage: data["apiErrorMessage"],
              termedFullySubsidisedMessage:data["termedFullySubsidisedMessage"],
              isLoading: true,
            });
          }
        }
      } catch (error) {
        console.log("Error while calling termed I28 member content fragment");
      }
    });
  }, [contentfragement_endpoint]);

  const ifSubscriber = () => isSubscriber;  //checking the user category
  const userCategory = ifSubscriber () ? "subscriber" : "dependent"

  const ifLoggedIn = () => userCategory       //checking if the member is logged in
  const isLoggedIn = ifLoggedIn () ? "logged in" : "not logged in"
 
  let planStatus=""; //checking plan status of member
  if(coverageStatus === "pre-effective" || dentalCoverageStatus === "pre-effective"|| visionCoverageStatus === "pre-effective")
    planStatus+="pre-effective"
  if(coverageStatus === "active" || dentalCoverageStatus === "active"|| visionCoverageStatus === "active"){
  if(planStatus === "pre-effective"){
    planStatus+="|"
  }
    planStatus+="active"
  }
  if(coverageStatus === "termed" || dentalCoverageStatus === "termed"|| visionCoverageStatus === "termed"){
    if(planStatus === "pre-effective" || "active" || "pre-effective|active"){
      planStatus+="|"
    }
    planStatus+="termed"
  }
  
  let planCoverage = ""  //checking plan coverage of member
  if(coverageStatus === "active")
    planCoverage += "m" 
  if(dentalCoverageStatus === "active"){
      if(planCoverage === "m")
       planCoverage+="|"
      planCoverage += "d"
    }
  if(visionCoverageStatus === "active"){
      if(planCoverage === "m" || "d" || "m|d")
       planCoverage+="|"
    planCoverage += "v"
    }

  
  let preLoginLang = GetCookieItem("LOGINLOCALE") || "en" //checking language  
  let postLoginLang = GetCookieItem("lang") || "en"
  let lang = preLoginLang === "es" || postLoginLang === "es" ? "spanish" : "english"

  const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);  //checking user device
  const experienceType = isMobile () ? "mobile" : "desktop"
  let global_api_constants = {};
  if (sessionStorage.getItem("global-api-constants")) {
    global_api_constants = JSON.parse(
      sessionStorage.getItem("global-api-constants"));
  }
  const { adobeAnalyticsNew } = global_api_constants || {}
  useEffect(() => {
    UseContentFragments(contentfragement_endpoint, (response) => {
      const isError= () => (response.APIError)
      const is404 = isError() ? true : false      //checking the API Error 
      if(adobeAnalyticsNew){   
      pageTrackAnalytics( experienceType, is404, lang, "dashboard", "secure", "", "", "",
       "", "content", isLoggedIn, "member", userCategory, planStatus, planCoverage );
      } else
      pageLoadAnalytics("dashboard", "dashboard", "", "", "iex");
      })
    }, [])
  
  //US4303479 - Message Center Deep Link. MyUHC Legacy repo has dependent changes.
  useEffect(() => {
    if (window.location.href.includes("deeplinkmc")) {
      let global_api_constants = {}
      if (sessionStorage.getItem("global-api-constants")) {
        global_api_constants = JSON.parse(
            sessionStorage.getItem("global-api-constants")
        )
      }
      if (coverageStatus !== "pre-effective") {
        console.log(
          "Opening message center page in new tab for Termed members"
        );
        const { myuhc_20_url, myuhc_20_es_url } = config;
        const lang = GetCookieItem("LOGINLOCALE");
        let domain = `${myuhc_20_url}`;

        if (lang === "es") {
          domain = `${myuhc_20_es_url}`;
        }

        if(global_api_constants && global_api_constants.MESSAGECENTER_POPUP_SWITCH == "true") {
          //adding 5s delay to make screen reader read the tab name
          const delayModal = setTimeout(() => {
              window.open(
                domain + `/content/myuhc/en/secure/message-center-popup.html`,
                "popup",
                "width=600,height=600"
              );
          }, 5000);
  
          return () => clearTimeout(delayModal);
          
        } else if (global_api_constants.MESSAGECENTER_POPUP_SWITCH == "false") {
          window.location.href = "/ifp/accounts#/messagecenter"
        }
      }
    }
  }, []);

  const [dashboardData, setDashboardData] = useState({
    isDataReady: false,
  });

  const selectedUser = dashboardData ?.dashboardviewFor;

  const {
    coverages: { medical: selectedMedical = {} } = {},
  } = [loggedinMember, ...dependents].find(
    (x) => x ?.dependentSeqNbr === selectedUser ?.dependentSeqNbr
  ) || {};

  useEffect(() => {
    const { loggedinMember, dependents } = profile || {};
    //object to filterdataby
    //**
    let dashboardMemberData = [loggedinMember];
    dashboardMemberData = loggedinMember.relationship === "SUBSCRIBER" &&
      dependents.length > 0 && [...dashboardMemberData, ...dependents];
    //setDefaultview
    const viewFor = {
      name: loggedinMember.firstName,
      relationship: loggedinMember.relationship,
      dependentSeqNbr: loggedinMember.dependentSeqNbr,
    };
    setDashboardData({
      dashboardMemberData,
      dashboardviewFor: viewFor,
      isDataReady: true,
    });
  }, [profile]);

  const handleView = (dashboardviewFor) => {
    //update state on selecting members

    //console.log(dashboardviewFor)
    setDashboardData({
      ...dashboardData,
      dashboardviewFor,
      isDataReady: true,
    });
  };

  const {
    medicalTab,
    planInfoTermed,
    reviewDocuments,
    makePayment,
    healthResourceTermed,
    shopForPlanButton,
    prpMedicalPlanTermed,
    claimAccountsTermed,
    termedTitles,
    apiErrorMessage,
    termedFullySubsidisedMessage,
    isLoading,
  } = dashboardI28;
  const { dashboardMemberData, dashboardviewFor, isDataReady } = dashboardData;

  const buttonClickHandler = (ssoprops, hrefprops, pdfprops) => {
    if (ssoprops) window.open(hrefprops, "_blank");
    else if (pdfprops) window.open(aem_content_url + hrefprops, "_blank");
    else {
      window.location.href = hrefprops;
    }
  };

  const isKelseySeybold = sessionStorage.getItem("isKelseySeybold") === 'true' ? true : false;
  const suppressClaimsAccumulatorsSwitch = global_api_constants?.KS_CLAIMS_ACCUMULATOR_SUPPRESS === "true" ? true : false;
  const suppressClaimsAccumulators = isKelseySeybold && suppressClaimsAccumulatorsSwitch;

  return (
    <>
      {isLoading && isDataReady ? (
        <>
          <Banner />
          <MemberInfo
            planInfoTermed={planInfoTermed}
            reviewDocuments={reviewDocuments}
            makePayment={makePayment}
            clickrd={() =>
              buttonClickHandler(
                reviewDocuments.sso,
                reviewDocuments.href,
                reviewDocuments.pdf
              )
            }
            clickmp={() =>
              buttonClickHandler(
                makePayment.sso,
                makePayment.href,
                makePayment.pdf
              )
            }
            profile={profile}
            coverageStatus={coverageStatus}
            dependents={dependentsArr}
            isSubscriber={isSubscriber}
            activeCoverages={activeCoverages}
            memberName={memberName}
            handleView={handleView}
            dashboardMemberData={dashboardMemberData}
            dashboardviewFor={dashboardviewFor}
            isLoading={isLoading}
            isNMPayExempt = {isNMPayExempt}
          />
          <HealthResources
            coverageStatus={coverageStatus}
            healthResourceTermed={healthResourceTermed}
            shopForPlanButton={shopForPlanButton}
            isLoading={isLoading}
            selectedMedical={selectedMedical}
            isNMuser = {isNMuser}
            isNMPayExempt = {isNMPayExempt}
          />
          <PremiumPayments
            coverageStatus={coverageStatus}
            medicalMemberID={medicalMemberID}
            prpMedicalPlanTermed={prpMedicalPlanTermed}
            clickPay={() =>
              buttonClickHandler(
                prpMedicalPlanTermed.sso,
                prpMedicalPlanTermed.href,
                prpMedicalPlanTermed.pdf
              )
            }
            termedFullySubsidisedMessage={termedFullySubsidisedMessage}
            termedTitles={termedTitles}
            apiErrorMessage={apiErrorMessage}
            contact={contact}
            isLoading={isLoading}
            isNMPayExempt = {isNMPayExempt}
          />
          <ClaimsAccounts
            medicalTab={medicalTab}
            coverageStatus={coverageStatus}
            dependentSeqNbr={dashboardviewFor.dependentSeqNbr}
            claimAccountsTermed={claimAccountsTermed}
            apiErrorMessage={apiErrorMessage}
            termedTitles={termedTitles}
            suppressClaimsAccumulators = {suppressClaimsAccumulators}
          />
        </>
      ) : null}
    </>
  );
}

export default DashboardI28Termed

import { useContext } from 'react'
import { ProfileContext } from '../../../../contexts/profileContext'
import getPlanYear from '../../../../tools/getPlanYear';

const MedicadeQuery = () => {

  const { profile } = useContext(ProfileContext) || {}
  const { loggedinMember } = profile || {}
  const { coverages } = loggedinMember || {}
  const { medical } = coverages || {}
  const { situsState, reportingCode, coverageStatus, startDate } = medical || {}
  const planYear = getPlanYear(startDate)

  let memberStatus = 'effective'
  if (coverageStatus === 'active') {
    memberStatus = 'effective'
  }
  else if (coverageStatus === 'pre-effective') {
    memberStatus = 'pre-effective'
  }
  else if (coverageStatus === 'termed') {
    memberStatus = 'termed'
  }

  const searchPath = `/content/dam/iex/${planYear}/${memberStatus}/dashboard/medicaid-redetermination`
  const requestParameters = `;searchPath=${searchPath};situsState=${situsState};reportingCode=${reportingCode}`
  const requestPath = `/myuhc/graphql/execute.json/IEX/dashboardMedicaidQuery.json${encodeURIComponent(requestParameters)}`
  return requestPath;
}
export default MedicadeQuery

import React, { useState, useEffect } from 'react';
import Expand from '@material-ui/icons/KeyboardArrowRight';
import { Link, UtilityIcon } from '@uhc-tempo/components';
import ReferralApi from '../../../../../hooks/referralApi';
import ReferralsQuery from '../effective-components/referralQuery';
import UseGraphqlApi from '../../../../../hooks/useGraphqlApi';
import styles from './notificationVariation.module.css';
import clickTrack from "../../../../../hooks/clickTrack"

const Refferal = ({ viewReferrals, seqNumbr, selectedMedical }) => {
  const [referralState, setReferralState] = useState([]);

  const referralList = ReferralApi();
  const getReferralsList = ReferralsQuery();
  const domain = window.location.origin + window.location.pathname
  const RefferalssQueryHandler = (response) => {
    if (response && !response.isError) {
      if (response.data) {
        setReferralState(response?.data?.eligibiltyByPath?.item?.state || null);
      }
    }
  };

  useEffect(() => {
    UseGraphqlApi(getReferralsList, RefferalssQueryHandler);
  }, []);

  const numberOfReferrals = referralList?.formattedReferralsData?.referralsData?.find(
    (x) => x?.dependentSeqNbr === seqNumbr
  )?.noOfReferrals;

  const refferalClass = numberOfReferrals ? `${styles.referralBtn} ${styles.centerReferral}` : `${styles.referralBtn}`;

  const matchState = referralState.includes(selectedMedical?.situsState);

  let unAuthorizedMember = false
  const pageLocation = viewReferrals?.href;
  const pageBlockingList = sessionStorage.getItem("pageBlockingList") && JSON.parse(sessionStorage.getItem("pageBlockingList"));
  const pageBlocked = pageBlockingList && pageBlockingList?.find(path => pageLocation.includes(path));
  if (pageBlocked) {
    unAuthorizedMember = true   
  } 

  return (
    <>
      {!matchState && !unAuthorizedMember && ( 
        <Link href={viewReferrals?.href} 
        noIcon 
        className={refferalClass} 
        id="go-to-link"
        onClick={()=>
          clickTrack(viewReferrals?.title, "main-body", "text", 'internal',domain + viewReferrals?.href)}
          >
          {viewReferrals?.title}
          {numberOfReferrals ? (
            <span>
              <span className={styles.referralNr}>
                <span className={styles['sr-only']}>{numberOfReferrals}</span>
                <span>{numberOfReferrals}</span>
              </span>
            </span>
          ) : null}
          <UtilityIcon icon={Expand} className={styles.expandIcon} />
        </Link>
      )}
    </>
  );
};

export default Refferal;

import React, { useContext, useState, useEffect, useMemo } from "react"
import Dashboard from "../components/Dental/dashboard/index"
import DashboardI28 from "../components/I-28/dashboard/index"
import DashboardI28Termed from "../components/I-28/dashboard/components/termed-components/index"
import { ProfileContext } from "../contexts/profileContext"
import { Loader } from "@uhc/pattern-library"
import PrimaryCareContextProvider from '../contexts/primarycareContext'
import PlanDetailContextProvider from '../contexts/planDetailContext'
import SuperUserResctrictionMessage from '../components/shared-components/superuserrestrictionmessage'
import { Messagebox } from "../components/MessageOverlay/Messagebox"

//import Placeholder from '../components/pages/index'
//import GenericError from '../components/shared-components/error'

const AppRouting = () => {

   const { profile } = useContext(ProfileContext)
   const { isProfileSet, hasError } = profile
   const [profileSet, setProfileSet] = useState(false)

   useEffect(() => {
      isProfileSet && !hasError && setProfileSet(true)
   }, [isProfileSet, hasError])

   const { loggedinMember, showSuperUserMessage } = profile ? profile : ''
   const { isIEX, coverages, isSubscriber } = loggedinMember || {}
   const { medical } = coverages || {}
   const { coverageStatus } = medical || {}
   localStorage.removeItem('WAITING_ROOM_REDIRECT_URL');

   let global_api_constants = {};

  if (sessionStorage.getItem("global-api-constants")) {
    global_api_constants = JSON.parse(
      sessionStorage.getItem("global-api-constants"));
  }

  const { Messagebox_switch } = global_api_constants || {}

   const isNMuser = useMemo(
      () =>
        isIEX && sessionStorage.getItem("isNM") === "true",
      [isIEX]
    );

    const isNMPayExempt = useMemo(
      () =>
        isIEX && sessionStorage.getItem("isNMPayExempt") === "true",
      [isIEX]
    );

   return (
      <>
         {showSuperUserMessage && <SuperUserResctrictionMessage />}
         {profileSet && !isIEX ?
            <Dashboard /> : ''}

         {profileSet && isIEX && coverageStatus !== 'termed' && coverageStatus !== 'active' ?
            <PlanDetailContextProvider>
               <PrimaryCareContextProvider>
                  <DashboardI28 isNMPayExempt = {isNMPayExempt} isNMuser= {isNMuser}/>
               </PrimaryCareContextProvider>
            </PlanDetailContextProvider> : ''}

            {profileSet && isIEX && coverageStatus === 'active' ?
            <PlanDetailContextProvider>
               <PrimaryCareContextProvider>
               { Messagebox_switch === "true" && isSubscriber ? <Messagebox/> : ''}
                  <DashboardI28 isNMPayExempt = {isNMPayExempt} isNMuser= {isNMuser}/>
               </PrimaryCareContextProvider>
            </PlanDetailContextProvider> : ''}

         {profileSet && isIEX && coverageStatus === 'termed' ?
            <PlanDetailContextProvider>
               <DashboardI28Termed  isNMPayExempt = {isNMPayExempt} isNMuser= {isNMuser}/>
            </PlanDetailContextProvider> : ''}

         {!isProfileSet && hasError && <Loader Blocking />}
         {/* {hasError && <GenericError />} */}
      </>
   )
}

export default AppRouting


import React, { useContext, useEffect, useState } from 'react'
import { Typography, Button, Link } from '@uhc-tempo/components'
import config from '../../../../config'
import styled from "styled-components";
import UsePRPBills from '../../../../hooks/usePRPBills'
import UseMemberService from '../../../../hooks/useMemberService'
import Constant from '../../../../constants/constants'
import UseBaseService from '../../../../hooks/useBaseService'
import ApiCallError from './apiCallError'
import getCookieItem from '../../../../tools/getCookieItem'
import { Markup } from 'interweave'
import PremiumModal from "./premiumModal";
import { ProfileContext } from "../../../../contexts/profileContext"
import UseWalletService from "../../../../hooks/useWalletService";
import clickTrack from '../../../../hooks/clickTrack';
import "./premiumPayments.css";

const loginLang = getCookieItem("LOGINLOCALE")
const selectedLang = getCookieItem("lang")
const { aem_content_url } = config


const PremiumPayments = (props) => {
const {
  premiumPaymentTitle,
  premiumPaymentSubTitle,
  medicalPlan,
  autoPay,
  cancelAutoPay,
  clickPay,
  clickAutoPay,
  medicalMemberID,
  apiFailErrorMsg,
  prpMedicalPlanTermed,
  termedTitles,
  apiErrorMessage,
  contact,
  coverageStatus,
  isGalileo,
  defaultFullySubsidisedMessage,
  fullySubsidisedWithWalletMessage,
  fullySubsidisedWithoutWalletMessage,
  termedFullySubsidisedMessage,
  isNMPayExempt
} = props

const { profile } = useContext(ProfileContext);
const { loggedinMember, subscriber } = profile || {};
const { dependentSeqNbr, coverages } = loggedinMember || {};
const { medical } = coverages || {};
const { memberID, policyNumber, startDate } = medical || {};
const [fullySubdizedWithNoDue, setFullySubsidizedWithNoDue] = useState(false);
const [isWalletExist, setIsWalletExist] = useState(false);
const domain = window.location.origin + window.location.pathname
const [isPartialOrNonSubsidisedDependent, setIsPartialOrNonSubsidisedDependent] = useState(false)
const [checkRecurringObj, setCheckRecurringObj] = useState(false);
const [PRPBills, setPRPBills] = useState({
    error: false,
    loading: false,
  });
const [memberSubsidy, setMemberSubsidy] = useState({
    error: false,
    loading: false,
  });
const [checkMemberSubsidyObj, setCheckMemberSubsidyObj] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("PRPBills")) {
      setPRPBills(JSON.parse(sessionStorage.getItem("PRPBills")))
    }
    else {
      UsePRPBills(medicalMemberID, coverageStatus, data => {
        try {
          const { hasError, billings, pastDueAmt, currentDueAmt, netDueAmount, billedAmount, billDueDate, invoiceCreateDate } = data;
          if (hasError) {
            setPRPBills({ error: true });
          } else {
            // Commenting below code to avoid storing the data in session storage to fix "Clear text storage of sensitive information" as dashboard app is not used
           /* sessionStorage.setItem(
              "PRPBills",
              JSON.stringify({
                error: false,
                loading: true,
                billings,
                pastDueAmt, currentDueAmt, netDueAmount, billedAmount, billDueDate, invoiceCreateDate
              })
            ) */
            setPRPBills({
              error: false,
              loading: true,
              billings,
              pastDueAmt, currentDueAmt, netDueAmount, billedAmount, billDueDate, invoiceCreateDate
            });
          }
        } catch (error) { setPRPBills({ hasError: true, loading: true }) }
      });
    }
  }, [medicalMemberID]);

  useEffect(() => {
    if (sessionStorage.getItem("GetMemberService")) {
      setMemberSubsidy(JSON.parse(sessionStorage.getItem("GetMemberService")));
      setCheckMemberSubsidyObj(true);
    } else {    
      const startDateYr = new Date(startDate + 'T00:00:00').getFullYear()
      const currentDate = new Date()
      const CSToffSet = -360
      const offset= CSToffSet*60*1000;
      const currentDateWithOffset = new Date(currentDate.getTime()+offset)

      let memberSuffix = dependentSeqNbr ? dependentSeqNbr.substring(1) : "1";
      if (loggedinMember?.relationship !== 'SUBSCRIBER' && !loggedinMember?.isSubscriber) {
        memberSuffix  = subscriber && subscriber.length > 0 ? subscriber[0]?.dependentSeqNbr?.substring(1) : "1"
      }

      UseMemberService(memberID, policyNumber, memberSuffix, currentDateWithOffset, (data) => {
        try {
          const { hasError, subsOvrridePremium, subsidyAmount, stateSubsidyAmount } = data; 
          setMemberSubsidy({
            error: false,
            loading: true,
            subsOvrridePremium,
            subsidyAmount,
            stateSubsidyAmount
          });   
      
          if (hasError) {
            setMemberSubsidy({ error: true });
          } else {
            sessionStorage.setItem(
              "GetMemberService",
              JSON.stringify({
                error: false,
                loading: true,
                subsOvrridePremium,
                subsidyAmount,
                stateSubsidyAmount,
              })
            );
            setMemberSubsidy({
              error: false,
              loading: true,
              subsOvrridePremium,
              subsidyAmount,
              stateSubsidyAmount
            });
          }
          setCheckMemberSubsidyObj(true);
        } catch (error) {
          setMemberSubsidy({ hasError: true, loading: false });
          setCheckMemberSubsidyObj(true);
        }
      });
    }
  }, [memberID]);
  

  const { subsOvrridePremium, subsidyAmount, stateSubsidyAmount } = memberSubsidy || {};
  const aptcStateSubsidyAmount = (+subsidyAmount + +stateSubsidyAmount).toFixed(2)

  let totalDueAmount = ""
  const { billedAmount, billDueDate, billings, pastDueAmt, currentDueAmt } = PRPBills;

  if (coverageStatus !== "pre-effective") {
    totalDueAmount = (+currentDueAmt + +pastDueAmt).toFixed(2)
  } else {
    totalDueAmount = (+currentDueAmt).toFixed(2)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      const subsidized =
        parseFloat(subsOvrridePremium) - parseFloat(aptcStateSubsidyAmount) ===
        0
          ? true
          : false;
      const subdizedAndNoDue = parseFloat(totalDueAmount) <= 0.0 && subsidized;
      sessionStorage.setItem("subdizedAndNoDue", subdizedAndNoDue);
      sessionStorage.setItem("totalDueAmount", totalDueAmount);
      setFullySubsidizedWithNoDue(subdizedAndNoDue);
      setCheckRecurringObj(checkMemberSubsidyObj);
      if (!subsidized && loggedinMember?.relationship !== "SUBSCRIBER") {
        setIsPartialOrNonSubsidisedDependent(true);
      } else {
        setIsPartialOrNonSubsidisedDependent(false);
      }
    
    }, 3000);
    return () => clearTimeout(timer);
  }, [
    subsOvrridePremium,
    aptcStateSubsidyAmount,
    fullySubdizedWithNoDue,
    totalDueAmount,
    isPartialOrNonSubsidisedDependent,
    checkMemberSubsidyObj,
  ]);

  const currentDate = new Date();
  let date = currentDate.getDate();
  let month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();
  month = month > 9 ? month : "0" + month;
  date = date > 9 ? date : "0" + date;
  const asOfDate = month + "/" + date + "/" + year;
  //check for recurring payment search
  const { recurring_endpoint } = Constant;
  const { getmember_service_endpoint } = Constant;
  const [recurringObj, setRecurringObj] = useState()
  useEffect(() => {
    const getDateStringyyyymmdd = (strDate) => {
      strDate.toString('yyyy-MM-dd');
      var dd = strDate.getDate();
      var mm = strDate.getMonth() + 1; // January is 0!
      var yyyy = strDate.getFullYear();
      if (dd < 10) { dd = '0' + dd }
      if (mm < 10) { mm = '0' + mm }
      strDate = yyyy + '-' + mm + '-' + dd;
      return strDate;
    }
    let postObject = {
      "searchRecurringPaymentRequest": {
        "subscriberId": medicalMemberID,
        "consumerData": {
          "clientCode": "MYUHC",
          "instance": "CSP",
          "userId": ""
        },
        "subscriberContrivedKey": "",
        "groupId": "",
        "effectiveDate": getDateStringyyyymmdd(new Date(new Date().setMonth(new Date().getMonth()))) + "T00:00:00.000Z",
        "termDate": getDateStringyyyymmdd(new Date(new Date().setMonth(new Date().getMonth() + 2))) + "T23:59:59.000Z"
      }
    }
    UseBaseService(
      "searchRecurringPayment",
      "POST",
      "dmz",
      recurring_endpoint,
      postObject,
      response => {
        try {
          const { data, APIError } = response
          let recResponse = false;
          if (APIError) {
          }
          else {
            const recurData = data && data.data ? data.data : data ? data : {}
            recurData.forEach((element) => {
              if (element.hasOwnProperty("effectiveDate") && element.hasOwnProperty("termDate") && element.termDate.split("T")[0] === "9999-12-31" && recResponse === false) {
                recResponse = element;
              }
            })
            setRecurringObj(recurData[0])
          }
        } catch (err) {
          console.log(err);
        }
      }
    )
  }, [recurring_endpoint, medicalMemberID])
  sessionStorage.setItem('ISAUTOPAY', recurringObj ? true : false)

  const checkifWalletExist = (searchData) => {
    try {
      const { APIError, data, errData } = searchData;
      if (APIError) {
        if (errData.data.errors[0].code === '2000') {
          setIsWalletExist(false)

        } 
      } else if (data.data[0]) {
        setIsWalletExist(true);

      } else {
        setIsWalletExist(false)
      }
    } catch (error) {
      setIsWalletExist(false)
      console.log(error)
    }

  }

  useEffect(() => {
    const POSTOBJ = {
      subscriberId: memberID,
      groupId: policyNumber,
      exchangeId: '',
      activeInd: 'y'
  }

  UseWalletService('SEARCH', POSTOBJ, checkifWalletExist)

  },[memberID, policyNumber]);



  return (
    <>
    {loggedinMember?.relationship === "SUBSCRIBER" && loggedinMember?.isSubscriber && !isNMPayExempt ? 
      <>
      <div className={coverageStatus === 'active' ? 'payments' : ''}>
        <div className='styledContainer'>
          <div className={coverageStatus === 'pre-effective' && !isGalileo ? 'payments-top' :
            coverageStatus === 'pre-effective' && isGalileo ? 'payment-galileo' : 'active-bottom'}>
            {premiumPaymentTitle && premiumPaymentTitle.text ?
              <Typography.H2 style={{ color: 'rgb(0, 38, 119)', marginBottom: 0, marginTop: 10, fontSize: '1.5rem' }}>{premiumPaymentTitle.text}</Typography.H2>
              : termedTitles && termedTitles.title && termedTitles.title[3] ?
                <Typography.H2 style={{ color: 'rgb(0, 38, 119)', marginBottom: 0, fontSize: '1.5rem' }}>{termedTitles.title[3]}</Typography.H2>
                : <Typography.H2 className='premium-payment-head'>Your premium payment</Typography.H2>
            }
            {asOfDate ? <Typography.Paragraph style={{ color: 'rgb(90, 90, 90)', marginTop: 5, marginBottom: 30 }}>
              {' '}
              As of {asOfDate}
            </Typography.Paragraph> : ''}
            {billings ?
              <div className="styled-flex-wrap" style={coverageStatus === 'termed' ? { width: '75%', marginBottom: 40 } : {}}>
                <div className="pay-now-col">
                  {medicalPlan && medicalPlan.bodyTitle ?
                    <Typography.H3 className="tds-header__h4 payments-header premium-payments-header">{medicalPlan.bodyTitle}</Typography.H3>
                    : prpMedicalPlanTermed && prpMedicalPlanTermed.bodyTitle ?
                      <Typography.H3 className="tds-header__h4 payments-header premium-payments-header" >{prpMedicalPlanTermed.bodyTitle}</Typography.H3>
                      : null}
                  <div className="pay-now-list">
                    <div className='pay-now-list-items'>
                      <Typography.Paragraph
                        style={{ color: 'rgb(90, 90, 90)', marginTop: 0, marginBottom: 0, fontWeight: 600 }}>
                        {premiumPaymentSubTitle && premiumPaymentSubTitle.title && premiumPaymentSubTitle.title[0] ?
                          premiumPaymentSubTitle.title[0] : "Total amount due"}
                      </Typography.Paragraph>
                      {checkRecurringObj && totalDueAmount && !(pastDueAmt > "0") ? <Typography.Paragraph style={{ color: 'rgb(37,156,39)', fontSize: '1.5rem', marginTop: 0 }}>
                        ${totalDueAmount}
                      </Typography.Paragraph> : ''}
                      {checkRecurringObj && totalDueAmount && pastDueAmt > "0" ? <Typography.Paragraph style={{ color: 'rgb(196, 0, 0)', fontSize: '1.5rem', marginTop: 0 }}>
                        ${totalDueAmount}
                      </Typography.Paragraph> : ''}
                    </div>
                    <div className='pay-now-list-items'>
                      <Typography.Paragraph
                        style={{ color: 'rgb(90, 90, 90)', marginTop: 0, marginBottom: 0, fontWeight: 600 }}
                      >
                        {premiumPaymentSubTitle && premiumPaymentSubTitle.title && premiumPaymentSubTitle.title[1] ?
                          premiumPaymentSubTitle.title[1] : "Payment due date"}
                      </Typography.Paragraph>
                      {checkRecurringObj && billDueDate && !fullySubdizedWithNoDue ? <Typography.Paragraph style={{ color: 'rgb(0, 38, 119)', fontSize: '1.5rem', marginTop: 0 }}>
                        {billDueDate}
                      </Typography.Paragraph> : ''}
                      {checkRecurringObj && fullySubdizedWithNoDue ? <Typography.Paragraph style={{ color: 'rgb(0, 38, 119)', fontSize: '1.5rem', marginTop: 0, marginLeft:'3.5rem' }}>
                        <span>{'- -'}</span>
                      </Typography.Paragraph> : ''}
                    </div>
                    <div className='pay-now-list-para-items'>
                    {checkRecurringObj && !fullySubdizedWithNoDue ? medicalPlan && medicalPlan.description ? <Typography.Paragraph style={{ fontSize: 14, fontStyle: 'Italic', marginTop: 0 }}>
                        {medicalPlan.description.replace(/(<([^>]+)>)/gi, "")}
                      </Typography.Paragraph>
                        : prpMedicalPlanTermed && prpMedicalPlanTermed.description ?
                          <Typography.Paragraph style={{ fontSize: 14, fontStyle: 'Italic', marginTop: 0, width: '70%' }}>
                            {prpMedicalPlanTermed.description.replace(/(<([^>]+)>)/gi, "")}</Typography.Paragraph>
                          : '' : ''} 
                    {fullySubdizedWithNoDue && coverageStatus !== "termed" ? recurringObj ? <><Typography.Paragraph>
                            <Markup allowAttributes={true} content={defaultFullySubsidisedMessage?.description} />
                          </Typography.Paragraph></> :  '' : ''} 
                    </div>
                    {checkRecurringObj && !fullySubdizedWithNoDue && coverageStatus === 'termed' ? 
                    <Button text={prpMedicalPlanTermed && prpMedicalPlanTermed.footerTitle ? prpMedicalPlanTermed.footerTitle : 'Pay now'}
                      buttonType="primary-one"
                      className="premium-pay-now-btn"
                      id="pay-now-button"
                      onClick={()=>{clickPay(); 
                      clickTrack(
                        prpMedicalPlanTermed && prpMedicalPlanTermed.footerTitle ? prpMedicalPlanTermed.footerTitle : 'Pay now', 
                        "main-body", 
                        "button",
                        "internal", 
                        "")}} /> : ''}
                  </div>
                  {checkRecurringObj && !fullySubdizedWithNoDue && coverageStatus !== 'termed' && !(pastDueAmt > "0") ?<>
                    <Button text={medicalPlan && medicalPlan.footerTitle ? medicalPlan.footerTitle : 'Pay now'}
                      buttonType="primary-one"
                      className="premium-pay-now-btn"
                      id="pay-now"
                      onClick={()=>{clickPay(); 
                        clickTrack(
                          medicalPlan && medicalPlan.footerTitle ? medicalPlan.footerTitle : 'Pay now', 
                          "main-body", 
                          "button",
                          "internal", 
                          "")}} />
                          <div className='bottomBorder'></div>
                          </>
                    :
                    ''}
                  {checkRecurringObj && pastDueAmt > "0" && coverageStatus !== 'termed' ?
                    <div style={{ marginTop: 20 }} className="notification-container">
                      <div className='payment-notification notification-warning pay-now-btn-mb'
                        style={(loginLang === "es" || selectedLang === "es") ? {} : { display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex' }}>
                          <div className="notification-icon">
                            <svg className="MuiSvgIcon-root payment-utility-icon" focusable="false" color="#f5b700" aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20">
                              <g fill="none" fill-rule="evenodd"><path fill="currentColor" d="M0 19.5h22L11 .5z"></path>
                                <path fill="#000" d="M12 16.5h-2v-2h2zM12 12.5h-2v-4h2z"></path></g>
                            </svg>
                            <h2 className="sr-only">Warning Message</h2>
                          </div>
                          <Typography.Paragraph style={{ margin: "0 0 0 10px" }}>
                          {recurringObj && (new Date().getDate() <= 5) ? <><span style={{ fontFamily: '"UHC Sans Bold", Arial, sans-serif' }}>You are set up with auto-pay.</span>
                            <span style={{ display: 'block' }}>Payment will be taken out the first of the month and will not reflect here within 3-5 business days.</span></> : <><span style={{ fontFamily: '"UHC Sans Bold", Arial, sans-serif' }}>You are past due.</span>
                            <span style={{ display: 'block' }}>Complete payment now to continue coverage.</span></>}
                          </Typography.Paragraph>
                        </div>
                        {/* {!recurringObj || (new Date().getDate() > 5) ?  */}
                        <Button style={(loginLang === "es" || selectedLang === "es") ? { marginTop: 20 } : {}}
                          text={prpMedicalPlanTermed && prpMedicalPlanTermed.footerTitle ? prpMedicalPlanTermed.footerTitle : 'Pay now'}
                          buttonType="primary-three" 
                          className="pay-now-button"
                          onClick={()=>{clickPay(); 
                            clickTrack(
                              prpMedicalPlanTermed && prpMedicalPlanTermed.footerTitle ? prpMedicalPlanTermed.footerTitle : 'Pay now', 
                              "main-body", 
                              "button",
                              "internal", 
                              "")}}
                              id="pay-now-button" /> 
                          {/* : null} */}
                      </div>
                    </div> : null}
                </div>
                {fullySubdizedWithNoDue && coverageStatus === 'termed' ? 
                <div className="auto-pay-col">
                      <Typography.H3 className="tds-header__h4 payments-header">
                      Good news!
                      </Typography.H3>                      
                      <div>
                        <><Typography.Paragraph>
                            <Markup allowAttributes={true} content={termedFullySubsidisedMessage?.description} />
                          </Typography.Paragraph></>
                      <div>
                        <PremiumModal
                          subsOvrridePremium={subsOvrridePremium}
                          subsidyAmount={subsidyAmount}
                          billedAmount={billedAmount}
                          stateSubsidyAmount={stateSubsidyAmount}
                        />
                      </div>                  
                    </div>
                  </div> : ''}
                {coverageStatus !== 'termed' && !recurringObj && !isWalletExist &&
                  <div className="auto-pay-col">
                    {!fullySubdizedWithNoDue && autoPay && autoPay.bodyTitle ?
                      <Typography.H3 className="tds-header__h4 payments-header auto-pay-title">
                        {autoPay.bodyTitle}
                      </Typography.H3> : null}
                      {fullySubdizedWithNoDue && !recurringObj && !isWalletExist ?
                      <Typography.H3 className="tds-header__h4 payments-header">
                      Good news!
                      </Typography.H3> : null}
                      <div className="AutoPayList">
                      <div>
                      {!fullySubdizedWithNoDue && 
                        <><Typography.Paragraph>
                            <Markup allowAttributes={true} content={autoPay && autoPay.description ? autoPay.description : ''} />
                          </Typography.Paragraph><Button
                              style={{ maxHeight: 'none' }}
                              className='set-auto-btn-mobile'
                              buttonType="secondary-one"
                              text={autoPay && autoPay.footerTitle ? autoPay.footerTitle : ''}
                              id="set-auto-pay"
                              onClick={()=>{clickAutoPay(); 
                                clickTrack(
                                  autoPay && autoPay.footerTitle ? autoPay.footerTitle : '', 
                                  "main-body", 
                                  "button",
                                  "internal", 
                                  "")}} /></>
                      }
                      {fullySubdizedWithNoDue &&
                        <><Typography.Paragraph>
                            <Markup allowAttributes={true} content={fullySubsidisedWithoutWalletMessage?.description} />
                          </Typography.Paragraph></>
                      }
                      {fullySubdizedWithNoDue && 
                      <div>
                        <PremiumModal
                          subsOvrridePremium={subsOvrridePremium}
                          subsidyAmount={subsidyAmount}
                          billedAmount={billedAmount}
                          stateSubsidyAmount={stateSubsidyAmount}
                        />
                      </div>
                      }
                    </div>
                      {!fullySubdizedWithNoDue && autoPay && autoPay.imgPath ? <div className='img-container'>
                        <img src={`${aem_content_url}${autoPay.imgPath}`} alt="" style={{ margin: '-10px 0px -40px -20px' }} className='img-box'/>
                      </div> : ''}
                    </div>
                  </div>}

                {coverageStatus !== 'termed' && !recurringObj && isWalletExist &&
                  <div className="auto-pay-col">
                    {!fullySubdizedWithNoDue && autoPay && autoPay.bodyTitle ?
                      <Typography.H3 className="tds-header__h4 payments-header">
                        {autoPay.bodyTitle}
                      </Typography.H3> : null}
                      {fullySubdizedWithNoDue && !recurringObj && isWalletExist ?
                      <Typography.H3 className="tds-header__h4 payments-header">
                      Good news!
                      </Typography.H3> : null}
                      <div className="AutoPayList">
                      <div>
                      {!fullySubdizedWithNoDue && 
                        <><Typography.Paragraph>
                            <Markup allowAttributes={true} content={autoPay && autoPay.description ? autoPay.description : ''} />
                          </Typography.Paragraph><Button
                              style={{ maxHeight: 'none' }}
                              // className='prp-mobile'
                              buttonType="secondary-one"
                              text={autoPay && autoPay.footerTitle ? autoPay.footerTitle : ''}
                              id="set-auto-pay"
                              onClick={()=>{clickAutoPay(); 
                                clickTrack(
                                  autoPay && autoPay.footerTitle ? autoPay.footerTitle : '', 
                                  "main-body", 
                                  "button",
                                  "internal", 
                                  "")}}
                                  /></>
                      }
                      {fullySubdizedWithNoDue &&
                        <><Typography.Paragraph>
                            <Markup allowAttributes={true} content={fullySubsidisedWithWalletMessage?.description} />
                          </Typography.Paragraph></>
                      }
                      {fullySubdizedWithNoDue && 
                      <div>
                        <PremiumModal
                          subsOvrridePremium={subsOvrridePremium}
                          subsidyAmount={subsidyAmount}
                          billedAmount={billedAmount}
                          stateSubsidyAmount={stateSubsidyAmount}
                        />
                      </div>
                      }
                    </div>
                      {!fullySubdizedWithNoDue && autoPay && autoPay.imgPath ? <div>
                        <img src={`${aem_content_url}${autoPay.imgPath}`} alt="" style={{ margin: '-10px 0px -40px -20px' }} />
                      </div> : ''}
                    </div>
                  </div>}
            
                {coverageStatus !== 'termed' && recurringObj ?
                  <div className="auto-pay-col">
                    {cancelAutoPay && cancelAutoPay.bodyTitle ?
                      <Typography.H3 className="tds-header__h4 payments-header auto-pay-title">
                        {cancelAutoPay.bodyTitle}
                      </Typography.H3> : null}
                    <div className="AutoPayList">
                      <div>
                        <Typography.Paragraph>
                          {cancelAutoPay && cancelAutoPay.description ?
                            <Markup allowAttributes={true} content={cancelAutoPay.description} /> : ''}
                        </Typography.Paragraph>
                        <Link
                          style={{ maxHeight: 'none', cursor: 'pointer' }}
                          className='cancel-text-mobile' //prp-mobile 
                          text={cancelAutoPay && cancelAutoPay.footerTitle ? cancelAutoPay.footerTitle : "Cancel auto-pay"}
                          id="auto-pay"
                          href={cancelAutoPay && cancelAutoPay.href && cancelAutoPay.href}
                          onClick={()=>{
                            clickTrack(cancelAutoPay && cancelAutoPay.footerTitle ? cancelAutoPay.footerTitle : "Cancel auto-pay", 
                            "main-body", "text", 'internal', domain + cancelAutoPay && cancelAutoPay.href && cancelAutoPay.href)
                        }}
                        // onClick={onClickCancelRecurringPay} 
                        />
                      </div>
                      {cancelAutoPay && cancelAutoPay.imgPath ? <div>
                        <img src={`${aem_content_url}${cancelAutoPay.imgPath}`} alt="" style={{ margin: '-10px 0px -40px -20px' }} className='img-box' />
                      </div> : ''}
                    </div>
                  </div> : ''}
              </div> :
              apiFailErrorMsg && apiFailErrorMsg.title && apiFailErrorMsg.title[2] ?
                <ApiCallError text={`${apiFailErrorMsg.title[2]}${' '}${contact}`} />
                : apiErrorMessage && apiErrorMessage.title && apiErrorMessage.title[2] ?
                  <ApiCallError text={`${apiErrorMessage.title[2]}${' '}${contact}`} /> :
                  <ApiCallError text={`Premium Payment is currently unavailable while we work on our daily web maintenance. You can check back later or securely pay over the phone 24/7 by calling${' '}${contact}`} />
            }
          </div>
        </div>
      </div>
    </>: 
    <>
      {isPartialOrNonSubsidisedDependent && !loggedinMember?.isSubscriber && loggedinMember?.relationship !== "SUBSCRIBER" && !isNMPayExempt &&
        <>
        <div className={coverageStatus === 'active' ? 'payments' : ''}>
          <div className='styledContainer'>
            <div className={coverageStatus === 'pre-effective' && !isGalileo ? 'payments-top' :
              coverageStatus === 'pre-effective' && isGalileo ? 'payment-galileo' : 'active-bottom'}>
              <Typography.H2 style={{ color: 'rgb(0, 38, 119)', marginBottom: 0, fontSize: '1.5rem' }}>Premium payments</Typography.H2>
              {asOfDate ? <Typography.Paragraph style={{ color: 'rgb(90, 90, 90)', marginTop: 5, marginBottom: 30 }}>
                {' '}
                As of {asOfDate}
              </Typography.Paragraph> : ''}
              {billings ?
                <div className="styled-flex-wrap" style={coverageStatus === 'termed' ? { width: '75%', marginBottom: 40 } : {}}>
                  <div className="pay-now-col">
                    {medicalPlan && medicalPlan.bodyTitle ?
                      <Typography.H3 className="tds-header__h4 payments-header">{medicalPlan.bodyTitle}</Typography.H3>
                      : prpMedicalPlanTermed && prpMedicalPlanTermed.bodyTitle ?
                        <Typography.H3 className="tds-header__h4 payments-header" >{prpMedicalPlanTermed.bodyTitle}</Typography.H3>
                        : null}
                    <div className="pay-now-list">
                      <div>
                        <Typography.Paragraph
                          style={{ color: 'rgb(90, 90, 90)', marginTop: 0, marginBottom: 0, fontWeight: 600 }}>
                          {premiumPaymentSubTitle && premiumPaymentSubTitle.title && premiumPaymentSubTitle.title[0] ?
                            premiumPaymentSubTitle.title[0] : "Total amount due"}
                        </Typography.Paragraph>
                        {totalDueAmount && !(pastDueAmt > "0") ? <Typography.Paragraph style={{ color: 'rgb(37,156,39)', fontSize: '1.5rem', marginTop: 0 }}>
                          ${totalDueAmount}
                        </Typography.Paragraph> : ''}
                        {totalDueAmount && pastDueAmt > "0" ? <Typography.Paragraph style={{ color: 'rgb(196, 0, 0)', fontSize: '1.5rem', marginTop: 0 }}>
                          ${totalDueAmount}
                        </Typography.Paragraph> : ''}
                      </div>
                      <div>
                        <Typography.Paragraph
                          style={{ color: 'rgb(90, 90, 90)', marginTop: 0, marginBottom: 0, fontWeight: 600 }}
                        >
                          {premiumPaymentSubTitle && premiumPaymentSubTitle.title && premiumPaymentSubTitle.title[1] ?
                            premiumPaymentSubTitle.title[1] : "Payment due date"}
                        </Typography.Paragraph>
                        {billDueDate ? <Typography.Paragraph style={{ color: 'rgb(0, 38, 119)', fontSize: '1.5rem', marginTop: 0 }}>
                          {billDueDate}
                        </Typography.Paragraph> : ''}
                      </div>
                    </div>
                  </div>

                  <div className="auto-pay-col">
                      <div className="AutoPayList">
                        <Typography.H3 className="tds-header__h4 payments-header">
                          {"What is a premium payment?"}
                        </Typography.H3>
                      <div>
                  </div>
                      </div>
                      <div>
                        <Typography.Paragraph>
                            <Markup content={"This is the amount that is owed monthly to keep you covered. This payment is made by the primary account holder or subscriber each month."} />
                        </Typography.Paragraph>
                      </div>
                  </div>                            
                </div> :
                apiFailErrorMsg && apiFailErrorMsg.title && apiFailErrorMsg.title[2] ?
                  <ApiCallError text={`${apiFailErrorMsg.title[2]}${' '}${contact}`} />
                  : apiErrorMessage && apiErrorMessage.title && apiErrorMessage.title[2] ?
                    <ApiCallError text={`${apiErrorMessage.title[2]}${' '}${contact}`} /> :
                    <ApiCallError text={`Premium Payment is currently unavailable while we work on our daily web maintenance. You can check back later or securely pay over the phone 24/7 by calling${' '}${contact}`} />
              }
            </div>
          </div>
        </div>
      </>
      }
    </>
    }
    </>
  )
}

export default PremiumPayments

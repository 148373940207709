import Constants from "../constants/constants"
import UseBaseService from "../hooks/useBaseService"

const UsePlanDetail = (callback) => {
  const { plandetail_endpoint } = Constants;
  if (plandetail_endpoint.indexOf('offline') < 0) {
    UseBaseService("plan detail", "GET", "dmz", plandetail_endpoint, "",
      response => {
        const { APIError, data } = response
        if (APIError) {
          //logging for API error
          console.log("PlanDetail API Error: Check api call!")
          callback({ hasError: true });
        } else {
          try {
            //access data from baseservice
            const { member } = data
            const { eligibility } = member[0]
            const dentalBenCov = eligibility.benefitSet.find(info =>
              info.coverageTypeCode.code === 'D' ||
              info.coverageTypeCode.code === 'DD' ||
              info.coverageTypeCode.code === 'DE' ||
              info.coverageTypeCode.code === 'DM' ||
              info.coverageTypeCode.code === 'DS' ||
              info.coverageTypeCode.code === 'DV' ||
              info.coverageTypeCode.code === 'OX')
            const { planNameType } = dentalBenCov
            const brandingLongName = planNameType.brandingLongName
            callback({ hasError: false, brandingLongName });
          } catch (error) {
            callback({ hasError: true })
            //logging for errors
          }
        }
      }
    )
  }
  else if (plandetail_endpoint.indexOf('offline') > 0) {
    const fetchPlandetail = async () => {
      const response = await fetch(plandetail_endpoint);
      const plandetailResponse = await response.json();
      return plandetailResponse;
    }
    fetchPlandetail().then(Response => {
      const { data, Error } = Response || {};
      if (Error) {
        console.log("PlanDetail API Error: Check api call!")
        callback({ hasError: true });
      } else {
        try {
          //access data from baseservice
          const { member } = data
          const { eligibility } = member[0]
          const dentalBenCov = eligibility.benefitSet.find(info =>
            info.coverageTypeCode.code === 'D' ||
            info.coverageTypeCode.code === 'DD' ||
            info.coverageTypeCode.code === 'DE' ||
            info.coverageTypeCode.code === 'DM' ||
            info.coverageTypeCode.code === 'DS' ||
            info.coverageTypeCode.code === 'DV' ||
            info.coverageTypeCode.code === 'OX')
          const { planNameType } = dentalBenCov
          const brandingLongName = planNameType.brandingLongName
          callback({ hasError: false, brandingLongName });
        } catch (error) {
          callback({ hasError: true })
          //logging for errors
        }
      }
    })
  }
}
export default UsePlanDetail;

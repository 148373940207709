import React, { useState, useEffect, useContext } from 'react'
import { Button, BrandIcon, Typography, Link } from '@uhc-tempo/components'
import { IconProv } from '@uhc-tempo/icons'
import Config from '../../../../../config';
import getCookieItem from "../../../../../tools/getCookieItem"
import ChangeProvider from './changeProvider';
import Notification from './notification';
import NotificationVariation from './notificationVariation';
import { ProfileContext } from '../../../../../contexts/profileContext';
import Manage from './manage';
import ManageSubcriber from './manageSubcriber';
import styles from './notification.module.css';
import Referral from './referral';
//  import { PrimaryCareContext } from '../../../../../contexts/primarycareContext';
import RallyRedirects from '../../../../../constants/rally-redirects';
import clickTrack from '../../../../../hooks/clickTrack';

const { aem_content_url } = Config
const { view_pcp } = RallyRedirects || {}
const loginLang = getCookieItem("LOGINLOCALE")
const selectedLang = getCookieItem("lang")

//US4949442, US4988117: Don't change rally_connect_lang value
let rally_connect_lang = "en-US"
if (loginLang === "es" || selectedLang === "es") {
  rally_connect_lang = "es"
}


const PCPCard = props => {
  const { profile } = useContext(ProfileContext) || {};
  const { loggedinMember, dependents } = profile || {};
  const { coverages, isSubscriber } = loggedinMember || {};
  const { medical } = coverages || {};
  const { coverageStatus } = medical || {};
  const domain = window.location.origin + window.location.pathname
  // const { primarycare: { pcpData: { primarycareData = [] } = {} }, } = useContext(PrimaryCareContext) || {};

  const {
    isGalileo,
    isKelseySeybold,
    dependentSeqNbr,
    isPrimarycareSet,
    apiFailErrorMsg,
    pcpInfo,
    referralTitle,
    referralDescription,
    referralImage,
    pcpLinkText2,
    pcpUrl2,
    urlType2,
    situsState,
    pcpPopup,
    viewReferrals,
    selectedDependentSeqNbr,
    selectedMedical
  } = props || null

  const [pcpNames, setPcpNames] = useState([]);
  const primarycareData = props.pcpInfo && props.pcpInfo.primarycareData ?
    props.pcpInfo.primarycareData : []

  useEffect(() => {
    if (props.pcpInfo !== null && props.pcpInfo.primarycareData !== null && props.pcpInfo.primarycareData !== undefined && props.pcpInfo.primarycareData.length) {
      const pcpNames = props.pcpInfo.primarycareData.map(data => data.pcpName).filter(data => data.trim())
      setPcpNames(pcpNames)
    }
  }, [props.pcpInfo]);

  let pcpSet = false
  const TilteCase = str => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }

  return (
    <>
      {/* <Card backgroundColor="primary" layout="custom" border className="provider-card" style={{ width: '100%' }}>
        <div style={{ width: '100%', display: 'flex', padding: 30 }}> */}
      <div>
        {isPrimarycareSet && pcpInfo !== null && pcpInfo.primarycareData !== null && pcpInfo.primarycareData !== undefined && dependentSeqNbr !== null &&
          pcpInfo.primarycareData.map((pcpdata, i) => {
            if (isSubscriber && dependents.length >= 1 && pcpdata && pcpdata.depSeqNbr === "01" && !pcpdata.pcpError && pcpNames.length === 1) {
              pcpSet = true
              const isClinic = pcpdata.pcpName && (pcpdata.pcpName.toUpperCase().trim() === ("KELSEY-SEYBOLD CLINIC") ||
                pcpdata.pcpName.toUpperCase().trim() === ("GALILEO HEALTH")) ? true : false;
              const isKelseyClinic = pcpdata.pcpName && pcpdata.pcpName.toUpperCase().includes("KELSEY") ? true : false
              return (
                <>
                  <div className={styles.card}>
                    <div className={styles.cardContainerLeft}>
                      <div className={styles.providerWrapper2}>
                        <div className={styles.leftSection}>
                          <div className={styles.itemOne}>
                            <BrandIcon icon={IconProv} size="EXTRA_SMALL" styleVariation="TWO_TONE" />
                            <div className="tempo-v1">
                              <Typography.Paragraph className="provider-name" id="provider-name">
                                {!isClinic ?
                                  <span className={styles.styledHeading}>Dr.{" "}{TilteCase(pcpdata.pcpName)}</span> :
                                  <span className={styles.styledHeading}>{TilteCase(pcpdata.pcpName)}</span>}
                              </Typography.Paragraph>
                              {pcpLinkText2 && urlType2 === 'pcpUrl' && !isClinic ?
                                <Link size="small"
                                  id="see-provider-details"
                                  href={`${view_pcp}/${pcpdata.providerID &&
                                    pcpdata.providerID.id &&
                                    pcpdata.providerID.id}?partnerId=true&locale=${rally_connect_lang}`}
                                  onClick={() => clickTrack(pcpLinkText2, "main-body", "text", 'internal',
                                    domain + `${view_pcp}/${pcpdata.providerID &&
                                    pcpdata.providerID.id &&
                                    pcpdata.providerID.id}?partnerId=true&locale=${rally_connect_lang}`)}
                                  text={pcpLinkText2} /> :
                                pcpLinkText2 && isKelseyClinic ?
                                  <Link size="small" id="see-provider-details"
                                    href="https://www.kelsey-seybold.com/find-a-houston-doctor"
                                    text={pcpLinkText2}
                                    target="_blank"
                                    external
                                    onClick={() =>
                                      clickTrack(pcpLinkText2, "main-body", "text", 'external', 'https://www.kelsey-seybold.com/find-a-houston-doctor')}
                                  /> :
                                  pcpLinkText2 && !isClinic &&
                                  <Link size="small" id="see-provider-details" href={pcpUrl2}
                                    text={pcpLinkText2}
                                    onClick={() =>
                                      clickTrack(pcpLinkText2, "main-body", "text", 'internal', domain + pcpUrl2)}
                                  />}
                            </div>
                          </div>
                          {!isKelseySeybold ?
                            <ChangeProvider dependentSeqNbr={dependentSeqNbr} /> : null}
                        </div>
                      </div>
                    </div>
                    {!isKelseySeybold ?
                      <Referral
                        viewReferrals={viewReferrals}
                        seqNumbr={selectedDependentSeqNbr}
                      /> : null}
                  </div>
                  {!isKelseySeybold ?
                    <ManageSubcriber pcpPopup={pcpPopup} /> : null}
                </>
              )
            } else if ((isSubscriber || 1) && pcpdata !== null && !pcpdata.pcpError && (dependentSeqNbr === pcpdata.depSeqNbr) && pcpdata.pcpName) {
              pcpSet = true
              const isClinic = pcpdata.pcpName && (pcpdata.pcpName.toUpperCase().trim() === ("KELSEY-SEYBOLD CLINIC") ||
                pcpdata.pcpName.toUpperCase().trim() === ("GALILEO HEALTH")) ? true : false;
              const isKelseyClinic = pcpdata.pcpName && pcpdata.pcpName.toUpperCase().includes("KELSEY") ? true : false
              return (
                <>
                  <div className={styles.card}>
                    <div className={styles.cardContainerLeft}>
                      <div className={styles.providerWrapper2}>
                        <div className={styles.leftSection}>
                          <div className={styles.itemOne}>
                            <BrandIcon icon={IconProv} size="EXTRA_SMALL" styleVariation="TWO_TONE" />
                            <div className="tempo-v1">
                              <Typography.Paragraph className="provider-name" id="provider-name">
                                {!isClinic ?
                                  <span className={styles.styledHeading}>Dr.{" "}{TilteCase(pcpdata.pcpName)}</span> :
                                  <span className={styles.styledHeading}>{TilteCase(pcpdata.pcpName)}</span>}
                              </Typography.Paragraph>
                              {pcpLinkText2 && urlType2 === 'pcpUrl' && !isClinic ?
                                <Link size="small"
                                  id="see-provider-details"
                                  href={`${view_pcp}/${pcpdata.providerID &&
                                    pcpdata.providerID.id &&
                                    pcpdata.providerID.id}?partnerId=true&locale=${rally_connect_lang}`}
                                  text={pcpLinkText2}
                                  onClick={() =>
                                    clickTrack(pcpLinkText2, "main-body", "text", 'internal',
                                      domain + `${view_pcp}/${pcpdata.providerID &&
                                      pcpdata.providerID.id &&
                                      pcpdata.providerID.id}?partnerId=true&locale=${rally_connect_lang}`)}
                                /> :
                                pcpLinkText2 && isKelseyClinic ?
                                  <Link size="small" id="see-provider-details"
                                    href="https://www.kelsey-seybold.com/find-a-houston-doctor"
                                    text={pcpLinkText2}
                                    target="_blank"
                                    external
                                    onClick={() =>
                                      clickTrack(pcpLinkText2, "main-body", "text", 'external', 'https://www.kelsey-seybold.com/find-a-houston-doctor')}
                                  /> :
                                  pcpLinkText2 && !isClinic ?
                                    <Link size="small" id="see-provider-details"
                                      href={pcpUrl2}
                                      text={pcpLinkText2}
                                      onClick={() =>
                                        clickTrack(pcpLinkText2, "main-body", "text", 'internal', domain + pcpUrl2)}
                                    /> : null}
                            </div>
                          </div>
                          {!isKelseySeybold ?
                            <ChangeProvider dependentSeqNbr={dependentSeqNbr} /> : null}
                          {!isKelseySeybold && isSubscriber && pcpNames.length > 1 ?
                            <Manage loggedinMember={loggedinMember} dependents={dependents} primarycareData={primarycareData} pcpPopup={pcpPopup}
                            /> : null}
                        </div>
                      </div>
                    </div>
                    {!isKelseySeybold ?
                      <Referral
                        viewReferrals={viewReferrals}
                        seqNumbr={selectedDependentSeqNbr}
                        selectedMedical={selectedMedical}
                      /> : null}
                  </div>
                </>
              )
            } else if (pcpdata.pcpError && pcpNames.length === 0 && dependentSeqNbr === pcpdata.depSeqNbr) {
              return (
                <NotificationVariation isKelseySeybold={isKelseySeybold} viewReferrals={viewReferrals} seqNumbr={selectedDependentSeqNbr} selectedMedical={selectedMedical} />
              )
            } else if (pcpdata.pcpError && isSubscriber && pcpdata.depSeqNbr === "01" && pcpNames.length >= 1) {
              return (
                <NotificationVariation isKelseySeybold={isKelseySeybold} viewReferrals={viewReferrals} seqNumbr={selectedDependentSeqNbr} selectedMedical={selectedMedical} />
              )
            }
          })
        }
        {/* ********************** Referrals section ****************** */}
        {/* ********************** Referrals section ****************** */}
        {/* </div>
      </Card> */}
      </div>
      {/* ********************** Referrals Description section ****************** */}
      {
        coverageStatus === "active" && referralDescription !== null && referralTitle !== null ?
          <div style={{ display: 'block', padding: '0 20px', backgroundColor: 'rgb(229, 248, 251)', margin: '10px 0' }}>
            <div className="flex-items">
              {/* <img src={HealthProvider} alt="" style={{ height: '109px' }} /> */}
              <img className="desktop" src={`${aem_content_url}${referralImage}`}
                alt="" style={{ height: '109px' }} />
              <Typography.Paragraph style={{ padding: '10px 0 10px 10px' }}>
                {/* <span className="bold-p">{referralTitle} </span> */}
                <span>{referralDescription}</span>
              </Typography.Paragraph>
            </div>
          </div> : null
      }
    </>
  )
}
export default PCPCard


import React, { useEffect, useState } from 'react'
import { Card, Section, Flex } from '@uhc/pattern-library'
import { Link, BrandIcon } from '@uhc-tempo/components'
import { IconPillBot, IconSavings, IconProv, Icon_$0 } from '@uhc-tempo/icons'
import config from '../../../../../config'
import RallyRedirects from '../../../../../constants/rally-redirects'
import clickTrack from '../../../../../hooks/clickTrack'

const { aem_content_url } = config
const { find_provider } = RallyRedirects;

const PlanInfoMobile = props => {
  const { dashboardviewFor, findProvider, viewBenefits, mngPrpLink, mngRxLink, hsaLink, activeRX, totalBalance, isHSAEligState, isHSAEligible, isGalileo, isNMPayExempt } = props || {}
  const [fullySubdizedWithNoDue, setFullySubsidizedWithNoDue] = useState(false);
  const [totalDueAmount, setTotalDueAmount] = useState(false);
  const domain = window.location.origin + window.location.pathname

  useEffect(() => {
    const timer = setTimeout(() => {
      const totalDueAmount = sessionStorage.getItem("totalDueAmount");
      const subdizedAndNoDue = sessionStorage.getItem("subdizedAndNoDue") === 'true'? true : false;
      setFullySubsidizedWithNoDue(subdizedAndNoDue);
      setTotalDueAmount(totalDueAmount);
    }, 3000);
    return () => clearTimeout(timer);
  }, [fullySubdizedWithNoDue,totalDueAmount]);

  return (
    <>
      <div className="mobile">
        <Section style={{ position: 'relative', top: '-120px' }}>
          <Card>
            <Card.Content>
              {!isGalileo ? <Flex alignItems="center">
                <Flex>
                  {findProvider && findProvider.imgPath ? <img src={`${aem_content_url}${findProvider.imgPath}`} alt=''
                    style={{ height: 48, margin: 'auto' }} /> :
                    <BrandIcon icon={IconProv} size="EXTRA_SMALL" styleVariation="TWO_TONE_DARK_CIRCLE" />}
                </Flex>
                <Flex
                  alignItems="center"
                  flex={2}
                  style={{
                    padding: ' 20px 0',
                    borderBottom: '1px solid #E0E0E0',
                    justifyContent: 'space-between',
                    marginLeft: 15,
                  }}
                >
                  <Link
                    noIcon={true}
                    href={find_provider}
                    id="find-provider"
                    text={findProvider.bodyTitle}
                    onClick={()=>
                      clickTrack(findProvider.bodyTitle, "main-body", "text", 'internal', domain + find_provider)}
                  />
                  <img src={`${aem_content_url}/content/dam/myuhc/consumer/assets/myuhc/next-arrow.svg`} alt='' />
                </Flex>
              </Flex> :
                <Flex alignItems="center">
                  <Flex>
                    {viewBenefits && viewBenefits.imgPath ? <img src={`${aem_content_url}${viewBenefits.imgPath}`} alt=''
                      style={{ height: 48, margin: 'auto' }} /> :
                      <BrandIcon icon={IconProv} size="EXTRA_SMALL" styleVariation="TWO_TONE_DARK_CIRCLE" />}
                  </Flex>
                  <Flex
                    alignItems="center"
                    flex={2}
                    style={{
                      padding: ' 20px 0',
                      borderBottom: '1px solid #E0E0E0',
                      justifyContent: 'space-between',
                      marginLeft: 15,
                    }}
                  >
                    <Link noIcon={true} href={viewBenefits.href} id="find-provider" text={viewBenefits.bodyTitle} 
                    onClick={()=>
                      clickTrack(viewBenefits.bodyTitle, "main-body", "text", 'internal', domain + viewBenefits.href)}
                      />
                    <img src={`${aem_content_url}/content/dam/myuhc/consumer/assets/myuhc/next-arrow.svg`} alt='' />
                  </Flex>
                </Flex>}
              {dashboardviewFor.relationship === "SUBSCRIBER" && !fullySubdizedWithNoDue && !isNMPayExempt ?
                <Flex alignItems="center">
                  <Flex>
                    {mngPrpLink && mngPrpLink.imgPath ? <img src={`${aem_content_url}${mngPrpLink.imgPath}`} alt=''
                      style={{ height: 48, margin: 'auto' }} /> :
                      <BrandIcon icon={Icon_$0} size="EXTRA_SMALL" styleVariation="TWO_TONE_DARK_CIRCLE" />}
                  </Flex>
                  <Flex
                    alignItems="center"
                    flex={2}
                    style={{
                      padding: ' 20px 0',
                      borderBottom: '1px solid #E0E0E0',
                      justifyContent: 'space-between',
                      marginLeft: 15,
                    }}
                  >
                    {mngPrpLink ? <Link noIcon={true} href={mngPrpLink.href} id="make-payment" text={mngPrpLink.bodyTitle}
                     onClick={()=>
                      clickTrack(mngPrpLink.bodyTitle, "main-body", "text", 'internal', domain + mngPrpLink.href)}
                       /> : null}
                    <img src={`${aem_content_url}/content/dam/myuhc/consumer/assets/myuhc/next-arrow.svg`} alt='' />
                  </Flex>
                </Flex> : null}
              {activeRX ? <Flex alignItems="center">
                <Flex>
                  {mngRxLink && mngRxLink.imgPath ? <img src={`${aem_content_url}${mngRxLink.imgPath}`} alt=''
                    style={{ height: 48, margin: 'auto' }} /> :
                    <BrandIcon icon={IconPillBot} size="EXTRA_SMALL" styleVariation="TWO_TONE_DARK_CIRCLE" />}
                </Flex>
                <Flex
                  alignItems="center"
                  flex={2}
                  style={{
                    padding: ' 20px 0',
                    borderBottom: '1px solid #E0E0E0',
                    justifyContent: 'space-between',
                    marginLeft: 15,
                  }}
                >
                  {mngRxLink ? <Link noIcon={true} href={mngRxLink.href} id="manage-rx" text={mngRxLink.bodyTitle} 
                  onClick={()=>
                    clickTrack(mngRxLink.bodyTitle, "main-body", "text", 'internal', domain + mngRxLink.href)}/> : null}
                  <img src={`${aem_content_url}/content/dam/myuhc/consumer/assets/myuhc/next-arrow.svg`} alt='' />
                </Flex>
              </Flex> : null}
              {dashboardviewFor.relationship === "SUBSCRIBER" &&
                isHSAEligState && isHSAEligible ?
                <Flex alignItems="center">
                  <Flex>
                    {hsaLink && hsaLink.imgPath ? <img src={`${aem_content_url}${hsaLink.imgPath}`} alt=''
                      style={{ height: 48, margin: 'auto' }} /> :
                      <BrandIcon icon={IconSavings} size="EXTRA_SMALL" styleVariation="TWO_TONE_DARK_CIRCLE" />}
                  </Flex>
                  <Flex
                    alignItems="center"
                    flex={2}
                    style={{
                      padding: ' 20px 0',
                      justifyContent: 'space-between',
                      marginLeft: 15,
                    }}
                  >
                    {hsaLink ? <Link noIcon={true} href={hsaLink.href + '#/hsa'} id="hsa"
                    onClick={()=>
                      clickTrack(hsaLink.bodyTitle, "main-body", "text", 'internal', domain + hsaLink.href + '#/hsa')}
                      >
                      <span className="link-text">{hsaLink.bodyTitle}</span>
                      &nbsp;
                  {totalBalance ? <span className="link-text">(${totalBalance})</span> : null}
                    </Link> : null}
                    <img src={`${aem_content_url}/content/dam/myuhc/consumer/assets/myuhc/next-arrow.svg`} alt='' />
                  </Flex>
                </Flex> : null}
            </Card.Content>
          </Card>
        </Section>
      </div>
    </>
  )
}

export default PlanInfoMobile

import info from '../logger/info'
import error from '../logger/error'
import Axios from "axios"
const UseGraphqlApi = (query, callback) => {
    const startTime = new Date()
    const domain = window.location.host;
    Axios.get(query)
    .then((response) => {
        const data = response?.data?.data
        info({
            responseCode: '200',
            function: 'UseGraphqlApi',
            status: 'success',
            query: query,
            duration: Date.now() - startTime.getTime()
        })
        callback({ isError: false, data })
    })
    .catch((err) => {
        const logObj = {
            function: 'UseGraphqlApi',
            status: 'error',
            query: query,
            error: err,
            duration: Date.now() - startTime.getTime()
        }
        info(logObj)
        error(logObj)
        callback({ isError: true })
    })
}

export default UseGraphqlApi

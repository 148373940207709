import { useContext } from 'react'
import { ProfileContext } from '../../contexts/profileContext'
import getPlanYear from '../../tools/getPlanYear'


const MessageboxQuery = () => {
    const { profile } = useContext(ProfileContext) || {}
    const { loggedinMember } = profile || {}
    const { coverages,isSubscriber } = loggedinMember || {}
    const { medical } = coverages || {}
    const { situsState, reportingCode, coverageStatus, startDate } = medical || {}

    const planYear = getPlanYear(startDate)

    let memberStatus = 'effective'
    if (coverageStatus === 'active') {
        memberStatus = 'effective'
    } else if (coverageStatus === 'pre-effective') {
        memberStatus = 'pre-effective'
    }

    let memberType;
  if(isSubscriber){
     memberType = "subscriber";
  }
  else{
     memberType = "dependent";
  }
  const searchPath = `/content/dam/iex/${planYear}/${memberStatus}/dashboard/modeloverlay`
  const requestParameters = `;searchPath=${searchPath};situsState=${situsState};reportingCode=${reportingCode}`
  const requestPath = `/myuhc/graphql/execute.json/IEX/MessageboxQuery.json${encodeURIComponent(requestParameters)}`
  return requestPath;
}
export default MessageboxQuery
import React, { useContext, useEffect, useState } from "react";
import { Section, Card, Flex, Typography as DplTypography, Heading } from '@uhc/pattern-library'
import { Typography, BrandIcon as Icon, Button, Link, UtilityIcon } from "@uhc-tempo/components"
import { IconProv } from '@uhc-tempo/icons'
import Arrow from '@material-ui/icons/ArrowForward'
import styled from 'styled-components'
import UseGraphqlApi from "../../../../../hooks/useGraphqlApi";
import DoctorsQuery from "./doctorsQuery";
import { Loader } from "@uhc/pattern-library";
import { PrimaryCareContext } from "../../../../../contexts/primarycareContext"
import ChangeProviderModal from "./modalChangeProvider"
import Config from '../../../../../config'
import GetCookieItem from "../../../../../tools/getCookieItem";
import RallyRedirects from '../../../../../constants/rally-redirects';
import GenericError from '../../../../shared-components/error'
import getFilteredContentList from "../../../../../tools/getFilteredContentList";
import Create from '@material-ui/icons/Create'
import clickTrack from "../../../../../hooks/clickTrack";

const loginLang = GetCookieItem("LOGINLOCALE");
const selectedLang = GetCookieItem("lang");

//US4949442, US4988117: Don't change rally_connect_lang value
let rally_connect_lang = "en-US"
if (loginLang === "es" || selectedLang === "es") {
  rally_connect_lang = "es"
}

const { aem_content_url } = Config
const { change_pcp, view_pcp } = RallyRedirects || {}
const Desktop = styled.div`
  @media (max-width: 991px) {
    display: none;
  }
`
const Mobile = styled.div`
   @media (min-width: 991px) {
      display: none;
   }
`

const ProviderFacilities = props => {
  const {
    pcpTitle,
    pcp,
    // savedProvider,
    changeProvider,
    providerUrl,
    dependentSeqNbr,
    situsState,
    isGalileo,
    isKelseySeybold
  } = props

  const { primarycare } = useContext(PrimaryCareContext) || {}
  const { pcpData, isPrimarycareSet } = primarycare || {}
  const pcpInfo = pcpData ? pcpData : null
  let pcpSet = false

  const TilteCase = str => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }
  const [loading, setLoading] = useState(true);
  const [hasError, setError] = useState(false);

  const [doctorsState, setDoctorsList] = useState([]);
  const getDoctorsList = DoctorsQuery();

  useEffect(() => {
    UseGraphqlApi(getDoctorsList, DoctorsQueryHandler);
  }, []);

  const DoctorsQueryHandler = (response) => {
    if (response && !response.isError) {
      let dataContent = response.data
      if (dataContent) {
        if (dataContent.listTileList.items.length > 0) {
          const listWithDates = getFilteredContentList(dataContent ?.listTileList ?.items)
          if (listWithDates.length > 0) {
            setDoctorsList(
              listWithDates[0]
            );
          }
          else {
            setDoctorsList(
              dataContent.listTileByPath && dataContent.listTileByPath.item ?
                dataContent.listTileByPath.item : null
            );
          }
        }
        else if (dataContent.listTileByPath.item) {
          setDoctorsList(
            dataContent.listTileByPath && dataContent.listTileByPath.item ?
              dataContent.listTileByPath.item : null
          );
        }
      }

    } else {
      setError(true);
    }
    setLoading(false);
  };
  const { title, image, description, linkText, urlType, url } = doctorsState || {}

  const { rally_url, rally_vc_url, legacy_url, find_provider } = Config
  const domain = window.location.origin + window.location.pathname

  let newUrl = ''
  function renderUrl(urlType) {
    if (urlType === "pcpUrl") {
      isPrimarycareSet && pcpInfo !== null && pcpInfo.primarycareData !== null && pcpInfo.primarycareData !== undefined && dependentSeqNbr !== null &&
        pcpInfo.primarycareData.map((pcpdata) => {
          if ((dependentSeqNbr === pcpdata.depSeqNbr) && pcpdata.providerID && pcpdata.providerID.id && pcpdata.pcpError === false) {
            newUrl = `${view_pcp}/${pcpdata.providerID.id}?partnerId=true&locale=${rally_connect_lang}`
          }
        })
    }
    else if (urlType === "rallyUrl") {
      newUrl = `${rally_url}` + url
    }
    else if (urlType === "rallySSO") {
      newUrl = `${rally_vc_url}` + url
    }
    else if (urlType === "pageUrl" || urlType === "default" || urlType === "externalUrl") {
      newUrl = url
    }
    else if (urlType === "legacyUrl") {
      newUrl = `${legacy_url}` + url
    }
    else {
      newUrl = `${find_provider}`
    }
    return newUrl
  }
  // console.log(renderUrl(urlType))
  return (
    <>
      {loading ? (
        <Loader Blocking />
      ) : (
          <>
            {hasError ? (
              <GenericError></GenericError>
            ) : (
                <Section border
                  style={!isGalileo ? { position: 'relative', top: -90, paddingBottom: 30, backgroundColor: 'rgb(251, 252, 254)' } :
                    { position: 'relative', paddingBottom: 30, backgroundColor: 'rgb(251, 252, 254)' }}>
                  <Desktop>
                    <Flex alignItems="center" >
                      {!isGalileo && pcpTitle && pcpTitle.text ?
                        <Typography.H2 className="tds-header__h3">
                          {pcpTitle.text || "Meet your doctor"}
                        </Typography.H2> :
                        <Typography.H2 className="tds-header__h3">Primary care provider</Typography.H2>}
                    </Flex>

                    <Card span={12} spanLg={6} style={{ width: '100%', margin: 0 }}>
                      <div style={isGalileo ? { width: '100%', display: 'flex', alignItems: 'center' } : { width: '100%', display: 'flex' }}>
                        <div style={{ flex: 1 }}>
                          <div className="provider-center" style={{ padding: 40 }}>
                            {isPrimarycareSet && pcpInfo !== null && pcpInfo.primarycareData !== null && pcpInfo.primarycareData !== undefined && dependentSeqNbr !== null &&
                              pcpInfo.primarycareData ? pcpInfo.primarycareData.map((pcpdata, i) => {
                                if (pcpdata !== null && (dependentSeqNbr === pcpdata.depSeqNbr) && pcpdata.pcpName
                                  && pcpdata.pcpError === false) {
                                  pcpSet = true;
                                  return (
                                    <div key={i}>
                                      {!isGalileo &&
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginBottom: 30,
                                          }}
                                        >
                                          {pcp && pcp.bodyTitle ? <Heading level="3" style={{ color: 'rgb(0, 38, 119)', fontSize: 25, margin: 0 }}>
                                            {pcp.bodyTitle || "Primary Care Provider"}
                                          </Heading> : null}
                                          {changeProvider && changeProvider.title && changeProvider.title[0] ?
                                            <Link href={change_pcp} size="medium" id="change-provider" text={changeProvider.title[0]} target='_blank' style={{ verticalAlign: '20px' }} 
                                            onClick={()=> {
                                              clickTrack(changeProvider.title[0], "main-body", "text", 'internal', domain + change_pcp)}}
                                              /> : null}
                                          {/* <ReplaceProviderModal situsState={situsState} /> */}
                                        </div>}
                                      <Flex alignItems="center" justifyContent="space-between">
                                        <Flex alignItems="center">
                                          <Icon icon={IconProv} size="SMALL" styleVariation="TWO_TONE" />
                                          {/* {pcp && pcp.imgPath ?
                                            <img style={{ width: 48, height: 48, position: 'relative' }}
                                            src={`${aem_content_url}${pcp.imgPath}`}
                                            alt=""
                                          />  */}
                                          {/* <Icon
                                              className="uhc-tempo-icon--md"
                                              name="icon_prov_1c_rgb"
                                              style={{
                                                width: 40,
                                                position: 'relative',
                                                marginLeft: -10,
                                                marginRight: 20,
                                               }} /> */}

                                          {pcpdata.pcpName ?
                                            <div id="provider-name" className="provider-name" style={{ position: 'relative', marginBottom: '-20px' }}>
                                              {!isKelseySeybold && pcpdata.pcpName.toUpperCase().trim() !== "GALILEO HEALTH" ?
                                                <DplTypography fontWeight="bold">Dr.{" "}{TilteCase(pcpdata.pcpName)}</DplTypography> :
                                                <DplTypography fontWeight="bold">{TilteCase(pcpdata.pcpName)}</DplTypography>}
                                              <p style={{ marginTop: 0 }}>
                                                {pcpdata && pcpdata.providerID && pcpdata.providerID && pcpdata.providerID.id && pcpdata.pcpName.toUpperCase().trim() !== "GALILEO HEALTH" ?
                                                  // <Link size="small" noIcon={true} id="facility-name" text={changeProvider.title[1]} href={`${view_pcp}&pcpId=${pcpdata.providerID.id}`} />}
                                                  <Link size="small" id="facility-name" text={changeProvider && changeProvider.title && changeProvider.title[1] ?
                                                    changeProvider.title[1] : "See provider details"}
                                                    href={`${view_pcp}/${pcpdata.providerID && pcpdata.providerID.id && pcpdata.providerID.id}?partnerId=true&locale=${rally_connect_lang}`} 
                                                    onClick={()=> {
                                                      clickTrack(
                                                        changeProvider && changeProvider.title && changeProvider.title[1] ?
                                                          changeProvider.title[1] : "See provider details", 
                                                        "main-body",  "text",
                                                        'internal', 
                                                        domain + `${view_pcp}/${pcpdata.providerID && pcpdata.providerID.id && pcpdata.providerID.id}?partnerId=true&locale=${rally_connect_lang}`)
                                                      }}/>
                                                  : null}
                                              </p>
                                            </div> : null}
                                        </Flex>
                                        {isGalileo && changeProvider && changeProvider.title && changeProvider.title[0] ?
                                          // <ChangeProviderModal linkName={changeProvider.title[0]} situsState={situsState} />
                                          <Button id="change-provider" buttonType="secondary-one" style={{ display: 'flex' }}
                                            href={change_pcp} 
                                            onClick={()=> {
                                              clickTrack(changeProvider.title[0], "main-body", "button", "internal", domain + change_pcp)}}
                                              > 
                                          {changeProvider.title[0]} {<UtilityIcon icon={Create} />}</Button>
                                          : null}
                                      </Flex>
                                    </div>
                                  )
                                } if (pcpdata !== null && (dependentSeqNbr === pcpdata.depSeqNbr) && pcpdata.pcpError === true)
                                  return (<GenericError />)
                                if (pcpSet === false && !isPrimarycareSet) {
                                  return (
                                    <div>
                                      <div>
                                        {pcp && pcp.bodyTitle ?
                                          <Typography.Paragraph
                                            style={{
                                              color: "rgb(0, 38, 119)",
                                              fontSize: 25,
                                              marginTop: 0
                                            }}
                                          >
                                            {pcp.bodyTitle || 'Primary Care Provider'}
                                          </Typography.Paragraph> : null}
                                        {pcp && pcp.description ? <Typography.Paragraph>
                                          {pcp.description.replace(
                                            /(<([^>]+)>)/gi,
                                            ""
                                          )}
                                        </Typography.Paragraph> : null}
                                        {pcp && pcp.footerTitle ? <Button
                                          buttonType="primary-one"
                                          id="select-provider"
                                          text={pcp.footerTitle || "Select Your Provider"}
                                          className="margin-xsm-horizontal"
                                          onClick={()=> { providerUrl()
                                            clickTrack(pcp.footerTitle || "Select Your Provider", 
                                            "main-body", "button", "internal", "")}}
                                        /> : null}
                                      </div>
                                    </div>
                                  )
                                }
                              }
                              ) : null
                            }
                          </div>
                        </div>

                        {/* ******************** Provider Right Tile ************************ */}
                        {doctorsState ?
                          <div style={{ flex: 1, padding: 20, borderLeft: '1px solid #ddd', background: 'rgb(229, 248, 251)' }}>
                            <div>
                              <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                <div>
                                  <img style={{ width: '133px', marginRight: 20 }} src={image && image._path ? `${aem_content_url}${image._path}` : ''} alt="" />
                                </div>
                                <div>
                                  {description && (
                                    <Typography.Paragraph>
                                      {title ? <span style={{ fontFamily: '"UHC Sans Bold",Arial,sans-serif' }}>{title}{' '}</span> : null}
                                      {description && description.plaintext ? description.plaintext : ''}
                                    </Typography.Paragraph>
                                  )}
                                  {linkText ? <Link
                                    href={renderUrl(urlType)}
                                    id="meet-now" noIcon className="meet-now-lnk"
                                    target={urlType === 'externalUrl' ? '_blank' : ''}
                                    onClick={()=> {
                                      clickTrack(linkText, "main-body",  "text",
                                      urlType === 'externalUrl' ? 'external' : 'internal', domain + renderUrl(urlType))
                                      }}
                                      >
                                    <span> {linkText}
                                      <UtilityIcon icon={Arrow} />
                                    </span>

                                  </Link> : ''}
                                </div>
                              </div>
                            </div>
                          </div> : null}
                      </div>
                    </Card>
                  </Desktop>
                  {/* **************************** Mobile View***************************** */}
                  <Mobile>
                    {isPrimarycareSet && pcpInfo.primarycareData !== null && pcpInfo.primarycareData !== undefined && dependentSeqNbr !== null ? (
                      <Card
                        cardType="background"
                        backgroundColor="primary"
                        border={true}
                        layout="custom"
                      >
                        {pcpInfo && pcpInfo.primarycareData ? pcpInfo.primarycareData.map((pcpdata, i) => {
                          if (pcpdata !== null && (dependentSeqNbr === pcpdata.depSeqNbr) && pcpdata.pcpName
                            && pcpdata.pcpError === false) {
                            pcpSet = true
                            return (
                              <>
                                <Card.Content>
                                  {pcp && pcp.bodyTitle ? <Typography.Paragraph
                                    style={{
                                      color: "rgb(0, 38, 119)",
                                      fontSize: 25,
                                      margin: 0
                                    }}
                                  >
                                    {pcp.bodyTitle || "Primary Care Provider"}
                                  </Typography.Paragraph> : null}
                                  {/* <ReplaceProviderModal situsState={situsState} /> */}
                                  {!isGalileo && changeProvider && changeProvider.title && changeProvider.title[0] ?
                                    <Link id="change-provider" href={change_pcp} target='_blank' size="medium" style={{ verticalAlign: '20px' }}
                                    onClick={()=> {
                                      clickTrack(changeProvider.title[0], "main-body", "text", 'internal', domain + change_pcp)
                                      }}
                                    >
                                      {changeProvider.title[0]}
                                    </Link> : null}
                                  <Flex alignItems="center" justifyContent="space-between">
                                    <Flex alignItems="center">
                                      {/* {pcp && pcp.imgPath ? <img style={{ width: 48, height: 48, position: 'relative' }}
                                        src={`${aem_content_url}${pcp.imgPath}`}
                                        alt=""
                                      /> : ''} */}
                                      <Icon icon={IconProv} size="SMALL" styleVariation="TWO_TONE" />

                                      {pcpdata.pcpName ? <div id="provider-name" style={{ marginTop: 20 }}>
                                        {pcpdata.pcpName.toUpperCase().trim() !== "GALILEO HEALTH" ?
                                          < DplTypography fontWeight="bold" fontColor="#002677" key={i}>
                                            Dr.{" "}{TilteCase(pcpdata.pcpName)}
                                          </DplTypography> : < DplTypography fontWeight="bold" fontColor="#002677" key={i}>
                                            {TilteCase(pcpdata.pcpName)}
                                          </DplTypography>}
                                        <p style={{ marginTop: 0 }}>
                                          {pcpdata && pcpdata.providerID && pcpdata.providerID && pcpdata.providerID.id && pcpdata.pcpName.toUpperCase().trim() !== "GALILEO HEALTH" ?
                                            <Link id="facility-name" size="small" target='_blank'
                                              href={`${view_pcp}/${pcpdata.providerID && pcpdata.providerID.id && pcpdata.providerID.id}?partnerId=true&locale=${rally_connect_lang}`}
                                              onClick={()=> {
                                                clickTrack(
                                                  changeProvider && changeProvider.title && changeProvider.title[1] ?
                                                changeProvider.title[1] : "See provider details", 
                                                  "main-body",  "text",
                                                  'internal', 
                                                  domain + `${view_pcp}/${pcpdata.providerID && pcpdata.providerID.id && pcpdata.providerID.id}?partnerId=true&locale=${rally_connect_lang}`)
                                                }}
                                              >
                                              {changeProvider && changeProvider.title && changeProvider.title[1] ?
                                                changeProvider.title[1] : "See provider details"}
                                            </Link> : null}
                                        </p>
                                      </div> : null}
                                    </Flex>
                                  </Flex>
                                </Card.Content>
                                <Card.Content
                                  style={{
                                    backgroundColor: "rgb(251, 251, 251)",
                                    padding: 10
                                  }}
                                >
                                  {/* {!isGalileo ?
                                      <Button
                                      fullWidth={true}
                                      buttonType="secondary-one"
                                      text="Provider details"
                                      href={`${view_pcp}&pcpId=${pcpdata.providerID && pcpdata.providerID.id && pcpdata.providerID.id}`}
                                      style={{
                                        position: "relative",
                                        top: "-30px",
                                        backgroundColor: "#fff"
                                      }}
                                    /> : */}
                                  {changeProvider && changeProvider.title && changeProvider.title[0] ?
                                    // <ChangeProviderModal linkName={changeProvider.title[0]} situsState={situsState} />
                                    <Button buttonType="secondary-one" href={change_pcp} text={changeProvider.title[0]} 
                                    onClick={()=> {
                                      clickTrack(changeProvider.title[0], "main-body", "button", "internal", domain + change_pcp)}}
                                      /> : null}
                                </Card.Content>
                              </>
                            )
                          } if (pcpdata !== null && (dependentSeqNbr === pcpdata.depSeqNbr) && pcpdata.pcpError === true)
                            return (<GenericError />)
                        }) : null}
                      </Card>
                    ) : ''}

                    {(!pcpSet & !isPrimarycareSet) ? (
                      <Card
                        cardType="background"
                        backgroundColor="primary"
                        border={true}
                        layout="custom"
                      >
                        <Card.Content>
                          {pcp && pcp.bodyTitle ? <Typography.Paragraph
                            style={{
                              color: "rgb(0, 38, 119)",
                              fontSize: 25,
                              margin: 0
                            }}
                          >
                            {pcp.bodyTitle || "Primary Care Provider"}
                          </Typography.Paragraph> : null}
                          {pcp && pcp.description ? <Typography.Paragraph style={{ marginBottom: 20 }}>
                            {pcp.description.replace(/(<([^>]+)>)/gi, "")}
                          </Typography.Paragraph> : null}
                        </Card.Content>
                        <Card.Content
                          style={{
                            backgroundColor: "rgb(251, 251, 251)",
                            padding: "10px 10px 0 10px"
                          }}
                        >
                          {pcp && pcp.footerTitle ? <Button
                            fullWidth={true}
                            buttonType="secondary-one"
                            text={pcp.footerTitle || "Select Your Provider"}
                            className="margin-xsm-horizontal"
                            style={{
                              position: "relative",
                              top: -30,
                              backgroundColor: "#fff",
                              alignItem: "center"
                            }}
                            onClick={()=> { providerUrl();
                              clickTrack(pcp.footerTitle || "Select Your Provider", "main-body", "button", "internal", "")}}
                          /> : null}
                        </Card.Content>
                      </Card>
                    ) : null}

                    {/*  ***************** Mobile - Provider Right Section ********************* */}
                    {doctorsState ? <div style={{ padding: "10px 20px 20px 20px", background: 'rgb(229, 248, 251)' }}>
                      {title ? <Typography.Paragraph style={{ color: "rgb(0, 38, 119)", fontSize: 25 }}>
                        {title}
                      </Typography.Paragraph> : null}
                      {linkText ? (
                        <div style={{ lineHeight: 1.4, display: "flex", alignItems: "center" }}>
                          {image && image._path ? <img
                            style={{
                              width: 72,
                              height: 72,
                              position: "relative",
                              top: -10,
                            }}
                            src={`${aem_content_url}${image._path}`}
                            alt=""
                          />
                            : (
                              <Icon
                                className="uhc-tempo-icon--lg"
                                name="icon_prov_2c_rgb"
                                style={{ marginTop: -10 }}
                              />
                            )}
                          <Link
                            href={renderUrl(urlType)}
                            noIcon={false}
                            size="large"
                            target={urlType === 'externalUrl' ? "_blank" : ''}
                            text={linkText}
                            style={{
                              padding: "20px",
                              marginBottom: "10px",
                              verticalAlign: "20px",
                            }}
                            onClick={()=>{
                              clickTrack(linkText, "main-body", "text", urlType === 'externalUrl' ? "external" :"internal", domain + renderUrl(urlType))
                            }}
                          />
                        </div>
                      ) : null}
                      {description && description.plaintext ? <Typography.Paragraph>
                        {description.plaintext}
                      </Typography.Paragraph> : null}
                    </div> : ''}
                  </Mobile>
                </Section>
              )}
          </>
        )
      }
    </>
  )
}

export default ProviderFacilities
import React from "react";

//Adobe Analytics Standardization Page Track

const pageTrackAnalytics = (
    experienceType, 
    is404,
    lang, 
    pageName, 
    sitesection1, 
    sitesection2, 
    sitesection3, 
    sitesection4, 
    test, 
    group, 
    loginStatus, 
    userType,
    userCategory,
    planStatus,
    planCoverageType
    ) => {

    if (window.adobeDataLayer = window.adobeDataLayer || []) {
        window.adobeDataLayer.push({
            event: "page track",
    page: {
        experienceType: experienceType, //mobile" || "desktop"
        is404: is404, //true || false,
        lang: lang, //"english" || "spanish",
        name: pageName,
        sitesection1: sitesection1, //"secure" || "public",
        sitesection2: sitesection2,
        sitesection3: "",
        sitesection4: "",
        test: "",
        group: group, //product, search, content
        },
    user: {
        loginStatus: loginStatus, //"logged in" || "not logged in" ,
        userType: userType + ">ifp", //"provider" || "member" || "employee",
        userCategory: userCategory, //"subscriber" || "spouse" ||"dependent" ||"consumer" || "domestic partner"
        planStatus: planStatus, //"active" ||"pre-effective" || "termed" for multiple plans add delimiter e.g. "active|termed|termed" e.g.2: "pre-effective|pre-effective|active"
        planCoverageType: planCoverageType, //"m"||"d"||"v" for multiple plans add delimiter e.g.1 "m|d|v"  e.g.2 "m|d"
        groupId: "",
        carrierId: "",
        hsid: "",
        rallyId: "",
        patientId: "",
        payerGroup: "ifp",
        accountId: "",
        memberId: ""
        }
        });
    }


    if (typeof _satellite == 'object') {
        window._satellite.track('atViewStart', { viewName: 'myuhc:' + pageName, viewCount: true }
        )
    };
    
    return (
        <> {console.log("Page track Analytics Set on Dashboard")}</>
    )
};
export default pageTrackAnalytics;

import React, { useContext, useState, useEffect } from "react"
import { Grid, Card, Section } from '@uhc/pattern-library'
import { Typography, BrandIcon as Icon, Button } from '@uhc-tempo/components'
import config from '../../../../../config'
import { ProfileContext } from "../../../../../contexts/profileContext"
import PlanInfoMobile from './planInfoMobile'
import styled from 'styled-components'
import Constant from '../../../../../constants/constants'
import UseBaseService from '../../../../../hooks/useBaseService'
import clickTrack from "../../../../../hooks/clickTrack"


const Desktop = styled.div`
  @media (max-width: 991px) {
    display: none;
  }
`
const { aem_content_url } = config
const PlanInfo = (props) => {
  const { planInfo, findProvider, viewBenefits, goPaperless, clickvb, clickgp, providerUrl,
    planInfoTermed, reviewDocuments, makePayment, clickrd, clickmp, dashboardviewFor, isGalileo, medicalMemberID, isNMPayExempt } = props
  const domain = window.location.origin

  const { profile } = useContext(ProfileContext) || {}
  const { loggedinMember } = profile || {}
  const { coverages, isSubscriber } = loggedinMember || {}
  const { medical } = coverages || {}
  const { startDate, endDate, coverageStatus } = medical || {}
  let medicalStartDate = startDate ? startDate : ''
  medicalStartDate = new Date(medicalStartDate ? medicalStartDate.replace(/-/g, '/') : '')
  medicalStartDate = medicalStartDate ? medicalStartDate.toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' }) : ''

  let medicalEndDate = endDate ? endDate : ''
  medicalEndDate = new Date(medicalEndDate ? medicalEndDate.replace(/-/g, '/') : '')
  medicalEndDate = medicalEndDate ? medicalEndDate.toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' }) : ''
 
  const [fullySubdizedWithNoDue, setFullySubsidizedWithNoDue] = useState(false);
  const [totalDueAmount, setTotalDueAmount] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      const totalDueAmount = sessionStorage.getItem("totalDueAmount");
      const subdizedAndNoDue = sessionStorage.getItem("subdizedAndNoDue") === 'true'? true : false;
      setFullySubsidizedWithNoDue(subdizedAndNoDue);
      setTotalDueAmount(totalDueAmount);
    }, 3000);
    return () => clearTimeout(timer);
  }, [fullySubdizedWithNoDue,totalDueAmount]);


  //check for recurring payment search
  const { recurring_endpoint } = Constant
  const [recurringObj, setRecurringObj] = useState()
  useEffect(() => {
    const getDateStringyyyymmdd = (strDate) => {
      strDate.toString('yyyy-MM-dd');
      var dd = strDate.getDate();
      var mm = strDate.getMonth() + 1; // January is 0!
      var yyyy = strDate.getFullYear();
      if (dd < 10) { dd = '0' + dd }
      if (mm < 10) { mm = '0' + mm }
      strDate = yyyy + '-' + mm + '-' + dd;
      return strDate;
    }
    let postObject = {
      "searchRecurringPaymentRequest": {
        "subscriberId": medicalMemberID,
        "consumerData": {
          "clientCode": "MYUHC",
          "instance": "CSP",
          "userId": ""
        },
        "subscriberContrivedKey": "",
        "groupId": "",
        "effectiveDate": getDateStringyyyymmdd(new Date(new Date().setMonth(new Date().getMonth()))) + "T00:00:00.000Z",
        "termDate": getDateStringyyyymmdd(new Date(new Date().setMonth(new Date().getMonth() + 2))) + "T23:59:59.000Z"
      }
    }
    UseBaseService(
      "searchRecurringPayment",
      "POST",
      "dmz",
      recurring_endpoint,
      postObject,
      response => {
        try {
          const { data, APIError } = response;

          // this api response handles both oauth endpoint & offline endpoint so data can be data.data or data
          const apiData = data?.data || data;
          
          let recResponse = false;

          if (APIError) {
          } else {
            // apiData.forEach((element) => {
            //   if (element.hasOwnProperty("effectiveDate") && element.hasOwnProperty("termDate") && element.termDate.split("T")[0] === "9999-12-31" && recResponse === false) {
            //     recResponse = element;
            //   }
            // })
            setRecurringObj(apiData[0])
          }
        } catch (err) {
          console.log(err);
        }
      }
    )

  }, [recurring_endpoint, medicalMemberID])

  return (
    <>
      <Section style={{ position: 'relative', top: '-120px' }}>
        <Desktop>
          <Card>
            <Card.Content>
              <Grid mediaSm={200} mediaMd={989} mediaLg={1200}>
                <Grid.Item span={12} spanSm={12} spanMd={6} style={{ padding: '30px' }}>

                  <Typography.H2>
                    {coverageStatus === 'pre-effective' ? <Typography.Paragraph style={{ fontWeight: 600, margin: 0 }}>
                      {planInfo && planInfo.question ? planInfo.question : ''}
                    </Typography.Paragraph> : null}
                    {coverageStatus === 'pre-effective' && medicalStartDate ? <>{medicalStartDate}.</> : ''}
                  </Typography.H2>

                  <Typography.H2>
                    {coverageStatus === 'termed' ? <Typography.Paragraph style={{ fontWeight: 600, margin: 0 }}>
                      {planInfoTermed && planInfoTermed.question ? planInfoTermed.question : ""}
                    </Typography.Paragraph> : null}
                    {coverageStatus === 'termed' && medicalEndDate ? <>{medicalEndDate}.</> : ''}
                  </Typography.H2>

                  {coverageStatus === 'pre-effective' ? <Typography.Paragraph>
                    {planInfo && planInfo.answer ? planInfo.answer.replace(/(<([^>]+)>)/gi, "") : ""}
                  </Typography.Paragraph> : coverageStatus === 'termed' ? <Typography.Paragraph>
                    {planInfoTermed && planInfoTermed.answer ? planInfoTermed.answer.replace(/(<([^>]+)>)/gi, "") : ''}
                  </Typography.Paragraph> : null}

                </Grid.Item>
                <Grid.Item
                  span={12}
                  spanSm={12}
                  spanMd={6}
                  spanLg={6}
                // style={{ textAlign: 'center', padding: '40px 0' }}
                >
                  <div id="plan-info" style={{ display: 'flex', margin: '20px 0 20px 0' }}>
                    {!isGalileo && findProvider && findProvider.bodyTitle ?
                      <div style={{ flex: '1 0', padding: '0 20px', display: 'flex', justifyContent: 'center', height: '100%' }}>
                        <Button
                          noIcon={true}
                          onClick={()=> {providerUrl();
                            clickTrack(findProvider.bodyTitle, "main-body", "button", "internal", "")}}
                          className="link-button uhc-tempo-link"
                          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}
                        >
                          {findProvider && findProvider.imgPath ? <img src={`${aem_content_url}${findProvider.imgPath}`} alt=''
                            style={{ height: 70, margin: 10 }} /> :

                            <Icon className="uhc-tempo-icon--md" name="icon_prov_2c_rgb" />}
                          <span className="link-text">{findProvider.bodyTitle}</span>
                        </Button>
                      </div> : null}

                    {isGalileo && isSubscriber && !recurringObj && !isNMPayExempt ?
                      <div style={{ flex: '1 0', padding: '0 20px', display: 'flex', justifyContent: 'center', height: '100%' }}>
                        <Button
                          noIcon={true}
                          href='/payment#/setup-recurring'
                          id="setup-autopay"
                          className="link-button uhc-tempo-link"
                          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}
                          onClick={()=> 
                          clickTrack("Set up auto-pay", "main-body", "button", "internal", domain + "/payment#/setup-recurring")}
                          >
                          <img src={`${aem_content_url}/content/dam/iex/images/autopay-icon.svg`} alt=''
                            style={{ height: 70, margin: 10 }} />
                          <span className="link-text">Set up auto-pay</span>
                        </Button>
                      </div> : null}

                    {viewBenefits && viewBenefits.bodyTitle ? <div
                      id="view-benefits"
                      style={{
                        height: '100%',
                        textAlign: 'center',
                        display: 'flex',
                        flex: '1 0',
                        margin: 0,
                        padding: '0 20px',
                        justifyContent: 'center',
                        borderLeft: '1px solid #E0E0E0',
                      }}
                    >
                      <Button
                        className="link-button uhc-tempo-link"
                        noIcon={true}
                        onClick={()=> { clickvb();
                          clickTrack(viewBenefits.bodyTitle, "main-body", "button", "internal", "")}}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          whiteSpace: 'nowrap',
                          height: '100%'
                        }}
                      >
                        {viewBenefits && viewBenefits.imgPath ? <img src={`${aem_content_url}${viewBenefits.imgPath}`} alt=''
                          style={{ height: 70, margin: 10 }} /> :
                          <Icon className="uhc-tempo-icon--md" name="icon_heart_2c_rgb" />}
                        <span className="link-text">{viewBenefits.bodyTitle}</span>
                      </Button>
                    </div> : null}

                    {goPaperless && goPaperless.bodyTitle ? <div
                      id="go-paperless"
                      style={{
                        height: '100%',
                        textAlign: 'center',
                        display: 'flex',
                        flex: '1 0',
                        margin: 0,
                        borderLeft: '1px solid #E0E0E0',
                        padding: '0 20px',
                        justifyContent: 'center',
                      }}
                    >
                      <Button
                        target="_blank"
                        noIcon={true}
                        onClick={()=> { clickgp();
                          clickTrack(goPaperless.bodyTitle, "main-body", "button", "internal", "")}}
                        className="link-button uhc-tempo-link"
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                      >
                        {goPaperless && goPaperless.imgPath ? <img src={`${aem_content_url}${goPaperless.imgPath}`} alt=''
                          style={{ height: 70, margin: 10 }} /> :
                          <Icon className="uhc-tempo-icon--md" name="icon_gogrn_2c_rgb" />}
                        <span className="link-text">{goPaperless.bodyTitle}</span>
                      </Button>
                    </div> : null}

                    {reviewDocuments && reviewDocuments.bodyTitle ? <div
                      id="review-documents"
                      style={{ flex: '1 0', display: 'flex', padding: '0 20px', height: '100%', justifyContent: 'center', }}>
                      <Button
                        target="_blank"
                        noIcon={true}
                        onClick={()=> { clickrd();
                          clickTrack(reviewDocuments.bodyTitle, "main-body", "button", "internal", "")}}
                        className="link-button uhc-tempo-link"
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}
                      >
                        {reviewDocuments && reviewDocuments.imgPath ? <img src={`${aem_content_url}${reviewDocuments.imgPath}`} alt=''
                          style={{ height: 70, margin: 10 }} /> :
                          <Icon className="uhc-tempo-icon--md" name="icon_gogrn_2c_rgb" />}
                        <span className="link-text" style={{ whiteSpace: 'nowrap' }}>{reviewDocuments.bodyTitle}</span>
                      </Button>
                    </div> : null}

                    {dashboardviewFor.relationship === "SUBSCRIBER" && makePayment && makePayment.bodyTitle && !fullySubdizedWithNoDue && !isNMPayExempt ? <div
                      id="make-payment"
                      style={{
                        height: '100%',
                        textAlign: 'center',
                        display: 'flex',
                        flex: '1 0',
                        margin: 0,
                        borderLeft: '1px solid #E0E0E0',
                        padding: '0 20px',
                        justifyContent: 'center',
                      }}
                    >
                      <Button
                        target="_blank"
                        noIcon={true}
                        onClick={()=> { clickmp();
                          clickTrack(makePayment.bodyTitle, "main-body", "button", "internal", "")}}
                        className="link-button uhc-tempo-link"
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}
                      >
                        {makePayment && makePayment.imgPath ? <img src={`${aem_content_url}${makePayment.imgPath}`} alt=''
                          style={{ height: 70, margin: 10 }} /> :
                          <Icon className="uhc-tempo-icon--md" name="icon_gogrn_2c_rgb" />}
                        <span className="link-text" style={{ whiteSpace: 'nowrap' }}>{makePayment.bodyTitle}</span>
                      </Button>
                    </div> : null}

                  </div>
                </Grid.Item>
              </Grid>
            </Card.Content>
          </Card>
        </Desktop>
        <PlanInfoMobile planInfo={planInfo} findProvider={findProvider}
          viewBenefits={viewBenefits} goPaperless={goPaperless}
          clickvb={clickvb} clickgp={clickgp} medicalStartDate={medicalStartDate}
          providerUrl={providerUrl}
          planInfoTermed={planInfoTermed}
          reviewDocuments={reviewDocuments}
          makePayment={makePayment}
          clickrd={clickrd}
          clickmp={clickmp}
          medicalEndDate={medicalEndDate}
          dashboardviewFor={dashboardviewFor}
          coverageStatus={coverageStatus}
          isGalileo={isGalileo}
          isSubscriber={isSubscriber}
          recurringObj={recurringObj}
          isNMPayExempt = {isNMPayExempt}

        />
      </Section>
    </>
  )
}
export default PlanInfo

import React,{ useState } from 'react';
import moment from 'moment';
import { Typography, Button } from '@uhc-tempo/components';
import config from '../../../../../config';
import styles from './rewards.module.css';
import clickTrack from '../../../../../hooks/clickTrack';

const { aem_content_url,consumer_url } = config;

const Rewards = ({
  preEffectiveRewards,
  preEffectiveRewardsButton,
  reimbursementImage,
  selectedDateOfBirth,
  selectedMedical,
  situsState,
  isNMuser
}) => {

  const { coverageStatus = '', startDate = '' } = selectedMedical;
  // const [rewardsUrl, setRewardsUrl] = useState('');

  let rewardsUrl = ""
  let global_api_constants = {};

  if (sessionStorage.getItem("global-api-constants")) {
    global_api_constants = JSON.parse(
      sessionStorage.getItem("global-api-constants"));
  }

  const { Incentive_switch } = global_api_constants || {}

  if (coverageStatus?.toLowerCase() === 'termed') return null;

  const reimbursementHref = reimbursementImage?.href ? `${aem_content_url}${reimbursementImage.href}` : '#';

  const age = moment().diff(moment(selectedDateOfBirth), 'years');

  // const twentyTwentyThreePlan = moment(startDate).isBetween('2023-01-01', '2023-12-31', 'year', '[]')

  // const showReward = age >= 18 && coverageStatus.toLowerCase() === 'pre-effective';
  const domain = window.location.origin + window.location.pathname

  const showincentive = age >= 18 && coverageStatus.toLowerCase() === 'pre-effective' && situsState !=="NJ" &&  situsState !=="NM";

  if(Incentive_switch === "true"){
      rewardsUrl = `${consumer_url}/ifp/wellness/incentives/healthperks`
  }else{
    rewardsUrl = `{preEffectiveRewardsButton?.href}`
  }

  return (
    <>
      {showincentive && !isNMuser  ? (
        <div className={styles.section}>
          <div className={styles.wrapper}>
            <div className={styles.container}>
              <div className={styles.leftCol}>
                <img src={reimbursementHref} alt="coin dropping into a hand" />
              </div>
              <div className={styles.middleCol}>
                <Typography.H3 headingStyle={4}>
                  {preEffectiveRewards?.title?.[0]}
                </Typography.H3>
                <Typography.Paragraph>
                  {preEffectiveRewards?.title?.[1]}
                </Typography.Paragraph>
                <Button
                  id="more-info"
                  buttonType="primary-one"
                  href={rewardsUrl}
                  target ={ rewardsUrl.includes("BIW") ? "_blank" : ""}
                  onClick={()=> { 
                    clickTrack(preEffectiveRewardsButton?.title, "main-body", "button", "internal", domain + preEffectiveRewardsButton?.href)}}
                >
                  {preEffectiveRewardsButton?.title}
                </Button>
              </div>
              <div className={styles.rightCol}>
                <ul className={styles.ulist}>
                  <li>{preEffectiveRewards?.title?.[2]}</li>
                  <li>{preEffectiveRewards?.title?.[3]}</li>
                  <li>{preEffectiveRewards?.title?.[4]}</li>
                  <li>{preEffectiveRewards?.title?.[5]}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Rewards;

import React from "react"
import { App as UhcDpl } from "@uhc/pattern-library"
import ProfileContextProvider from "./contexts/profileContext"
import AppRouting from "./app-routing/appRouting"
import 'bootstrap/dist/css/bootstrap.min.css';

class App extends React.Component {

   render() {
      return (
         <>
         <UhcDpl>
            <ProfileContextProvider>
               <AppRouting />
            </ProfileContextProvider>
         </UhcDpl>
         </>
      )
   }
}

export default App

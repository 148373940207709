import constants from "../../constants/constants"
import UseBaseService from "../useBaseService"

const UseRecentClaims = callback => {
   // let processedStartDate = new Date();
   // processedStartDate.setMonth(processedStartDate.getMonth() - 18);

   const { claimsummary_endpoint } = constants
   UseBaseService(
      "claims summary",
      "GET",
      "dmz",
      `${claimsummary_endpoint}?callType=recentclaims&claimType=DENTAL&claimCount=4&cache=off`,
      // `${claimsummary_endpoint}?claimType=DENTAL&processedStartDate=${
      //   processedStartDate.toISOString().split("T")[0]
      // }&processedStopDate=${new Date().toISOString().split("T")[0]}`,
      "",
      response => {
         const { APIError, data } = response
         if (APIError) {
            if (data && data.claimResponse && data.claimResponse.claims) {
               const claimErrorCode = data.claimResponse.claims.map(err => {
                  return (
                     err.error && err.error.responseError &&
                     err.error.responseError.find(errCode => errCode !== null ? errCode.errorCode : '')
                  )
               })
               callback({ hasError: true, claimErrorCode })
            }
         } else {
            try {
               const claimFormat = ({ firstName, listOfClaims }) => {
                  const claimsList = listOfClaims.map(claim =>
                     destructureClaims(claim)
                  )
                  return { firstName, claimsList }
               }
               const destructureClaims = ({ claimInfo }) => {
                  if (claimInfo) {
                     const {
                        claimID,
                        claimDates,
                        relatedClaimInformation,
                        providerInfo,
                        claimLevelAdjudicationAmt,
                        claimAdjudicationStatus,
                        claimKey
                     } = claimInfo
                     //get claim info values
                     const { id } = claimID ? claimID : ""
                     const { startDate } = claimDates && claimDates.serviceDate ? claimDates.serviceDate : ""
                     const { originalClaimProcessDate } = relatedClaimInformation && relatedClaimInformation[0] ? relatedClaimInformation[0] : ""
                     const { totalBilledChargeAmt, totalClaimPaidAmt, youOwe } = claimLevelAdjudicationAmt && claimLevelAdjudicationAmt[0] ?
                        claimLevelAdjudicationAmt[0] : ""
                     const { firstName, lastName, organizationName } = providerInfo && providerInfo[0] ? providerInfo[0] : ""
                     const { desc } = claimAdjudicationStatus ? claimAdjudicationStatus : ""

                     return {
                        claimNumber: id ? id : "",
                        actualServiceDate: startDate ? startDate : "",
                        serviceDate: startDate ? new Date(startDate).toLocaleDateString('en-US', { timeZone: 'UTC' }).replace(/(^|\D)(\d)(?!\d)/g, '$10$2') : "",
                        actualProcessDate: originalClaimProcessDate ? originalClaimProcessDate : "",
                        processDate: originalClaimProcessDate ? new Date(originalClaimProcessDate).toLocaleDateString('en-US', { timeZone: 'UTC' }).replace(/(^|\D)(\d)(?!\d)/g, '$10$2') : "",
                        amountBilled: totalBilledChargeAmt ? totalBilledChargeAmt : "",
                        planPaid: totalClaimPaidAmt ? totalClaimPaidAmt : "",
                        amountOwed: youOwe ? youOwe : "",
                        provider: `${firstName ? firstName : ""} ${lastName ? lastName : ""}
                              ${ !firstName && !lastName && organizationName ? organizationName : ""}`,
                        claimAdjudicationStatus: desc ? desc : "",
                        claimKey: claimKey ? claimKey : "",
                        claimType
                     }
                  }
               }
               //access data from baseservice
               const { claimResponse } = data || ""
               const { claims } = claimResponse || ""
               const claimType = claims && claims[0] && claims[0].claimType ? claims[0].claimType : "DENTAL"
               const claimData = claims && claims[0] && claims[0].patientInfo && claims[0].patientInfo.map(info => {
                  return claimFormat(info)
               })
               const recentClaimsdata = claimData

               if (typeof recentClaimsdata === "object") {
                  callback({ hasError: false, recentClaims: recentClaimsdata });
               } else {
                  throw Error
               }

            } catch (error) {
               callback({ hasError: true })

               //logging for errors
            }
         }
      }
   )
}
export default UseRecentClaims

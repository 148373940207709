import React from "react"
import { Input } from "@uhc/icons"
//import { useHistory } from 'react-router-dom'
import styled from "styled-components"
import config from "../../config"
import {
   Card,
   Typography,
   Flex,
   Heading,
   Grid
} from "@uhc/pattern-library"
import { Button } from "@uhc-tempo/components"
import clickTrack from "../../hooks/clickTrack"
const LabelTypography = styled(Typography)`
   color: rgb(74, 74, 74);
   font-size: 14px;
   font-weight: 500;
   alignself: "flexEnd";
`
const ClaimStatusTypography = styled(Typography)`
   font-size: 14px;
   font-weight: 400;
   align-self: flex-end;
   text-align: right;
`
const cardFooter = {
   textAlign: "center",
   backgroundColor: "rgb(249, 249, 249)",
   margin: "0",
   padding: "15px 0"
}
const cardHeading = {
   fontSize: "19px",
   fontWeight: "600",
   alignSelf: "flexStart",
   margin: "0"
}
const processedClaim = {
   color: "rgb(13, 136, 11)"
}
const deniedClaim = {
   color: "rgb(208, 2, 27)",
   display: "flex",
   alignItems: "center"
}
const processingClaim = {
   color: "rgb(25, 110, 207)"
}

const { aem_content_url } = config
const domain = window.location.origin
const DashboardClaimCard = ({
   member,
   claimNumber,
   serviceDate,
   processDate,
   provider,
   amountBilled,
   planPaid,
   amountOwed,
   claimAdjudicationStatus,
   claimKey,
   claimType
}) => {
   //const history = useHistory()
   return (
      <Card>
         <Card.Content>
            <Flex
               flexDirection="column"
               flexDirectionMd="row"
               justifyContentMd="space-between"
            >
               <Flex style={{ marginBottom: 3 }}>
                  {member ? <Heading level="3" style={cardHeading}>
                     {member}'s Dental Claim
                  </Heading> : null}
               </Flex>
               {claimNumber ? <Flex style={{ marginBottom: 3 }}>
                  <Typography fontWeight="bold" style={{ fontSize: "16px" }} >
                     #{claimNumber}
                  </Typography>
               </Flex> : null}
            </Flex>
            <Flex
               flexDirection="column"
               flexDirectionMd="row"
               justifyContentMd="space-between"
            >
               {serviceDate ? <Flex style={{ marginBottom: 3 }}>
                  <Typography
                     fontWeight="medium" style={{ fontSize: "14px", color: "rgb(87, 87, 87)" }} >
                     Date of Service on {serviceDate}
                  </Typography>
               </Flex> : null}
               <Flex style={{ marginBottom: 3 }}>
                  {processDate && claimAdjudicationStatus && claimAdjudicationStatus === "PROCESSED" ? (
                     <ClaimStatusTypography style={processedClaim}>
                        <img src={`${aem_content_url}/content/dam/myuhc/consumer/assets/myuhc/checkmark-green.svg`}
                           alt="green-checkmark" />{" "}
                        Processed on {processDate}
                     </ClaimStatusTypography>
                  ) : null}
                  {processDate && claimAdjudicationStatus && claimAdjudicationStatus === "DENIED" ? (
                     <ClaimStatusTypography style={deniedClaim}>
                        <Input style={{ marginRight: "5px" }} />
                        Denied on {processDate}
                     </ClaimStatusTypography>
                  ) : null}
                  {claimAdjudicationStatus && (claimAdjudicationStatus === "INPROCESS" || claimAdjudicationStatus === "IN PROCESS") ? (
                     <ClaimStatusTypography style={processingClaim}>
                        In Process
                     </ClaimStatusTypography>
                  ) : null}
               </Flex>
            </Flex>
            <Flex alignItems="center">
               <Flex>
                  <img src={`${aem_content_url}/content/dam/myuhc/consumer/assets/myuhc/dental-icon2.svg`}
                     alt="" style={{ marginRight: "10px" }} />
               </Flex>
               {provider ? <Flex style={{ flex: 'initial' }}>
                  <Heading level="3" variant="secondary">
                     {provider}
                  </Heading>
               </Flex> : null}
            </Flex>
            <Grid mediaSm={360} mediaMd={768}>
               {amountBilled ? <Grid.Item span={12} spanMd={4} spanSm={6}>
                  <LabelTypography>Amount Billed</LabelTypography>
                  <div style={{ fontSize: "21px" }}>${amountBilled}</div>
               </Grid.Item> : null}

               {planPaid ? <Grid.Item span={12} spanMd={4} spanSm={6}>
                  <LabelTypography>Insurance Covered</LabelTypography>
                  <div style={{ fontSize: "21px" }}>${planPaid}</div>
               </Grid.Item> : null}
               {amountOwed ? <Grid.Item span={12} spanMd={4} spanSm={6}>
                  <LabelTypography>You May Owe</LabelTypography>
                  <div style={{ fontWeight: "bold", justifyContent: "left", fontSize: "21px" }}>
                     ${amountOwed}
                  </div>
               </Grid.Item> : null}
            </Grid>
         </Card.Content>

         <p style={cardFooter}>
            <Button
               id={`goto-claim-detail-${claimNumber}`}
               buttonType="secondary-one"
               style={{ width: 200 }}
               href={`/dental-only/claims#/claim-detail/${encodeURIComponent(claimKey)}/${claimType}`}
               //onClick={() => history.push(`/claims#/claim-detail/${encodeURIComponent(claimKey)}`)}
               onClickTrackingData={{ clickName: "view claim detail", clickLocation: "/dental-only/claims#/claim-detail" }}
               onClick={()=> {
                  clickTrack("View this Claim", "main-body", "button", "internal", 
                  domain + `/dental-only/claims#/claim-detail/${encodeURIComponent(claimKey)}/${claimType}`)}}>
               View this Claim
            </Button>

         </p>
      </Card>
   )
}

export default DashboardClaimCard

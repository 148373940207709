import Moment from 'moment'

const getFilteredContentList = (list) => {
    const today = Moment(
        new Date().toLocaleString('en-US', {
            timeZone: 'CST6CDT'
        })
    ).format('YYYY-MM-DD HH:mm')

    const filteredList = list.filter((listItem) => {
        const startDateFormatted = Moment(
            new Date(listItem.startDate).toLocaleString('en-US', {
                timeZone: 'CST6CDT'
            })
        ).format('YYYY-MM-DD HH:mm')
        const endDateFormatted = Moment(
            new Date(listItem.endDate).toLocaleString('en-US', {
                timeZone: 'CST6CDT'
            })
        ).format('YYYY-MM-DD HH:mm')
        return startDateFormatted <= today && endDateFormatted >= today
    })
    return filteredList.length > 0 ? filteredList : []
}

export default getFilteredContentList

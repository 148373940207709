import { useContext } from 'react'
import { ProfileContext } from '../../../../../contexts/profileContext'
import getPlanYear from '../../../../../tools/getPlanYear';

const GalileoHeroQuery = () => {

  const { profile } = useContext(ProfileContext) || {}
  const { loggedinMember } = profile || {}
  const { coverages } = loggedinMember || {}
  const { medical } = coverages || {}
  const { situsState, reportingCode, coverageStatus, startDate } = medical || {}

  const planYear = getPlanYear(startDate)

  const searchPath = `/content/dam/iex/${planYear}/effective/dashboard/hero-image`
  const requestParameters = `;searchPath=${searchPath};situsState=${situsState};reportingCode=${reportingCode}`
  const requestPath = `/myuhc/graphql/execute.json/IEX/dashboardGalileoHeroQuery.json${encodeURIComponent(requestParameters)}`
  return requestPath;
}
export default GalileoHeroQuery

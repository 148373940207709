import React, { useState } from 'react'
// import { Modal } from '@uhc/pattern-library'
import { Modal, Button, Typography, UtilityIcon } from '@uhc-tempo/components'
import InfoIcon from '@material-ui/icons/InfoRounded'
import styles from './premiumModal.module.css'
import { Markup } from 'interweave'
import modalTrack from '../../../../hooks/modalTrack'

const PremiumModal = ({subsOvrridePremium = 0, subsidyAmount, stateSubsidyAmount }) => {
    const [open, setOpen] = useState(false)
    let aptcStateSubsidyAmount = (+subsidyAmount + +stateSubsidyAmount).toFixed(2)
    let monthlyPremium = (+subsOvrridePremium - +aptcStateSubsidyAmount).toFixed(2)
    monthlyPremium = (isNaN(monthlyPremium) || monthlyPremium === undefined) ? '' : monthlyPremium

    return (
        <>
            <span style={{ display: 'block' }}>
                <Button
                    id="eob-button"
                    noIcon={true}
                    className="link-button tds-link definition-lnk"
                    onClick={() => {setOpen(true); modalTrack("Explanation of monthly premium and APTC")}}
                >
                    <span className={styles.linkModal}>
                        <span className="link-text" style={{ textTransform: 'none' }}>Explanation of monthly premium and APTC</span>
                        <UtilityIcon icon={InfoIcon} />
                    </span>
                </Button>
            </span>

            {open && (
                <Modal handleClose={() => setOpen(false)} contentId={100}>
                    <Modal.Header style={{ justifyContent: 'flex-start', fontFamily: '“UHC Sans Bold”, Arial, sans-serif' }}>
                        {/* <Modal.Title>Your monthly premium and APTC</Modal.Title> */}
                        <Modal.Title> <span style={{ fontFamily: '"UHC Sans Bold", Arial, sans-serif' }}>Your monthly premium and APTC</span> </Modal.Title>
                    </Modal.Header>
                    <Modal.Content style={{ borderTop: 0 }}>
                        <div className="container tempo-v1">
                            {/* <Markup content={modalDesc} /> */}
                            {<Typography.H3 style={{ marginBottom: 0 }}></Typography.H3>}
                            { <><Typography.H4 style={{ marginBottom: 0 }}>Federal and State Subsidies</Typography.H4><Typography.Paragraph>The Advance Premium Tax Credit (APTC) is a subsidy from the Federal Government that reduces the amount you pay in monthly health insurance premiums for the plan you enrolled in on the Exchange. Similarly, some states also provide an additional State Subsidy. Both APTC and State subsidies are determined by Federal and State Health Insurance Exchanges and sent directly to insurance companies. Both these credits are determined by Federal and State Government and are based on estimated annual household income and household size. Not all members qualify for Federal and/or State subsidies.</Typography.Paragraph><Typography.H4 style={{ marginBottom: 0 }}>Pre-Subsidy Monthly Premium</Typography.H4><Typography.Paragraph style={{ marginTop: 0 }}>
                         This is the total monthly cost of your plan before APTC and State Subsidy are applied.
                      </Typography.Paragraph><Typography.H4 style={{ marginBottom: 0 }}>Your Monthly Premium</Typography.H4><Typography.Paragraph style={{ marginTop: 0 }}>
                            Your monthly premium is the amount you owe every month. It is equal to the Pre-Subsidy Monthly Premium minus APTC and State Subsidy.
                         </Typography.Paragraph>
                         {subsOvrridePremium && <Typography.Paragraph>
                            <span><i>Your Premium, APTC, and State Subsidy information:</i></span>
                            <span style={{ display: 'block', fontWeight: '700' }}>
                            ${Number(subsOvrridePremium).toFixed(2)} (Pre-Subsidy Cost) - ${aptcStateSubsidyAmount} (APTC and State Subsidy) = ${monthlyPremium} (Monthly Premium)</span>
                         </Typography.Paragraph>}</>}
                        </div>
                    </Modal.Content>
                </Modal>
            )}
        </>
    )
}

export default PremiumModal

const checkOfflinePaymentFlow = () => {
    try {
       const global_api_constant_sesssion = sessionStorage.getItem("global-api-constants")
       const global_api_constant = JSON.parse(global_api_constant_sesssion || "{}")
       if (global_api_constant && global_api_constant.PAYMENT_OFFLINE && global_api_constant.PAYMENT_OFFLINE === "true") {
          return true
       } else {
          return false
       }
    }
    catch {
       return false
    }
 }

 export default checkOfflinePaymentFlow
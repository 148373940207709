import React from 'react'
import { Card, Heading, Typography } from '@uhc/pattern-library'
import config from "../../config"
import functionErrorTrack from '../../hooks/functionErrorTrack';

const GenericError = () => {
    const { aem_content_url } = config;
    functionErrorTrack("Dashoard Error", "Server-side error", "500", "");
    return (
        <>
            <Card>
                <Card.Content>
                    <div style={{ textAlign: 'center' }}>
                        <img src={`${aem_content_url}/content/dam/myuhc/consumer/assets/myuhc/urgentIcon.svg`} alt="" />
                    </div>
                    <Heading level="2" variant="secondary" style={{ textAlign: 'center' }}>
                        Uh Oh!
                    </Heading>
                    <p style={{ textAlign: 'center', margin: 'auto' }}>
                        <Typography fontWeight="medium" style={{ fontSize: 16 }}>
                            It's not you, it's us. We are experiencing some <br /> technical difficulties.
                        </Typography>
                    </p>
                </Card.Content>
            </Card>
        </>
    )
}

export default GenericError
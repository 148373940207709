import React from 'react'
const clickTrack = (clickName, clickLocation, controlType, clickType, url) => {
    let global_api_constants = {}
    if (sessionStorage.getItem('global-api-constants')) {
        global_api_constants = JSON.parse(sessionStorage.getItem('global-api-constants'))
    }
    const { adobeAnalyticsNew } = global_api_constants || {}
    if (adobeAnalyticsNew === 'true' &&
    window.location.pathname.includes("ifp")) {
        window.adobeDataLayer = window.adobeDataLayer || []
        window.adobeDataLayer.push({
            event: 'link track',
            link: {
                name: clickName, //if PII or no text value exists please request an alternate value
                location: clickLocation, //examples:"header nav","hero","find your plan", etc
                controlType: controlType, //"button"|| "text" ||"icon"||"tile"
                type: clickType, //internal" || "external" || "phone" || "download" || "accordion open" || "accordion close" || "tab" || "email",
                destinationUrl: url //leave blank when link does not point to a URL
            },
            component: {
                name: '' //should only be populated when element is global component. Leave blank if not global component.
            }
        })
    }
}
export default clickTrack

import React, { useState, useEffect, useContext } from "react"
import { Section, Grid, Heading, Flex } from "@uhc/pattern-library"
import { Button as TempoButton, Typography } from "@uhc-tempo/components"
import DashBoardMemberInfo from "../dashboard/components/memberInfo"
import JourneyCardComponent from "../dashboard/components/journeyCard"
import PlanSummaryCard from "../../shared-components/planSummaryCard"
import ClaimCard from "../../../components/shared-components/claimCard"
import ActionCard from "../../../components/shared-components/actionCard"
import UseRecentClaims from "../../../hooks/claims/useRecentClaims"
import NoclaimsMessage from "../../shared-components/noclaimsMessage"
import UseContentFragment from "../../../hooks/contentfragment/useContentFragment"
import { ProfileContext } from "../../../contexts/profileContext"
import constants from "../../../constants/contentEndpoints"
import config from "../../../config"
import pageLoadAnalytics from "../../../hooks/pageLoadAnalytics"
import { Loader } from "@uhc/pattern-library"

const Dashboard = () => {
   useEffect(() => {
      pageLoadAnalytics("dashboard", "dashboard", "", "", "e&i");

   }, []);
   const { profile } = useContext(ProfileContext) || {}
   const { loggedinMember } = profile || {}
   const memberName = loggedinMember ? loggedinMember['firstName'] : ''
   const dentalCoverage = loggedinMember ? loggedinMember['dentalCoverage'] : ''
   const covStatus = dentalCoverage ? dentalCoverage['coverageStatus'] : ''
   //Claims API Callback
   const [recentClaimData, setrecentClaimData] = useState({
      error: false,
      loading: false,
      claims: []
   })
   const { aem_content_url } = config
   useEffect(() => {
      if (sessionStorage.getItem("RecentClaims")) {
         setrecentClaimData(JSON.parse(sessionStorage.getItem("RecentClaims")))
      }
      else {
         UseRecentClaims(({ hasError, recentClaims } = {}) => {
            try {
               if (hasError) {
                  setrecentClaimData({ error: true })
               } else {
                  sessionStorage.setItem(
                     "RecentClaims",
                     JSON.stringify({
                        error: false,
                        loading: true,
                        claims: recentClaims
                     })
                  )
                  setrecentClaimData({
                     error: false,
                     loading: true,
                     claims: recentClaims
                  })
               }
            } catch (error) { setrecentClaimData({ hasError: true, loading: true }) }
         })
      }
   }, [])
   const { loading, claims, error } = recentClaimData

   //AEM Content Fragment callback
   const [dashFragment, setDashFragment] = useState({
      error: false,
      dataSet: false,
      journeyTitle: "",
      journeyCardElem: [],
      actionCardElem: []
   })
   useEffect(() => {
      UseContentFragment(
         constants.DASHBOARD_CONTENT,
         ({
            hasError,
            journeyTitle,
            journeyCards,
            actionCards,
            recentclaimElem,
            seeclaimElem,
            planSummary,
            welcomeTitle,
            viewidcardElem
         } = {}) => {
            try {
               if (hasError) {
                  setDashFragment({ error: true })
               } else {
                  setDashFragment({
                     error: false,
                     dataSet: true,
                     journeyTitle,
                     journeyCardElem: journeyCards,
                     actionCardElem: actionCards,
                     recentclaimElem,
                     seeclaimElem,
                     planSummary,
                     welcomeTitle,
                     viewidcardElem
                  })
               }
            } catch (error) { }
         }
      )
   }, [])
   const {
      journeyTitle,
      journeyCardElem,
      actionCardElem,
      seeclaimElem,
      recentclaimElem,
      welcomeTitle,
      viewidcardElem,
      planSummary,
      dataSet
   } = dashFragment

   const viewIDCardLinkName = viewidcardElem && viewidcardElem.title && viewidcardElem.title.value
   const viewIDCardLink = viewidcardElem && viewidcardElem.href && viewidcardElem.href.value
   const learnMoreLinkName = planSummary && planSummary.footerTitle && planSummary.footerTitle.value
   const learnMoreLink = planSummary && planSummary.href && planSummary.href.value
   const recentClaimTitle = recentclaimElem && recentclaimElem.bodyTitle && recentclaimElem.bodyTitle.value
   const recentClaimImg = recentclaimElem && recentclaimElem.imgPath && recentclaimElem.imgPath.value
   const seeClaimLinkName = seeclaimElem && seeclaimElem.title && seeclaimElem.title.value
   const seeClaimLink = seeclaimElem && seeclaimElem.href && seeclaimElem.href.value
   const planSummaryTitle = planSummary && planSummary.headerTitle && planSummary.headerTitle.value
   const planMemberNo = planSummary && planSummary.bodySubtext && planSummary.bodySubtext.value[0]
   const planGroupNo = planSummary && planSummary.bodySubtext && planSummary.bodySubtext.value[1]
   const planPersonsCvrd = planSummary && planSummary.bodySubtext && planSummary.bodySubtext.value[2]
   let termedJourneyCard = journeyCardElem.filter(ele => ele && ele.footerTitle && ele.footerTitle.value.slice() !== "Find a dentist")
   termedJourneyCard = termedJourneyCard.filter(ele => ele && ele.footerTitle && ele.footerTitle.value.slice() !== "Estimate now")
   let termedActionCard = actionCardElem.filter(ele => ele && ele.bodyTitle && ele.bodyTitle.value.slice() !== "Don't See Your Dentist?")
   termedActionCard = termedActionCard.filter(ele => ele && ele.bodyTitle && ele.bodyTitle.value.slice() !== "Don't See Your Dentist")

   const buttonClickHandler = (ssoprops, hrefprops, pdfprops) => {
      if (ssoprops)
         window.open(hrefprops, "_blank")
      else if (pdfprops)
         window.open(aem_content_url + hrefprops, "_blank")
      else {
         window.location.href = hrefprops
      }
   }
   return (
      <>
         <DashBoardMemberInfo
            welcomeTitle={welcomeTitle}
            viewIDCardLinkName={viewIDCardLinkName}
            viewIDCardLink={viewIDCardLink}
            memberName={memberName}
            covStatus={covStatus}
         />
         <Section border style={{ padding: "20px 0" }}>
            <Grid mediaSm={200} mediaMd={989}>
               <PlanSummaryCard planSummaryTitle={planSummaryTitle} planMemberNo={planMemberNo}
                  planGroupNo={planGroupNo} planPersonsCvrd={planPersonsCvrd}>
                  {dataSet ? (
                     <TempoButton
                        style={{ width: "100%" }}
                        href={ learnMoreLink }>
                        {learnMoreLinkName || "Learn More About Your Plan"}
                     </TempoButton>
                  ) : null}
               </PlanSummaryCard>

               {dataSet && (covStatus === 'pre-effective' || covStatus === 'active') ? (
                  <Grid.Item span={12} spanSm={12} spanMd={12} spanLg={8}>
                     <div className="tempo-v1">
                        <Typography.H2 style={{ fontFamily: '"UHC Sans SemiBold", Arial, sans-serif' }}>
                           {journeyTitle}
                        </Typography.H2>
                     </div>
                     <Grid as="ul" mediaSm={200} mediaMd={989} style={{ margin: 0 }}>
                        {journeyCardElem.map((data, i) => (
                           <Grid.Item as="li" span={12} spanSm={6} spanMd={6} spanLg={3} key={i}>
                              <JourneyCardComponent
                                 title={data ? data.description ? data.description.value.replace(/(<([^>]+)>)/gi, "").split("\n").join("") : '' : ''}
                                 btnText={data ? data.footerTitle ? data.footerTitle.value : '' : ''}
                                 clicked={() => buttonClickHandler(data.sso.value, data.href.value, data.pdf.value)}
                                 imgPath={data ? data.imgPath ? `${aem_content_url}${data.imgPath.value}` : '' : ''}
                              />
                           </Grid.Item>
                        ))}
                     </Grid>
                  </Grid.Item>
               ) :
                  <Grid.Item span={12} spanSm={12} spanMd={12} spanLg={8}>
                     <Heading level="2" variant="secondary">
                        {journeyTitle}
                     </Heading>
                     <Grid as="ul" mediaSm={200} mediaMd={989} style={{ margin: 0 }}>
                        {termedJourneyCard.map((data, i) => (
                           <Grid.Item as="li" span={12} spanSm={6} spanMd={6} spanLg={3} key={i}>
                              <JourneyCardComponent
                                 title={data ? data.description ? data.description.value.replace(/(<([^>]+)>)/gi, "").split("\n").join("") : '' : ''}
                                 btnText={data ? data.footerTitle ? data.footerTitle.value : '' : ''}
                                 clicked={() => buttonClickHandler(data.sso.value, data.href.value, data.pdf.value)}
                                 imgPath={data ? data.imgPath ? `${aem_content_url}${data.imgPath.value}` : '' : ''}
                              />
                           </Grid.Item>
                        ))}
                     </Grid>
                  </Grid.Item>
               }
            </Grid>
         </Section>
         <Section border style={{ padding: "20px 0" }}>
            <Flex alignItems="center">
               <img src={`${aem_content_url}${recentClaimImg}`} alt="" style={{ height: 60, width: 60 }} />
               <Typography.H2>
                  {recentClaimTitle}
               </Typography.H2>
            </Flex>
            <Grid as="ul" mediaSm={200} mediaMd={936}>
               {loading && claims.length
                  ? claims.map(
                     ({ firstName, claimsList }, i) =>
                        claimsList.length &&
                        claimsList.map((data, j) => (
                           <Grid.Item key={j} as="li" span={12} spanSm={12}
                              spanMd={claims.length > 1 || claimsList.length > 1 ? 6 : 12}
                              spanLg={claims.length > 1 || claimsList.length > 1 ? 6 : 12}>
                              <ClaimCard
                                 key={j}
                                 member={firstName}
                                 serviceDate={data.serviceDate}
                                 claimNumber={data.claimNumber}
                                 processDate={data.processDate}
                                 provider={data.provider}
                                 amountBilled={data.amountBilled}
                                 planPaid={data.planPaid}
                                 amountOwed={data.amountOwed}
                                 claimAdjudicationStatus={data.claimAdjudicationStatus}
                                 claimKey={data.claimKey}
                                 claimType={data.claimType}
                              />
                           </Grid.Item>
                        ))
                  )
                  : null}
            </Grid>
            {/* if no claims display this message */}
            {!loading && !error ? <Loader Blocking /> : null}
            <NoclaimsMessage error={error} covStatus={covStatus} />

            <p style={{ textAlign: "center" }}>
               {claims && claims.length >= 1 ? (
                  <TempoButton
                     href={seeClaimLink}
                     buttonType='secondary-one'>
                     {seeClaimLinkName || "See All of Your Dental Claims"}
                  </TempoButton>
               ) : null}
            </p>
         </Section>
         {
            dataSet && (
               <Section border>
                  <Grid as="ul" mediaSm={200} mediaMd={936}>
                     {covStatus !== 'termed' ? actionCardElem.map((data, iv) => (
                        <li>
                        <ActionCard as="li" key={iv}
                           titleAction={data ? data.bodyTitle ? data.bodyTitle.value : '' : ''}
                           textAction={data ? data.description ? data.description.value.replace(/(<([^>]+)>)/gi, "") : '' : ''}
                           linkName={data ? data.footerTitle ? data.footerTitle.value : '' : ''}
                           clicked={() => buttonClickHandler(data.sso.value, data.href.value, data.pdf.value)}
                           imgPath={data ? data.imgPath ? `${aem_content_url}${data.imgPath.value}` : '' : ''}
                        />
                        </li>
                     )) :
                        termedActionCard.map((data, iv) => (
                           <li>
                           <ActionCard as="li" key={iv}
                              covStatus={covStatus}
                              titleAction={data ? data.bodyTitle ? data.bodyTitle.value : '' : ''}
                              textAction={data ? data.description ? data.description.value.replace(/(<([^>]+)>)/gi, "") : '' : ''}
                              linkName={data ? data.footerTitle ? data.footerTitle.value : '' : ''}
                              clicked={() => buttonClickHandler(data.sso.value, data.href.value, data.pdf.value)}
                              imgPath={data ? data.imgPath ? `${aem_content_url}${data.imgPath.value}` : '' : ''}
                           />
                           </li>
                        ))
                     }
                  </Grid>
               </Section>
            )
         }
      </>
   )
}

export default Dashboard
import { useContext } from "react";
import { ProfileContext } from "../../../../../contexts/profileContext";
import getPlanYear from '../../../../../tools/getPlanYear';

const DoctorsQuery = () => {
  const { profile } = useContext(ProfileContext) || {};
  const { loggedinMember } = profile || {};
  const { coverages } = loggedinMember || {};
  const { medical } = coverages || {};
  const { situsState, reportingCode, coverageStatus, startDate } = medical || {};

  const planYear = getPlanYear(startDate)

  const searchPathByList = `/content/dam/iex/${planYear}/pre-effective/dashboard/provider-right-tile`
  const searchPathByPath = `/content/dam/iex/${planYear}/pre-effective/dashboard/provider-right-tile/gated`
  const requestParameters = `;searchPathByList=${searchPathByList};searchPathByPath=${searchPathByPath};situsState=${situsState};reportingCode=${reportingCode}`
  const requestPath = `/myuhc/graphql/execute.json/IEX/dashboardDoctorsQuery.json${encodeURIComponent(requestParameters)}`
  return requestPath;
};

export default DoctorsQuery;

import React, { useState, useEffect, useContext } from "react"
import MemberInfo from "./components/memberInfo"
import PremiumPayments from "./components/premiumPayments"
import ProviderFacilities from "./components/preeffective-components/providerFacilities"
//import CoverageHandbook from "./components/coverageHandbook"
// import HealthResources from "./components/healthResources"
import AddlHealthResources from "./components/healthResourcesAddl"
import UseContentFragments from "../../../hooks/contentfragment/useContentFragments"
import Constants from "../../../constants/contentEndpoints"
import config from "../../../config"
import { ProfileContext } from "../../../contexts/profileContext"
import "./components/memberModal.css"
import './index.css'
// import Provider from './components/effective-components/provider'
import PcpSelector from './components/pcp/pcpSelector'
// import ClaimsAccounts from './components/effective-components/claimsAccounts'
import ClaimsAccounts from './components/effective-components/claimsAccount-redesign/claimsAccountsNew'
import pageLoadAnalytics from "../../../hooks/pageLoadAnalytics"
import RallyRedirects from "../../../constants/rally-redirects"
import PaymentContacts from "../../../constants/paymentContacts";
import Banner from './components/banner'
import MedicaidBanner from './components/medicaidBanner'
import GalileoVirtualCare from './components/preeffective-components/galileoVirtualCare'
import GalileoHeroImage from './components/effective-components/galileoHeroImage'
import GalileoHeroQuery from './components/preeffective-components/galileoHeroQuery'
import UseGraphqlApi from '../../../hooks/useGraphqlApi'
import GalileoProvider from "./components/effective-components/GalileoProvider";
import GetCookieItem from "../../../tools/getCookieItem";
import pageTrackAnalytics from "../../../hooks/pageTrackAnalytics"


const { aem_content_url } = config;
const { find_provider, virtual_visit } = RallyRedirects;

const DashboardI28 = (props) => {
  const { isNMPayExempt, isNMuser } = props
  const { profile } = useContext(ProfileContext) || {};
  const { loggedinMember, dependents } = profile || {};
  const dependentsArr = dependents || {};
  const {
    firstName: memberName,
    isSubscriber,
    coverages,
    activeCoverages,
    dependentSeqNbr,
    isMedicaidReDt,
  } = loggedinMember || {};
  const { medical, dental, vision } = coverages || {};
  const { coverageStatus } = medical || {};
  const medicalMemberID = medical ? medical["memberID"] : "";
  const dentalMemberID = dental ? dental["memberID"] : "";
  const visionMemberID = vision ? vision["memberID"] : "";
  const situsState = medical ? medical["situsState"] : "";
  const { coverageStatus: dentalCoverageStatus } = dental;
  const { coverageStatus: visionCoverageStatus } = vision;
  const contact = PaymentContacts(situsState) || {};
  const isNJuser = situsState && situsState === "NJ" ? true : false; 

  const contentfragement_endpoint = Constants.DASHBOARDI28_CONTENT;
  const [dashboardI28, setDashboardI28] = useState({
    isLoading: false,
  });

  const [dashboardData, setDashboardData] = useState({
    isDataReady: false
  });

  const selectedUser = dashboardData ?.dashboardviewFor;

  const {
    dateOfBirth: selectedDateOfBirth = '',
    dependentSeqNbr: selectedDependentSeqNbr = '',
    coverages: { medical: selectedMedical = {} } = {},
  } = [loggedinMember, ...dependents].find(
    (x) => x ?.dependentSeqNbr === selectedUser ?.dependentSeqNbr
  ) || {};


  const ifSubscriber = () => isSubscriber;    //checking the user category
  const userCategory = ifSubscriber () ? "subscriber" : "dependent"

  const ifLoggedIn = () => userCategory       //checking if the member is logged in
  const isLoggedIn = ifLoggedIn () ? "logged in" : "not logged in"
 

  let planStatus=""; //checking plan status of member
  if(coverageStatus === "pre-effective" || dentalCoverageStatus === "pre-effective"|| visionCoverageStatus === "pre-effective")
    planStatus+="pre-effective"
  if(coverageStatus === "active" || dentalCoverageStatus === "active"|| visionCoverageStatus === "active"){
  if(planStatus === "pre-effective"){
    planStatus+="|"
  }
    planStatus+="active"
  }
  if(coverageStatus === "termed" || dentalCoverageStatus === "termed"|| visionCoverageStatus === "termed"){
    if(planStatus === "pre-effective" || "active" || "pre-effective|active"){
      planStatus+="|"
    }
    planStatus+="termed"
  }
  
  let planCoverage = ""  //checking plan coverage of member
  if(coverageStatus === "active")
    planCoverage += "m" 
  if(dentalCoverageStatus === "active"){
      if(planCoverage === "m")
       planCoverage+="|"
      planCoverage += "d"
    }
  if(visionCoverageStatus === "active"){
      if(planCoverage === "m" || "d" || "m|d")
       planCoverage+="|"
    planCoverage += "v"
    }
    

  let preLoginLang = GetCookieItem("LOGINLOCALE") || "en" //checking language 
  let postLoginLang = GetCookieItem("lang") || "en"
  let lang = preLoginLang === "es" || postLoginLang === "es" ? "spanish" : "english"

  const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);  //checking user device
  const experienceType = isMobile () ? "mobile" : "desktop"
  let global_api_constants = {};
  if (sessionStorage.getItem("global-api-constants")) {
    global_api_constants = JSON.parse(
      sessionStorage.getItem("global-api-constants"));
  }
  const { adobeAnalyticsNew } = global_api_constants || {}
  useEffect(() => {
    UseContentFragments(contentfragement_endpoint, (response) => {
      const isError= () => (response.APIError)
      const is404 = isError() ? true : false    //checking the API Error
      if(adobeAnalyticsNew){
        pageTrackAnalytics( experienceType, is404, lang, "dashboard", "secure", "", "", "", 
        "", "content", isLoggedIn, "member", userCategory, planStatus, planCoverage );
      }
      else
      pageLoadAnalytics("dashboard", "", "", "", "iex")
    })
  }, [])

   //US5889065 - Support viewing ID card page via deeplink
   useEffect(() => {
    if (window.location.href.includes("deeplinkidcards")) {
      if (coverageStatus === "pre-effective" || coverageStatus === "active" ) {
        console.log("Opening ID card page for Pre-effective and Active members");
        const { consumer_url } = config;
        const delayModal = setTimeout(() => {
          window.open(
            consumer_url + `/ifp/accounts#/viewidcard`,
            "popup",
            "width=1200,height=1000"
          );
      }, 5000);

      return () => clearTimeout(delayModal);

      }
    }
  }, []); 

  //US4303479 - Message Center Deep Link. MyUHC Legacy repo has dependent changes.
  useEffect(() => {
    if (window.location.href.includes("deeplinkmc")) {
      let global_api_constants = {}
      if (sessionStorage.getItem("global-api-constants")) {
        global_api_constants = JSON.parse(
            sessionStorage.getItem("global-api-constants")
        )
      }
      if (coverageStatus !== "pre-effective") {
        console.log(
          "Opening message center page in new tab for Active and Pre-effective"
        );
        const { myuhc_20_url, myuhc_20_es_url } = config;
        const lang = GetCookieItem("LOGINLOCALE");
        let domain = `${myuhc_20_url}`;

        if (lang === "es") {
          domain = `${myuhc_20_es_url}`;
        }

        if(global_api_constants && global_api_constants.MESSAGECENTER_POPUP_SWITCH == "true") {
          //adding 5s delay to make screen reader read the tab name
          const delayModal = setTimeout(() => {
              window.open(
                domain + `/content/myuhc/en/secure/message-center-popup.html`,
                "popup",
                "width=600,height=600"
              );
          }, 5000);
  
          return () => clearTimeout(delayModal);
          
        } else if (global_api_constants.MESSAGECENTER_POPUP_SWITCH == "false") {
          window.location.href = "/ifp/accounts#/messagecenter"
        }
      }
    }
  }, []);

  useEffect(() => {
    const { loggedinMember, dependents } = profile || {};
    //object to filterdataby
    //**
    let dashboardMemberData = [loggedinMember];
    dashboardMemberData = loggedinMember.relationship === "SUBSCRIBER" &&
      dependents.length > 0 && [...dashboardMemberData, ...dependents];
    //setDefaultview
    const viewFor = {
      name: loggedinMember.firstName,
      relationship: loggedinMember.relationship,
      dependentSeqNbr: loggedinMember.dependentSeqNbr,
      pediatric:
        Math.floor(
          (new Date() - new Date(loggedinMember.dobFormatted)) / 31557600000
        ) < 19,
      waState:
        loggedinMember.coverages &&
          loggedinMember.coverages.medical &&
          loggedinMember.coverages.medical.situsState &&
          loggedinMember.coverages.medical.situsState === "WA"
          ? true
          : false,
    };
    setDashboardData({
      dashboardMemberData,
      dashboardviewFor: viewFor,
      isDataReady: true,
    });
  }, [profile]);

  useEffect(() => {
    UseContentFragments(contentfragement_endpoint, (response) => {
      // response ={data: {data: { pcp: {bodyTitle: "Primary care provider",
      // button: false,
      // description: "<p>You haven't selected your Primary Care Provider yet. Select yours today.</p>\n",
      // footerTitle: "Select Your provider",
      // href: "",
      // imgPath: "/content/dam/myuhc/consumer/assets/myuhc/provider-facility.svg",
      // link: true,
      // pdf: false,
      // sso: false },
      // pcpTitle: {type: "consumer/components/title",
      //   linkDisabled: false,
      //   text: "Meet your doctor",
      //   type: "h4"
      // },
      // APIError: false}}}
      try {
        if (response.APIError) {
          console.log("error");
        } else {
          const data = response.data.data;
          // console.log(data)
          if (data) {
            setDashboardI28({
              viewIdCard: data["viewIdCard"],
              planInfo: data["planInfo"],
              findProvider: data["findProvider"],
              viewBenefits: data["viewBenefits"],
              goPaperless: data["goPaperless"],
              mngPrpLink: data["mngPrpLink"],
              mngRxLink: data["mngRxLink"],
              hsaLink: data["hsaLink"],
              premiumPaymentTitle: data["premiumPaymentTitle"],
              premiumPaymentSubTitle: data["premiumPaymentSubTitle"],
              medicalPlan: data["medicalPlan"],
              autoPay: data["autoPay"],
              cancelAutoPay: data["cancelAutoPay"],
              pcpTitle: data["pcpTitle"],
              pcp: data["pcp"],
              changeProvider: data["changeProvider"],
              savedProvider: data["savedProvider"],
              healthResourceTitle: data["healthResourceTitle"],
              healthResourceList: data["healthResourceList"],
              healthResourceButton: data["healthResourceButton"],
              virtualCare: data["virtualCare"],
              apiFailErrorMsg: data["apiFailErrorMsg"],
              claimsTitle: data["claimsTitle"],
              viewClaimsLink: data["viewClaimsLink"],
              medicalTab: data["medical"],
              indDedMet: data["indDedMet"],
              indDedNotMet: data["indDedNotMet"],
              familyDedMet: data["familyDedMet"],
              familyDedNotMet: data["familyDedNotMet"],
              oopMaxTooltip: data["oopMaxTooltip"],
              adultDental: data["adultDental"],
              annualMaxMet: data["annualMaxMet"],
              annualMaxApproach: data["annualMaxApproach"],
              annualMaxNotMet: data["annualMaxNotMet"],
              accumulatorMsg: data["accumulatorMsg"],
              pcpPopup: data.pcpPopup,
              preEffectiveRewards: data.preEffectiveRewards,
              preEffectiveRewardsButton: data.preEffectiveRewardsButton,
              effectiveRewards: data.effectiveRewards,
              effectiveRewardsButton: data.effectiveRewardsButton,
              reimbursementImage: data.rewardsReimbursementImage,
              mobileAppTileContent: data["mobileAppTileContent"], 
              mobileAppTileLink: data["mobileAppTileLink"],
              mobileAppTileImage: data["mobileAppTileImage"],
              viewReferrals: data.viewReferrals,
              defaultFullySubsidisedMessage: data["defaultFullySubsidisedMessage"],
              fullySubsidisedWithoutWalletMessage: data["fullySubsidisedWithoutWalletMessage"],
              fullySubsidisedWithWalletMessage: data["fullySubsidisedWithWalletMessage"],
              //isLocalHost: window.location.href.includes('localhost') ? true : false,
              isLoading: true,
            });
          }
        }
      } catch (error) {
        console.log("Error while calling I28 member content fragment");
      }
    });
  }, [contentfragement_endpoint, situsState]);

  const handleView = (dashboardviewFor) => {
    //update state on selecting members

    setDashboardData({
      ...dashboardData,
      dashboardviewFor,
      isDataReady: true,
    });
  };

  const {
    viewIdCard,
    planInfo,
    findProvider,
    viewBenefits,
    mngPrpLink,
    mngRxLink,
    hsaLink,
    goPaperless,
    premiumPaymentTitle,
    premiumPaymentSubTitle,
    medicalPlan,
    autoPay,
    cancelAutoPay,
    pcpTitle,
    pcp,
    savedProvider,
    changeProvider,
    healthResourceTitle,
    healthResourceList,
    healthResourceButton,
    virtualCare,
    apiFailErrorMsg,
    claimsTitle,
    viewClaimsLink,
    medicalTab,
    indDedMet,
    indDedNotMet,
    familyDedMet,
    familyDedNotMet,
    oopMaxTooltip,
    adultDental,
    annualMaxMet,
    annualMaxApproach,
    annualMaxNotMet,
    accumulatorMsg,
    isLoading,
    pcpPopup,
    preEffectiveRewards,
    preEffectiveRewardsButton,
    effectiveRewards,
    effectiveRewardsButton,
    reimbursementImage,
    mobileAppTileContent, 
    mobileAppTileLink,
    mobileAppTileImage,
    viewReferrals,
    defaultFullySubsidisedMessage,
    fullySubsidisedWithoutWalletMessage,
    fullySubsidisedWithWalletMessage,

  } = dashboardI28;
  const { dashboardMemberData, dashboardviewFor, isDataReady } = dashboardData;

  const buttonClickHandler = (ssoprops, hrefprops, pdfprops) => {
    if (ssoprops) window.open(hrefprops, "_blank");
    else if (pdfprops) window.open(aem_content_url + hrefprops, "_blank");
    else {
      window.location.href = hrefprops;
    }
  };

  const virtualVisiturl = () => {
    window.open(virtual_visit, "_self");
  };
  const providerUrl = () => {
    // if (situsState === "AZ")
    //    window.open(
    //       "https://connect.werally.com/guest/eyJwbGFuTmFtZSI6IkFaIENvbXBhc3MgSE1PIiwiZGVsc3lzIjoiOTA3IiwiY292ZXJhZ2VUeXBlIjoibWVkaWNhbCIsInBhcnRuZXJJZCI6InVoYy5tZWRpY2FpZCIsImxhbmd1YWdlIjoiZW4iLCJzaG93Q29zdHMiOnRydWUsImZpcHNDb2RlIjoiMDQifQ-30vIWtlCFmSsO9z8H2q05Kqjcsdh-f8mlEAXBeJOsE",
    //       "_blank"
    //    )
    // if (situsState === "NC")
    //    window.open(
    //       "https://connect.werally.com/guest/eyJwbGFuTmFtZSI6Ik5DIENvbXBhc3MgSE1PIiwiZGVsc3lzIjoiOTAxIiwiY292ZXJhZ2VUeXBlIjoibWVkaWNhbCIsInBhcnRuZXJJZCI6InVoYy5tZWRpY2FpZCIsImxhbmd1YWdlIjoiZW4iLCJzaG93Q29zdHMiOnRydWUsImZpcHNDb2RlIjoiMzcifQM4fyQ0BElzekYhW92Wng3Nxhgd9-uPgaCbXQq6b8lYM",
    //       "_blank"
    //    )
    // if (situsState === "OK")
    //    window.open(
    //       "https://connect.werally.com/guest/eyJwbGFuTmFtZSI6Ik9LIENvbXBhc3MgSE1PIiwiZGVsc3lzIjoiOTAyIiwiY292ZXJhZ2VUeXBlIjoibWVkaWNhbCIsInBhcnRuZXJJZCI6InVoYy5tZWRpY2FpZCIsImxhbmd1YWdlIjoiZW4iLCJzaG93Q29zdHMiOnRydWUsImZpcHNDb2RlIjoiNDAifQkbZr4hD241lwP7duW9jMz5OyzZsHNr-WGWXDtqi47-w",
    //       "_blank"
    //    )
    // if (situsState === "WA")
    //    window.open(
    //       "https://connect.werally.com/guest/eyJwbGFuTmFtZSI6IldBIENoYXJ0ZXIgRVBPIiwiZGVsc3lzIjoiOTAzIiwiY292ZXJhZ2VUeXBlIjoibWVkaWNhbCIsInBhcnRuZXJJZCI6InVoYy5tZWRpY2FpZCIsImxhbmd1YWdlIjoiZW4iLCJzaG93Q29zdHMiOnRydWUsImZpcHNDb2RlIjoiNTMifQDl2Y0HmE7q-Uxcxoc5JBqsjR46h9yXvxMswsHpOJ80U",
    //       "_blank"
    //    )
    // if (situsState === "VA")
    //    window.open(
    //       "https://connect.werally.com/guest/eyJwbGFuTmFtZSI6IlZBIENvbXBhc3MgSE1PIiwiZGVsc3lzIjoiOTA0IiwiY292ZXJhZ2VUeXBlIjoibWVkaWNhbCIsInBhcnRuZXJJZCI6InVoYy5tZWRpY2FpZCIsImxhbmd1YWdlIjoiZW4iLCJzaG93Q29zdHMiOnRydWUsImZpcHNDb2RlIjoiNTEifQNehJdTD3bgycUadu6vhA6Q_HsJmGAcAChCmXmuVg0Jo",
    //       "_blank"
    //    )
    // if (situsState === "TN")
    //    window.open(
    //       "https://connect.werally.com/guest/eyJwbGFuTmFtZSI6IlROIENvbXBhc3MgRVBPIiwiZGVsc3lzIjoiOTA2IiwiY292ZXJhZ2VUeXBlIjoibWVkaWNhbCIsInBhcnRuZXJJZCI6InVoYy5tZWRpY2FpZCIsImxhbmd1YWdlIjoiZW4iLCJzaG93Q29zdHMiOnRydWUsImZpcHNDb2RlIjoiNDcifQRvmA0twtEdumTYSW9MiU5wDU7HN6mK5tgGBpZGc5bZ4",
    //       "_blank"
    //    )
    // if (situsState === "MD")
    //    window.open(
    //       "https://connect.werally.com/guest/eyJwbGFuTmFtZSI6Ik1EIENvbXBhc3MgSE1PIiwiZGVsc3lzIjoiOTA1IiwiY292ZXJhZ2VUeXBlIjoibWVkaWNhbCIsInBhcnRuZXJJZCI6InVoYy5tZWRpY2FpZCIsImxhbmd1YWdlIjoiZW4iLCJzaG93Q29zdHMiOnRydWUsImZpcHNDb2RlIjoiMjQifQj5ZfXDppcZyWGDvgw477ftP6Q9DehTy418vGgDAetHA",
    //       "_blank"
    //    )
    window.open(find_provider, "_self");
  };

  // GraphQl query implementation starts
  const [virtualCareGalieo, setVirtualCareGalieo] = useState([]);
  const [vcLoading, setVCLoading] = useState(true);
  const [vcError, setVCError] = useState(false);
  const VCQuery = GalileoHeroQuery();

  useEffect(() => {
    UseGraphqlApi(VCQuery, postVirtualCareHandler);
  }, []);

  const postVirtualCareHandler = (response) => {
    if (response && !response.isError) {
      if (response.data) {
        setVirtualCareGalieo(
          response.data.listTileList &&
            response.data.listTileList.items &&
            response.data.listTileList.items.length > 0
            ? response.data.listTileList.items[0]
            : null
        );
      }
    } else {
      setVCError(true);
    }
    setVCLoading(false);
  };
  const {
    title,
    description,
    bodySubtext,
    image,
    linkText,
    urlType,
    url,
    icon1,
    icon2,
  } = virtualCareGalieo || {};
  const isGalileo = !vcError && virtualCareGalieo ? true : false;
  // GraphQl query implementation ends

  const isKelseySeybold = sessionStorage.getItem("isKelseySeybold") === 'true' ? true : false;
  const suppressClaimsAccumulatorsSwitch = global_api_constants?.KS_CLAIMS_ACCUMULATOR_SUPPRESS === "true" ? true : false;
  const suppressClaimsAccumulators = isKelseySeybold && suppressClaimsAccumulatorsSwitch;

  return (
    <>
      {isLoading && isDataReady ? (
        <>
          {isMedicaidReDt ? <MedicaidBanner depSeqNbr={dependentSeqNbr} /> : null}
          <Banner depSeqNbr={dependentSeqNbr} />
          <MemberInfo
            viewIdCard={viewIdCard}
            planInfo={planInfo}
            findProvider={findProvider}
            viewBenefits={viewBenefits}
            goPaperless={goPaperless}
            mngPrpLink={mngPrpLink}
            mngRxLink={mngRxLink}
            hsaLink={hsaLink}
            //clickfp={() => buttonClickHandler(findProvider.sso, findProvider.href, findProvider.pdf)}
            clickvb={() =>
              buttonClickHandler(
                viewBenefits.sso,
                viewBenefits.href,
                viewBenefits.pdf
              )
            }
            clickgp={() =>
              buttonClickHandler(
                goPaperless.sso,
                goPaperless.href,
                goPaperless.pdf
              )
            }
            profile={profile}
            coverageStatus={coverageStatus}
            dependents={dependentsArr}
            isSubscriber={isSubscriber}
            situsState={situsState}
            activeCoverages={activeCoverages}
            memberName={memberName}
            medicalMemberID={medicalMemberID}
            dentalMemberID={dentalMemberID}
            visionMemberID={visionMemberID}
            providerUrl={() => providerUrl()}
            handleView={handleView}
            dashboardMemberData={dashboardMemberData}
            dashboardviewFor={dashboardviewFor}
            isLoading={isLoading}
            isGalileo={isGalileo}
            isNMPayExempt = {isNMPayExempt}
          />


          {/* {coverageStatus === "active" && !isGalileo ? ( 
            <Provider
              pcpTitle={pcpTitle}
              pcp={pcp}
              savedProvider={savedProvider}
              changeProvider={changeProvider}
              dependentSeqNbr={dashboardviewFor.dependentSeqNbr}
              providerUrl={() => providerUrl()}
              virtualCare={virtualCare}
              clickVC={virtualVisiturl}
              apiFailErrorMsg={apiFailErrorMsg}
              situsState={situsState}
            ></Provider>
          ) : null}  */}


          {coverageStatus === "active" && !isGalileo ? (
            <PcpSelector
              isKelseySeybold={isKelseySeybold}
              pcpTitle={pcpTitle}
              pcp={pcp}
              savedProvider={savedProvider}
              changeProvider={changeProvider}
              dependentSeqNbr={dashboardviewFor.dependentSeqNbr}
              providerUrl={() => providerUrl()}
              virtualCare={virtualCare}
              clickVC={virtualVisiturl}
              apiFailErrorMsg={apiFailErrorMsg}
              situsState={situsState}
              pcpPopup={pcpPopup}
              viewReferrals={viewReferrals}
              selectedDependentSeqNbr={selectedDependentSeqNbr}
              selectedDateOfBirth={selectedDateOfBirth}
            ></PcpSelector>
          ) : null}

          {coverageStatus === "active" && isGalileo ? (
            <GalileoHeroImage />
          ) : null}

          {coverageStatus === "active" && isGalileo ? (
            <PcpSelector
              isGalileo={isGalileo}
              pcpTitle={pcpTitle}
              pcp={pcp}
              savedProvider={savedProvider}
              changeProvider={changeProvider}
              dependentSeqNbr={dashboardviewFor.dependentSeqNbr}
              providerUrl={() => providerUrl()}
              virtualCare={virtualCare}
              clickVC={virtualVisiturl}
              apiFailErrorMsg={apiFailErrorMsg}
              situsState={situsState}
              pcpPopup={pcpPopup}
              viewReferrals={viewReferrals}
              selectedDependentSeqNbr={selectedDependentSeqNbr}
              selectedDateOfBirth={selectedDateOfBirth}
            ></PcpSelector>
          ) : null}


          {/* {coverageStatus === "active" && isGalileo ? (
            <GalileoProvider
              pcpTitle={pcpTitle}
              pcp={pcp}
              savedProvider={savedProvider}
              situsState={situsState}
              changeProvider={changeProvider}
              dependentSeqNbr={dashboardviewFor.dependentSeqNbr}
              providerUrl={() => providerUrl()}
              virtualCare={virtualCare}
              clickVC={virtualVisiturl}
              apiFailErrorMsg={apiFailErrorMsg}
            ></GalileoProvider>
          ) : null} */}

          {coverageStatus === "pre-effective" && isGalileo ? (
            <GalileoVirtualCare
              title={title}
              description={description}
              image={image}
              linkText={linkText}
              urlType={urlType}
              url={url}
              bodySubtext={bodySubtext}
              icon1={icon1}
              icon2={icon2}
            />
          ) : null}

          {coverageStatus === "pre-effective" ? (
              <PremiumPayments
                isGalileo={isGalileo}
                premiumPaymentTitle={premiumPaymentTitle}
                medicalPlan={medicalPlan}
                autoPay={autoPay}
                cancelAutoPay={cancelAutoPay}
                defaultFullySubsidisedMessage={defaultFullySubsidisedMessage}
                fullySubsidisedWithWalletMessage={fullySubsidisedWithWalletMessage}
                fullySubsidisedWithoutWalletMessage={fullySubsidisedWithoutWalletMessage}
                clickPay={() =>
                  buttonClickHandler(
                    medicalPlan.sso,
                    medicalPlan.href,
                    medicalPlan.pdf
                  )
                }
                clickAutoPay={() =>
                  buttonClickHandler(autoPay.sso, autoPay.href, autoPay.pdf)
                }
                medicalMemberID={medicalMemberID}
                apiFailErrorMsg={apiFailErrorMsg}
                contact={contact}
                isLoading={isLoading}
                coverageStatus={coverageStatus}
                isNMPayExempt = {isNMPayExempt}
              />
            ) : null}
          {coverageStatus === "pre-effective" && !isGalileo ? (
            <PcpSelector
              isKelseySeybold={isKelseySeybold}
              pcpTitle={pcpTitle}
              pcp={pcp}
              savedProvider={savedProvider}
              changeProvider={changeProvider}
              dependentSeqNbr={dashboardviewFor.dependentSeqNbr}
              providerUrl={() => providerUrl()}
              virtualCare={virtualCare}
              clickVC={virtualVisiturl}
              apiFailErrorMsg={apiFailErrorMsg}
              situsState={situsState}
              pcpPopup={pcpPopup}
              viewReferrals={viewReferrals}
              selectedDependentSeqNbr={selectedDependentSeqNbr}
            ></PcpSelector>
          ) : null}
          {coverageStatus === "pre-effective" && isGalileo ? (
            <ProviderFacilities
              isGalileo={isGalileo}
              isKelseySeybold={isKelseySeybold}
              pcpTitle={pcpTitle}
              pcp={pcp}
              savedProvider={savedProvider}
              changeProvider={changeProvider}
              dependentSeqNbr={dashboardviewFor.dependentSeqNbr}
              clickSelectProvider={() => buttonClickHandler(pcp.sso, pcp.href, pcp.pdf)}
              clickSavedProvider={() => buttonClickHandler(savedProvider.sso, savedProvider.href, savedProvider.pdf)}
              isSubscriber={isSubscriber}
              providerUrl={() => providerUrl()}
              isLoading={isLoading}
              handleView={handleView}
              dashboardMemberData={dashboardMemberData}
              dashboardviewFor={dashboardviewFor}
            />
          ) : null}
          {/* <CoverageHandbook coverageHandbook={coverageHandbook}
                  isLoading={isLoading} /> */}

          {/* <HealthResources
                  coverageStatus={coverageStatus}
                  healthResourceTitle={healthResourceTitle}
                  healthResourceList={healthResourceList}
                  healthResourceButton={healthResourceButton}
                  isLoading={isLoading}
          /> */}

          <AddlHealthResources
            isGalileo={isGalileo}
            coverageStatus={coverageStatus}
            healthResourceTitle={healthResourceTitle}
            healthResourceList={healthResourceList}
            healthResourceButton={healthResourceButton}
            isLoading={isLoading}
            preEffectiveRewards={preEffectiveRewards}
            preEffectiveRewardsButton={preEffectiveRewardsButton}
            effectiveRewards={effectiveRewards}
            effectiveRewardsButton={effectiveRewardsButton}
            reimbursementImage={reimbursementImage}
            selectedDateOfBirth={selectedDateOfBirth}
            selectedMedical={selectedMedical}
            situsState={situsState}
            isNJuser={isNJuser}
            isNMuser={isNMuser}
            isNMPayExempt = {isNMPayExempt}
            mobileAppTileContent = {mobileAppTileContent}            
            mobileAppTileLink = {mobileAppTileLink}
            mobileAppTileImage = {mobileAppTileImage}            
          />

          {coverageStatus === "active" ? (
              <PremiumPayments
                coverageStatus={coverageStatus}
                premiumPaymentTitle={premiumPaymentTitle}
                premiumPaymentSubTitle={premiumPaymentSubTitle}
                medicalPlan={medicalPlan}
                autoPay={autoPay}
                cancelAutoPay={cancelAutoPay}
                defaultFullySubsidisedMessage={defaultFullySubsidisedMessage}
                fullySubsidisedWithWalletMessage={fullySubsidisedWithWalletMessage}
                fullySubsidisedWithoutWalletMessage={fullySubsidisedWithoutWalletMessage}
                clickPay={() =>
                  buttonClickHandler(
                    medicalPlan.sso,
                    medicalPlan.href,
                    medicalPlan.pdf
                  )
                }
                clickAutoPay={() =>
                  buttonClickHandler(autoPay.sso, autoPay.href, autoPay.pdf)
                }
                medicalMemberID={medicalMemberID}
                apiFailErrorMsg={apiFailErrorMsg}
                contact={contact}
                isLoading={isLoading}
                isNMPayExempt = {isNMPayExempt}
              />
            ) : null}
          {coverageStatus === "active" ? (
            <ClaimsAccounts
              dependentSeqNbr={dashboardviewFor.dependentSeqNbr}
              pediatric={dashboardviewFor.pediatric}
              waState={dashboardviewFor.waState}
              apiFailErrorMsg={apiFailErrorMsg}
              claimsTitle={claimsTitle}
              viewClaimsLink={viewClaimsLink}
              medicalTab={medicalTab}
              indDedMet={indDedMet}
              indDedNotMet={indDedNotMet}
              familyDedMet={familyDedMet}
              familyDedNotMet={familyDedNotMet}
              oopMaxTooltip={oopMaxTooltip}
              adultDental={adultDental}
              annualMaxMet={annualMaxMet}
              annualMaxApproach={annualMaxApproach}
              annualMaxNotMet={annualMaxNotMet}
              accumulatorMsg={accumulatorMsg}
              suppressClaimsAccumulators={suppressClaimsAccumulators}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default DashboardI28
import config from "../config";
import GetConstants from "../tools/getConstants";
import ContentEndpoints from "../constants/contentEndpoints";

const { GLOBAL_API_CONSTANTS } = ContentEndpoints || {};
const { obapi_base_uri, bills_base_uri, dashboard_url, common_url, consumer_url, sheild_base_uri, sheild_base2_uri } = config || {};
const Constants = () => {
   let endpoints = {}
   let profileEndpoint, referralsEndpoint,
      memberactions_endpoint, memberactionupdate_endpoint,
      plandetail_endpoint, primarycare_endpoint, claimsummary_endpoint,
      accountbalance_endpoint, getmember_service_endpoint,b360_endpoint = '';
   if (process.env.NODE_ENV === "development") {
      endpoints = {
         ...endpoints,
         profile_endpoint: "/profile.json",
         eligiblity_endpoint: "/eligiblity.json",
         claimsummary_endpoint: "/claimsummary.json",
         plandetail_endpoint: "/plandetail.json",
         primarycare_endpoint: "/primarycare.json",
         bills_endpoint: '/bills.json',
         recurring_endpoint: "/recurringsearch.json",
         referrals_endpoint: "/referrals.json",
         accountbalance_endpoint: "/accountbalance.json",
         memberactions_endpoint: "/memberactions.json?requestType=OFFERS&offersCount=15",
         memberactionupdate_endpoint: "/memberactions.json",
         getmember_service_endpoint: '/cel/shield/members/v5/search',
         wallet_service_endpoint: '/cel/shield/wallets/v1',
         b360_endpoint: "/ben/b360/benefit-details"  
      }
   } else {
      GetConstants(GLOBAL_API_CONSTANTS, (constants) => {
         const { PROFILE_ENDPOINT, REFERRAL_ENDPOINT, MEMBERACTIONS_ENDPOINT,
            MEMBERACTIONS_UPDATE_ENDPOINT, PLANDETAIL_ENDPOINT, CLAIMSTATUS_ENDPOINT,
            PRIMARYCARE_ENDPOINT, ACCOUNTBALANCE_ENDPOINT, B360_ENDPOINT } = constants || {};
         PROFILE_ENDPOINT.includes('offline') ?
            profileEndpoint = `${consumer_url}/ifp/getProfileContextOffline?endpoint=${PROFILE_ENDPOINT}` :
            profileEndpoint = `${common_url}/getProfileContext?endpoint=${PROFILE_ENDPOINT}`
         REFERRAL_ENDPOINT.includes('offline') ?
            referralsEndpoint = `${consumer_url}/ifp/globalApiService?endpoint=${REFERRAL_ENDPOINT}` :
            referralsEndpoint = `${dashboard_url}/v4.6/referrals${obapi_base_uri}${REFERRAL_ENDPOINT}`
         MEMBERACTIONS_ENDPOINT.includes('offline') ?
            memberactions_endpoint = `${consumer_url}/ifp/globalApiService?endpoint=${MEMBERACTIONS_ENDPOINT}` :
            memberactions_endpoint = `${dashboard_url}/v4.6/memberactions${obapi_base_uri}/ccs/uhcd-tools/v4.6/memberactions?requestType=OFFERS&offersCount=15`
         MEMBERACTIONS_UPDATE_ENDPOINT.includes('offline') ?
            memberactionupdate_endpoint = `${consumer_url}/ifp/globalApiService?endpoint=${MEMBERACTIONS_UPDATE_ENDPOINT}` :
            memberactionupdate_endpoint = `${dashboard_url}/v4.6/memberactions-update${obapi_base_uri}/ccs/uhcd-tools/v4.6/memberactions`
         PLANDETAIL_ENDPOINT.includes('offline') ?
            plandetail_endpoint = `${consumer_url}/ifp/globalApiService?endpoint=${PLANDETAIL_ENDPOINT}` :
            plandetail_endpoint = `${dashboard_url}/v4.6/plandetails${obapi_base_uri}${PLANDETAIL_ENDPOINT}`
         PRIMARYCARE_ENDPOINT.includes('offline') ?
            primarycare_endpoint = `${consumer_url}/ifp/globalApiService?endpoint=${PRIMARYCARE_ENDPOINT}` :
            primarycare_endpoint = `${dashboard_url}/v5/primarycare${obapi_base_uri}${PRIMARYCARE_ENDPOINT}`
         ACCOUNTBALANCE_ENDPOINT.includes('offline') ?
            accountbalance_endpoint = `${consumer_url}/ifp/globalApiService?endpoint=${ACCOUNTBALANCE_ENDPOINT}` :
            accountbalance_endpoint = `${dashboard_url}/v4.6/accountbalance${obapi_base_uri}${ACCOUNTBALANCE_ENDPOINT}`
         CLAIMSTATUS_ENDPOINT.includes('offline') ?
            claimsummary_endpoint = `${consumer_url}/ifp/globalApiService?endpoint=${CLAIMSTATUS_ENDPOINT}` :
            claimsummary_endpoint = `${dashboard_url}/v4.7/claimstatus${obapi_base_uri}${CLAIMSTATUS_ENDPOINT}`
      })
      endpoints = {
         ...endpoints,
         profile_endpoint: profileEndpoint,
         referrals_endpoint: referralsEndpoint,
         memberactions_endpoint: memberactions_endpoint,
         memberactionupdate_endpoint: memberactionupdate_endpoint,
         plandetail_endpoint: plandetail_endpoint,
         primarycare_endpoint: primarycare_endpoint,
         accountbalance_endpoint: accountbalance_endpoint,
         claimsummary_endpoint: claimsummary_endpoint,
         b360_endpoint: "/ben/b360/benefit-details",
         bills_endpoint: `${consumer_url}/ifp/globalApiService?endpoint=${bills_base_uri}/fin/billing/bills/v3/search`,
         recurring_endpoint: `${consumer_url}/ifp/globalApiService?endpoint=${sheild_base_uri}/fin/billing/recurring-payments/v1/search`,
         getmember_service_endpoint: `${consumer_url}/ifp/globalApiService?endpoint=${sheild_base2_uri}/cel/shield/members/v5/search`,
         wallet_service_endpoint: `/cel/shield/wallets/v1`,
         // profile_endpoint: `${common_url}/getProfileContext?endpoint=/cel/uhcd-member/v5/profile`,
         // eligiblity_endpoint: `${dashboard_url}/eligibilities/v1/read${obapi_base_uri}/cel/consumer/eligibilities/v1/read`,
         // claimsummary_endpoint: `${dashboard_url}/v4.7/claimstatus${obapi_base_uri}/clm/uhcd-claims/v4.7/claimstatus`,
         // plandetail_endpoint: `${dashboard_url}/v5/plandetails${obapi_base_uri}/ben/uhcd-plans/v5/plandetails`,
         // primarycare_endpoint: `${dashboard_url}/v5/primarycare${obapi_base_uri}/ben/uhcd-plans/v5/primarycare`,
         // referrals_endpoint: `${dashboard_url}/v4.6/referrals${obapi_base_uri}/clin/uhcd-clinical/v4.6/referrals`,
         // accountbalance_endpoint: `${dashboard_url}/v4.6/accountbalance${obapi_base_uri}/fin/uhcd-financial/v4.6/accountbalance`,
         // memberactions_endpoint: `${dashboard_url}/v4.6/memberactions${obapi_base_uri}/ccs/uhcd-tools/v4.6/memberactions?requestType=OFFERS&offersCount=15`,
         // memberactionupdate_endpoint: `${dashboard_url}/v4.6/memberactions${obapi_base_uri}/ccs/uhcd-tools/v4.6/memberactions`,
      }
   }
   return endpoints
}

export default Constants()

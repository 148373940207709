import React, { useState, useMemo } from "react";
import {
  Modal,
  Typography,
  Link,
  Button,
  UtilityIcon,
} from "@uhc-tempo/components";
import Banner from "./banner";
import Assignment from "@material-ui/icons/Assignment";
import RallyRedirects from "../../../../../constants/rally-redirects";
import GetCookieItem from "../../../../../tools/getCookieItem";
import styles from "./notification.module.css";
import clickTrack from "../../../../../hooks/clickTrack";
import modalTrack from "../../../../../hooks/modalTrack";

const formatName = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return `${txt.charAt(0).toUpperCase()}${txt.substr(1).toLowerCase()}`;
  });
};

const Manage = ({
  loggedinMember = {},
  dependents = [],
  primarycareData = [],
  pcpPopup = {}
}) => {
  const [open, setOpen] = useState(false);

  const { title: text = [] } = pcpPopup;
  const domain = window.location.origin + window.location.pathname

  const { change_pcp, view_pcp, find_provider } = RallyRedirects || {};
  const loginLang = GetCookieItem("LOGINLOCALE")
  const selectedLang = GetCookieItem("lang")

  //US4949442, US4988117: Don't change rally_connect_lang value
  let rally_connect_lang = "en-US"
  if(loginLang === "es" || selectedLang === "es"){
      rally_connect_lang = "es"
  }

  const pcpMemberArray = useMemo(() => {
    let memberArr = [];

    if (loggedinMember && loggedinMember.firstName) {
      memberArr.push({
        firstName: loggedinMember.firstName,
        dependentSeqNbr: loggedinMember.dependentSeqNbr,
      });

      if (loggedinMember.isSubscriber && dependents && dependents.length > 0) {
        for (let dependent of dependents) {
          let { firstName, dependentSeqNbr } = dependent;

          let dependentMember = {};

          dependentMember.firstName = firstName;
          dependentMember.dependentSeqNbr = dependentSeqNbr;

          memberArr.push(dependentMember);
        }
      }
    }

    const finalArray = memberArr.map((x) => {
      const pcpCheck = primarycareData.find(
        (y) => y.depSeqNbr === x.dependentSeqNbr && y.providerID
      );
      return pcpCheck ? { ...x, ...pcpCheck } : x;
    });
    return finalArray;
  }, [loggedinMember, dependents, primarycareData]);

  const pcpList = pcpMemberArray.filter((x) => x.pcpName);
  const noPcpList = pcpMemberArray.filter((x) => !x.pcpName);

  return (
    <>
      <Button
        buttonType="primary-one"
        className={styles.choosePcpBtn}
        id='mng-pcp-btn'
        onClick={() => {setOpen(true); modalTrack(text[8])}}
      >
        {text[8]}
        <UtilityIcon icon={Assignment} />
      </Button>{" "}
      {open && (
        <Modal handleClose={() => setOpen(false)}>
          <Modal.Header>
            <Modal.Title>{text[0]}</Modal.Title>
          </Modal.Header>
          <Modal.Content>
            {noPcpList.length ? (
              <>
                <Banner bannerText={text[1]} />
                <div
                  style={{
                    textAlign: "left",
                    borderBottom: pcpList.length && noPcpList.length ? "1px solid #ccc" : undefined,
                    paddingBottom: 30,
                    marginBottom: 30,
                  }}
                >
                  <Typography.H2
                    headingStyle={6}
                    className={styles.headingStyled}
                  >
                    {text[2]}
                  </Typography.H2>
                  <ul>
                    {noPcpList.map((x, i) => (
                      <li key={i}>
                        <dl className={styles.list}>
                          <dt className={styles.dt}>
                            <Typography.Paragraph useBold>
                              {x.firstName}
                            </Typography.Paragraph>
                          </dt>
                          <dd>
                            <Typography.Paragraph>
                              {text[3]}
                            </Typography.Paragraph>
                          </dd>
                        </dl>
                      </li>
                    ))}
                  </ul>
                  <Typography.Paragraph>
                    {text[4]}
                  </Typography.Paragraph>
                  <div style={{ textAlign: "left" }}>
                    <Button
                      href={find_provider}
                      id="choose-providers-1"
                      text={text[5]}
                      onClick={()=> 
                        clickTrack(
                          text[5], 
                          "main-body", 
                          "button",
                          "internal", 
                          domain + find_provider)}
                    />
                  </div>
                </div>
              </>
            ) : null}
            <div style={{ textAlign: "left" }}>
              {noPcpList.length && pcpList.length ? (
                <Typography.H2
                  headingStyle={6}
                  className={styles.headingStyled}
                >
                  {text[6]}
                </Typography.H2>
              ) : null}
              {pcpList.length ? (
                <>
                  <ul>
                    {pcpList.map((x, i) => (
                      <li key={i}>
                        <dl className={styles.list}>
                          <dt className={styles.dt}>
                            <Typography.Paragraph useBold>
                              {x.firstName}
                            </Typography.Paragraph>
                          </dt>
                          <dd>
                            <Link
                              href={`${view_pcp}/${x.providerID.id}?partnerId=true&locale=${rally_connect_lang}`}
                              id={`doctor-${i}`}
                              text={`Dr. ${formatName(x.pcpName)}`}
                              onClick={() => {
                                clickTrack(`Doctor ${i+1}`, 
                                "main-body",  "text",
                                "internal", 
                                domain + `${view_pcp}/${x.providerID.id}?partnerId=true&locale=${rally_connect_lang}`)}}
                            />
                          </dd>
                        </dl>
                      </li>
                    ))}
                  </ul>
                  <div className={styles.button}>
                    <Button
                      buttonType="secondary-one"
                      id="change-provider"
                      text={text[7]}
                      href={change_pcp}
                      onClick={() => {clickTrack(text[7], "main-body", "button", "internal", change_pcp)}}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};

export default Manage;
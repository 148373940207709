import { useEffect, useState } from 'react'
import UseBaseService from './useBaseService';
import ReferralsFormatter from './referralsFormatter';
import info from '../logger/info'
import error from '../logger/error'
import Constants from "../constants/constants";

const ReferralApi = () => {
    // const startTime = new Date();
    const { referrals_endpoint } = Constants;
    const [referralsData, setReferralsData] = useState({ loadingReferral: false });

    useEffect(() => {
        if (sessionStorage.getItem("Referrals")) {
            setReferralsData(JSON.parse(sessionStorage.getItem("Referrals")))
        }
        else {
            // if (referrals_endpoint.indexOf('offline') < 0) {
            UseBaseService("referrals", "GET", "dmz", referrals_endpoint, "", response => {
                try {
                    const { data } = response
                    if (data) {
                        const formattedReferralsData = ReferralsFormatter(data)
                        //console.log(formattedReferralsData)
                        sessionStorage.setItem(
                            "Referrals",
                            JSON.stringify({
                                loadingReferral: true,
                                formattedReferralsData
                            })
                        )
                        setReferralsData({ formattedReferralsData, error: false, loadingReferral: true })
                    }
                } catch (error) {
                    console.log("catch", error)
                    setReferralsData({ error: true })
                    const logObj = {
                        function: "Referral api error",
                        status: "error",
                        errorMessage: "Error in the catch block",
                    };
                    info(logObj);
                    error(logObj);
                }
            })
            // }
            // else if (referrals_endpoint.indexOf('offline') > 0) {
            //     const fetchReferral = async () => {
            //         const response = await fetch(referrals_endpoint);
            //         const refferalResponse = await response.json();
            //         return refferalResponse;
            //     }
            //     fetchReferral().then(refferalResponse => {
            //         const { data, Error } = refferalResponse || {};
            //         if (data) {
            //             const formattedReferralsData = ReferralsFormatter(data)
            //             //console.log(formattedReferralsData)
            //             sessionStorage.setItem(
            //                 "Referrals",
            //                 JSON.stringify({
            //                     loadingReferral: true,
            //                     formattedReferralsData
            //                 })
            //             )
            //             setReferralsData({ formattedReferralsData, error: Error, loadingReferral: true })
            //         } else {
            //             setReferralsData({ hasError: true, loadingReferral: false });
            //             const logObj = {
            //                 function: "Referral api error",
            //                 status: "error",
            //                 // duration: Date.now() - startTime.getTime(),
            //             };
            //             info(logObj);
            //             error(logObj);
            //         }
            //     })
            //         .catch((err) => {
            //             console.log("catch", err)
            //             setReferralsData({ hasError: true, loadingReferral: false });
            //             const logObj = {
            //                 function: "Referral api error",
            //                 status: "error",
            //                 errorMessage: "Error in the catch block",
            //                 // duration: Date.now() - startTime.getTime(),
            //             };
            //             info(logObj);
            //             error(logObj);
            //         });
            // }
        }
    }, [referrals_endpoint])

    return referralsData
}

export default ReferralApi
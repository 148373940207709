import React,{useContext} from 'react'
import { Typography, BrandIcon, Button, UtilityIcon } from '@uhc-tempo/components'
import Assignment from '@material-ui/icons/Assignment'
import { IconAlert } from '@uhc-tempo/icons'
import styles from './notification.module.css'
import RallyRedirects from '../../../../../constants/rally-redirects'
import Manage from './manage'
import { ProfileContext } from '../../../../../contexts/profileContext';
import { PrimaryCareContext } from '../../../../../contexts/primarycareContext';


const { find_provider } = RallyRedirects;

const ManageSubcriber = ({ pcpPopup }) => {
    const { profile } = useContext(ProfileContext) || {};
    const {
      primarycare: { pcpData: { primarycareData = [] } = {} },
    } = useContext(PrimaryCareContext) || {};
    const { loggedinMember, dependents } = profile || {};

    return (
        <>
            <div className={styles.alertContainer}>
                <div className={styles.contentWrapper}>
                    <div className={styles.icon}>
                        <BrandIcon
                            icon={IconAlert}
                            size="EXTRA_SMALL"
                            styleVariation="ONE_TONE_DARK_CIRCLE"
                        />
                    </div>

                    <Typography.Paragraph style={{margin:"10px 0px"}}>
                        <span> There are members on your plan without assigned PCPs</span>
                    </Typography.Paragraph>
                </div>
                <div className={"desktop"}>
                <div className={styles.btnGroup}>
                    <Manage loggedinMember={loggedinMember} dependents={dependents} primarycareData={primarycareData} pcpPopup={pcpPopup} />
                </div>
                </div>
                <div className={styles.btnGroup,"mobile"}>
                    <Manage  loggedinMember={loggedinMember} dependents={dependents} primarycareData={primarycareData} pcpPopup={pcpPopup} style={{ margin: 10 }} />
                </div>
                
            </div>
        </>
    )
}

export default ManageSubcriber
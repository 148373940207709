import React, { useState, useEffect } from 'react'
import { Typography, Button, UtilityIcon } from '@uhc-tempo/components'
import { Modal } from '@uhc/pattern-library'
import Create from '@material-ui/icons/Create'
import UseGraphqlApi from '../../../../../hooks/useGraphqlApi'
import ChangeProviderQuery from './modalChangeProviderQuery'
import { Markup } from 'interweave';
import Config from '../../../../../config'
import styled from 'styled-components'
import getFilteredContentList from "../../../../../tools/getFilteredContentList";
import modalTrack from '../../../../../hooks/modalTrack'
import clickTrack from '../../../../../hooks/clickTrack'

const RemoveCloseButton = styled.div`
.RemoveCloseBtn> div >button {
  display: none;
  .RemoveCloseBtn> div >{
    padding:0;
  }
}`
const HeaderWrap = styled.div`
.providerModalTitle{
  line-height: normal;
  color: #002677; 
  font-weight: 500;
  font-size:28px;
  padding-left:15px;
}`
const Desktop = styled.div`
  @media (max-width: 991px) {
    display: none;
  }
`
const Mobile = styled.div`
  @media (min-width: 991px) {
    display: none;
  }
`

const ChangeProviderModal = (props) => {
  const { linkName, from } = props;
  const [open, setOpen] = useState(false);

  // GraphQl query implementation starts
  const [changepcpModal, setChangepcpModal] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasError, setError] = useState(false);
  const CPQuery = ChangeProviderQuery();

  useEffect(() => {
    UseGraphqlApi(CPQuery, postChangeProviderHandler);
  }, []);

  const postChangeProviderHandler = (response) => {
    if (response && !response.isError) {
      if (response.data) {
        if (response.data.titleDescriptionList.items.length > 0) {
          const listWithDates = getFilteredContentList(response.data?.titleDescriptionList?.items)
          if (listWithDates.length > 0) {
            setChangepcpModal(
              listWithDates[0]
            );
          }
        }
      }
    } else {
      setError(true);
    }
    setLoading(false);
  };
  const { title, description } = changepcpModal || {};
  // GraphQl query implementation ends
  const { aem_content_url } = Config;

  return (
    <>
      {linkName ? (
        <Desktop>
          <Button
            id="change-provider"
            buttonType="secondary-one"
            target="_blank"
            className="changeprovider-button"
            onClick={() => {setOpen(true); 
            modalTrack(linkName)
          }}
          >
            {linkName}
            {from !== "effective" && <UtilityIcon icon={Create} />}
          </Button>{" "}
        </Desktop>
      ) : null}
      {linkName ? (
        <Mobile>
          <Button
            id="change-provider"
            buttonType="secondary-one"
            target="_blank"
            className="changeprovider-button changeprovider-btn-mobile"
            onClick={() => {setOpen(true);
              modalTrack(linkName)
            }}
          >
            {linkName}
            {from !== "effective" && <UtilityIcon icon={Create} />}
          </Button>
        </Mobile>
      ) : null}
      {open && (
        <Modal>
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 30 }}
          >
            <img
              src={`${aem_content_url}/content/dam/myuhc/consumer/assets/myuhc/warning-icon.svg`}
              alt=""
              style={{ width: 72 }}
            />
          </div>

          <RemoveCloseButton>
            <Modal.Header
              className="RemoveCloseBtn"
              style={{
                fontFamily: '"UHC Serif Semibold",Georgia Bold,serif',
                borderBottom: "none",
              }}
            >
              <HeaderWrap>
                {title ? (
                  <Modal.Title className="providerModalTitle">
                    {title}
                  </Modal.Title>
                ) : null}
              </HeaderWrap>
            </Modal.Header>
          </RemoveCloseButton>

          <Modal.Content style={{ marginTop: -40, padding: 40 }}>
            <div className="container">
              {description && description.html ? (
                <p>
                  <Typography.Paragraph
                    fontWeight="medium"
                    className="member-modal__status"
                  >
                    <Markup allowAttributes={true} content={description.html} />
                  </Typography.Paragraph>
                </p>
              ) : null}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 20,
              }}
            >
              <Button
                onClick={() => {setOpen(false)
                clickTrack("Dismiss Modal", "main-body", "button", "internal", "")}}
                onCloseTrackingData={{
                  clickName: "Closing Modal",
                  clickLocation: "Modal",
                }}
              >
                Dismiss
              </Button>
            </div>
          </Modal.Content>
        </Modal>
      )}
    </>
  );
}

export default ChangeProviderModal

import Axios from "axios"
import getCookieItem from "../tools/getCookieItem"
import config from "../config"
import { v4 as uuidv4 } from "uuid"
import info from "../logger/info";
import error from "../logger/error"
import UseOfflineToken from "./useOfflineToken"
import checkOfflinePaymentFlow from '../utils/checkOfflinePaymentFlow'
//takes in 3 arguments (apiname, endpoint, callback=>())
const isOfflineFlow = checkOfflinePaymentFlow()
// const TOKENDIR = isOfflineFlow ? "OFFLINETOKEN" : "ONLINE"
let apiCounter = 0
const payLoad = {}
payLoad.function = "UseBaseService"
payLoad.apiDetails = []
const setLog = (msg) => {
   payLoad.apiDetails.push(msg)
   if (apiCounter === payLoad.apiDetails.length) {
      info(payLoad)
   }
}

const hasBigFive = getCookieItem("big_five")

const UseBaseService = (API, METHOD, REGION, ENDPOINT, postObject, callback) => {
   apiCounter = apiCounter + 1
   // let APIresponse = { APIError: false }
   const getCookie = cookie => {
      if (document.cookie) {
         const TOKEN = document.cookie
            .split(";")
            .find(list => list.indexOf(cookie) > -1)
         return TOKEN ? "Bearer " + TOKEN.split("=")[1] : null
      }
   }
   let cookieDomain = window.location.href.search("myuhc") > 0 ? ".myuhc.com" : ".uhc.com"

   if (hasBigFive) {
      const { payment_url, sheild_base2_uri } = config
      const cid = `MYUHC2-CONSUMER-${API.toUpperCase()}-${uuidv4()}`

      let apiUrl = `${ENDPOINT}`
      let myheaders = {
         actor: "myuhc2.0",
         "Client-Code": "MYUHC",
         "Optum-CID-Ext": cid,
         timestamp: 1111,
      }
      if (apiUrl.includes('offline')) {
         myheaders = {
            ...myheaders
         }
      }
      let responseCode = 'NULL'
      const startTime = new Date()
      if (METHOD === "GET") {
         Axios.get(`${apiUrl}`, { headers: myheaders })
            .then(response => {
               const data = response && response.data && response.data.data ? response.data.data :
                  response && response.data ? response.data : ''
               if (response.status) {
                  responseCode = response.status
               }
               setLog({ 'responseCode': responseCode, 'status': 'success', 'cid': cid, 'apiUrl': apiUrl, 'duration': Date.now() - startTime.getTime() })
               callback({ APIError: false, data })
            })
            .catch(err => {
               if (err.response) {
                  responseCode = err.response.status
               }
               setLog({ 'responseCode': responseCode, 'status': 'error', 'cid': cid, 'apiUrl': apiUrl, 'error': 'Error in the catch block', 'duration': Date.now() - startTime.getTime() })
               error({ 'responseCode': responseCode, 'status': 'error', 'cid': cid, 'apiUrl': apiUrl, 'error': 'Error in the catch block', 'duration': Date.now() - startTime.getTime() })
               callback({ APIError: true })
            })
      }
      else {
         const callApi = () => {
            Axios.post(`${apiUrl}`, { ...postObject }, { headers: myheaders })
               .then(response => {
                  const data = response && response.data && response.data.data ? response.data.data :
                     response && response.data ? response.data : ''
                  if (response.status) {
                     responseCode = response.status
                  }
                  setLog({ 'responseCode': responseCode, 'status': 'success', 'response': data, 'cid': cid, 'apiUrl': apiUrl, 'duration': Date.now() - startTime.getTime() })
                  callback({ APIError: false, data })
               })
               .catch(err => {
                  if (err.response) {
                     responseCode = err.response.status
                  }
                  setLog({ 'responseCode': responseCode, 'status': 'error', 'cid': cid, 'apiUrl': apiUrl, 'error': 'Error in the catch block', 'duration': Date.now() - startTime.getTime() })
                  error({ 'responseCode': responseCode, 'status': 'error', 'cid': cid, 'apiUrl': apiUrl, 'error': 'Error in the catch block', 'duration': Date.now() - startTime.getTime() })
                  callback({ APIError: true })
               })
         }
         let myheaders = {
            actor: "myuhc2.0",
            "Client-Code": "MYUHC",
            "Optum-CID-Ext": cid,
            timestamp: 1111,
            'application-name': 'MYUHC'
         }

         if ((apiUrl.includes("b360"))) {
            if (getCookie("OFFLINETOKEN")) {
               if (API === "B360") {
                  myheaders = {
                     ...myheaders,
                     "Content-Length": "3",
                     "Content-Type": "application/json",
                     Authorization: getCookie("OFFLINETOKEN"),
                  }
               } else {
                  myheaders = {
                     ...myheaders,
                     Authorization: getCookie("OFFLINETOKEN"),
                  }
               }
               callApi();
            } else {
               UseOfflineToken(response => {
                  let date = new Date();
                  let minutes = 30;
                  date.setTime(date.getTime() + (minutes * 60 * 1000));
                  document.cookie = "OFFLINETOKEN=" + response.access_token + ";domain=" + cookieDomain + ";path=/;expires=" + date.toUTCString();
                  const token = "Bearer " + response.access_token
                  console.log("1st time token", response.access_token)
                  if (API === "GetMemberService") {
                     cid = `MYUHC2-CONSUMER-getMember-${API.toUpperCase()}-${uuidv4()}`
                  }
                  if (API === "B360") {
                     cid = `MYUHC2-CONSUMER-b360Details-${API.toUpperCase()}-${uuidv4()}`
                     myheaders = {
                        ...myheaders,
                        "Content-Length": "3",
                        "Content-Type": "application/json",
                        Authorization: token,
                     }
                  } else {
                     myheaders = {
                        ...myheaders,
                        Authorization: token,
                     }
                  }
                  callApi();
               })
            }
         }
         else if (apiUrl.includes("billing") || apiUrl.includes("shield")) {
            myheaders = {
               ...myheaders,
            }
            callApi();
         }
      }
   }
}

export default UseBaseService
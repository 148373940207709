import Constants from "../constants/constants"
import UseBaseService from "../hooks/useBaseService"

const { memberactions_endpoint } = Constants;

const UseMemberAction = (callback) => {
    if (memberactions_endpoint.indexOf('offline') < 0) {
        UseBaseService("memberactions", "GET", "dmz", memberactions_endpoint, "",
            response => {
                const { APIError, data } = response
                if (APIError) {
                    //logging for API error
                    console.log("memberactions api error: check api call!")
                    callback({ hasError: true });
                } else {
                    try {
                        const { responseMessage, leadPromotionCount, offers } = data && data.data ? data.data : data
                        const leadPromotion = offers && offers.map(offer => offer.hasOwnProperty('leadPromotion')
                            && offer.leadPromotion[0])
                        callback({ hasError: false, responseMessage, leadPromotionCount, leadPromotion });
                    } catch (error) {
                        callback({ hasError: true })
                    }
                }
            }
        )
    }
    else if (memberactions_endpoint.indexOf('offline') > 0) {
        const fetchMemberactions = async () => {
            const response = await fetch(memberactions_endpoint);
            const memberActionsResponse = await response.json();
            return memberActionsResponse;
        }
        fetchMemberactions().then(Response => {
            const { data, Error } = Response || {};
            if (Error) {
                console.log("memberactions api error: check api call!")
                callback({ hasError: true });
            }
            else {
                try {
                    if (data) {
                        const { responseMessage, leadPromotionCount, offers } = data
                        const leadPromotion = offers && offers.map(offer => offer.hasOwnProperty('leadPromotion')
                            && offer.leadPromotion[0])
                        callback({ hasError: false, responseMessage, leadPromotionCount, leadPromotion });

                    }
                } catch (error) {
                    callback({ hasError: true })
                }
            }
        })
    }

}
export default UseMemberAction;

import Constants from "../constants/constants"
import UseBaseService from "../hooks/useBaseService"

const UseMemberService = (medicalMemberID, groupId, memberSuffix, currentDateWithOffset, callback) => {
    const postObject = {
        "requestType": "EXCHANGE",
        "memberInquiry": {
            "groupId": groupId,
            "subscriberId": medicalMemberID,
            "memberSuffix": memberSuffix
        }
    }
    const { getmember_service_endpoint } = Constants;
    UseBaseService("GetMemberService", "POST", "dmz", getmember_service_endpoint, postObject,
        response => {
            const { APIError, data } = response
            if (APIError) {
                //logging for API error
                callback({ hasError: true });
            } else if (data) {
                try {
                    // data►0►attributes►member►memberSubsidyDetails►0►subsidyAmount
                    const { attributes } = data && data[0] || {}
                    const { premiumRateDetails, member } = attributes || {}

                    const isActiveDates = (effDate, termedDate, currentDateWithOffset) => {
                        let startDate = new Date(effDate)
                        startDate.setHours(0,0,0,0)

                        let endDate = new Date(termedDate)
                        endDate.setHours(0,0,0,0)

                        let currentDate = new Date(currentDateWithOffset)
                        currentDate.setHours(0,0,0,0)

                        return startDate <= currentDate && endDate >= currentDate
                    }
                    
                    const premiumRateDetailsObj = premiumRateDetails &&
                        premiumRateDetails.filter((a) => {
                            return isActiveDates(a.ratingEffDate, a.ratingTermDate, currentDateWithOffset)
                        })[0] || {}
                    const { subsOvrridePremium } = premiumRateDetailsObj || {}
                    console.log(subsOvrridePremium)
                    const { memberSubsidyDetails } = member || {}
                    const memberSubsidyAPTC = memberSubsidyDetails &&
                        memberSubsidyDetails.filter(data => data.subsidyType && data.subsidyType === 'APTC')
                    const memberSubsidySTSU = memberSubsidyDetails &&
                        memberSubsidyDetails.filter(data => data.subsidyType && data.subsidyType === 'STSU')
                    const aptcSubsidyAmount = memberSubsidyAPTC &&
                        memberSubsidyAPTC.filter((i) => {
                            return isActiveDates(i.subsidyEffectiveDate, i.subsidyTermDate, currentDateWithOffset)
                        })[0] || {}
                    const stsuSubsidyAmount = memberSubsidySTSU &&
                        memberSubsidySTSU.filter((i) => {
                            return isActiveDates(i.subsidyEffectiveDate, i.subsidyTermDate, currentDateWithOffset)
                        })[0] || {}
                    const subsidyAmount = aptcSubsidyAmount && aptcSubsidyAmount.subsidyAmount ? aptcSubsidyAmount.subsidyAmount : ''
                    const stateSubsidyAmount = stsuSubsidyAmount && stsuSubsidyAmount.subsidyAmount ? stsuSubsidyAmount.subsidyAmount : ''
                    callback({ hasError: false, subsOvrridePremium, subsidyAmount, stateSubsidyAmount });
                } catch (error) {
                    callback({ hasError: true })
                    //logging for errors
                }
            }
        }
    )
}
export default UseMemberService;

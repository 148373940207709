import React from "react"
import { Card, Heading, Typography } from "@uhc/pattern-library"
import config from "../../config"
// import { Loader } from "@uhc/pattern-library"

const NoClaimMessage = ({ error, covStatus }) => {
  const { aem_content_url } = config
  return (
    <>
      {covStatus && covStatus === "pre-effective" ? <Card>
        <Card.Content style={{ padding: "40px" }}>
          <Heading
            level="2"
            variant="secondary"
            style={{ textAlign: "center" }}>
            We will put your most recent dental <br />
            claims here as they arrive.
              </Heading>
        </Card.Content>
      </Card> : null}
      {covStatus && covStatus !== "pre-effective" && error ? <Card>
        <Card.Content style={{ padding: "40px" }}>
          <div style={{ textAlign: 'center' }}>
            <img src={`${aem_content_url}/content/dam/myuhc/consumer/assets/myuhc/magnify-icon_2.svg`} alt="" />
          </div>
          <Heading
            level="2"
            variant="secondary"
            style={{ textAlign: "center" }}>
            No Claims Found
            </Heading>
          <p style={{ textAlign: "center", margin: "auto" }}>
            <Typography fontWeight="medium" style={{ fontSize: 16 }}>
              This could be because there are no claims for the search <br /> selected or the information may not be
              available.
          </Typography>
          </p>
        </Card.Content>
      </Card> : null}
    </>
  );
};

export default NoClaimMessage;

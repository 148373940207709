import React, { useContext, useState, useEffect } from "react";
import { Typography } from "@uhc-tempo/components";
import { Loader } from "@uhc/pattern-library";
import UseGraphqlApi from "../../../../../hooks/useGraphqlApi";
import Config from "../../../../../config";
import GetCookieItem from "../../../../../tools/getCookieItem";
import RallyRedirects from '../../../../../constants/rally-redirects';
import { PrimaryCareContext } from "../../../../../contexts/primarycareContext";
import PCP_Query from "./pcpQuery";
import VirtualCareQuery from "./virtualCareQuery";
import GenericError from "../../../../shared-components/error";
// import { Markup } from "interweave";
import GPCPCard from "./GpcpCard";
import getFilteredContentList from "../../../../../tools/getFilteredContentList";

const { legacy_en_url, rally_url, rally_vc_url } = Config;
const { view_pcp } = RallyRedirects || {}
const loginLang = GetCookieItem("LOGINLOCALE")
const selectedLang = GetCookieItem("lang")

//US4949442, US4988117: Don't change rally_connect_lang value
let rally_connect_lang = "en-US"
if(loginLang === "es" || selectedLang === "es"){
    rally_connect_lang = "es"
}

const GalileoProvider = (props) => {
  const {
    changeProvider,
    situsState,
    dependentSeqNbr,
    apiFailErrorMsg,
  } = props;

  const { primarycare } = useContext(PrimaryCareContext) || {};
  const { pcpData, isPrimarycareSet } = primarycare || {};
  const pcpInfo = pcpData ? pcpData : null;

  const [loading, setLoading] = useState(true);
  const [PCP_Error, set_PCP_Error] = useState(false);
  const [virtualCareEError, setVirtualCareEError] = useState(false);

  const [pcpState, setPcpState] = useState([]);
  const [virtualCareState, setVirtualCareState] = useState({});
  const get_PCP_Data = PCP_Query();
  const getVirtualCareData = VirtualCareQuery();

  useEffect(() => {
    UseGraphqlApi(get_PCP_Data, PCP_QueryHandler);
    UseGraphqlApi(getVirtualCareData, virtualQueryHandler);
  }, []);

  const PCP_QueryHandler = (response) => {
    if (response && !response.isError) {
      if (response.data) {
        if (response.data.listTileList.items.length > 0) {
          const listWithDates = getFilteredContentList(response.data?.listTileList?.items)
          if (listWithDates.length > 0) {
            setPcpState(
              listWithDates[0]
            );
          }
          else {
            setPcpState(
              response.data.listTileByPath && response.data.listTileByPath.item
                ? response.data.listTileByPath.item
                : ""
            );
          }
        }
        else if (response.data.listTileByPath.item) {
          setPcpState(
            response.data.listTileByPath && response.data.listTileByPath.item
              ? response.data.listTileByPath.item
              : ""
          );
        }
      }
    } else {
      set_PCP_Error(true);
    }
    setLoading(false);
  };
  const {
    title: pcpTitle,
    subTitle: pcpSubTitle,
    description: pcpDescription,
    image: pcpImage,
    linkText: pcpLinkText,
    url: pcpUrl,
    urlType: pcpUrlType,
    linkText2: pcpLinkText2,
    url2: pcpUrl2,
    urlType2: pcpUrlType2,
  } = pcpState || {};

  const virtualQueryHandler = (response) => {
    if (response && !response.isError) {
      if (response.data) {
        if (response.data.listTileList.items.length > 0) {
          const listWithDates = getFilteredContentList(response.data?.listTileList?.items)
          if (listWithDates.length > 0) {
            setVirtualCareState(
              listWithDates[0]
            );
          }
          else {
            setVirtualCareState(
              response.data.listTileByPath && response.data.listTileByPath.item
                ? response.data.listTileByPath.item
                : ""
            )
          }
        }
        else if (response.data.listTileByPath.item) {
          setVirtualCareState(
            response.data.listTileByPath && response.data.listTileByPath.item
              ? response.data.listTileByPath.item
              : ""
          )
        }
      }
    } else {
      setVirtualCareEError(true);
    }
    setLoading(false);
  };

  let newPcpUrl = "";
  let newPcpUrl2 = "";

  function renderPcpUrl(pcpUrlType) {
    if (pcpUrlType === "pcpUrl") {
      isPrimarycareSet &&
        pcpData !== null &&
        pcpData.primarycareData !== null && pcpData.primarycareData !== undefined &&
        dependentSeqNbr !== null &&
        pcpData.primarycareData.map((pcpdata, i) => {
          if (
            dependentSeqNbr === pcpdata.depSeqNbr &&
            pcpdata.providerID &&
            pcpdata.providerID.id &&
            pcpdata.pcpError === false
          ) {
            newPcpUrl = `${view_pcp}/${pcpdata.providerID.id}?partnerId=true&locale=${rally_connect_lang}`;
          }
        });
    }
    if (pcpUrlType === "rallyUrl") {
      newPcpUrl = `${rally_url}` + pcpUrl;
    } else if (pcpUrlType === "rallySSO") {
      newPcpUrl = `${rally_vc_url}` + pcpUrl;
    }
    else if (pcpUrlType === "pageUrl" || pcpUrlType === "default") {
      newPcpUrl = pcpUrl;
    } else if (pcpUrlType === "legacyUrl") {
      newPcpUrl = `${legacy_en_url}` + pcpUrl;
    }
    return newPcpUrl;
  }

  function renderPcpUrl2(pcpUrlType2) {
    if (pcpUrlType2 === "pcpUrl") {
      isPrimarycareSet &&
        pcpData !== null &&
        pcpData.primarycareData !== null && pcpData.primarycareData !== undefined &&
        dependentSeqNbr !== null &&
        pcpData.primarycareData.map((pcpdata) => {
          if (
            dependentSeqNbr === pcpdata.depSeqNbr &&
            pcpdata.providerID &&
            pcpdata.providerID.id &&
            pcpdata.pcpError === false
          ) {
            newPcpUrl2 = `${view_pcp}/${pcpdata.providerID.id}?partnerId=true&locale=${rally_connect_lang}`;
          }
        });
    }
    if (pcpUrlType2 === "rallyUrl") {
      newPcpUrl2 = `${rally_url}` + pcpUrl2;
    } else if (pcpUrlType2 === "rallySSO") {
      newPcpUrl2 = `${rally_vc_url}` + pcpUrl2;
    } else if (pcpUrlType2 === "pageUrl" || pcpUrlType2 === "default") {
      newPcpUrl2 = pcpUrl2;
    } else if (pcpUrlType2 === "legacyUrl") {
      newPcpUrl2 = `${legacy_en_url}` + pcpUrl2;
    }
    return newPcpUrl2;
  }
  //  ****************** Banner url function **********************
  return (
    <>
      {loading ? (
        <Loader Blocking />
      ) : (
          <div className="styledContainer active-bottom">
            <div className="styled-flex-wrap">
              <div style={{ flex: '1 1' }}>
                {PCP_Error ? (
                  <GenericError></GenericError>
                ) : (
                    <>
                      <div className="pcp-heading-link tempo-v1">
                        {pcpTitle ? (
                          <Typography.H2>{pcpTitle}</Typography.H2>
                        ) : null}
                      </div>

                      <div style={{ flex: '1 1' }}>
                        {/* Should come from API */}
                        {/* display this if no referrals to show  */}
                        <GPCPCard
                          isPrimarycareSet={isPrimarycareSet}
                          pcpInfo={pcpInfo}
                          dependentSeqNbr={dependentSeqNbr}
                          pcpLinkText={pcpLinkText}
                          situsState={situsState}
                          changeProvider={changeProvider}
                          pcpRenderUrl={renderPcpUrl(pcpUrlType)}
                          // providerUrl={() => providerUrl()}
                          apiFailErrorMsg={apiFailErrorMsg}
                          referralTitle={pcpSubTitle ? pcpSubTitle : null}
                          referralDescription={
                            pcpDescription && pcpDescription.plaintext
                              ? pcpDescription.plaintext
                              : null
                          }
                          referralImage={
                            pcpImage && pcpImage._path ? pcpImage._path : null
                          }
                          pcpSubTitle={pcpSubTitle ? pcpSubTitle : null}
                          pcpLinkText2={pcpLinkText2 ? pcpLinkText2 : null}
                          pcpUrl2={renderPcpUrl2(pcpUrlType2)}
                          urlType2={pcpUrlType2 ? pcpUrlType2 : null}
                        />
                        {/* display this if there are referrals to show */}
                        {/* <ProviderReferral /> */}
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default GalileoProvider;

import React, { useState, useEffect, useContext } from "react"
import { Section } from "@uhc/pattern-library"
import { Typography, Button, UtilityIcon } from "@uhc-tempo/components";
import { ProfileContext } from "../../../../contexts/profileContext";
import PlanInfo from "./preeffective-components/planInfo";
import PlanInfoEffective from "./effective-components/planInfo";
import MemberCard from "./memberModal";
import config from "../../../../config";
import getCookieItem from "../../../../tools/getCookieItem";
import memberInfoQuery from "./memberInfoQuery";
import UseGraphqlApi from "../../../../hooks/useGraphqlApi";
import Info from "@material-ui/icons/Info";
import getFilteredContentList from "../../../../tools/getFilteredContentList";
import clickTrack from "../../../../hooks/clickTrack";
const loginLang = getCookieItem("LOGINLOCALE");
const lang = getCookieItem("lang");
const { aem_content_url } = config;

const MemberInfo = (props) => {
  const {
    viewIdCard,
    planInfo,
    findProvider,
    viewBenefits,
    goPaperless,
    mngPrpLink,
    mngRxLink,
    hsaLink,
    clickvb,
    clickgp,
    providerUrl,
    handleView,
    dashboardMemberData,
    dashboardviewFor,
    planInfoTermed,
    reviewDocuments,
    makePayment,
    clickrd,
    clickmp,
    isGalileo,
    isNMPayExempt,
  } = props;
  const domain = window.location.origin + window.location.pathname
  const { profile } = useContext(ProfileContext) || {};
  const { loggedinMember, dependents } = profile || {};
  //const dependentsArr = dependents || {}
  const { firstName: memberName, isSubscriber, coverages, activeCoverages } =
    loggedinMember || {};
  const { medical, dental, vision } = coverages || {};
  const { coverageStatus } = medical || {};
  const medicalMemberID = medical ? medical["memberID"] : "";
  const dentalMemberID = dental ? dental["memberID"] : "";
  const visionMemberID = vision ? vision["memberID"] : "";
  const situsState = medical ? medical["situsState"] : "";

  // GraphQl query implementation starts
  const [memberInfo, setMemberInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasError, setError] = useState(false);
  const MIQuery = memberInfoQuery();

  useEffect(() => {
    UseGraphqlApi(MIQuery, postMemberInfoHandler);
  }, []);

  const postMemberInfoHandler = (response) => {
    if (response && !response.isError) {
      if (response.data) {
        if (response.data.subTitleList.items.length > 0) {
          const listWithDates = getFilteredContentList(response.data?.subTitleList?.items)
          if (listWithDates.length > 0) {
            setMemberInfo(
              listWithDates[0].subTitle
            );
          }
          else {
            setMemberInfo(
              response.data.subTitleByPath &&
                response.data.subTitleByPath.item &&
                response.data.subTitleByPath.item.subTitle
                ? response.data.subTitleByPath.item.subTitle
                : ""
            );
          }
        }
        else if (response.data.subTitleByPath.item) {
          setMemberInfo(
            response.data.subTitleByPath &&
              response.data.subTitleByPath.item &&
              response.data.subTitleByPath.item.subTitle
              ? response.data.subTitleByPath.item.subTitle
              : ""
          );
        }
      }

    } else {
      setError(true);
    }
    setLoading(false);
  };
  // GraphQl query implementation ends

  return (
    <>
      <div className="desktop">
        <Section
          border
          style={{
            padding: "20px 0 110px 0",
            borderBottom: "1px solid #E0E0E0",
            backgroundColor: "rgb(251, 252, 254)",
          }}
        >
          <div
            style={{
              textAlign: "right",
              whiteSpace: "nowrap",
              margin: "10px 0",
            }}
          >
            <Typography.H2
              className="uhc-tempo-typography__paragraph member-typo-heading"
              id="member-id"
              style={{
                color: "#002677",
                fontSize: 16,
                fontWeight: 600,
                display: "inline-flex",
              }}
            >
              Member ID:{" "}
            </Typography.H2>
            {medicalMemberID ? (
              <span id="medical-id" style={{ marginLeft: 10 }}>
                {" "}
                Medical: <strong>{medicalMemberID}</strong>
              </span>
            ) : null}
            {dentalMemberID ? (
              <span
                id="dental-id"
                style={{ borderLeft: "1px solid #e0e0e0", marginLeft: 10 }}
              >
                {" "}
                <span style={{ marginLeft: 10 }}>
                  Dental: <strong>{dentalMemberID} </strong>
                </span>
              </span>
            ) : null}
            {visionMemberID ? (
              <span
                id="vision-id"
                style={{ borderLeft: "1px solid #e0e0e0", marginLeft: 10 }}
              >
                {" "}
                <span style={{ marginLeft: 10 }}>
                  Vision: <strong>{visionMemberID} </strong>
                </span>
              </span>
            ) : null}
          </div>
          <div className="member-popup">
            {isSubscriber && dependents.length > 0 ? (
              <div className="tempo-v1">
                <Typography.H1>
                  <span
                    id="welcome-message"
                    style={{ display: "flex", alignItems: "flex-start" }}
                  >
                    {coverageStatus !== "termed" ? (
                      <>
                        {memberInfo[0]}
                        {coverageStatus === "active" & isGalileo ? <sup className="tooltip">
                          <UtilityIcon icon={Info} />
                          <span className="tooltiptext">
                            {memberInfo[1]}
                          </span>
                        </sup> : null}
                      </>
                    ) : (
                        "Welcome, "
                      )}
                    {/* {memberInfo[1] ? <PopupTip icon="question" placement="top" className="virtual-care-popup">
                                 <Typography.Paragraph className="tds-margin-none">
                                    {memberInfo[1]}
                                 </Typography.Paragraph>
                              </PopupTip> : null} */}
                  </span>
                  {/* {(loginLang !== 'es' || lang !== 'es') ? <MemberCard
                              memberName={memberName}
                              isSubscriber={isSubscriber}
                              profile={profile}
                              handleView={handleView}
                              dashboardMemberData={dashboardMemberData}
                              dashboardviewFor={dashboardviewFor}
                           /> : */}
                  <span style={{ display: "block" }}>
                    <MemberCard
                      memberName={memberName}
                      isSubscriber={isSubscriber}
                      profile={profile}
                      handleView={handleView}
                      dashboardMemberData={dashboardMemberData}
                      dashboardviewFor={dashboardviewFor}
                    />
                  </span>
                </Typography.H1>
              </div>
            ) : (
                <div>
                  <Typography.H1>
                    <span
                      id="welcome-message"
                      style={{ display: "flex", alignItems: "flex-start" }}
                    >
                      {coverageStatus !== "termed" ? (
                        <>
                          {memberInfo[0]}
                          {coverageStatus === "active" & isGalileo ? <sup className="tooltip">
                            <UtilityIcon icon={Info} />
                            <span className="tooltiptext">
                              {memberInfo[1]}
                            </span>
                          </sup> : null}
                        </>
                      ) : (
                          "Welcome, "
                        )}
                      {/* {memberInfo[1] && <PopupTip icon="question" placement="top" className="virtual-care-popup">
                              <Typography.Paragraph className="tds-margin-none">
                                 {memberInfo[1]}
                              </Typography.Paragraph>
                           </PopupTip>} */}
                      {/* {coverageStatus !== 'termed' ? <>Welcome to your plan,{" "}</> : 'Welcome, '} */}
                      {/* {(loginLang !== 'es' || lang !== 'es') ?
                           <>
                              {dashboardviewFor.name}
                           </> :
                           <> */}
                    </span>
                    <span style={{ display: "block" }}>
                      {" "}
                      {dashboardviewFor.name}
                    </span>
                    {/* </> */}
                    {/* } */}
                  </Typography.H1>
                </div>
              )}
            {coverageStatus !== "termed" ? (
              <div style={{ textAlign: "right", marginTop: -70 }}>
                {viewIdCard.title ? (
                  <Button
                    id="member-button-desktop"
                    buttonType="secondary-one"
                    href={viewIdCard.href}
                    onClick={()=>{
                      clickTrack(
                        viewIdCard.title || "View & print member ID cards", 
                        "main-body", 
                        "button",
                        "internal", 
                        domain + viewIdCard.href)}}
                  >
                    <span
                      id="print-id-card"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {viewIdCard.title || "View & print member ID cards"}
                      <img
                        alt=""
                        id="card-icon"
                        style={{ marginLeft: 5 }}
                        src={`${aem_content_url}/content/dam/myuhc/consumer/assets/myuhc/card-icon.svg`}
                      />
                    </span>
                  </Button>
                ) : null}
              </div>
            ) : null}
          </div>
        </Section>
      </div>
      {/* ***************** */}
      <div className="mobile">
        <Section border style={{ backgroundColor: "rgb(251, 252, 254)" }}>
          <div
            style={
              {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "20px 0px"
              }
            }
          >
            <div>
              <Typography.H4>Member ID: </Typography.H4>
              {medicalMemberID ? (
                <Typography.Paragraph>
                  Medical: <strong>{medicalMemberID}</strong>
                </Typography.Paragraph>
              ) : null}

              {dentalMemberID ? (
                <Typography.Paragraph>
                  Dental: <strong>{dentalMemberID}</strong>
                </Typography.Paragraph>
              ) : null}

              {visionMemberID ? (
                <Typography.Paragraph>
                  Vision: <strong>{visionMemberID}</strong>
                </Typography.Paragraph>
              ) : null}
            </div>
            {coverageStatus !== "termed" ? (
              <div>
                <Button
                  id="member-button-desktop"
                  buttonType="secondary-one"
                  href={viewIdCard.href}
                  onClick={()=>{
                    clickTrack(
                      "ID Cards", 
                      "main-body", 
                      "button",
                      "internal", 
                      domain + viewIdCard.href)}}
                >
                  <span
                    style={
                      loginLang !== "es" || lang !== "es"
                        ? {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }
                        : {}
                    }
                  >
                    ID Cards
                    <img
                      alt=""
                      id="card-icon"
                      style={{ marginLeft: 5 }}
                      src={`${aem_content_url}/content/dam/myuhc/consumer/assets/myuhc/card-icon.svg`}
                    />
                  </span>
                </Button>
              </div>
            ) : null}
          </div>
        </Section>
        <Section border className="claims-member-section">
          <Typography.H1
            style={{ color: "rgb(0, 38, 119)", fontWeight: 600, fontSize: 30 }}
          >
            {isSubscriber && dependents.length > 0 ? (
              <>
                {coverageStatus !== "termed" ? (
                  <>
                    {memberInfo[0]}
                    {coverageStatus === "active" & isGalileo ? <sup className="tooltip">
                      <UtilityIcon icon={Info} />
                      <span className="tooltiptext">
                        {memberInfo[1]}
                      </span>
                    </sup> : null}
                  </>
                ) : (
                    "Welcome, "
                  )}
                {/* {console.log(memberInfo)} */}
                {/* {memberInfo[1] && <PopupTip icon="question" placement="top" className="virtual-care-popup">
                           <Typography.Paragraph className="tds-margin-none">
                              {memberInfo[1]}
                           </Typography.Paragraph>
                        </PopupTip>} */}
                <span>
                  {" "}
                  <MemberCard
                    memberName={memberName}
                    isSubscriber={isSubscriber}
                    profile={profile}
                    handleView={handleView}
                    dashboardMemberData={dashboardMemberData}
                    dashboardviewFor={dashboardviewFor}
                  />
                </span>
              </>
            ) : (
                <>
                  {coverageStatus !== "termed" ? (
                    <>
                      {memberInfo[0]}
                      {coverageStatus === "active" & isGalileo ? <sup className="tooltip">
                        <UtilityIcon icon={Info} />
                        <span className="tooltiptext">
                          {memberInfo[1]}
                        </span>
                      </sup> : null}
                    </>
                  ) : (
                      "Welcome, "
                    )}
                  {/* {memberInfo[1] && <PopupTip icon="question" placement="top" className="virtual-care-popup">
                           <Typography.Paragraph className="tds-margin-none">
                              {memberInfo[1]}
                           </Typography.Paragraph>
                        </PopupTip>} */}
                  <span>{dashboardviewFor.name}</span>
                </>
              )}
          </Typography.H1>
        </Section>
      </div>
      {/* ******************* */}
      {coverageStatus !== "active" ? (
        <PlanInfo
          planInfo={planInfo}
          findProvider={findProvider}
          viewBenefits={viewBenefits}
          goPaperless={goPaperless}
          clickvb={clickvb}
          clickgp={clickgp}
          profile={profile}
          providerUrl={providerUrl}
          planInfoTermed={planInfoTermed}
          reviewDocuments={reviewDocuments}
          makePayment={makePayment}
          clickrd={clickrd}
          clickmp={clickmp}
          dashboardviewFor={dashboardviewFor}
          isGalileo={isGalileo}
          medicalMemberID={medicalMemberID}
          isNMPayExempt = {isNMPayExempt}
        />
      ) : null}

      {coverageStatus === "active" ? (
        <PlanInfoEffective
          isSubscriber={isSubscriber}
          findProvider={findProvider}
          viewBenefits={viewBenefits}
          providerUrl={providerUrl}
          mngPrpLink={mngPrpLink}
          mngRxLink={mngRxLink}
          hsaLink={hsaLink}
          dashboardviewFor={dashboardviewFor}
          situsState={situsState}
          activeCoverages={activeCoverages}
          isGalileo={isGalileo}
          isNMPayExempt = {isNMPayExempt}
        />
      ) : null}
    </>
  );
};

export default MemberInfo
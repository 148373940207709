import React from "react";

//Adobe Analytics Standardization Function Error track

const functionfunctionErrorTrack = (
    message,
    source,
    code,
    subtype
    ) => {
        let global_api_constants = {}
    if (sessionStorage.getItem('global-api-constants')) {
        global_api_constants = JSON.parse(sessionStorage.getItem('global-api-constants'))
    }
    const { adobeAnalyticsNew } = global_api_constants || {}
    if (window.location.pathname.includes("ifp") && adobeAnalyticsNew === 'true') {
        window.adobeDataLayer = window.adobeDataLayer || []
        window.adobeDataLayer.push({
            event: 'func error',
            error: { 
                message: message, //"<error message server/service responded with>",
                source: source, //"<name of system/service that generated the error message>",
                code: code, //"<type of error i.e. 404, 500, etc>",
                subtype: subtype, //"<error subtype>",
                customAttributes:{
                  //<key> : <value> 'n' number of key value pairs required for the local error related information
                } 
                }
        });

    return (
        <> {console.log("Function Error Track Analytics Set on Dashboard")}</>
    )
    }
};
export default functionfunctionErrorTrack;
import React from 'react';
import moment from 'moment';
import config from '../../../../../config';
import { Typography, Button, Link } from '@uhc-tempo/components';
import styles from './promoTile.module.css';
import VirtualCare from './promoVirtualCare';
import clickTrack from '../../../../../hooks/clickTrack';

const { aem_content_url } = config;

const PromoTile = ({
  effectiveRewards,
  effectiveRewardsButton,
  reimbursementImage,
  selectedDateOfBirth,
  selectedMedical,
  situsState,
  mobileAppTileContent,
  mobileAppTileLink,
  mobileAppTileImage,
}) => {
  const { coverageStatus = '', startDate = '' } = selectedMedical;

  if (coverageStatus?.toLowerCase() === 'termed') return null;

  const incentiveLink = effectiveRewardsButton?.href ? `${effectiveRewardsButton.href}` : '#';
  const reimbursementHref = reimbursementImage?.href ? `${aem_content_url}${reimbursementImage.href}` : '#';
  const age = moment().diff(moment(selectedDateOfBirth), 'years');
  //US6449760: IFP - Update Dashboard Tile With Mobile App Content (NJ/NM & U18)
  const isU18 = (age < 18);
  const isNMNJUser = (situsState === "NJ" || situsState === "NM");
  const showMobileAppTile = ((coverageStatus === "active") && (isU18 || isNMNJUser));

  return (
    <>
    {coverageStatus !== "pre-effective" ?
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.leftCol}>
          <div className="tempo-v1">
            <Typography.H2>{showMobileAppTile ? mobileAppTileContent?.title?.[0] : effectiveRewards?.title?.[0]}</Typography.H2>
          </div>
          <div className={styles.parContainer}>
            <div className={styles.imageContainer}>
              <div className={styles.image}>
                {showMobileAppTile ? <img src={mobileAppTileImage?.href ? `${aem_content_url}${mobileAppTileImage.href}` : '#'} alt="mobile app" /> : <img src={reimbursementHref} alt="coin dropping into a hand" />}
              </div>
            </div>
            <div>
              <Typography.Paragraph className={styles.subHeading}>{showMobileAppTile ? mobileAppTileContent?.title?.[1] : effectiveRewards?.title?.[1]}</Typography.Paragraph>
              {showMobileAppTile ?
                (<>
                  <div>
                    <ul className={styles.discList}>
                      <li>{mobileAppTileContent?.title?.[2]}</li>
                      <li>{mobileAppTileContent?.title?.[3]}</li>
                      <li>{mobileAppTileContent?.title?.[4]}</li>
                      <li>{mobileAppTileContent?.title?.[5]}</li>
                    </ul>
                    <div className={styles.mobileAppTileLink}>
                      <Link
                        href={mobileAppTileLink?.url}
                        size="small"
                        target={mobileAppTileLink?.newTab ? "_blank" : "_self"}
                        onClick={() => {
                          clickTrack(mobileAppTileLink?.title, "main-body", "text", 'internal', mobileAppTileLink?.url)
                        }}
                      >
                        {mobileAppTileLink?.title}
                      </Link>
                    </div>
                  </div>
                </>) :
                (<>
                  <ul className={styles.discList}>
                    <li>{effectiveRewards?.title?.[2]}</li>
                    <li>{effectiveRewards?.title?.[3]}</li>
                    <li>{effectiveRewards?.title?.[4]}</li>
                    <li>{effectiveRewards?.title?.[5]}</li>
                  </ul>
                  <div className={styles.buttonContainer}>
                    <Button
                      id="get-started"
                      buttonType="primary-one"
                      href={incentiveLink}
                      newTabWindow = {incentiveLink ? incentiveLink.includes("BIW") : null}
                      className={styles.getStartedBtn}
                      onClick={() => clickTrack(effectiveRewardsButton?.title, "main-body", "button", "internal", incentiveLink)}
                    >
                      {effectiveRewardsButton?.title}
                    </Button>
                  </div>
                </>)
              }
            </div>
          </div>
        </div>
        <VirtualCare />
      </div>
    </div>: null}
  </>
    );
  };
  
export default PromoTile;
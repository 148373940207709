import Constant from "../constants/constants"
import Axios from "axios"
import config from "../config"
import { v4 as uuidv4 } from "uuid"
import info from "../logger/info";
// import error from "../logger/error"
let payLoad = {}
const setLog = (msg) => {
    msg.function = "UseWalletService"
    payLoad = msg
    info(payLoad)
    payLoad = {}
}
const UseWalletService = (serviceType, POSTOBJ, callback) => {

    console.log('wallet service cld');
    let APIresponse = { APIError: false, errData: null }
    let { wallet_service_endpoint } = Constant;
    const API = "WalletService"

    if (serviceType === 'SEARCH') {
        wallet_service_endpoint = `${wallet_service_endpoint}/search`;
    }
    function callAPI() {
        const { payment_url, sheild_base2_uri } = config

        let responseCode = 'NULL'
        const startTime = new Date()
        const cid = `MYUHC2-CONSUMER-${API.toUpperCase()}-${uuidv4()}`
        let apiUrl = `/ifp/globalApiService?endpoint=${sheild_base2_uri}${wallet_service_endpoint}`

        const headers = {
            "Optum-CID-Ext": cid,
            timestamp: 1111,
            "Client-Code": "MYUHC",
            actor: "myuhc2.0"
        }
        //restricting firstName to 15 charachters 
        if (POSTOBJ && POSTOBJ.wallet && POSTOBJ.wallet.holderFirstName && POSTOBJ.wallet.holderFirstName.length > 15) {
            POSTOBJ.wallet.holderFirstName = POSTOBJ.wallet.holderFirstName.substring(0, 15);
        }

        if (serviceType === 'SEARCH' || serviceType === 'ADD') {
            Axios.post(apiUrl, POSTOBJ, { headers })
                .then(response => {
                    const { data } = response
                    if (response.status) {
                        responseCode = response.status
                    }
                    setLog({ 'responseCode': responseCode, 'pageUrl': window.location.href, 'serviceType': serviceType, 'requestObj': POSTOBJ, 'response': data, 'status': 'success', 'cid': cid, 'apiUrl': payment_url + apiUrl, 'duration': Date.now() - startTime.getTime() })
                    callback({ ...APIresponse, data, cid })
                })
                .catch(err => {
                    //toDo:Error Logging
                    if (err.response) {
                        responseCode = err.response.status
                    }
                    setLog({ 'responseCode': responseCode, 'pageUrl': window.location.href, 'serviceType': serviceType, 'requestObj': POSTOBJ, 'error': err, 'status': 'Error', 'cid': cid, 'apiUrl': payment_url + apiUrl, 'error': 'Error in the catch block', 'duration': Date.now() - startTime.getTime() })
                    //error({ 'responseCode': responseCode,'requestObj': POSTOBJ, 'status': 'Error', 'cid': cid, 'apiUrl': apiUrl, 'error': "Error in the catch block", 'duration': Date.now() - startTime.getTime() })

                    const errRes = err.response
                    callback({ APIError: true, errData: errRes, cid })
                })
        }

        if (serviceType === 'UPDATE' || serviceType === 'REMOVE') {
            Axios.put(apiUrl, POSTOBJ, { headers })
                .then(response => {
                    if (response.status) {
                        responseCode = response.status === 204 ? true : false
                    }
                    setLog({ 'responseCode': responseCode, 'pageUrl': window.location.href, 'serviceType': serviceType, 'requestObj': POSTOBJ, 'status': 'success', 'cid': cid, 'apiUrl': payment_url + apiUrl, 'duration': Date.now() - startTime.getTime() })
                    callback({ ...APIresponse, responseCode, cid })
                })
                .catch(err => {
                    //toDo:Error Logging
                    if (err.response) {
                        responseCode = err.response.status
                    }
                    setLog({ 'responseCode': responseCode, 'error': err, 'pageUrl': window.location.href, 'serviceType': serviceType, 'requestObj': POSTOBJ, 'status': 'Error', 'cid': cid, 'apiUrl': payment_url + apiUrl, 'error': 'Error in the catch block', 'duration': Date.now() - startTime.getTime() })
                    //error({ 'responseCode': responseCode,'requestObj': POSTOBJ, 'status': 'Error', 'cid': cid, 'apiUrl': apiUrl, 'error': "Error in the catch block", 'duration': Date.now() - startTime.getTime() })

                    const errRes = err.response
                    callback({ APIError: true, errData: errRes, cid })
                })
        }
    }
    callAPI();
}
export default UseWalletService;

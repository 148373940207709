import React, { useState, useEffect } from 'react'
import { Card } from '@uhc/pattern-library'
import { Section } from '@uhc/pattern-library'
import { Typography, Button } from '@uhc-tempo/components'
import HealthTile from './healthTiles'
import HealthResources from './healthResources'
import UseMemberaction from '../../../../hooks/useMemberactions'
import styles from './healthResources.module.css'
import config from '../../../../config'
import Rewards from './preeffective-components/rewards'
import PromoTile from './effective-components/promoTile'
import RewardsGalileo from './effective-components/promoTileGalileo'
import HealthQuery from './healthResourcesAddlQuery'
import UseGraphqlApi from '../../../../hooks/useGraphqlApi'
import getFilteredContentList from '../../../../tools/getFilteredContentList'
import { Markup } from 'interweave'
import clickTrack from '../../../../hooks/clickTrack'

const { aem_content_url } = config;

const AddlHealthResources = (props) => {
  const {
    coverageStatus,
    healthResourceTitle,
    healthResourceList,
    healthResourceButton,
    isLoading,
    isGalileo,
    preEffectiveRewards,
    preEffectiveRewardsButton,
    effectiveRewards,
    effectiveRewardsButton,
    reimbursementImage,
    mobileAppTileContent,
    mobileAppTileLink,
    mobileAppTileImage,
    selectedDateOfBirth,
    selectedMedical,
    situsState,
    isNJuser,
    isNMuser,
    isNMPayExempt
  } = props;

  const [tiles, setTiles] = useState({
    error: false,
    loading: false,
  });
  useEffect(() => {
    // Note : session storage not applicable for memberactions offer api
    // since OGM update instantly on clicking/updating any offers
    // if (sessionStorage.getItem("memberactions")) {
    //   setTiles(JSON.parse(sessionStorage.getItem("memberactions")))
    // }
    // else {
    UseMemberaction(data => {
      try {
        const { hasError, leadPromotionCount, leadPromotion } = data;
        if (hasError) {
          setTiles({ error: true });
        } else {
          // sessionStorage.setItem(
          //   "memberactions",
          //   JSON.stringify({
          //     error: false,
          //     loading: true,
          //     leadPromotionCount,
          //     leadPromotion,

          //   })
          // )
          setTiles({
            error: false,
            loading: true,
            leadPromotionCount,
            leadPromotion,
          });
        }
      } catch (error) { setTiles({ hasError: true, loading: true }) }
    });
    //}
  }, []);

  const { loading, error, leadPromotionCount, leadPromotion } = tiles || {};

  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(3);

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);
  }
  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);
  }

  // GraphQL for Health Resources RTO tile
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const CPQuery = HealthQuery();
  useEffect(() => {
    UseGraphqlApi(CPQuery, HealthHandler);
  }, []);
  const HealthHandler = (response) => {
    if (response && !response.isError) {
      if (response.data) {
        if (response.data.titleDescriptionList.items.length > 0) {
          const listWithDates = getFilteredContentList(
            response.data?.titleDescriptionList?.items
          );
          if (listWithDates.length > 0) {
            // setchangeTitle(listWithDates[0])
            setTitle(listWithDates[0].title);
            setDescription(listWithDates[0].description);
          }
        }
      }
    }
  };
  return (
    <> 
    {/* US6359827 IFP - Suppress Rewards Promo Tile on Dashboard (Pre-Effective)*/}
      {/* <Rewards preEffectiveRewards={preEffectiveRewards} preEffectiveRewardsButton={preEffectiveRewardsButton} reimbursementImage={reimbursementImage} selectedDateOfBirth={selectedDateOfBirth} selectedMedical={selectedMedical} situsState={situsState} isNMuser = {isNMuser} /> */}
      {isGalileo ? <RewardsGalileo effectiveRewards={effectiveRewards} effectiveRewardsButton={effectiveRewardsButton} reimbursementImage={reimbursementImage} selectedDateOfBirth={selectedDateOfBirth} selectedMedical={selectedMedical} isNJuser={isNJuser} isNMuser = {isNMuser}/> : null}
      {!isGalileo ? <PromoTile effectiveRewards={effectiveRewards} effectiveRewardsButton={effectiveRewardsButton} reimbursementImage={reimbursementImage} selectedDateOfBirth={selectedDateOfBirth} selectedMedical={selectedMedical} mobileAppTileContent = {mobileAppTileContent} mobileAppTileLink = {mobileAppTileLink} mobileAppTileImage = {mobileAppTileImage} situsState = {situsState} /> : null}
      {loading && leadPromotion && coverageStatus === 'active' ?
        <div className={isGalileo ? styles.resourceContainerGalileo : styles.resourceContainer}>
          <div className={styles.styledContainer}>
            <div className={styles.headingGroup}>
              <Section>
                {title ? <Typography.H2 className={styles.headingResource}>{title}</Typography.H2> : <Typography.H2>{`Health resources`}</Typography.H2>}
                {description && description["html"] ? (
                  <Typography.Paragraph>
                    {/* Based on your history, we think you might be interested in this incentive program. */}
                    <Markup allowAttributes={true} content={description.html} />
                  </Typography.Paragraph>
                ) : (<Typography.Paragraph>
                  <Markup allowAttributes={true} content={'Based on your history, we think you might be interested in this incentive program.'} />
              </Typography.Paragraph> )}
              </Section>
              <div className={styles.groupRight}>
                {leadPromotionCount && leadPromotionCount > 3 ?
                  <div aria-live="polite" aria-atomic="true">
                    <span className={styles.sronly}> Results Updated</span>
                    <Typography.Paragraph className={styles.pagination}>
                      {leadPromotion.length <= 0 ? leadPromotion.length : ((currentPage * itemsPerPage) - itemsPerPage + 1)}{' - '}
                      {((currentPage) * itemsPerPage) <= leadPromotion.length ? ((currentPage) * itemsPerPage) : leadPromotion.length}
                      {' '}of {leadPromotion.length}
                    </Typography.Paragraph>
                  </div> : ''}

                {leadPromotionCount && leadPromotionCount > 3 && (
                  <>
                    <Button className="hw-prev-btn" aria-label='previous' id="previous-feature"
                      buttonType={currentPage === 1 ? 'disabled' : 'secondary-one'}
                      onClick={()=>{handlePrevbtn(); 
                        clickTrack("Previous Button", "main-body", "button", "internal", "")}}
                      disabled={currentPage === 1 ? true : false}
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 10, height: 30 }}>
                      <img
                        src={`${aem_content_url}/content/dam/myuhc/consumer/wellness/health-resources/images/Arrow__Left.svg`}
                        alt="previous"
                        style={{ width: "27%" }}
                      />
                      {/* <img src={ArrowLeft} alt="previous" style={{ width: '27%' }} /> */}
                    </Button>
                    <Button
                      className="hw-next-btn" aria-label='next' id="next-feature"
                      buttonType={leadPromotion.length / 3 <= currentPage ? 'disabled' : 'secondary-one'}
                      onClick={()=>{handleNextbtn(); 
                        clickTrack("Next Button", "main-body", "button", "internal", "")}}
                      disabled={leadPromotion.length / 3 <= currentPage ? true : false}
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 30 }}
                    >
                      <img
                        src={`${aem_content_url}/content/dam/myuhc/consumer/wellness/health-resources/images/Arrow__Right.svg`}
                        alt="next"
                        style={{ width: "27%" }}
                      />
                      {/* <img src={ArrowRight} alt="next" style={{ width: '27%' }} /> */}
                    </Button>
                  </>
                )}
              </div>
            </div>

            <Card className={styles.resourceCard}>
              <ul className={styles.tileList} >
                {leadPromotionCount > 0 && leadPromotion &&
                  leadPromotion
                    .sort((a, b) => b.offerScore - a.offerScore)
                    .slice(
                      (currentPage * itemsPerPage) - itemsPerPage,
                      currentPage * itemsPerPage)
                    .map((item, i) => (
                      <li className={styles.containerBlock} key={i} >

                        <HealthTile 
                        image={item.nbaHoverBullet2} 
                        title={item.nbaEventDesc}
                        description={item.nbaHoverDesc} 
                        btnText={item.nbaToDoDesc}
                        url={item.nbaHoverBullet1} 
                        offerKey={item.offerKey} 
                        offerStatus='TellMeMore'
                        nbaBlacklistDaysTellMeMore={item.nbaBlacklistDaysTellMeMore} 
                        treatmentCode={item.treatmentCode} />
                      </li>
                    ))}
              </ul>
            </Card>
          </div>

         </div>:
        <HealthResources
          coverageStatus={coverageStatus}
          healthResourceTitle={healthResourceTitle}
          healthResourceList={healthResourceList}
          healthResourceButton={healthResourceButton}
          isLoading={isLoading}
          isGalileo={isGalileo}
          isNMuser = {isNMuser}
          isNMPayExempt = {isNMPayExempt}
        /> }
    </>
  )
}
export default AddlHealthResources

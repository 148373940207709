import React, { useState, useEffect, useContext } from 'react'
import ReactDOM from 'react-dom';
import { Button, Link, Typography } from '@uhc-tempo/components'
import styles from './banner.module.css'
import UseGraphqlApi from '../../../../hooks/useGraphqlApi'
import BannerQuery from './bannerQuery'
import { Loader } from "@uhc/pattern-library"
import Config from '../../../../config'
import RallyRedirects from '../../../../constants/rally-redirects'
import { PrimaryCareContext } from "../../../../contexts/primarycareContext"
import getCookieItem from "../../../../tools/getCookieItem";
import getFilteredContentList from "../../../../tools/getFilteredContentList";
import { Markup } from 'interweave'
import clickTrack from '../../../../hooks/clickTrack'
import { ProfileContext } from '../../../../contexts/profileContext'
import ExternalIcon from '../../../../assets/icons/external-icon';
const loginLang = getCookieItem("LOGINLOCALE");
const selectedlang = getCookieItem("lang");

//US4949442, US4988117: Don't change rally_connect_lang value
let rally_connect_lang = "en-US"
if (loginLang === "es" || selectedlang === "es") {
  rally_connect_lang = "es"
}
let svgContainer = document.createElement('span')

const Banner = ({ depSeqNbr }) => {
  const { profile } = useContext(ProfileContext);
  const { loggedinMember } = profile || {};
  const { isSubscriber, policyNumber, isMedicaidReDt } = loggedinMember || {};
  const { legacy_en_url, rally_url, aem_content_url } = Config || {}
  const { view_pcp, find_provider } = RallyRedirects || {}
  const [open, setOpen] = useState(true)
  const [openBanner, setOpenBanner] = useState(true)
  // GraphQl query implementation starts
  const [bannerContent, setBannerContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasError, setError] = useState(false);
  const bannerContentQuery = BannerQuery()
  const domain = window.location.origin + window.location.pathname
  useEffect(() => {
    UseGraphqlApi(bannerContentQuery, postBannersHandler);
  }, []);

  const postBannersHandler = (response) => {
    if (response && !response.isError) {
      if (response.data) {
        if (response.data.bannerList.items.length > 0) {
          const listWithDates = getFilteredContentList(response.data.bannerList.items)
          if (listWithDates.length > 0) {
            //console.log('listwithdates', listWithDates);
            setBannerContent(
              listWithDates[0]
            )
          }
          else {
            setBannerContent(
              response.data.bannerByPath && response.data.bannerByPath.item ?
                response.data.bannerByPath.item : null
            );
          }
        }
        else if (response.data.bannerByPath.item) {
          setBannerContent(
            response.data.bannerByPath && response.data.bannerByPath.item ?
              response.data.bannerByPath.item : null
          );
        }
      }
    } else {
      setError(true);
    }
    setLoading(false);
  };
  const { urlType, closeLink, buttonLink, type, description, image, title, buttonTitle } = bannerContent || {}
  
  const hasDepPFNYBanner = !isSubscriber && (buttonLink?.includes("prepare-for-next-year")) 

  // GraphQl query implementation ends
  //  ****************** Banner url function **********************
  const { primarycare } = useContext(PrimaryCareContext) || {}
  const { pcpData, isPrimarycareSet } = primarycare || {}
  let url = ''
  function renderUrl(urlType) {
    if (urlType === "pcpUrl") {
      isPrimarycareSet && pcpData !== null && pcpData.primarycareData !== null && pcpData.primarycareData!==undefined && depSeqNbr !== null &&
        pcpData.primarycareData.map((pcpdata, i) => {
          if ((depSeqNbr === pcpdata.depSeqNbr) && pcpdata.providerID && pcpdata.providerID.id && pcpdata.pcpError === false) {
            url = `${view_pcp}/${pcpdata.providerID.id}?partnerId=true&locale=${rally_connect_lang}`
          }
        })
    }
    else if (urlType === "pageUrl" || urlType === "default") {
      url = buttonLink
    }
    else if (urlType === "legacyUrl") {
      url = `${legacy_en_url}` + buttonLink
    }
    else if (urlType === "rallyUrl") {
      url = `${rally_url}` + buttonLink
    }
    else {
      url = `${find_provider}`
    }
    return url
  }
  // console.log(renderUrl(urlType))
  //  ****************** Banner url function **********************

  const descriptionforNMONEXMember = () => {
    return (
      <><Typography.Paragraph style={{ margin: "0 0 0 10px" }}>
        <span style={{ fontFamily: '"UHC Sans Bold", Arial, sans-serif' }}>Trying to pay your premium payment ?</span>
        <span style={{ display: 'block' }}>Head to

          <span style={{maxHeight:'20px', padding:'3px'}}>
            <a href='https://beWellnm.com' target='external'>beWellnm.com</a>
            <span style={{marginLeft:'-3px'}}><ExternalIcon /></span>
          </span>
          to pay your premium payment. BeWellnm can also answer questions about your plan invoices and billing.</span>
      </Typography.Paragraph><br /></>
    )
  }

  const notificationBanner = () => {
    return <>
          {openBanner && policyNumber === 'NMONEX' ?
              <div className={styles.wrapAlert} style={{backgroundColor:'beige'}}>
                <div className={styles.container}>
                  <div className={styles.flexWrap}>
                    <div className={styles.mobileFlex}>
                      <div className={styles.icon} style={{ width: 34, marginRight: 20}} >
                          <svg className="MuiSvgIcon-root payment-utility-icon" focusable="false" color="#f5b700" aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20">
                            <g fill="none" fill-rule="evenodd"><path fill="currentColor" d="M0 19.5h22L11 .5z"></path>
                            <path fill="#000" d="M12 16.5h-2v-2h2zM12 12.5h-2v-4h2z"></path></g>
                          </svg>
                      </div>
                    <div id="markup-margin" className={styles.mobileOnlyBanner}>
                      {descriptionforNMONEXMember()}
                    </div>
                  </div>
                  <div id="markup-margin" className={styles.desktopOnlyBanner} >
                  {descriptionforNMONEXMember()}
                  </div>
                  </div>
                  <div  className={styles.warningButton} style={{ marginBottom: 10, marginLeft: 'auto', whiteSpace: 'nowrap' }} >
                    {openBanner ? (
                      <Button
                        onClick={() => setOpenBanner(false)}
                        style={{
                          backgroundColor: 'transparent',
                          border: 'none',
                          color: '#5a5a5a'
                        }}
                      >
                        <span className="sr-only">close banner</span>
                        <span aria-hidden="true"> X </span>
                      </Button>
                    ) : null}
                  </div>
                </div>
              </div>
                : null}
          </>
  }
  return (
    <>
      {loading && hasError && !isMedicaidReDt?
        <Loader Blocking /> :
        
        // US6402287: IFP - Suppress PFNY Banner For Dependents
        (open && (title || description?.html) && !hasDepPFNYBanner) ?
          <div className={type === 'info' ? styles.wrapInfo : type === 'alert' ? styles.wrapAlert :
            type === 'success' ? styles.wrapSuccess : type === 'error' ? styles.wrapError : styles.wrapInfo}>
            <div className={styles.container}>
              <div className={styles.flexWrap}>

                <div className={styles.mobileFlex}>
                  {image && image._path ? <img src={`${aem_content_url}${image._path}`} alt="" className={styles.icon} style={{ width: 34, marginRight: 20 }} /> :
                    null}
                  <div id="markup-margin" className={styles.mobileOnly}>
                    {title ? <h3> {title} </h3> : null}
                    {description && description.html ?
                      <Typography.Paragraph style={{ fontSize: 16 }}>
                        <Markup allowAttributes={true} content={description.html} />
                      </Typography.Paragraph> : null}
                  </div>
                </div>

                <div id="markup-margin" className={styles.desktopOnly} >
                  {title ? <h3>{title} </h3> : null}
                  {description && description.html ? <Typography.Paragraph className={styles.bannerBody}>
                    <Markup allowAttributes={true} content={description.html} />
                  </Typography.Paragraph> : null}
                </div>

              </div>
              <div style={{ marginBottom: 10, marginLeft: 'auto', whiteSpace: 'nowrap' }}>
                {type === 'info' || type === 'alert' ?
                  <>
                    {buttonTitle ? 
                    <Button
                      role="button"
                      buttonType={type === 'info' ? "primary-one" : type === 'alert' ? "primary-three" : "primary-one"}
                      href={renderUrl(urlType)}
                      id="see-resources-btn"
                      target={urlType === 'pageUrl' ? '_blank' : ''}
                      newTabWindow={urlType === 'pageUrl'}
                      onClick = {() => 
                        clickTrack(
                        buttonTitle, 
                        "main-body",
                        "button",
                        urlType === 'pageUrl' ? 'external' : 'internal',
                        domain + renderUrl(urlType)
                        )}
                    >
                      {buttonTitle}
                    </Button> :
                      <Link
                        herf={renderUrl(urlType)}
                        id="see-resources-btn"
                        style={{ marginLeft: 20 }}
                        target={urlType === 'pageUrl' ? '_blank' : ''}
                        onClick={()=>{
                          clickTrack(buttonTitle, "main-body", "text", urlType === 'pageUrl' ? 'external ' : 'internal', domain + renderUrl(urlType))
                       }}
                      >
                        {buttonTitle}
                      </Link>}
                  </>
                  : null}
                {closeLink ? (
                  <Button
                    onClick={() => setOpen(false)}
                    style={{
                      backgroundColor: 'transparent',
                      border: 'none',
                      color: '#5a5a5a'
                    }}
                  >
                    <span className="sr-only">close banner</span>
                    <span aria-hidden="true"> X </span>
                  </Button>
                ) : null}
              </div>
            </div>
          </div> : null}

          {notificationBanner()}
    </>
  )
}

export default Banner

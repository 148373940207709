import React from 'react'
import { Typography } from '@uhc-tempo/components'
import config from "../../../../config"
//import noClaimBackground from '../../../../assets/images/noClaimCard.png'

const ApiCallError = (props) => {
  const { text } = props
  const { aem_content_url } = config;

  return (
    <>
      <div>
        <img
          //src={noClaimBackground}
          src={`${aem_content_url}/content/dam/myuhc/consumer/assets/myuhc/noClaimCard.png`}
          alt="no claims" className="no-claim-card" />
        <Typography.Paragraph className="no-claim-p">{text}</Typography.Paragraph>
      </div>
    </>
  )
}

export default ApiCallError

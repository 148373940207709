import { useContext } from "react";
import { ProfileContext } from "../../../../../contexts/profileContext";
import getPlanYear from '../../../../../tools/getPlanYear';

const PCP_Query = () => {
  const { profile } = useContext(ProfileContext) || {};
  const { loggedinMember } = profile || {};
  const { coverages } = loggedinMember || {};
  const { medical } = coverages || {}
  const { situsState, reportingCode, coverageStatus, startDate } = medical || {}

  const statusConfig = {
    active: 'effective',
    'pre-effective': 'pre-effective',
    termed: 'termed'
  }

  const planYear = getPlanYear(startDate)

  const searchPathByList = `/content/dam/iex/${planYear}/${statusConfig[coverageStatus]}/dashboard/primary-care-provider`
  const searchPathByPath = `/content/dam/iex/${planYear}/${statusConfig[coverageStatus]}/dashboard/primary-care-provider/gated`
  const requestParameters = `;searchPathByList=${searchPathByList};searchPathByPath=${searchPathByPath};situsState=${situsState};reportingCode=${reportingCode}`
  const requestPath = `/myuhc/graphql/execute.json/IEX/dashboardPCPQuery.json${encodeURIComponent(requestParameters)}`
  return requestPath;
};
export default PCP_Query;
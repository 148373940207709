import React, { createContext, useState, useEffect } from "react"
import info from "../logger/info"
import error from "../logger/error"
import GetConstants from "../tools/getConstants"
import ContentEndpoints from "../constants/contentEndpoints"
const { GLOBAL_API_CONSTANTS } = ContentEndpoints

export const ProfileContext = createContext()
const ProfileContextProvider = ({ children }) => {
   const startTime = new Date()
   const [profile, setProfile] = useState({
      isProfileSet: false,
      hasError: false
   })
   useEffect(() => {
      if (sessionStorage.getItem("ProfileContext")) {
         setProfile(JSON.parse(sessionStorage.getItem("ProfileContext")))
         info({ 'function': 'ProfileContextProvider', 'status': 'cache', 'duration': Date.now() - startTime.getTime() })
      } else {
         GetConstants(GLOBAL_API_CONSTANTS, constants => {
            if (constants) {
               const { PROFILE_ENDPOINT } = constants
               console.log("Inside Profile Context Offline flow")
               async function fetchProfile() {
               var requestOptions = {
                  method: 'GET',
               };
               const response = await fetch(`/ifp/getProfileContextOffline?endpoint=${PROFILE_ENDPOINT}`, requestOptions);
               const profileResponse = await response.json();
               return profileResponse;
               }
               fetchProfile().then(profileResponse => {
               const data = profileResponse;
               if (data && data.loggedinMember) {
                  sessionStorage.setItem(
                     "ProfileContext",
                     JSON.stringify({ ...data })
                  );
                  setProfile({ ...data });
                  info({
                     function: "ProfileContextProvider",
                     status: "success",
                     duration: Date.now() - startTime.getTime(),
                  });
               } else if(data && data.type === "invalid-json"){
                  setProfile({ hasError: true, isProfileSet: true });
               }else {
                  setProfile({ hasError: true, isProfileSet: true });
                  const logObj = {
                     function: "ProfileContextProvider",
                     status: "error",
                     "response error": "loggedinMember Not Found",
                     duration: Date.now() - startTime.getTime(),
                  };
                  info(logObj);
                  error(logObj);
               }
               })
               .catch((err) => {
                  setProfile({ hasError: true, isProfileSet: true });
                  const logObj = {
                     function: "ProfileContextProvider",
                     status: "error",
                     'error': JSON.stringify(err),
                     'error-message': "Error in the catch block",
                     duration: Date.now() - startTime.getTime(),
                  };
                  info(logObj);
                  error(logObj);
               });

            } else {
               setProfile({ hasError: true, isProfileSet: true })
            }
         })
      }
   }, [])

   const superUserActions = {
      blockActionForSuperUser: (params, callback) => {
         /**
          * Function to check if loggedin member is not superuser with restrictions for specified levels
          * Takes arguments, array of superuser levels with restrictions and a callback function
          * Syntax: checkSuperUserRestriction([],()=>{...})
          */
         try {
            const { superUserInfo } = profile || {};
            const { aemsulevel } = superUserInfo || {};
            if (aemsulevel && aemsulevel !== "") {
               const checksuperuserlevel = params.find(
                  level => level === parseInt(aemsulevel)
               )
                  ? true
                  : false;
               checksuperuserlevel && callback();
               !checksuperuserlevel &&
                  setProfile({ ...profile, showSuperUserMessage: true });
            } else {
               //not superuser
               throw new Error("isNotSuperUserLogin");
            }
         } catch (error) {
            callback();
         }
      },
      hideSuperUserMessage: () => {
         setProfile({ ...profile, showSuperUserMessage: false });
      }
   };


   return (
      <ProfileContext.Provider value={{ profile, ...superUserActions }}>
         {children}
      </ProfileContext.Provider>
   );
};

export default ProfileContextProvider;

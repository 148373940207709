import React, { useEffect, useContext } from "react";
import { Section } from "@uhc/pattern-library";
import { Typography, Button } from "@uhc-tempo/components";
import config from "../../config";
import { ProfileContext } from "../../contexts/profileContext";

const SuperUserResctrictionErrorMessage = () => {
  const { aem_content_url } = config;
  const { hideSuperUserMessage } = useContext(ProfileContext);

  useEffect(() => {
    //scroll to top of page when error message shows
    window.scrollTo(0, 0);
  }, []);

  return (
    <Section>
      <div style={{ display: "flex", justifyContent: "space-between", padding: 5, margin: "20px auto", background: "#ffe0e0", alignItems: "center" }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={`${aem_content_url}/content/dam/myuhc/consumer/assets/myuhc/caution-icon.svg`} alt="restricted access" />
          <Typography.H1 className="banner-font">
            You are not authorized to submit this request.  Use of this website is restricted to our health care plan subscribers.
          </Typography.H1>
        </div>
        <Button className="superuser-alert-btn" style={{ backgroundColor: "transparent", border: "none", color: "#002677" }} 
        onClick={() => hideSuperUserMessage()}>
          <span className='sr-only'>close banner</span>
          <span aria-hidden='true'> X </span>
        </Button>
      </div>
    </Section>
  );
};

export default SuperUserResctrictionErrorMessage;

import React, { useContext, useState, useEffect } from "react";
import { Typography } from "@uhc-tempo/components";
// import Check from "@material-ui/icons/Check";
// import Create from "@material-ui/icons/Create";
import PcpCard from "./provider-card";
import { Loader } from "@uhc/pattern-library";
import UseGraphqlApi from "../../../../../hooks/useGraphqlApi";
import Config from "../../../../../config";
import RallyRedirects from '../../../../../constants/rally-redirects';
import { PrimaryCareContext } from "../../../../../contexts/primarycareContext";
import { ProfileContext } from "../../../../../contexts/profileContext"
import PCP_Query from "../../components/effective-components/pcpQuery.js";
import VirtualCareQuery from "../../components/effective-components/virtualCareQuery";
import GetCookieItem from "../../../../../tools/getCookieItem";
import GenericError from "../../../../shared-components/error";
import styles from './notification.module.css';
import VirtualCare from "./virtualCare";
import getFilteredContentList from "../../../../../tools/getFilteredContentList";
import moment from 'moment';

const resolution = window.innerWidth;
const isDesktop = resolution >= 991;

const { legacy_url, rally_url, rally_vc_url, aem_content_url } = Config;
const { view_pcp } = RallyRedirects || {}
const loginLang = GetCookieItem("LOGINLOCALE");
const selectedLang = GetCookieItem("lang");

//US4949442, US4988117: Don't change rally_connect_lang value
let rally_connect_lang = "en-US"
if (loginLang === "es" || selectedLang === "es") {
  rally_connect_lang = "es"
}

const PcpSelector = (props) => {
  const {
    // providerUrl,
    isKelseySeybold,
    dependentSeqNbr,
    apiFailErrorMsg,
    situsState,
    pcpPopup,
    isGalileo,
    viewReferrals,
    selectedDateOfBirth,
    selectedDependentSeqNbr,
    selectedMedical
  } = props;


  const { profile } = useContext(ProfileContext) || {};
  const { loggedinMember } = profile || {};
  const { coverages, dateOfBirth } = loggedinMember || {};
  const { medical } = coverages || {};
  const { coverageStatus } = medical || {};
  const selectedAge = moment().diff(moment(selectedDateOfBirth), 'years');
  const loggedinAge = moment().diff(moment(dateOfBirth), 'years');
  const belowEighteen = (selectedAge < 18 || loggedinAge < 18) ? true : false;

  const { primarycare } = useContext(PrimaryCareContext) || {};
  const { pcpData, isPrimarycareSet } = primarycare || {};
  const pcpInfo = pcpData ? pcpData : null;

  const [loading, setLoading] = useState(true);
  const [PCP_Error, set_PCP_Error] = useState(false);
  const [virtualCareEError, setVirtualCareEError] = useState(false);

  const [pcpState, setPcpState] = useState([]);
  const [virtualCareState, setVirtualCareState] = useState({});
  const get_PCP_Data = PCP_Query();
  const getVirtualCareData = VirtualCareQuery();

  useEffect(() => {
    UseGraphqlApi(get_PCP_Data, PCP_QueryHandler);
    UseGraphqlApi(getVirtualCareData, virtualQueryHandler);
  }, []);

  const PCP_QueryHandler = (response) => {
    if (response && !response.isError) {
      if (response.data) {
        if (response.data.listTileList.items.length > 0) {
          const listWithDates = getFilteredContentList(response.data ?.listTileList ?.items)
          if (listWithDates.length > 0) {
            setPcpState(
              listWithDates[0]
            );
          }
          else {
            setPcpState(
              response.data.listTileByPath && response.data.listTileByPath.item ?
                response.data.listTileByPath.item : ''
            )
          }
        }
        else if (response.data.listTileByPath.item) {
          setPcpState(
            response.data.listTileByPath && response.data.listTileByPath.item ?
              response.data.listTileByPath.item : ''
          )
        }
      }
    } else {
      set_PCP_Error(true);
    }
    setLoading(false);
  };
  const { title: pcpTitle, subTitle: pcpSubTitle, description: pcpDescription, image: pcpImage,
    linkText: pcpLinkText, url: pcpUrl, urlType: pcpUrlType,
    linkText2: pcpLinkText2, url2: pcpUrl2, urlType2: pcpUrlType2 } = pcpState || {}

  const virtualQueryHandler = (response) => {
    if (response && !response.isError) {
      if (response.data) {
        if (response.data.listTileList.items.length > 0) {
          const listWithDates = getFilteredContentList(response.data ?.listTileList ?.items)
          if (listWithDates.length > 0) {
            setVirtualCareState(
              listWithDates[0]
            );
          }
          else {
            setVirtualCareState(
              response.data.listTileByPath && response.data.listTileByPath.item ?
                response.data.listTileByPath.item : ''
            )
          }
        }
        else if (response.data.listTileByPath.item) {
          setVirtualCareState(
            response.data.listTileByPath && response.data.listTileByPath.item ?
              response.data.listTileByPath.item : ''
          )
        }
      }
    } else {
      setVirtualCareEError(true);
    }
    setLoading(false);
  };
  let newPcpUrl2 = ''

  function renderPcpUrl2(pcpUrlType2) {
    if (pcpUrlType2 === "pcpUrl") {
      isPrimarycareSet && pcpData !== null && pcpData.primarycareData !== null && pcpData.primarycare!==undefined && dependentSeqNbr !== null &&
        pcpData.primarycareData.map((pcpdata) => {
          if ((dependentSeqNbr === pcpdata.depSeqNbr) && pcpdata.providerID && pcpdata.providerID.id && pcpdata.pcpError === false) {
            newPcpUrl2 = `${view_pcp}/${pcpdata.providerID.id}?partnerId=true&locale=${rally_connect_lang}`
          }
        })
    }
    if (pcpUrlType2 === "rallyUrl") {
      newPcpUrl2 = `${rally_url}` + pcpUrl2
    }
    else if (pcpUrlType2 === "rallySSO") {
      newPcpUrl2 = `${rally_vc_url}` + pcpUrl2
    }
    else if (pcpUrlType2 === "pageUrl" || pcpUrlType2 === "default") {
      newPcpUrl2 = pcpUrl2
    }
    else if (pcpUrlType2 === "legacyUrl") {
      newPcpUrl2 = `${legacy_url}` + pcpUrl2
    }
    return newPcpUrl2
  }
  //  ****************** Banner url function **********************
  return (
    <>
      {loading ? (
        <Loader Blocking />
      ) : (
          <div className="styledContainer"
            style={isDesktop && !isGalileo ? { position: "relative", top: "-40px" } :
              isDesktop && isGalileo ? { position: "relative", top: "20px" } :
                !isGalileo ? { position: "relative", top: "-60px" } :
                  null}>

            <div className="pay-now-col">
              {PCP_Error ? (
                <GenericError></GenericError>
              ) : (
                  <>
                    <div className="tempo-v1" style={{ marginTop: -23 }}>
                      {pcpTitle &&
                        <Typography.H2 className={`${styles.pcpTitle} ${styles.styledHeading}`}>{pcpTitle}</Typography.H2>}
                      {pcpSubTitle &&
                        <Typography.Paragraph className={styles.Paragraph}>{pcpSubTitle}</Typography.Paragraph>}
                    </div>
                    {/* Should come from API */}
                    {/* display this if no referrals to show  */}
                    <PcpCard
                      isKelseySeybold={isKelseySeybold}
                      isGalileo={isGalileo}
                      situsState={situsState}
                      isPrimarycareSet={isPrimarycareSet}
                      pcpInfo={pcpInfo}
                      dependentSeqNbr={dependentSeqNbr}
                      // providerUrl={() => providerUrl()}
                      apiFailErrorMsg={apiFailErrorMsg}
                      referralTitle={pcpSubTitle ? pcpSubTitle : null}
                      referralDescription={pcpDescription && pcpDescription.plaintext ? pcpDescription.plaintext : null}
                      referralImage={pcpImage && pcpImage._path ? pcpImage._path : null}
                      pcpSubTitle={pcpSubTitle ? pcpSubTitle : null}
                      pcpLinkText2={pcpLinkText2 ? pcpLinkText2 : null}
                      pcpUrl2={renderPcpUrl2(pcpUrlType2)}
                      urlType2={pcpUrlType2 ? pcpUrlType2 : null}
                      pcpPopup={pcpPopup}
                      viewReferrals={viewReferrals}
                      selectedDependentSeqNbr={selectedDependentSeqNbr}
                      selectedMedical={selectedMedical}
                    />
                    {/* display this if there are referrals to show */}
                    {/* <ProviderReferral /> */}

                  </>
                )}
              {/*coverageStatus === "active" && !isGalileo && belowEighteen &&
                <VirtualCare />
              */}
              {/* {coverageStatus === "pre-effective" &&
                <DoctorTile />
              } */}
            </div>

          </div>
        )}
    </>
  );
};

export default PcpSelector;
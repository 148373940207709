import Axios from "axios"
import info from "../logger/info";
import error from "../logger/error";

const GetConstants = (endpoint, callback) => {
   //check for constants in session
   const startTime = new Date()
   if (sessionStorage.getItem("global-api-constants")) {
      const global_api_constants = JSON.parse(
         sessionStorage.getItem("global-api-constants")
      )
      info({
         function: "GetContants from AEM",
         status: "cache",
         duration: Date.now() - startTime.getTime(),
       });
      callback(global_api_constants)
   } else {
      //make call to content if not in session
      Axios.get(endpoint)
         .then(response => {
            try {
               const { data } = response
               //access data
               //get and update session with formatted constants
               const fragmentItem = data[":items"]
               const { apilist, switches } = fragmentItem || {}
               const { items } = apilist
               const switchItems = switches && switches.items
               //loop and get items
               let constants_list = {}
               items.length &&
                  items.forEach(data => {
                     const { elements } = data
                     const { value, key } = elements
                     if (key.value && value.value) {
                        constants_list = {
                           ...constants_list,
                           [key.value]: value.value
                        }
                     }
                  })
                  switchItems.length &&
                  switchItems.forEach(data => {
                     const { elements } = data
                     const { value, key } = elements
                     if (key.value && value.value) {
                        constants_list = {
                           ...constants_list,
                           [key.value]: value.value
                        }
                     }
                  })
               //  check for not empty object and callback
               if (Object.keys(constants_list).length !== 0) {
                  //set constants in sessionStorage
                  sessionStorage.setItem(
                     "global-api-constants",
                     JSON.stringify({ ...constants_list })
                  )
                  info({
                     function: "GetContants from AEM",
                     status: "success",
                     duration: Date.now() - startTime.getTime(),
                   });
                  callback({ ...constants_list })
               }
            } catch (error) {
               console.log(error)
               const logObj = {
                  function: "GetContanst from AEM",
                  status: "error",
                  duration: Date.now() - startTime.getTime(),
               };
               info(logObj);
               error(logObj);
            }
         })
         .catch(e => {
            console.log(e)
            const logObj = {
               function: "GetContanst from AEM",
               status: "error",
               duration: Date.now() - startTime.getTime(),
            };
            info(logObj);
            error(logObj);
         })
   }
}

export default GetConstants

import { useContext } from 'react'
import { ProfileContext } from "../../../../contexts/profileContext"
import getPlanYear from '../../../../tools/getPlanYear';

const MemberInfoQuery = () => {

  const { profile } = useContext(ProfileContext) || {}
  const { loggedinMember } = profile || {}
  const { coverages } = loggedinMember || {}
  const { medical } = coverages || {}
  const { situsState, reportingCode, coverageStatus, startDate } = medical || {}

  const planYear = getPlanYear(startDate)

  let memberStatus = 'effective'
  if (coverageStatus === 'active') {
    memberStatus = 'effective'
  }
  else if (coverageStatus === 'pre-effective') {
    memberStatus = 'pre-effective'
  }

  const searchPathByList = `/content/dam/iex/${planYear}/${memberStatus}/dashboard/welcome-message`
  const searchPathByPath = `/content/dam/iex/${planYear}/${memberStatus}/dashboard/welcome-message/gated`
  const requestParameters = `;searchPathByList=${searchPathByList};searchPathByPath=${searchPathByPath};situsState=${situsState};reportingCode=${reportingCode}`
  const requestPath = `/myuhc/graphql/execute.json/IEX/dashboardMemberInfoQuery.json${encodeURIComponent(requestParameters)}`
  return requestPath;
}
export default MemberInfoQuery

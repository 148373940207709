import React from 'react';
import { Accumulator } from '@uhc/pattern-library';
import { UtilityIcon, Typography, PopupTip } from '@uhc-tempo/components';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Error from '@material-ui/icons/Error';
// import StopCircle from '@material-ui/icons/RemoveCircleOutline';
import { Loader } from '@uhc/pattern-library';
import '../../../index.css';
import '../../../../../shared-components/reusableComponents/grid.css';
import ApiCallError from '../../../components/apiCallError';
import { Markup } from 'interweave';
import modalTrack from '../../../../../../hooks/modalTrack';

// /* eslint-disable */

const PharmacyClaimsAccount = props => {
  const pointer = 12;
  const {
    dependentSeqNbr,
    apiFailErrorMsg,
    apiErrorMessage,
    familybenefits,
    isPlandetailSet,
    prescriptionDrug,
    prescriptionDrugMoop,
    indRxDedMet,
    indRxDedNotMet,
    famRxDedMet,
    famRxDedNotMet,
    oopMaxTooltip,
    pharmacyOutofPocMax,
    pharmacyNoMoopDeductable,
  } = props;

  const moopIndicator = JSON.parse(sessionStorage.getItem("isRxMoopIndicator"));

  console.log("moopIndicator",moopIndicator, moopIndicator===true)

  const dollarFormatter = v => v.toLocaleString('en-US', { style: 'currency', currency: 'USD' });

  return (
    <>
      {!isPlandetailSet ? (
        <>
          <Loader Blocking />
        </>
      ) : familybenefits ? (
        familybenefits.map(data => {
          console.log("pharmacyAccountSummary",data.pharmacyAccountSummary)
          if (data !== null && dependentSeqNbr === data.dependentSeqNbr) {
            return (
              <>
                <div>
                  {prescriptionDrug && prescriptionDrug.description && moopIndicator === true ?
                    <Typography.Paragraph id="markup-margin">
                      <Markup allowAttributes={true} content={prescriptionDrug.description} />
                    </Typography.Paragraph> :
                    <Typography.Paragraph>
                    <Markup allowAttributes={true} content={prescriptionDrugMoop?.description} />
                    </Typography.Paragraph>}
                </div>
                <div className="row" style={{ display: 'flex', padding: 0, margin: '-10px' }}>
                  <div className="col-xs-12 col-sm-12 flex-item">
                    <ul className="row">
                      {/*------------Individual deductible ----------- */}
                      <li className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                        <Accumulator
                          progressBarProps={{ style: { height: '4px' } }}
                          progressBarBackgroundProps={{
                            style: { height: '4px' },
                          }}
                          min={0}
                          max={
                            data.pharmacyAccountSummary
                              ? Number(data.pharmacyAccountSummary.indDeductibleMaxAmount)
                              : '0.00'
                          }
                          value={
                            data.pharmacyAccountSummary &&
                              data.pharmacyAccountSummary.indDedSpentAmount
                              ? Number(data.pharmacyAccountSummary.indDedSpentAmount)
                              : data.pharmacyAccountSummary &&
                                data.pharmacyAccountSummary.indDeductibleInitialAmount
                                ? Number(data.pharmacyAccountSummary.indDeductibleInitialAmount)
                                : '0.00'
                          }
                          labelFormatter={dollarFormatter}
                        >
                          <Typography.Paragraph style={{ fontSize: 14, margin: 0 }}>
                            {`${data.firstName}'s Prescription Drug Deductible`}
                          </Typography.Paragraph>
                          <Accumulator.Title style={{ marginTop: 8 }}>{`${dollarFormatter(
                            data.pharmacyAccountSummary &&
                              data.pharmacyAccountSummary.indDedSpentAmount
                              ? Number(data.pharmacyAccountSummary.indDedSpentAmount)
                              : data.pharmacyAccountSummary &&
                                data.pharmacyAccountSummary.indDeductibleInitialAmount
                                ? Number(data.pharmacyAccountSummary.indDeductibleInitialAmount)
                                : '0.00'
                          )}  Spent`}</Accumulator.Title>
                        </Accumulator>
                        {data.pharmacyAccountSummary && (
                          <div className="meet-dedctible-container">
                            {Number(data.pharmacyAccountSummary.indDedSpentAmount) >=
                              Number(data.pharmacyAccountSummary.indDeductibleMaxAmount) &&
                              data.pharmacyAccountSummary.indDeductibleMaxAmount !== '0.00' ? (
                                <div className="expandable-section not-met">
                                  <UtilityIcon icon={CheckCircle} />
                                  {indRxDedMet && indRxDedMet.title ? <span className="tds-header__h6 paragraph">
                                    {indRxDedMet.title}
                                  </span> :
                                    <span className="tds-header__h6 paragraph">
                                      {`You have met your prescription drug deductible`}
                                    </span>}
                                    <div
                                    onClick = {()=> 
                                      modalTrack("You have met your prescription drug deductible")}>
                                  <PopupTip icon="question" placement="top" iconVariant="outlined" pointer={parseInt(12, 3)}
                                  >
                                    {indRxDedMet && indRxDedMet.description ? (
                                      <Typography.Paragraph className="tds-margin-none">
                                        <Markup allowAttributes={true} content={indRxDedMet.description}></Markup>
                                      </Typography.Paragraph>
                                    ) : (
                                        <Typography.Paragraph className="tds-margin-none">
                                          This is the amount you must pay out of pocket each year for your covered medications filled at an in-network 
                                          pharmacy that are subject to the Annual Drug Deductible before your plan pays its share.
                                    </Typography.Paragraph>
                                      )}
                                  </PopupTip>
                                  </div>
                                </div>
                              ) : Number(data.pharmacyAccountSummary.indDedSpentAmount) <
                                Number(data.pharmacyAccountSummary.indDeductibleMaxAmount) &&
                                data.pharmacyAccountSummary.indDeductibleMaxAmount !== '0.00' ? (
                                  <div className="expandable-section not-met">
                                    <UtilityIcon icon={Error} className="blue-utility" />
                                    {indRxDedNotMet && indRxDedNotMet.title ? <span className="tds-header__h6 paragraph">
                                      {indRxDedNotMet.title}
                                    </span> :
                                      <span className="tds-header__h6 paragraph">
                                        {`You have not met your prescription drug deductible`}
                                      </span>}
                                      <div
                                      onClick = {()=> 
                                        modalTrack("You have not met your prescription drug deductible")}>
                                    <PopupTip
                                      icon="question"
                                      placement="top"
                                      iconVariant="outlined"
                                      pointer={parseInt(12, 3)}
                                    >
                                      {indRxDedNotMet && indRxDedNotMet.description && moopIndicator === true ? (
                                        <Typography.Paragraph className="tds-margin-none">
                                          <Markup allowAttributes={true} content={indRxDedNotMet.description}></Markup>
                                        </Typography.Paragraph>
                                      ) : (
                                          <Typography.Paragraph className="tds-margin-none">
                                          <Markup allowAttributes={true} content={pharmacyNoMoopDeductable.description}></Markup>
                                    </Typography.Paragraph>
                                        )}
                                    </PopupTip>
                                    </div>
                                  </div>
                                ) : null}
                          </div>
                        )}
                      </li>
                      {/*----------Family Deductible ------- */}
                      <li className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                        <Accumulator
                          progressBarProps={{ style: { height: '4px' } }}
                          progressBarBackgroundProps={{
                            style: { height: '4px' },
                          }}
                          min={0}
                          max={
                            data.pharmacyAccountSummary
                              ? Number(data.pharmacyAccountSummary.familyDeductibleMaxAmount)
                              : '0.00'
                          }
                          value={
                            data.pharmacyAccountSummary &&
                              data.pharmacyAccountSummary.familyDedSpentAmount
                              ? Number(data.pharmacyAccountSummary.familyDedSpentAmount)
                              : data.pharmacyAccountSummary &&
                                data.pharmacyAccountSummary.familyDeductibleInitialAmount
                                ? Number(data.pharmacyAccountSummary.familyDeductibleInitialAmount)
                                : '0.00'
                          }
                          labelFormatter={dollarFormatter}
                        >
                          <Typography.Paragraph style={{ fontSize: 14, margin: 0 }}>
                            {`Family Prescription Drug Deductible`}
                          </Typography.Paragraph>
                          <Accumulator.Title style={{ marginTop: 8 }}>{`${dollarFormatter(
                            data.pharmacyAccountSummary &&
                              data.pharmacyAccountSummary.familyDedSpentAmount
                              ? Number(data.pharmacyAccountSummary.familyDedSpentAmount)
                              : data.pharmacyAccountSummary &&
                                data.pharmacyAccountSummary.familyDeductibleInitialAmount
                                ? Number(data.pharmacyAccountSummary.familyDeductibleInitialAmount)
                                : '0.00'
                          )} Spent`}</Accumulator.Title>
                        </Accumulator>
                        {data.pharmacyAccountSummary && (
                          <div className="meet-dedctible-container">
                            {Number(data.pharmacyAccountSummary.familyDedSpentAmount) >=
                              Number(data.pharmacyAccountSummary.familyDeductibleMaxAmount) &&
                              data.pharmacyAccountSummary.familyDeductibleMaxAmount !== '0.00' ? (
                                <div className="expandable-section not-met">
                                  <UtilityIcon icon={CheckCircle} />
                                  {famRxDedMet && famRxDedMet.title ? <span className="tds-header__h6 paragraph">
                                    {famRxDedMet.title}
                                  </span> :
                                    <span className="tds-header__h6 paragraph">
                                      {`You have met your family prescription drug deductible`}
                                    </span>}
                                    <div
                                     onClick = {()=> 
                                      modalTrack("You have met your family prescription drug deductible")}>
                                  <PopupTip
                                    icon="question"
                                    placement="top"
                                    iconVariant="outlined"
                                    pointer={parseInt(pointer, 3)}
                                      >
                                    {famRxDedMet && famRxDedMet.description ? (
                                      <Typography.Paragraph className="tds-margin-none">
                                        <Markup allowAttributes={true} content={famRxDedMet.description}></Markup>
                                      </Typography.Paragraph>
                                    ) : (
                                      <Typography.Paragraph className="tds-margin-none">
                                          This is the amount you must pay out of pocket each year for your covered medications filled 
                                            at an in-network pharmacy that are subject to the Annual Drug Deductible before your plan pays its share.
                                    </Typography.Paragraph>
                                      )}
                                  </PopupTip>
                                  </div>
                                </div>
                              ) : Number(data.pharmacyAccountSummary.familyDedSpentAmount) <
                                Number(data.pharmacyAccountSummary.familyDeductibleMaxAmount) &&
                                data.pharmacyAccountSummary.familyDeductibleMaxAmount !== '0.00' ? (
                                  <div className="expandable-section not-met">
                                    <UtilityIcon icon={Error} className="blue-utility" />
                                    {famRxDedNotMet && famRxDedNotMet.title ? <span className="tds-header__h6 paragraph">
                                      {famRxDedNotMet.title}
                                    </span> :
                                      <span className="tds-header__h6 paragraph">
                                        {`You have not met your family prescription drug deductible`}
                                      </span>}
                                      <div
                                      onClick = {()=> 
                                        modalTrack("You have not met your family prescription drug deductible")}>
                                    <PopupTip
                                      icon="question"
                                      placement="top"
                                      iconVariant="outlined"
                                      pointer={parseInt(pointer, 3)}
                                    >
                                      {famRxDedNotMet && famRxDedNotMet.description ? (
                                        <Typography.Paragraph className="tds-margin-none">
                                          <Markup allowAttributes={true} content={famRxDedNotMet.description}></Markup>
                                        </Typography.Paragraph>
                                      ) : (
                                          <Typography.Paragraph className="tds-margin-none">
                                            This is the amount you must pay out of pocket each year for your covered medications filled 
                                            at an in-network pharmacy that are subject to the Annual Drug Deductible before your plan pays its share.
                                    </Typography.Paragraph>
                                        )}
                                    </PopupTip>
                                    </div>
                                  </div>
                                ) : null}
                          </div>
                        )}
                      </li>
                      {moopIndicator === true &&                  
                        <>
                      <div style={{ padding: '0 20px', width: '100%' }}>
                        { <Typography.H3 headingStyle={4} style={{ margin: '20px 0 10px 0' }}>
                          {`Out-of-pocket Max`}
                        </Typography.H3>}
                      </div>
                    
                      {/* ------------Individual Out-of-pocket Max ------- */}
                      <li className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                        <Accumulator
                          progressBarProps={{
                            style: { height: '4px' }, title: `${dollarFormatter(
                              data.pharmacyAccountSummary &&
                                data.pharmacyAccountSummary.indOopSpentAmount
                                ? Number(data.pharmacyAccountSummary.indOopSpentAmount)
                                : data.pharmacyAccountSummary &&
                                  data.pharmacyAccountSummary.indOopInitialAmount
                                  ? Number(data.pharmacyAccountSummary.indOopInitialAmount)
                                  : '0.00'
                            )} spent out of ${dollarFormatter(
                              data.pharmacyAccountSummary
                                ? Number(data.pharmacyAccountSummary.indOopMaxAmount)
                                : '0.00'
                            )}`
                          }}
                          progressBarBackgroundProps={{
                            style: { height: '4px' },
                          }}
                          min={0}
                          max={
                            data.pharmacyAccountSummary
                              ? Number(data.pharmacyAccountSummary.indOopMaxAmount)
                              : '0.00'
                          }
                          value={
                            data.pharmacyAccountSummary &&
                              data.pharmacyAccountSummary.indOopSpentAmount
                              ? Number(data.pharmacyAccountSummary.indOopSpentAmount)
                              : data.pharmacyAccountSummary &&
                                data.pharmacyAccountSummary.indOopInitialAmount
                                ? Number(data.pharmacyAccountSummary.indOopInitialAmount)
                                : '0.00'
                          }
                          labelFormatter={dollarFormatter}
                        >
                          <Typography.Paragraph style={{ fontSize: 14, margin: 0 }} id="ind-oop-max-title">
                            {`${data.firstName}'s Prescription Drug Out-of-pocket Max`}
                          </Typography.Paragraph>
                          <Accumulator.Title aria-hidden={true} style={{ marginTop: 8 }}>{`${dollarFormatter(
                            data.pharmacyAccountSummary &&
                              data.pharmacyAccountSummary.indOopSpentAmount
                              ? Number(data.pharmacyAccountSummary.indOopSpentAmount)
                              : data.pharmacyAccountSummary &&
                                data.pharmacyAccountSummary.indOopInitialAmount
                                ? Number(data.pharmacyAccountSummary.indOopInitialAmount)
                                : '0.00'
                          )} Spent`}</Accumulator.Title>
                        </Accumulator>
                        {data.pharmacyAccountSummary && (
                          <div className="meet-dedctible-container">
                            {Number(data.pharmacyAccountSummary.indOopSpentAmount) >=
                              Number(data.pharmacyAccountSummary.indOopMaxAmount) &&
                              data.pharmacyAccountSummary.indOopMaxAmount !== '0.00' ? (
                                <div className="expandable-section not-met">
                                  <UtilityIcon icon={CheckCircle} />
                                  <span className="tds-header__h6 paragraph">
                                    You have met your individual out-of-pocket max
                                </span>
                                <div
                                onClick = {()=> 
                                  modalTrack("You have met your individual out-of-pocket max")}>
                                  <PopupTip icon="question" placement="top"
                                    contentId="ind-oop-max-1"
                                    aria-label={pharmacyOutofPocMax && pharmacyOutofPocMax.description ? pharmacyOutofPocMax.description?.replace(/(<([^>]+)>)/gi, "") : "This is the most you pay for covered services from network providers during the year. Your deductible, copays and coinsurance for covered network services count toward this amount. After you reach your out-of-pocket maximum, the plan will pay 100% of your costs for the rest of the year. Plan premiums and costs for certain services do not count toward your out-of-pocket maximum amounts."}
                                  >
                                    {pharmacyOutofPocMax && pharmacyOutofPocMax.description ? (
                                      <Typography.Paragraph className="tds-margin-none">
                                        <Markup allowAttributes={true} content={pharmacyOutofPocMax.description}></Markup>
                                      </Typography.Paragraph>
                                    ) : (
                                        <Typography.Paragraph className="tds-margin-none">
                                          This is the most you pay for covered services from in-network providers during the year. Your deductible, copays and coinsurance for covered in-network services count toward this amount. Once you reach your maximum out-of-pocket, your plan will pay 100% of your costs for covered services for the rest of the year. Plan premiums and costs for certain services do not count toward your maximum out-of-pocket amounts.
                                    </Typography.Paragraph>
                                      )}
                                  </PopupTip>
                                </div>
                                </div>
                              ) :  (
                                  <div className="expandable-section not-met">
                                    <UtilityIcon icon={Error} className="blue-utility" />
                                    <span className="tds-header__h6 paragraph">
                                      You have not met your individual out-of-pocket max
                                </span>
                                    <div 
                                    onClick = {()=> 
                                      modalTrack("You have not met your individual out-of-pocket max")}>
                                    <PopupTip
                                      contentId="ind-oop-max-2"
                                      icon="question"
                                      placement="top"
                                      iconVariant="outlined"
                                      pointer={parseInt(pointer, 3)}
                                      aria-label={pharmacyOutofPocMax && pharmacyOutofPocMax.description ? pharmacyOutofPocMax.description ?.replace(/(<([^>]+)>)/gi, "") : "This is the most you pay for covered services from network providers during the year. Your deductible, copays and coinsurance for covered network services count toward this amount. After you reach your out-of-pocket maximum, the plan will pay 100% of your costs for the rest of the year. Plan premiums and costs for certain services do not count toward your out-of-pocket maximum amounts." }
                                    >
                                      {pharmacyOutofPocMax && pharmacyOutofPocMax.description ? (
                                        <Typography.Paragraph className="tds-margin-none">
                                          <Markup allowAttributes={true} content={pharmacyOutofPocMax.description}></Markup>
                                        </Typography.Paragraph>
                                      ) : (
                                          <Typography.Paragraph className="tds-margin-none">
                                            This is the most you pay for covered services from in-network providers during the year. Your deductible, copays and coinsurance for covered in-network services count toward this amount. Once you reach your maximum out-of-pocket, your plan will pay 100% of your costs for covered services for the rest of the year. Plan premiums and costs for certain services do not count toward your maximum out-of-pocket amounts.
                                    </Typography.Paragraph>
                                        )}
                                    </PopupTip>
                                  </div>
                                  </div>
                                ) }
                          </div>
                        )}
                      </li> 
                      {/*------------Family Out-of-pocket Max ------- */}
                     <li className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                        <Accumulator
                          progressBarProps={{
                            style: { height: '4px' }, title: `${dollarFormatter(
                              data.pharmacyAccountSummary &&
                                data.pharmacyAccountSummary.familyOopSpentAmount
                                ? Number(data.pharmacyAccountSummary.familyOopSpentAmount)
                                : data.pharmacyAccountSummary &&
                                  data.pharmacyAccountSummary.familyOopInitialAmount
                                  ? Number(data.pharmacyAccountSummary.familyOopInitialAmount)
                                  : '0.00'
                            )} spent out of ${dollarFormatter(
                              data.pharmacyAccountSummary
                                ? Number(data.pharmacyAccountSummary.familyOopMaxAmount)
                                : '0.00'
                            )}`
                          }}
                          progressBarBackgroundProps={{
                            style: { height: '4px' },
                          }}
                          min={0}
                          max={
                            data.pharmacyAccountSummary
                              ? Number(data.pharmacyAccountSummary.familyOopMaxAmount)
                              : '0.00'
                          }
                          value={
                            data.pharmacyAccountSummary &&
                              data.pharmacyAccountSummary.familyOopSpentAmount
                              ? Number(data.pharmacyAccountSummary.familyOopSpentAmount)
                              : data.pharmacyAccountSummary &&
                                data.pharmacyAccountSummary.familyOopInitialAmount
                                ? Number(data.pharmacyAccountSummary.familyOopInitialAmount)
                                : '0.00'
                          }
                          labelFormatter={dollarFormatter}
                        >
                          <Typography.Paragraph style={{ fontSize: 14, margin: 0 }}>
                            {`Family Prescription Drug Out-of-pocket Max`}
                          </Typography.Paragraph>
                          <Accumulator.Title aria-hidden={true} style={{ marginTop: 8 }}>{`${dollarFormatter(
                            data.pharmacyAccountSummary &&
                              data.pharmacyAccountSummary.familyOopSpentAmount
                              ? Number(data.pharmacyAccountSummary.familyOopSpentAmount)
                              : data.pharmacyAccountSummary &&
                                data.pharmacyAccountSummary.familyOopInitialAmount
                                ? Number(data.pharmacyAccountSummary.familyOopInitialAmount)
                                : '0.00'
                          )} Spent`}</Accumulator.Title>
                        </Accumulator>
                        {data.pharmacyAccountSummary && (
                          <div className="meet-dedctible-container">
                            {Number(data.pharmacyAccountSummary.familyOopSpentAmount) >=
                              Number(data.pharmacyAccountSummary.familyOopMaxAmount) &&
                              data.pharmacyAccountSummary.familyOopMaxAmount !== '0.00' ? (
                                <div className="expandable-section not-met">
                                  <UtilityIcon icon={CheckCircle} />
                                  <span className="tds-header__h6 paragraph">
                                    You have met your family out-of-pocket max
                                </span>
                                <div
                                onClick = {()=> 
                                  modalTrack("You have met your family out-of-pocket max")}>
                                  <PopupTip
                                    contentId="fmy-oop-max-1"
                                    icon="question"
                                    placement="top"
                                    iconVariant="outlined"
                                    pointer={parseInt(pointer, 3)}
                                    aria-label={pharmacyOutofPocMax && pharmacyOutofPocMax.description ? pharmacyOutofPocMax.description ?.replace(/(<([^>]+)>)/gi, "") : "This is the most you pay for covered services from network providers during the year. Your deductible, copays and coinsurance for covered network services count toward this amount. After you reach your out-of-pocket maximum, the plan will pay 100% of your costs for the rest of the year. Plan premiums and costs for certain services do not count toward your out-of-pocket maximum amounts."}
                                  >
                                    {pharmacyOutofPocMax && pharmacyOutofPocMax.description ? (
                                      <Typography.Paragraph className="tds-margin-none">
                                        <Markup allowAttributes={true} content={pharmacyOutofPocMax.description}></Markup>
                                      </Typography.Paragraph>
                                    ) : (
                                        <Typography.Paragraph className="tds-margin-none">
                                          This is the most you pay for covered services from in-network providers during the year. Your deductible, copays and coinsurance for covered in-network services count toward this amount. Once you reach your maximum out-of-pocket, your plan will pay 100% of your costs for covered services for the rest of the year. Plan premiums and costs for certain services do not count toward your maximum out-of-pocket amounts.
                                    </Typography.Paragraph>
                                      )}
                                  </PopupTip>
                                </div>
                                </div>
                              ) :  (
                                  <div className="expandable-section not-met">
                                    <UtilityIcon icon={Error} className="blue-utility" />
                                    <span className="tds-header__h6 paragraph">
                                      You have not met your family out-of-pocket max
                                </span>
                                <div
                                onClick = {()=> 
                                  modalTrack("You have not met your family out-of-pocket max")}>
                                    <PopupTip
                                      contentId="fmy-oop-max-2"
                                      icon="question"
                                      placement="top"
                                      iconVariant="outlined"
                                      pointer={parseInt(pointer, 3)}
                                      aria-label={pharmacyOutofPocMax && pharmacyOutofPocMax.description ? pharmacyOutofPocMax.description ?.replace(/(<([^>]+)>)/gi, "") : "This is the most you pay for covered services from network providers during the year. Your deductible, copays and coinsurance for covered network services count toward this amount. After you reach your out-of-pocket maximum, the plan will pay 100% of your costs for the rest of the year. Plan premiums and costs for certain services do not count toward your out-of-pocket maximum amounts."}
                                    >
                                      {pharmacyOutofPocMax && pharmacyOutofPocMax.description ? (
                                        <Typography.Paragraph className="tds-margin-none">
                                          <Markup allowAttributes={true} content={pharmacyOutofPocMax.description}></Markup>
                                        </Typography.Paragraph>
                                      ) : (
                                          <Typography.Paragraph className="tds-margin-none">
                                            This is the most you pay for covered services from in-network providers during the year. Your deductible, copays and coinsurance for covered in-network services count toward this amount. Once you reach your maximum out-of-pocket, your plan will pay 100% of your costs for covered services for the rest of the year. Plan premiums and costs for certain services do not count toward your maximum out-of-pocket amounts.
                                    </Typography.Paragraph>
                                        )}
                                    </PopupTip>
                                  </div>
                                  </div>
                                )}
                          </div>
                        )}
                      </li> 
                      </>}

                    </ul>
                  </div>
                </div>
              </>
            );
          } else if (data === null) {
            return apiFailErrorMsg && apiFailErrorMsg.title && apiFailErrorMsg.title[0] ? (
              <ApiCallError text={apiFailErrorMsg.title[0]} />
            ) : apiErrorMessage && apiErrorMessage.title && apiErrorMessage.title[0] ? (
              <ApiCallError text={apiErrorMessage.title[0]} />
            ) : (
                  <ApiCallError text="Sorry, we're experiencing technical difficulties. Please try again later." />
                );
          }
        })
      ) : null}
      {/* {hasError ? (
        <>
          apiFailErrorMsg && apiFailErrorMsg.title && apiFailErrorMsg.title[0] ?
          <ApiCallError text={apiFailErrorMsg.title[0]} />
          : apiErrorMessage && apiErrorMessage.title && apiErrorMessage.title[0] ?
          <ApiCallError text={apiErrorMessage.title[0]} />
          :{' '}
          <ApiCallError text="Sorry, we're experiencing technical difficulties. Please try again later." />
        </>
      ) : null} */}
    </>
  );
};

export default PharmacyClaimsAccount;

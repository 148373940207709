import React, { useState, useEffect } from "react"
import { Modal, Button } from "@uhc/pattern-library"
import config from "../../../../config"
import getMemberModalData from "../../../../hooks/getMemberModalData"
import clickTrack from "../../../../hooks/clickTrack"
import modalTrack from "../../../../hooks/modalTrack"
const { aem_content_url } = config

const MemberModal = props => {
   const { profile, handleView, dashboardMemberData, dashboardviewFor } = props

   const [open, setOpen] = useState(false)
   const [modalData, setModalData] = useState({
      isModalReady: false
   })
   const [consData, setConsData] = useState(null)
   useEffect(() => {
      getMemberModalData(profile, ({ loggedinMember, dependents } = {}) => {
         setModalData({
            loggedinMember,
            dependents,
            isModalReady: true
         })
      })
   }, [profile])

   useEffect(() => {
      !open && setConsData(null)
   },[open])
   const { isModalReady } = modalData

   const handleSubmit = () => {
      handleView(consData)
      setOpen(false)
   }

   const handleChange = (data) => {
      setConsData({
         relationship: data.relationship,
         name: data.firstName,
         dependentSeqNbr: data.dependentSeqNbr
      })
   }

   return (
      <>
         {dashboardMemberData && (
            <Button
               variant="text"
               color="primary"
               className="styled-button"
               style={{
                  fontWeight: 600,
                  fontSize: 36,
                  fontFamily: "UHC Serif Semibold,Georgia Bold,serif",
                  backgroundColor: 'rgb(251, 252, 254)',
                  padding: 0,
                  verticalAlign: 'baseline',
                  borderRadius: 0,
                  // marginTop: -30,
                  // height: 0,
               }}
               onClick={() => {setOpen(true); 
               modalTrack("View Home Page for :")}}
            >
               <span style={{ borderBottom: '1px solid #196ecf' }}>{dashboardviewFor.name}</span>
               <img
                  src={`${aem_content_url}/content/dam/myuhc/consumer/assets/myuhc/expand-icon.svg`}
                  alt="expand"
               />
            </Button>
         )}

         {isModalReady && open && (
            <Modal
               style={{ maxWidth: 768 }}
               handleClose={() => {
                  setOpen(false)
                  // window.location.reload()
               }}
               onCloseTrackingData={{
                  clickName: "Closing Modal",
                  clickLocation: "Dashboard"
               }}
            >

               <Modal.Header>
                  <Modal.Title>
                     <legend>View Homepage For:</legend>
                  </Modal.Title>
               </Modal.Header>
               <Modal.Content className="styled-content" style={{ minHeight: 100 }}>
                  <fieldset>
                     <ul className="container fade-in" >
                        {dashboardMemberData &&
                           dashboardMemberData.map((data, i) => {
                              const isChecked =  consData ? consData.dependentSeqNbr === data.dependentSeqNbr
                                 : dashboardviewFor.dependentSeqNbr === data.dependentSeqNbr
                              return (
                                 <li 
                                 className="account-selector" key={'homePageFor_'+i} >
                                    <input
                                       id={`${i}-name`}
                                       type="radio"
                                       name="consumer"
                                       value={data.firstName}
                                       aria-checked={isChecked}
                                       onChange={() => {handleChange(data); clickTrack(`Member ${i+1}`, "main-body", "text", "internal", "")}}
                                       checked={isChecked}
                                    />
                                    <label htmlFor={`${i}-name`}>
                                       <div className="member">
                                          <span className="member-name">
                                             {data.firstName}
                                          </span>
                                          <span
                                             className="member-info"
                                             style={{ display: "block" }}
                                          >
                                             {data.relationship} - DOB{" "}
                                             {data.dobFormatted}

                                          </span>
                                       </div>
                                    </label>
                                 </li>
                              )
                           })}
                     </ul>
                  </fieldset>
                  <Button
                     disabled={!consData}
                     onClick={() => {handleSubmit(); clickTrack("Submit ", "main-body", "button", "internal", "")}}
                  >
                     Submit
                  </Button>
               </Modal.Content>
            </Modal>
         )}
      </>
   )
}

export default MemberModal
const ReferralsFormatter = (data) => {
    const { memberReferrals } = data
    let referralsRecord
    let referralsData = []

    const response = memberReferrals && memberReferrals.filter(
        data => data && data.dependentSeqNbr ? data.dependentSeqNbr : ''
    )
    for (const data of response) {
        const dependentSeqNbr = data && data['dependentSeqNbr'] ? data.dependentSeqNbr : null
        const noOfReferrals = data && data['listOfReferrals'] ? data.listOfReferrals.length : null
        const referralsError = data && data['responseError'] ? data.responseError : null

        referralsRecord = {
            dependentSeqNbr,
            noOfReferrals,
            referralsError
        }
        referralsData.push(referralsRecord);
    }

    // console.log("Referral data", referralsData)
    return { referralsData }

}

export default ReferralsFormatter

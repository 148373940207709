import React, { useContext } from 'react'
import './components.css'
import { ProfileContext } from "../../../contexts/profileContext"

const Section = ({ children }) => {
  const { profile } = useContext(ProfileContext) || {}
  const { loggedinMember } = profile || {}
  const { coverages } = loggedinMember || {}
  const { medical } = coverages || {}
  const { coverageStatus } = medical || {}
  return (
    <section className={coverageStatus === 'pre-effective' ? 'payments-top' : 'section-container'}>
      <div className="section-div">{children}</div>
    </section>
  )
}
export default Section

import config from "../config"

const { aem_content_url } = config
const ContentEndpoints = {
  DASHBOARD_CONTENT: `${aem_content_url}/content/myuhc/consumer/dashboard/home/jcr:content/root/responsivegrid.model.json`,
  DASHBOARDI28_CONTENT: `${aem_content_url}/content/myuhc/consumer/dashboard/i28home/jcr:content/root/responsivegrid.model.json`,
  DASHBOARDI28TERMED_CONTENT: `${aem_content_url}/content/myuhc/consumer/dashboard/termedi28home/jcr:content/root/responsivegrid.model.json`,
  GLOBAL_API_CONSTANTS: `${aem_content_url}/content/myuhc/consumer/global-api/jcr:content/root/responsivegrid.model.json`,
  HMOTIERPLAN_CONTENT: `${aem_content_url}/content/myuhc/consumer/dashboard/hmoTierPlan/jcr:content/root/responsivegrid.model.json`,
  PHARMACYPLAN_CONTENT: `${aem_content_url}/content/myuhc/consumer/dashboard/rxPlan/jcr:content/root/responsivegrid.model.json`
}

export default ContentEndpoints

import { useContext } from "react";
import GetAge from '../../../../../tools/getAge';
import { ProfileContext } from "../../../../../contexts/profileContext";
import getPlanYear from '../../../../../tools/getPlanYear';

const VirtualCareQuery = () => {
  const { profile } = useContext(ProfileContext) || {};
  const { loggedinMember } = profile || {};
  const { coverages,dobFormatted } = loggedinMember || {};
  const { medical } = coverages || {};
  const { situsState, reportingCode, coverageStatus, startDate } = medical || {}

  const planYear = getPlanYear(startDate)
  const age = GetAge(dobFormatted)
  const searchPathByList = `/content/dam/iex/${planYear}/effective/dashboard/virtual-care`
  const searchPathByPath = `/content/dam/iex/${planYear}/effective/dashboard/virtual-care/gated`
  const requestParameters = `;searchPathByList=${searchPathByList};searchPathByPath=${searchPathByPath};situsState=${situsState};reportingCode=${reportingCode};age=${age}`
  const requestPath = `/myuhc/graphql/execute.json/IEX/dashboardVirtualCareQuery.json${encodeURIComponent(requestParameters)}`
  return requestPath;
};
export default VirtualCareQuery;

import React from 'react'
import { Card, Flex } from '@uhc/pattern-library'
import { Typography, BrandIcon as Icon, Link } from '@uhc-tempo/components'
import config from '../../../../../config'
import styled from 'styled-components'
import clickTrack from '../../../../../hooks/clickTrack'
import styles from './rewards.module.css';
const { aem_content_url } = config
const Mobile = styled.div`
  @media (min-width: 991px) {
    display: none;
  }
`
const PlanInfoMobile = (props) => {
    const { planInfo, findProvider, viewBenefits, clickvb, goPaperless, clickgp,
        medicalStartDate, providerUrl, planInfoTermed, reviewDocuments, makePayment,
        clickrd, clickmp, medicalEndDate, dashboardviewFor, coverageStatus, isGalileo, isSubscriber, recurringObj, isNMuser } = props
    const domain = window.location.origin

    return (
        <>
            <Mobile>
                <Card>
                    <Card.Content>
                        {planInfo && planInfo.question ? <Typography.Paragraph style={{ fontWeight: 600 }}>
                            {planInfo.question}
                        </Typography.Paragraph> :
                            planInfoTermed && planInfoTermed.question ? <Typography.Paragraph style={{ fontWeight: 600 }}>
                                {planInfoTermed.question}
                            </Typography.Paragraph>
                                : null}

                        {coverageStatus === 'pre-effective' ? <Typography.H2 style={{ color: 'rgb(0, 38, 119)', margin: '-15px 0 -5px 0' }}>
                            {medicalStartDate}.
                                </Typography.H2> :
                            coverageStatus === 'termed' ? <Typography.H2 style={{ color: 'rgb(0, 38, 119)', margin: '-15px 0 -5px 0' }}>
                                {medicalEndDate}.
                                </Typography.H2> :
                                null}

                        {planInfo && planInfo.answer ? <Typography.Paragraph>
                            {planInfo.answer.replace(/(<([^>]+)>)/gi, "")}
                        </Typography.Paragraph> :
                            planInfoTermed && planInfoTermed.answer ? <Typography.Paragraph>
                                {planInfoTermed.answer.replace(/(<([^>]+)>)/gi, "")}
                            </Typography.Paragraph>
                                : null}

                        {/* Pre-effective Member starts */}

                        {!isGalileo && findProvider && findProvider.bodyTitle ? <Flex alignItems="center">
                            <Flex>
                                {findProvider.imgPath ?
                                    <img src={`${aem_content_url}${findProvider.imgPath}`} alt=''
                                        style={{ width: 48, height: 48, margin: 'auto' }} /> :
                                    <Icon className="uhc-tempo-icon--md" name="icon_prov_2c_rgb" />}
                            </Flex>
                            <Flex alignItems="center" flex={2}
                                style={{ padding: '20px 10px', borderBottom: '1px solid #E0E0E0', justifyContent: 'space-between' }}>
                                <Link noIcon={true} href="#" 
                                id="find-provider"
                                onClick={()=> {providerUrl();
                                    clickTrack(findProvider.bodyTitle, "main-body", "text", 'internal', "")}}
                                    >
                                    {findProvider.bodyTitle}
                                </Link>
                                <img src={`${aem_content_url}/content/dam/myuhc/consumer/assets/myuhc/next-arrow.svg`} alt='' />
                            </Flex>
                        </Flex> : null}

                        {isGalileo && isSubscriber && !recurringObj && !isNMuser ? <Flex alignItems="center">
                            <Flex>
                                <img src={`${aem_content_url}/content/dam/iex/images/autopay-icon.svg`} alt='' style={{ width: 48, height: 48, margin: 'auto' }} />
                            </Flex>
                            <Flex alignItems="center" flex={2}
                                style={{ padding: '20px 10px', borderBottom: '1px solid #E0E0E0', justifyContent: 'space-between' }}>
                                <Link noIcon={true} href='/ifp/payment#/setup-recurring' id="setup-autopay"
                                className={styles.setUpBtn}
                                onClick={()=> {
                                    clickTrack("Set up auto-pay", "main-body", "text", 'internal', domain + "/ifp/payment#/setup-recurring")}}
                                >
                                    Set up auto-pay
                                </Link>
                                <img src={`${aem_content_url}/content/dam/myuhc/consumer/assets/myuhc/next-arrow.svg`} alt='' />
                            </Flex>
                        </Flex> : null}


                        {viewBenefits && viewBenefits.bodyTitle ? <Flex alignItems="center">
                            <Flex>
                                {viewBenefits.imgPath ?
                                    <img src={`${aem_content_url}${viewBenefits.imgPath}`} alt=''
                                        style={{ width: 48, height: 48, margin: 'auto' }} /> :
                                    <Icon className="uhc-tempo-icon--md" name="icon_heart_2c_rgb" />}
                            </Flex>
                            <Flex alignItems="center" flex={2}
                                style={{ padding: ' 20px 10px', borderBottom: '1px solid #E0E0E0', justifyContent: 'space-between' }}>
                                <Link noIcon={true} href="#" id="view-benefits"
                                onClick={()=> {clickvb();
                                    clickTrack(viewBenefits.bodyTitle, "main-body", "text", 'internal', "")}}>
                                    {viewBenefits.bodyTitle}
                                </Link>
                                <img src={`${aem_content_url}/content/dam/myuhc/consumer/assets/myuhc/next-arrow.svg`} alt='' />
                            </Flex>
                        </Flex> : null}

                        {goPaperless && goPaperless.bodyTitle ? <Flex alignItems="center">
                            <Flex>
                                {goPaperless.imgPath ?
                                    <img src={`${aem_content_url}${goPaperless.imgPath}`} alt=''
                                        style={{ width: 48, height: 48, margin: 'auto' }} /> :
                                    <Icon className="uhc-tempo-icon--md" name="icon_gogrn_2c_rgb" />}
                            </Flex>

                            <Flex alignItems="center" flex={2}
                                style={{ padding: ' 20px 10px', justifyContent: 'space-between' }}>
                                <Link noIcon={true} href="#" id="go-paperless"
                                onClick={()=> {clickgp();
                                    clickTrack(goPaperless.bodyTitle, "main-body", "text", 'internal', "")}}>
                                    {goPaperless.bodyTitle}
                                </Link>
                                <img src={`${aem_content_url}/content/dam/myuhc/consumer/assets/myuhc/next-arrow.svg`} alt='' />
                            </Flex>
                        </Flex> : null}

                        {/* Termed Member starts */}

                        {reviewDocuments && reviewDocuments.bodyTitle ? <Flex alignItems="center">
                            <Flex>
                                {reviewDocuments.imgPath ?
                                    <img src={`${aem_content_url}${reviewDocuments.imgPath}`} alt=''
                                        style={{ width: 48, height: 48, margin: 'auto' }} /> :
                                    <Icon className="uhc-tempo-icon--md" name="icon_prov_2c_rgb" />}
                            </Flex>
                            <Flex alignItems="center" flex={2}
                                style={{ padding: '20px 10px', borderBottom: '1px solid #E0E0E0', justifyContent: 'space-between' }}>
                                <Link noIcon={true} href="#" id="find-provider"
                                onClick={()=> {clickrd();
                                    clickTrack(reviewDocuments.bodyTitle, "main-body", "text", 'internal', "")}}
                                >
                                    {reviewDocuments.bodyTitle}
                                </Link>
                                <img src={`${aem_content_url}/content/dam/myuhc/consumer/assets/myuhc/next-arrow.svg`} alt='' />
                            </Flex>
                        </Flex> : null}

                        {dashboardviewFor.relationship === "SUBSCRIBER" && makePayment && makePayment.bodyTitle ?
                            <Flex alignItems="center">
                                <Flex>
                                    {makePayment.imgPath ?
                                        <img src={`${aem_content_url}${makePayment.imgPath}`} alt=''
                                            style={{ width: 48, height: 48, margin: 'auto' }} /> :
                                        <Icon className="uhc-tempo-icon--md" name="icon_gogrn_2c_rgb" />}
                                </Flex>

                                <Flex alignItems="center" flex={2}
                                    style={{ padding: ' 20px 10px', justifyContent: 'space-between' }}>
                                    <Link noIcon={true} href="#" id="go-paperless"
                                    onClick={()=> {clickmp();
                                        clickTrack(makePayment.bodyTitle, "main-body", "text", 'internal', "")}}
                                    >
                                        {makePayment.bodyTitle}
                                    </Link>
                                    <img src={`${aem_content_url}/content/dam/myuhc/consumer/assets/myuhc/next-arrow.svg`} alt='' />
                                </Flex>
                            </Flex> : null}

                    </Card.Content>
                </Card>
            </Mobile>
        </>
    )
}

export default PlanInfoMobile

import config from "../config"
import Axios from "axios"

const { logger_url } = config
const getCookie = cookie => {
  if (document.cookie) {
     const userInfo = document.cookie
        .split(";")
        .find(list => list.indexOf(cookie) > -1)
     return userInfo ? userInfo.split("=")[1] : null
  }
}
const error = (msg) => {
  if(getCookie("M_U_I")){
    msg.userInfo = getCookie("M_U_I")
    }
    msg.microApp = "dashboard"
    msg.timeStamp = new Date()
    Axios.post(`${logger_url}/events/error`,msg)
      .then(res => {
        //console.log(res)
      })
}

export default error
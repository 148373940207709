
import React, { useState, useEffect } from "react";
import { Typography, Button, UtilityIcon } from "@uhc-tempo/components";
import styles from './promoTile.module.css';
import UseGraphqlApi from "../../../../../hooks/useGraphqlApi";
import VirtualCareQuery from "./virtualCareQuery";
import { Markup } from 'interweave';
import getFilteredContentList from "../../../../../tools/getFilteredContentList";
import Config from "../../../../../config";
import clickTrack from "../../../../../hooks/clickTrack";

const { legacy_en_url, rally_url, rally_vc_url, aem_content_url } = Config || {}

const VirtualCare = (props) => {
    const [loading, setLoading] = useState(true);
    const [virtualCareError, setVirtualCareError] = useState(false);
    const [virtualCareState, setVirtualCareState] = useState({});
    const domain = window.location.origin + window.location.pathname
    const getVirtualCareData = VirtualCareQuery();
    useEffect(() => {
        UseGraphqlApi(getVirtualCareData, virtualQueryHandler);
    }, []);

    const virtualQueryHandler = (response) => {
        if (response && !response.isError) {
            if (response.data) {
                if (response.data.listTileList.items.length > 0) {
                    const listWithDates = getFilteredContentList(response.data ?.listTileList ?.items)
                    if (listWithDates.length > 0) {
                        setVirtualCareState(
                            listWithDates[0]
                        );
                    }
                    else {
                        setVirtualCareState(
                            response.data.listTileByPath && response.data.listTileByPath.item ?
                                response.data.listTileByPath.item : ''
                        )
                    }
                }
                else if (response.data.listTileByPath.item) {
                    setVirtualCareState(
                        response.data.listTileByPath && response.data.listTileByPath.item ?
                            response.data.listTileByPath.item : ''
                    )
                }
            }
        } else {
            setVirtualCareError(true);
        }
        setLoading(false);
    };

    const { title, subTitle, description, image, bodySubtext, linkText, url, urlType, linkText2 } = virtualCareState || {}
    let newUrl = ''

    function renderUrl(urlType) {
        if (urlType === "rallyUrl") {
            newUrl = `${rally_url}` + url
        }
        else if (urlType === "rallySSO") {
            newUrl = `${rally_vc_url}` + url
        }
        else if (urlType === "pageUrl" || urlType === "default") {
            newUrl = url
        }
        else if (urlType === "legacyUrl") {
            newUrl = `${legacy_en_url}` + url
        }
        return newUrl
    }

    return (
        <>
            <div className={styles.rightCol} style={{ visibility: 'visible' }}>
                <div className="tempo-v1">
                    <Typography.H2>{title ? title : "24/7 Virtual urgent care"}</Typography.H2>
                    <Typography.H4>{subTitle ? subTitle : null}</Typography.H4>
                </div>

                <div className={styles.care}>
                    <div className={styles.imageContainer}>
                        <div className={styles.imagePhone}>
                            {image && image._path &&
                                <img src={`${aem_content_url}${image._path}`}
                                    alt="" />}
                        </div>
                    </div>
                    <div className={styles.urgentCareCol}>
                        <Typography.Paragraph>
                            <Markup allowAttributes={true} content={description && description.html ? description.html : "Get the care you need, on the go or from home, with virtual visits."} />
                        </Typography.Paragraph>
                        <Typography.Paragraph useBold style={{ margin: 0, color: '#333' }}>
                            {linkText2 ? linkText2 : null}
                        </Typography.Paragraph>
                        <ul className={styles.discList}>
                            {bodySubtext && bodySubtext.map((data, i) => {
                                if (data !== null) {
                                    return (
                                        <>
                                            <li key={i}>{data}</li>
                                        </>
                                    )
                                }
                                return null;
                            })}
                        </ul>
                        <div className={styles.buttonContainer}>
                            <Button id="get-care"
                                buttonType="primary-one"
                                href={(renderUrl(urlType))}
                                className={styles.getCareBtn}
                                onClick={()=> clickTrack(linkText ? linkText : null, "main-body", "button", "internal", domain + renderUrl(urlType))}
                                >
                                {linkText ? linkText : null}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VirtualCare;
import React, { useContext, useState, useEffect } from 'react'
import { Typography, Link, Tab, Card } from '@uhc-tempo/components'
import Section from '../../../../../shared-components/reusableComponents/section'
import MedicalClaimAccount from './medicalClaimAccount'
import DentalClaimAccount from './dentalClaimAccount'
import { PlanDetailContext } from '../../../../../../contexts/planDetailContext'
import '../../../index.css'
import ApiCallError from '../../../components/apiCallError'
import { Markup } from "interweave"
import PharmacyClaimsAccount from './pharmacyClaimsAccount'
import Constants from "../../../../../../constants/contentEndpoints";
import UseContentFragments from "../../../../../../hooks/contentfragment/useContentFragments"
import clickTrack from '../../../../../../hooks/clickTrack'

const ClaimsAccounts = (props) => {
  const { dependentSeqNbr, apiFailErrorMsg, apiErrorMessage, waState,
    claimsTitle, viewClaimsLink, medicalTab, indDedMet, indDedNotMet,
    familyDedMet, familyDedNotMet, oopMaxTooltip, adultDental, annualMaxMet, annualMaxApproach,
    annualMaxNotMet, accumulatorMsg, suppressClaimsAccumulators } = props

  const [rxPlan, setRxPlan] = useState({ hasLoaded: false });
  const rxPlan_endpoint = Constants.PHARMACYPLAN_CONTENT;
  const domain = window.location.origin + window.location.pathname

  useEffect(() => {
    UseContentFragments(rxPlan_endpoint, (response) => {
      try {
        if (response.APIError) {
          console.log("error");
        } else {
          const data = response.data.data;
          // console.log(data)
          if (data) {
            setRxPlan({
              prescriptionDrug: data["prescriptionDrug"],
              prescriptionDrugMoop: data["prescriptionDrugMoop"],
              indRxDedMet: data["indRxDedMet"],
              indRxDedNotMet: data["indRxDedNotMet"],
              famRxDedMet: data["famRxDedMet"],
              famRxDedNotMet: data["famRxDedNotMet"],
              oopRxMaxTooltip: data["outofPocMaxTitle"],
              pharmacyOutofPocMax: data["pharmacyOutofPocMax"],
              pharmacyNoMoopDeductable: data["pharmacyNoMoopDeductable"],
              pharmacyAndMedicalOopToolTip: data["pharmacyAndMedicalOopToolTip"],
              hasLoaded: true,
            });
          }
        }
      } catch (error) {
        console.log("Error while calling RX content fragment");
      }
    });
  }, [rxPlan_endpoint]);

  const { prescriptionDrug, prescriptionDrugMoop, indRxDedMet, indRxDedNotMet, famRxDedMet, famRxDedNotMet, oopRxMaxTooltip, pharmacyOutofPocMax, pharmacyAndMedicalOopToolTip, pharmacyNoMoopDeductable  } = rxPlan || '';


  const { plandetail } = useContext(PlanDetailContext) || {}
  const { familybenefits, isPlandetailSet, hasError } = plandetail || {}
  const currentDate = new Date();
  let date = currentDate.getDate();
  let month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();
  month = month > 9 ? month : "0" + month;
  date = date > 9 ? date : "0" + date;
  const asOfDate = month + "/" + date + "/" + year;

  const asofDateText = claimsTitle && claimsTitle.subTitle.length ? `${claimsTitle.subTitle[1]} ${asOfDate}` : ''
  const medTab = medicalTab && medicalTab.title ? medicalTab.title : "Medical"
  const denTab = adultDental && adultDental.title ? adultDental.title : "Adult Dental"
  let pharmTab = prescriptionDrug && prescriptionDrug.title ? prescriptionDrug.title : 'Prescription Drugs'

  let loggedinMemberAcc = {};

  familybenefits && familybenefits.forEach(data => {
    if (data.dependentSeqNbr === dependentSeqNbr) {
      loggedinMemberAcc = data.pharmacyAccountSummary;
    }
  });

  const pharmacyAccountSummary = {
    ...loggedinMemberAcc,
  };

  const hasPharmacyAcc = !!Object.keys(pharmacyAccountSummary).length;
  sessionStorage.setItem("isRxAccount", JSON.stringify(hasPharmacyAcc));
  const isRxDualDeductible = (JSON.parse(sessionStorage.getItem("isRxDualDeductible")));
  const isRxTab = hasPharmacyAcc && isRxDualDeductible && rxPlan?.hasLoaded

  console.log("hasPharmacyAcc:",hasPharmacyAcc, 'isRxTab:',isRxTab)


  if(document.getElementById("tab_004_tab-list")){
    document.getElementById("tab_004_tab-list").getAttributeNode("aria-label").nodeValue = "Claims and accounts summary"
  }else if(document.getElementById("tab_006_tab-list")){
    document.getElementById("tab_006_tab-list").getAttributeNode("aria-label").nodeValue = "Claims and accounts summary"
  }
  

  if(document.getElementById("tab_006_Medical-tab-panel")){
    document.getElementById("tab_006_Medical-tab-panel").removeAttribute("tabindex")
  }else if(document.getElementById("tab_004_Medical-tab-panel")){
    document.getElementById("tab_004_Medical-tab-panel").removeAttribute("tabindex")
  }


  return (
    <div
      className="claims"
      style={{ backgroundColor: 'rgb(251, 252, 254)', borderTop: '1px solid rgb(224, 224, 224)' }}
    >
      <Section>
          <div className="claims-heading tempo-v1" style={{ marginTop: 20 }}>
          {claimsTitle && claimsTitle.subTitle.length ?
            <Typography.H2 style={{ color: 'rgb(0, 38, 119)', margin: 0, fontSize: '1.5rem' }}>
              <Markup content={claimsTitle.subTitle[0]} />
            </Typography.H2> :
            <Typography.H2 style={{ color: 'rgb(0, 38, 119)', margin: 0, fontSize: '1.5rem' }}>
              Claims & Accounts Summary</Typography.H2>}
          {viewClaimsLink && viewClaimsLink.title ?
            <Link href={viewClaimsLink.href && viewClaimsLink.href} text={viewClaimsLink.title} id="view-claims-accounts" 
            onClick={()=>
              clickTrack(viewClaimsLink.title, "main-body", "text", 'internal', domain + viewClaimsLink.href)}/> :
            <Link href="/ifp/claims" text="View all claims & accounts" id="view-claims-accounts" 
            onClick={()=>
              clickTrack("View all claims & accounts", "main-body", "text", 'internal', window.location.origin + "/ifp/claims")}
            />}
        </div>
        {suppressClaimsAccumulators ? null :
        (<>
        {asofDateText ?
          <Typography.Paragraph style={{ marginTop: 0, fontSize: 16 }}>
            <Markup content={asofDateText} />
          </Typography.Paragraph> :
          <Typography.Paragraph style={{ marginTop: 0, fontSize: 16 }}>
            In - Network Account - As of {asOfDate}
          </Typography.Paragraph>
        }
        {!hasError ?
          <div style={{ margin: '20px 0px' }}>
            <Card border
              layout="custom">

              {!waState ? familybenefits && familybenefits.map(el => {
                return el.hasOwnProperty('dentalAccountSummary') && Object.keys(el.dentalAccountSummary).length !== 0 &&
                  el.hasOwnProperty('pediatric') && !el.pediatric && (el.dependentSeqNbr === dependentSeqNbr) ?
                  <Tab tabHeadings={isRxTab ? [`${medTab}`, `${pharmTab}`, `${denTab}`] : [`${medTab}`, `${denTab}`]}>
                    <Tab.TabPanel>
                      <MedicalClaimAccount
                        dependentSeqNbr={dependentSeqNbr}
                        familybenefits={familybenefits}
                        isPlandetailSet={isPlandetailSet}
                        hasError={hasError}
                        medicalTab={medicalTab}
                        indDedMet={indDedMet}
                        indDedNotMet={indDedNotMet}
                        familyDedMet={familyDedMet}
                        familyDedNotMet={familyDedNotMet}
                        oopMaxTooltip={oopMaxTooltip} 
                        oopRxMaxTooltip={oopRxMaxTooltip}
                        pharmacyAndMedicalOopToolTip={pharmacyAndMedicalOopToolTip}
                        />
                        
                    </Tab.TabPanel>
                    {isRxTab &&
                      < Tab.TabPanel >
                        <PharmacyClaimsAccount
                          dependentSeqNbr={dependentSeqNbr}
                          familybenefits={familybenefits}
                          isPlandetailSet={isPlandetailSet}
                          hasError={hasError}
                          prescriptionDrug={prescriptionDrug}
                          prescriptionDrugMoop={prescriptionDrugMoop}
                          indRxDedMet={indRxDedMet}
                          indRxDedNotMet={indRxDedNotMet}
                          famRxDedMet={famRxDedMet}
                          famRxDedNotMet={famRxDedNotMet} 
                          oopMaxTooltip={oopRxMaxTooltip}
                          pharmacyOutofPocMax={pharmacyOutofPocMax}
                          pharmacyNoMoopDeductable={pharmacyNoMoopDeductable}
                          />
                      </Tab.TabPanel>}
                    < Tab.TabPanel >
                      <DentalClaimAccount
                        dependentSeqNbr={dependentSeqNbr}
                        familybenefits={familybenefits}
                        isPlandetailSet={isPlandetailSet}
                        hasError={hasError}
                        adultDental={adultDental}
                        annualMaxMet={annualMaxMet}
                        annualMaxApproach={annualMaxApproach}
                        annualMaxNotMet={annualMaxNotMet}
                        accumulatorMsg={accumulatorMsg} />
                    </Tab.TabPanel>
                  </Tab> : (el.pediatric && (el.dependentSeqNbr === dependentSeqNbr)) ?
                    <Tab tabHeadings={isRxTab ? [`${medTab}`, `${pharmTab}`] : [`${medTab}`]}>
                      <Tab.TabPanel>
                        <MedicalClaimAccount
                          dependentSeqNbr={dependentSeqNbr}
                          familybenefits={familybenefits}
                          isPlandetailSet={isPlandetailSet}
                          hasError={hasError}
                          medicalTab={medicalTab}
                          indDedMet={indDedMet}
                          indDedNotMet={indDedNotMet}
                          familyDedMet={familyDedMet}
                          familyDedNotMet={familyDedNotMet}
                          oopMaxTooltip={oopMaxTooltip}
                          oopRxMaxTooltip={oopRxMaxTooltip}
                          pharmacyAndMedicalOopToolTip={pharmacyAndMedicalOopToolTip} />
                      </Tab.TabPanel>
                      {isRxTab && < Tab.TabPanel >
                        <PharmacyClaimsAccount
                          dependentSeqNbr={dependentSeqNbr}
                          familybenefits={familybenefits}
                          isPlandetailSet={isPlandetailSet}
                          hasError={hasError}
                          prescriptionDrug={prescriptionDrug}
                          prescriptionDrugMoop={prescriptionDrugMoop}
                          indRxDedMet={indRxDedMet}
                          indRxDedNotMet={indRxDedNotMet}
                          famRxDedMet={famRxDedMet}
                          famRxDedNotMet={famRxDedNotMet}
                          oopMaxTooltip={oopRxMaxTooltip}
                          pharmacyOutofPocMax={pharmacyOutofPocMax} 
                          pharmacyNoMoopDeductable={pharmacyNoMoopDeductable}
                          />
                      </Tab.TabPanel>}
                    </Tab> : el.dentalAccountSummary && Object.keys(el.dentalAccountSummary).length === 0 &&
                      (el.dependentSeqNbr === dependentSeqNbr) ?
                      <Tab tabHeadings={isRxTab ? [`${medTab}`, `${pharmTab}`] : [`${medTab}`]}>
                        <Tab.TabPanel>
                          <MedicalClaimAccount
                            dependentSeqNbr={dependentSeqNbr}
                            familybenefits={familybenefits}
                            isPlandetailSet={isPlandetailSet}
                            hasError={hasError}
                            medicalTab={medicalTab}
                            indDedMet={indDedMet}
                            indDedNotMet={indDedNotMet}
                            familyDedMet={familyDedMet}
                            familyDedNotMet={familyDedNotMet}
                            oopMaxTooltip={oopMaxTooltip}
                            oopRxMaxTooltip={oopRxMaxTooltip}
                            pharmacyAndMedicalOopToolTip={pharmacyAndMedicalOopToolTip} />
                        </Tab.TabPanel>
                        {isRxTab && < Tab.TabPanel >
                          <PharmacyClaimsAccount
                            dependentSeqNbr={dependentSeqNbr}
                            familybenefits={familybenefits}
                            isPlandetailSet={isPlandetailSet}
                            hasError={hasError}
                            prescriptionDrug={prescriptionDrug}
                            prescriptionDrugMoop={prescriptionDrugMoop}
                            indRxDedMet={indRxDedMet}
                            indRxDedNotMet={indRxDedNotMet}
                            famRxDedMet={famRxDedMet}
                            famRxDedNotMet={famRxDedNotMet}
                            oopMaxTooltip={oopRxMaxTooltip}
                            pharmacyOutofPocMax={pharmacyOutofPocMax}
                            pharmacyNoMoopDeductable={pharmacyNoMoopDeductable} />
                        </Tab.TabPanel>}
                      </Tab> : null
              })
                : <Tab tabHeadings={isRxTab ? [`${medTab}`, `${pharmTab}`] : [`${medTab}`]}>
                  <Tab.TabPanel>
                    <MedicalClaimAccount
                      dependentSeqNbr={dependentSeqNbr}
                      familybenefits={familybenefits}
                      isPlandetailSet={isPlandetailSet}
                      hasError={hasError}
                      medicalTab={medicalTab}
                      indDedMet={indDedMet}
                      indDedNotMet={indDedNotMet}
                      familyDedMet={familyDedMet}
                      familyDedNotMet={familyDedNotMet}
                      oopMaxTooltip={oopMaxTooltip}
                      oopRxMaxTooltip={oopRxMaxTooltip}
                      pharmacyAndMedicalOopToolTip={pharmacyAndMedicalOopToolTip} />
                  </Tab.TabPanel>
                  {isRxTab && < Tab.TabPanel >
                    <PharmacyClaimsAccount
                      dependentSeqNbr={dependentSeqNbr}
                      familybenefits={familybenefits}
                      isPlandetailSet={isPlandetailSet}
                      hasError={hasError}
                      prescriptionDrug={prescriptionDrug}
                      prescriptionDrugMoop={prescriptionDrugMoop}
                      indRxDedMet={indRxDedMet}
                      indRxDedNotMet={indRxDedNotMet}
                      famRxDedMet={famRxDedMet}
                      famRxDedNotMet={famRxDedNotMet}
                      oopMaxTooltip={oopRxMaxTooltip} 
                      pharmacyOutofPocMax={pharmacyOutofPocMax}
                      pharmacyNoMoopDeductable={pharmacyNoMoopDeductable}
                      />
                  </Tab.TabPanel>}
                </Tab>
              }
            </Card>
          </div> :
          apiFailErrorMsg && apiFailErrorMsg.title && apiFailErrorMsg.title[0] ?
            <ApiCallError text={apiFailErrorMsg.title[0]} />
            : apiErrorMessage && apiErrorMessage.title && apiErrorMessage.title[0] ?
              <ApiCallError text={apiErrorMessage.title[0]} />
              : <ApiCallError text="Sorry, we're experiencing technical difficulties. Please try again later." />
        }</>)}
      </Section>

    </div >

  )
}
export default ClaimsAccounts
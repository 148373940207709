const UseContentFragmentFormatter = response => {
    let formattedData = {}
    try {
        //First iteration to minimize data inside element and create key value
        const data = response
        let itemsList = data && data[":items"] // to get all items
        let itemsOrderList = itemsList && data[":itemsOrder"]  // to get :items elements order
        itemsOrderList && itemsOrderList.forEach(itemsOrder => {
            let item = itemsList[itemsOrder] // to get item json object
            let elementList = null; let elementsOrderList = null;
            let elList = null; let elOrderList = null;
            if (item['elements'] !== undefined) {
                elementList = item['elements'] // to get all the elementlist
                elementsOrderList = item['elementsOrder'] // to get :items elements order
                if (elementList && elementsOrderList) {
                    elementsOrderList.forEach(elementsOrder => {
                        let elementData = elementList[elementsOrder]
                        if (elementData && !(elementData.hasOwnProperty('value'))) {
                            delete elementList[elementsOrder]
                        } else {
                            elementList[elementsOrder] = elementData['value']
                        }
                    })
                    itemsList[itemsOrder] = elementList
                } else {
                    itemsList[itemsOrder] = item['text']
                }
            }
            else if (item['items'] !== undefined) {
                elementList = item['items']  // to get all items array
                elementList.forEach((itOrder, idx) => {
                    elList = itOrder['elements']
                    elOrderList = itOrder['elementsOrder']
                    if (elList && elOrderList) {
                        elOrderList.forEach(elOrder => {
                            let elData = elList[elOrder]
                            if (elData && !(elData.hasOwnProperty('value'))) {
                                delete elList[elOrder]
                            } else {
                                elList[elOrder] = elData['value']
                            }
                        })
                        elementList[idx] = elList
                    }
                })
                itemsList[itemsOrder] = elementList
            }

        })

        //Second iteration to format it so that we can use in our application
        /*itemsOrderList && itemsOrderList.forEach(itemsOrder => {
            let item = itemsList[itemsOrder] // to get item json object
            let elementList = item && item['elements'] // to get all the elementlist
            if (elementList) {
                itemsList[itemsOrder] = elementList
            }
        })*/

        formattedData = {
            data: itemsList,
            // order : itemsOrderList,
            'APIError': false
        }

    } catch (error) {
        formattedData = {
            'APIError': true
        }
    }
    return formattedData
}

export default UseContentFragmentFormatter

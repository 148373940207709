import React from 'react'
import { Typography, BrandIcon, Button } from '@uhc-tempo/components'
import { IconAlert } from '@uhc-tempo/icons'
import styles from './notification.module.css'
import RallyRedirects from '../../../../../constants/rally-redirects'
import Referrals from './referral'
import clickTrack from '../../../../../hooks/clickTrack'

const { find_provider } = RallyRedirects;

const Notification = (props) => {
    const { dependentSeqNbr, situsState } = props
    const domain = window.location.origin + window.location.pathname

    return (
        <>
            <div className={styles.referralCard}>
                <div className={styles.cardContainerLeft}>
                    <div className={styles.providerWrapper}>
                        <div className={styles.leftSection}>
                            <div className={styles.referralItemOne}>
                                <div className={styles.icon}>
                                    <BrandIcon
                                        icon={IconAlert}
                                        size="EXTRA_SMALL"
                                        styleVariation="ONE_TONE_DARK_CIRCLE" className="desktop"
                                    />
                                    <BrandIcon
                                        icon={IconAlert}
                                        size="EXTRA_SMALL"
                                        className="mobile"
                                    />
                                </div>
                                <Typography.Paragraph >
                                    <span> You have one month after your plan start date to choose a primary care provider.</span>
                                    <span style={{ display: 'block' }}>If you do not choose, we will match you with a quality provider near you.</span>
                                </Typography.Paragraph>
                            </div>
                        </div>
                        <div className={styles.changeProvLink}>
                            <Button href={find_provider} 
                            id="choose-prov-1" 
                            text="Choose provider" 
                            buttonType="primary-one"
                            onClick={()=> 
                                clickTrack(
                                  "Choose provider", 
                                  "main-body", 
                                  "button",
                                  "internal", 
                                  domain + find_provider)} />
                        </div>
                    </div>
                </div>
                <Referrals
                    dependentSeqNbr={dependentSeqNbr}
                    situsState={situsState} />
            </div>
        </>
    )
}

export default Notification
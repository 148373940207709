
import React, { useState, useEffect } from "react";
import { Typography, Button, UtilityIcon } from "@uhc-tempo/components";
import Check from "@material-ui/icons/Check";
import UseGraphqlApi from "../../../../../hooks/useGraphqlApi";
import Config from "../../../../../config";
import GetCookieItem from "../../../../../tools/getCookieItem";
import VirtualCareQuery from "../../components/effective-components/virtualCareQuery";
import GenericError from "../../../../shared-components/error";
import { Markup } from 'interweave';
import styled from 'styled-components';
import getFilteredContentList from "../../../../../tools/getFilteredContentList";
import clickTrack from "../../../../../hooks/clickTrack";

const loginLang = GetCookieItem("LOGINLOCALE");
const selectedLang = GetCookieItem("lang");

const { legacy_en_url, rally_url, rally_vc_url, aem_content_url } = Config || {}

const Desktop = styled.div`
  @media (max-width: 991px) {
    display: none;
  }
`
const Mobile = styled.div`
   @media (min-width: 991px) {
      display: none;
   }
`

const VirtualCare = () => {

  const [loading, setLoading] = useState(true);
  const [virtualCareEError, setVirtualCareEError] = useState(false);

  const [virtualCareState, setVirtualCareState] = useState({});
  const getVirtualCareData = VirtualCareQuery();
  const domain = window.location.origin + window.location.pathname
  useEffect(() => {
    UseGraphqlApi(getVirtualCareData, virtualQueryHandler);
  }, []);

  const virtualQueryHandler = (response) => {
    if (response && !response.isError) {
      if (response.data) {
        if (response.data.listTileList.items.length > 0) {
          const listWithDates = getFilteredContentList(response.data ?.listTileList ?.items)
          if (listWithDates.length > 0) {
            setVirtualCareState(
              listWithDates[0]
            );
          }
          else {
            setVirtualCareState(
              response.data.listTileByPath && response.data.listTileByPath.item ?
                response.data.listTileByPath.item : ''
            )
          }
        }
        else if (response.data.listTileByPath.item) {
          setVirtualCareState(
            response.data.listTileByPath && response.data.listTileByPath.item ?
              response.data.listTileByPath.item : ''
          )
        }
      }
    } else {
      setVirtualCareEError(true);
    }
    setLoading(false);
  };
  const { title, subTitle, description, image, bodySubtext, linkText, url, urlType } = virtualCareState || {}
  let newUrl = ''

  function renderUrl(urlType) {
    if (urlType === "rallyUrl") {
      newUrl = `${rally_url}` + url
    }
    else if (urlType === "rallySSO") {
      newUrl = `${rally_vc_url}` + url
    }
    else if (urlType === "pageUrl" || urlType === "default") {
      newUrl = url
    }
    else if (urlType === "legacyUrl") {
      newUrl = `${legacy_en_url}` + url
    }
    return newUrl
  }

  return (
    <>
      <Desktop>
        <div className="virtual-care-col ">
          {virtualCareEError ? (
            <GenericError></GenericError>
          ) : (
              <>
                <div className="AutoPayList">
                  <div className="styled-image active-phone-img desktop" style=
                    {{ marginTop: 10 }}>
                    {image && image._path && (
                      <img
                        src={`${aem_content_url}${image._path}`}
                        alt=""
                      />
                    )}
                  </div>
                  <div className="virtual-care-container tempo-v1">
                    <Typography.H2 style={{ fontSize: '1.5rem' }}>
                      {title || "Virtual Urgent Care"}
                    </Typography.H2>
                    {subTitle ? (
                      <Typography.H3 className="virtual-care-subheader no-margin-bottom">
                        {subTitle}
                      </Typography.H3>
                    ) : null}

                    {description && description.html ? (
                      <Typography.Paragraph
                        style={{ marginTop: 0, marginBottom: "25px" }}
                      >
                        <Markup allowAttributes={true} content={description.html} />
                      </Typography.Paragraph>
                    ) : null}
                    <div className="virtual-list-mobile">
                      <div>
                        <ul
                          className="virtual-list"
                          style={{ display: "flex", flexWrap: "wrap" }}
                        >
                          {bodySubtext && bodySubtext.map((data, i) => {
                            if (data !== null) {
                              return (
                                <>
                                  <li key={i}>
                                    <Typography.H3 className="uhc-tempo-header__h4 flex-heading" style={{ fontSize: '1rem' }}>
                                      <UtilityIcon icon={Check} />
                                      {data}
                                    </Typography.H3>
                                  </li>
                                </>
                              );
                            }
                            return null;
                          })}
                        </ul>
                        <Button
                          buttonType="secondary-one"
                          text={
                            linkText
                              ? linkText
                              : null
                          }
                          href={(renderUrl(urlType))}
                          className={`${
                            loginLang === "es" || selectedLang === "es"
                              ? "vv-button desktop"
                              : "vv-button"
                            }`}
                          style={{ maxHeight: "none" }}
                          id="see-a-doctor"
                          onClick={()=> 
                            clickTrack(
                              linkText
                              ? linkText
                              : null, 
                              "main-body", 
                              "button",
                              "internal", 
                              domain + renderUrl(urlType))}
                        />
                      </div>

                      <div className="styled-image active-phone-img mobile">
                        {image && image._path && (
                          <img
                            src={`${aem_content_url}${image._path}`}
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                    {loginLang === "es" || selectedLang === "es" ? (
                      <Button
                        buttonType="secondary-one"
                        text={
                          linkText
                            ? linkText
                            : null
                        }
                        href={(renderUrl(urlType))}
                        id="virtual-visit"
                        className="vv-button mobile"
                        style={{ maxHeight: "none", marginBottom: 20 }}
                        onClick={()=> 
                          clickTrack(
                            linkText
                            ? linkText
                            : null, 
                            "main-body", 
                            "button",
                            "internal", 
                            domain + renderUrl(urlType))}
                      />
                    ) : null}
                  </div>
                </div>
              </>
            )}
        </div>
      </Desktop>
      <Mobile>
        <div className="virtual-care-col">
          {virtualCareEError ? (
            <GenericError></GenericError>
          ) : (
              <>
                <div className="AutoPayList">
                  <div className="styled-image active-phone-img desktop" style=
                    {{ marginTop: 30 }}>
                    {image && image._path && (
                      <img
                        src={`${aem_content_url}${image._path}`}
                        alt=""
                      />
                    )}
                  </div>
                  <div className="virtual-care-container tempo-v1">
                    <Typography.H2 style={{ fontSize: '1.5rem' }}>
                      {title || "Virtual Urgent Care"}
                    </Typography.H2>
                    {subTitle ? (
                      <Typography.H3 className="virtual-care-subheader no-margin-bottom">
                        {subTitle}
                      </Typography.H3>
                    ) : null}

                    {description && description.html ? (
                      <Typography.Paragraph
                        style={{ marginTop: 0, marginBottom: "25px" }}
                      >
                        <Markup allowAttributes={true} content={description.html} />
                      </Typography.Paragraph>
                    ) : null}
                    <div className="virtual-list-mobile">
                      <div>
                        <ul
                          className="virtual-list"
                          style={{ display: "flex", flexWrap: "wrap" }}
                        >
                          {bodySubtext && bodySubtext.map((data, i) => {
                            if (data !== null) {
                              return (
                                <>
                                  <li key={i}>
                                    <Typography.H3 className="uhc-tempo-header__h4 flex-heading" style={{ fontSize: '1rem' }}>
                                      <UtilityIcon icon={Check} />
                                      {data}
                                    </Typography.H3>
                                  </li>
                                </>
                              );
                            }
                            return null;
                          })}
                        </ul>
                        <Button
                          buttonType="secondary-one"
                          text={
                            linkText
                              ? linkText
                              : null
                          }
                          href={(renderUrl(urlType))}
                          className={`${
                            loginLang === "es" || selectedLang === "es"
                              ? "vv-button desktop"
                              : "vv-button"
                            }`}
                          style={{ maxHeight: "none" }}
                          id="see-a-doctor"
                          onClick={()=> 
                            clickTrack(
                              linkText
                              ? linkText
                              : null, 
                              "main-body", 
                              "button",
                              "internal", 
                              domain + renderUrl(urlType))}
                        />
                      </div>

                      <div className="styled-image active-phone-img mobile">
                        {image && image._path && (
                          <img
                            src={`${aem_content_url}${image._path}`}
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                    {loginLang === "es" || selectedLang === "es" ? (
                      <Button
                        buttonType="secondary-one"
                        text={
                          linkText
                            ? linkText
                            : null
                        }
                        href={(renderUrl(urlType))}
                        id="virtual-visit"
                        className="vv-button mobile"
                        style={{ maxHeight: "none", marginBottom: 20 }}
                        onClick={()=> 
                          clickTrack(
                            linkText
                            ? linkText
                            : null, 
                            "main-body", 
                            "button",
                            "internal", 
                            domain + renderUrl(urlType))}
                      />
                    ) : null}
                  </div>
                </div>
              </>
            )}
        </div>
      </Mobile>
    </>
  );
};

export default VirtualCare;
const PaymentContacts = (STATE) => {
    const Contacts = {
        WA: "1-888-832-0969, TTY 711",
        MD: "1-800-691-0021, TTY 711",
        AZ: "1-877-482-9045, TTY 711",
        NC: "1-800-980-5357, TTY 711",
        OK: "1-800-980-5319, TTY 711",
        TN: "1-877-250-8188, TTY 711",
        VA: "1-877-265-9199, TTY 711",
        AL: "1-888-200-0327, TTY 711",
        FL: "1-888-200-0405, TTY 711",
        GA: "1-800-609-9754, TTY 711",
        IL: "1-888-200-0325, TTY 711",
        LA: "1-866-268-6438, TTY 711",
        MI: "1-888-200-0324, TTY 711",
        TX: "1-866-811-2704, TTY 711",
        "": "1-888-555-5555, TTY 711",
        KS: "1-866-761-7748, TTY 711",
        MO: "1-877-940-4172, TTY 711",
        MS: "1-888-239-1451, TTY 711",
        OH: "1-800-331-4680, TTY 711",
        CO: "1-888-809-6539, TTY 711"
    }
    return Contacts[STATE];
}

export default PaymentContacts;
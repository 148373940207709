import Constants from "../constants/constants"
import UseBaseService from "../hooks/useBaseService"

const UsePRPBills = (medicalMemberID, coverageStatus, callback) => {

    const getDateStringyyyymmdd = (strDate) => {
        strDate.toString('yyyy-MM-dd');
        var dd = strDate.getDate();
        var mm = strDate.getMonth() + 1; // January is 0!
        var yyyy = strDate.getFullYear();
        if (dd < 10) { dd = '0' + dd }
        if (mm < 10) { mm = '0' + mm }
        strDate = yyyy + '-' + mm + '-' + dd;
        return strDate;
    }

    if (coverageStatus === "pre-effective") {
        var postObject = {
            "data": {
                "billsRequest": {
                    "subscriberId": medicalMemberID,
                    "endDate": getDateStringyyyymmdd(new Date(new Date().setMonth(new Date().getMonth() + 12))),
                    "startDate": getDateStringyyyymmdd(new Date(new Date().setMonth(new Date().getMonth())))
                }
            }
        }
    } else {
        postObject = {
            "data": {
                "billsRequest": {
                    "subscriberId": medicalMemberID,
                    "startDate": getDateStringyyyymmdd(new Date(new Date().setMonth(new Date().getMonth() - 11))),
                    "endDate": getDateStringyyyymmdd(new Date(new Date().getTime() + (27 * 24 * 60 * 60 * 1000)))
                }
            }
        }
    }

    const { bills_endpoint } = Constants;
    UseBaseService("PRP Bills", "POST", "dmz", bills_endpoint, postObject,
        response => {
            const { APIError, data } = response
            if (APIError) {
                //logging for API error
                callback({ hasError: true });
            } else {
                try {
                    const { billings } = data && data.data ? data.data : data ? data : {}
                    const { billingSummary } = billings || {}
                    //sort by latest invoice date
                    let billingHistory = [];
                    billingSummary.forEach(element => {
                        let billingSummaryJson = {
                            //                             "billedAmount": element.billSummaryHistory && element.billSummaryHistory.billedAmount ? element.billSummaryHistory.billedAmount : '',
                            "billDueDate": element.billSummary && element.billSummary.billDueDate ? element.billSummary.billDueDate : '',
                            "invoiceCreateDate": element.billInvoice && element.billInvoice.invoiceCreateDate ? element.billInvoice.invoiceCreateDate : '',
                            "billedAmount": element.billSummary && element.billSummary.billedAmount ? element.billSummary.billedAmount : '',
                            "netDueAmount": element.billingEntityInvoice && element.billingEntityInvoice.entityDueAmount ? element.billingEntityInvoice.entityDueAmount : '',
                            "currentDueAmt": element.currentDueAmount && element.currentDueAmount ? element.currentDueAmount : '',
                            "pastDueAmt": element.pastDueAmount && element.pastDueAmount ? element.pastDueAmount : '',
                        };
                        billingHistory.push(billingSummaryJson);
                    })
                    billingHistory = billingHistory.sort((a, b) => new Date(b.billDueDate) - new Date(a.billDueDate));
                    //end
                    let billDueDate = billingHistory[0] && billingHistory[0].billDueDate ? billingHistory[0].billDueDate : ''
                    billDueDate = new Date(billDueDate).toLocaleDateString('en-US', { timeZone: 'UTC' }).replace(/(^|\D)(\d)(?!\d)/g, '$10$2')
                    //                     const billedAmt = billingHistory[0] && billingHistory[0].billedAmt ? billingHistory[0].billedAmt : ''
                    const netDueAmount = billingHistory[0].netDueAmount ? billingHistory[0].netDueAmount : ''
                    const currentDueAmt = billingHistory[0].currentDueAmt ? billingHistory[0].currentDueAmt : ''
                    const pastDueAmt = billingHistory[0].pastDueAmt ? billingHistory[0].pastDueAmt : ''
                    const billedAmount = billingHistory[0].billedAmount ? billingHistory[0].billedAmount : ''
                    let invoiceCreateDate = billingHistory[0].invoiceCreateDate ? billingHistory[0].invoiceCreateDate : ''
                    invoiceCreateDate = new Date(invoiceCreateDate).toLocaleDateString('en-US', { timeZone: 'UTC' }).replace(/(^|\D)(\d)(?!\d)/g, '$10$2')

                    callback({ hasError: false, billings, billDueDate, netDueAmount, invoiceCreateDate, currentDueAmt, pastDueAmt, billedAmount });
                } catch (error) {
                    callback({ hasError: true })
                    //logging for errors
                }
            }
        }
    )
}
export default UsePRPBills;

import config from "../config"
import Axios from "axios"
//const eventsCheck = 3 // Change if we need more info on the page
const { logger_url } = config
let payload = {}
const getCookie = cookie => {
  if (document.cookie) {
     const userInfo = document.cookie
        .split(";")
        .find(list => list.indexOf(cookie) > -1)
     return userInfo ? userInfo.split("=")[1] : null
  }
}
payload.microApp = "dashboard"
const postLog = (payload) => {
  payload.userInfo = getCookie("M_U_I")
  Axios.post(`${logger_url}/events/info`,payload)
        .then(res => {
        //console.log(res)
      })
}
const info = (msg) => {
        payload.msg = msg
        payload.timeStamp = new Date()
        postLog(payload)
}


export default info

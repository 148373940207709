import React from 'react'
import { Typography, Button } from '@uhc-tempo/components'
import styles from './galileoVirtualCare.module.css'
import Config from '../../../../../config'
import { Markup } from 'interweave';
import clickTrack from '../../../../../hooks/clickTrack';

const galileoVirtualCare = ({ title, description, bodySubtext, image, linkText, urlType, url, icon1, icon2 }) => {

  const { legacy_url, rally_url, rally_vc_url, aem_content_url } = Config
  let newUrl = ''
  function renderUrl(urlType) {
    if (urlType === "pageUrl" || urlType === "default" || urlType === "externalUrl") {
      newUrl = url
    }
    else if (urlType === "legacyUrl") {
      newUrl = `${legacy_url}` + url
    }
    else if (urlType === "rallyUrl") {
      newUrl = `${rally_url}` + url
    }
    else if (urlType === "rallySSO") {
      newUrl = `${rally_vc_url}` + url
    }
    return newUrl
  }
  const domain = window.location.origin + window.location.pathname
  return (
    <div style={{ marginTop: "-100px", borderBottom: "1px solid #d8d8d8" }}>
      <div className={styles.container}>
        <div className={styles.wrap}>
          <div className={styles.content}>
            {icon1 && icon1._path ? (
              <img src={`${aem_content_url}${icon1._path}`} alt="app icon" className={styles.icon} />
            ) : null}
            <div className={styles.logoWrap}>
              {icon2 && icon2._path ? (
                <img src={`${aem_content_url}${icon2._path}`} alt="logo" className={styles.type} />
              ) : null}
              {title ? (
                <Typography.Paragraph bodyStyle={2} className={styles.header}>
                  {title}
                </Typography.Paragraph>
              ) : null}
            </div>
            <div className={styles.body}>
              <Typography.Paragraph bodyStyle={2}>
                <Markup
                  allowAttributes={true}
                  content={
                    description && description.html ? description.html : ""
                  }
                />
              </Typography.Paragraph>
              {bodySubtext && bodySubtext[0] ? (
                <Typography.Paragraph bodyStyle={2} style={{ fontWeight: 600 }}>
                  {bodySubtext[0]}
                </Typography.Paragraph>
              ) : null}
            </div>
            {linkText ? (
              <Button
                buttonType="secondary-one"
                href={renderUrl(urlType)}
                target="_blank"
                newTabWindow={urlType === "externalUrl"}
                onClick={()=> 
                  clickTrack(
                    linkText, 
                    "main-body", 
                    "button",
                    urlType === "externalUrl" ? "external" : "internal", 
                    domain + renderUrl(urlType))}
              >
                {linkText}
                {/* {urlType === "externalUrl" ? (
                  <>
                    <span class="uhc-tempo-sr-only">
                      , opens in a new window
                    </span>
                    <svg
                      class="MuiSvgIcon-root uhc-tempo-utility-icon uhc-tempo-button__icon MuiSvgIcon-fontSizeSmall"
                      focusable="false"
                      viewBox="0 0 24 24"
                      color="currentColor"
                      aria-hidden="true"
                      role="img"
                    >
                      <path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"></path>
                    </svg>{" "}
                  </>
                ) : null} */}
              </Button>
            ) : null}
          </div>
          <div className={styles.phoneContainer}>
            {image && image._path ? (
              <img src={`${aem_content_url}${image._path}`} alt="logo" className={styles.phone} />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
export default galileoVirtualCare

import React from "react";

const pageLoadAnalytics = (pageName, sectionL1, sectionL2, sectionL3, lob) => {
    if (window && window.appEventDataLayer && window.appEventDataLayer.push) {
        window.appEventDataLayer.push({
            event: "pageLoadEvent",

            data: {
                pageName: pageName,
                siteSectionL1: sectionL1,
                siteSectionL2: "",
                siteSectionL3: "",
                lineOfBusiness: lob
            },
        });
    }


    if (typeof _satellite == 'object') {
        window._satellite.track('atViewStart', { viewName: 'consumermyuhc:' + pageName, viewCount: true }
        )
    };
    /* var atViewStart = new CustomEvent('atViewStart', {
         detail: {
             viewName: 'consumermyuhc:' + pageName,
             viewCount: true
         }
     })
     document.body.dispatchEvent(atViewStart);*/

    return (
        <> {console.log("Analytics Set on Dashboard")}</>
    )
};
export default pageLoadAnalytics;

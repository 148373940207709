import config from "../config"
import getCookieItem from "../tools/getCookieItem"

//US4949442, US4988117: legacy_url has /myuhc in it which is not required, hence using legacy_en_url
const { legacy_en_url, rally_url } = config || {}
const loginLang = getCookieItem("LOGINLOCALE")
const selectedLang = getCookieItem("lang")

//US4949442, US4988117: Don't change rally_connect_lang value
let rally_connect_lang = "en-US"
if(loginLang === "es" || selectedLang === "es" ){
   rally_connect_lang = "es"
}
//updates for US3013373
//**
const RallyRedirects = {
    find_provider: `${legacy_en_url}/care/find-pcp?locale=${rally_connect_lang}`,
    view_pcp: `${legacy_en_url}/care/provider`,
    change_pcp: `${legacy_en_url}/care/edit-pcp?locale=${rally_connect_lang}`,
    saved_gallery: `${legacy_en_url}/care/saved-providers/gallery?locale=${rally_connect_lang}`,
    virtual_visit: `${rally_url}/virtual-visits/356?locale=en-US`
}

export default RallyRedirects

import React from 'react'
import { Accumulator } from '@uhc/pattern-library'
import { UtilityIcon, Typography, PopupTip } from '@uhc-tempo/components'
import Error from '@material-ui/icons/Error';
import RemoveCircle from '@material-ui/icons/RemoveCircleOutline'
import WarningCircle from '@material-ui/icons/ErrorOutlineRounded'
import CheckCircle from '@material-ui/icons/CheckCircle'
import { Loader } from "@uhc/pattern-library";
import '../../../index.css'
import '../../../../../shared-components/reusableComponents/grid.css'
import { Markup } from "interweave"
import modalTrack from "../../../../../../hooks/modalTrack"

const DentalClaimsAccounts = (props) => {
    const pointer = 12;
    const { dependentSeqNbr, familybenefits, isPlandetailSet, adultDental, annualMaxMet,
        annualMaxApproach, annualMaxNotMet, accumulatorMsg } = props
    const dollarFormatter = (v) => v.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    return (
        <>
            {!isPlandetailSet &&
                <>
                    <Loader Blocking />
                </>
            }
            <div>
                {adultDental && adultDental.description ?
                    <Typography.Paragraph>
                        <Markup content={adultDental.description} />
                    </Typography.Paragraph> :
                    <Typography.Paragraph> After an annual maximum is met, additional dental costs will be paid out of pocket. Dental benefits maximums only apply to qualifying adults.
                </Typography.Paragraph>}
            </div>

            <div className="row" style={{ display: 'flex', padding: 0 }}>
                <div className="col-xs-12 col-sm-12 flex-item">
                    <ul className="row">
                        {/* loggedin subscriber experience */}
                        {dependentSeqNbr === "01" && familybenefits && familybenefits.map(data => {
                            if (data !== null && !data.pediatric && data.dentalAccountSummary &&
                                (data.dentalAccountSummary.indOopMaxAmount || data.dentalAccountSummary.familyOopMaxAmount)) {
                                return (
                                    <>
                                        <li className="col-xs-12 col-sm-12 col-md-12 col-lg-6 accumulator-position" >
                                            <Accumulator
                                                progressBarProps={{ style: { height: '4px' } }}
                                                progressBarBackgroundProps={{
                                                    style: { height: '4px' },
                                                }}
                                                min={0}
                                                max={data.dentalAccountSummary ? Number(data.dentalAccountSummary.indOopMaxAmount) : "0.00"}
                                                value={data.dentalAccountSummary ? Number(data.dentalAccountSummary.indOopSpentAmount) :
                                                    data.dentalAccountSummary.indOopInitialAmount && data.dentalAccountSummary ?
                                                        Number(data.dentalAccountSummary.indOopInitialAmount) : "0.00"}
                                                labelFormatter={dollarFormatter}
                                            >
                                                <Typography.Paragraph style={{ fontSize: 14, margin: 0 }}>{data.firstName}'s Annual Maximum</Typography.Paragraph>
                                                <Accumulator.Title style={{ marginTop: 8 }}>
                                                    {`${dollarFormatter(data.dentalAccountSummary && data.dentalAccountSummary.indOopSpentAmount ?
                                                        Number(data.dentalAccountSummary.indOopSpentAmount) : data.dentalAccountSummary && data.dentalAccountSummary.indOopInitialAmount ?
                                                            Number(data.dentalAccountSummary.indOopInitialAmount) : "0.00")} Spent`}
                                                </Accumulator.Title>
                                            </Accumulator>
                                            {accumulatorMsg && accumulatorMsg.description ?
                                                <Typography.Paragraph style={{ fontSize: 14, margin: 0, position: 'relative', top: '-13px' }}>
                                                    <Markup content={accumulatorMsg.description} />
                                                </Typography.Paragraph> :
                                                <Typography.Paragraph style={{ fontSize: 14, margin: 0, position: 'relative', top: '-13px', paddingBottom: 10 }}>
                                                    There is no family annual maximum for this plan.
                                                    </Typography.Paragraph>}
                                            <div className="meet-dedctible-container">
                                                {(Number(data.dentalAccountSummary.indOopSpentAmount) < Number(data.dentalAccountSummary.indOopMaxAmount) - 150) &&
                                                    data.dentalAccountSummary.indOopMaxAmount !== "0.00" ?
                                                    <div className="expandable-section not-met deductible-svg">
                                                        <UtilityIcon icon={Error} className="blue-utility" />
                                                        {annualMaxNotMet && annualMaxNotMet.title ?
                                                            <span className="tds-header__h6 paragraph">
                                                                <Markup content={annualMaxNotMet.title} />
                                                            </span> :
                                                            <span className="tds-header__h6 paragraph">You have not met your annual maximum</span>}

                                                        <PopupTip icon="question" placement="top" iconVariant="outlined" pointer={parseInt(pointer, 3)}
                                                        onClick = {()=> 
                                                            modalTrack("You have not met your annual maximum")}
                                                        >
                                                            {annualMaxNotMet && annualMaxNotMet.description ? <Typography.Paragraph className="tds-margin-none">
                                                                <Markup content={annualMaxNotMet.description} />
                                                            </Typography.Paragraph> :
                                                                <Typography.Paragraph className="tds-margin-none">
                                                                    The maximum amount the plan will pay for dental services for a member in the current year. Claims for dental services not covered, such as cosmetic, do not count towards the annual maximum.
                                                            </Typography.Paragraph>}
                                                        </PopupTip>
                                                    </div> :
                                                    (Number(data.dentalAccountSummary.indOopSpentAmount) >= Number(data.dentalAccountSummary.indOopMaxAmount) - 150) &&
                                                        (Number(data.dentalAccountSummary.indOopSpentAmount) < Number(data.dentalAccountSummary.indOopMaxAmount)) &&
                                                        data.dentalAccountSummary.indOopMaxAmount !== "0.00" ?
                                                        <div className="expandable-section not-met deductible-svg">
                                                            <UtilityIcon icon={WarningCircle} />
                                                            {annualMaxApproach && annualMaxApproach.title ?
                                                                <span className="tds-header__h6 paragraph">
                                                                    <Markup content={annualMaxApproach.title} />
                                                                </span> :
                                                                <span className="tds-header__h6 paragraph">You are approaching your annual maximum
                                                            </span>}
                                                            <PopupTip icon="question" placement="top"
                                                            onClick = {()=> 
                                                                modalTrack("You are approaching your annual maximum")}>
                                                                {annualMaxApproach && annualMaxApproach.description ?
                                                                    <Typography.Paragraph className="tds-margin-none">
                                                                        <Markup content={annualMaxApproach.description} />
                                                                    </Typography.Paragraph> :
                                                                    <Typography.Paragraph className="tds-margin-none">
                                                                        The maximum amount the plan will pay for dental services for a member in the current year. Claims for dental services not covered, such as cosmetic, do not count towards the annual maximum.
                                                                </Typography.Paragraph>}
                                                            </PopupTip>
                                                        </div> :
                                                        (Number(data.dentalAccountSummary.indOopSpentAmount) >= Number(data.dentalAccountSummary.indOopMaxAmount)) &&
                                                            data.dentalAccountSummary.indOopMaxAmount !== "0.00" ?
                                                            <div className="expandable-section not-met">
                                                                <UtilityIcon icon={CheckCircle} />
                                                                {annualMaxMet && annualMaxMet.title ?
                                                                    <span className="tds-header__h6 paragraph">
                                                                        <Markup content={annualMaxMet.title} />
                                                                    </span> :
                                                                    <span className="tds-header__h6 paragraph">You have met your annual maximum
                                                              </span>}
                                                                <PopupTip icon="question" placement="top"
                                                                onClick = {()=> 
                                                                    modalTrack("You have met your annual maximum")}>
                                                                    {annualMaxMet && annualMaxMet.description ? <Typography.Paragraph className="tds-margin-none">
                                                                        <Markup content={annualMaxMet.description} />
                                                                    </Typography.Paragraph> :
                                                                        <Typography.Paragraph className="tds-margin-none">
                                                                            The maximum amount the plan will pay for dental services for a member in the current year. Claims for dental services not covered, such as cosmetic, do not count towards the annual maximum.
                                                                </Typography.Paragraph>}
                                                                </PopupTip>
                                                            </div> : null
                                                }
                                            </div>
                                        </li>
                                    </>
                                )
                            }
                            // else {
                            //     return (
                            //         apiFailErrorMsg && apiFailErrorMsg.title && apiFailErrorMsg.title[0] ?
                            //             <ApiCallError text={apiFailErrorMsg.title[0]} />
                            //             : apiErrorMessage && apiErrorMessage.title && apiErrorMessage.title[0] ?
                            //                 <ApiCallError text={apiErrorMessage.title[0]} />
                            //                 : <ApiCallError text="Sorry, we're experiencing technical difficulties. Please try again later." />
                            //     )

                            // }
                        })
                        }
                        {/* Logged in dependent experience */}
                        {dependentSeqNbr !== "01" && familybenefits && familybenefits.map(item => {
                            if (item !== null && !item.pediatric && (item.dependentSeqNbr === dependentSeqNbr) &&
                                item.dentalAccountSummary && (item.dentalAccountSummary.indOopMaxAmount || item.dentalAccountSummary.familyOopMaxAmount)) {
                                return (
                                    <>
                                        <li className="col-xs-12 col-sm-12 col-md-12 col-lg-6 accumulator-position">
                                            <Accumulator
                                                progressBarProps={{ style: { height: '4px' } }}
                                                progressBarBackgroundProps={{
                                                    style: { height: '4px' },
                                                }}
                                                min={0}
                                                max={item.dentalAccountSummary ? Number(item.dentalAccountSummary.indOopMaxAmount) : "0.00"}
                                                value={item.dentalAccountSummary && item.dentalAccountSummary.indOopSpentAmount ?
                                                    Number(item.dentalAccountSummary.indOopSpentAmount) : item.dentalAccountSummary && item.dentalAccountSummary.indOopInitialAmount ?
                                                        Number(item.dentalAccountSummary.indOopInitialAmount) : "0.00"}
                                                labelFormatter={dollarFormatter}
                                            >
                                                <Typography.Paragraph style={{ fontSize: 14, margin: 0 }}>{item.firstName}'s Annual Maximum</Typography.Paragraph>
                                                <Accumulator.Title style={{ marginTop: 8 }}>
                                                    {`${dollarFormatter(item.dentalAccountSummary && item.dentalAccountSummary.indOopSpentAmount ?
                                                        Number(item.dentalAccountSummary.indOopSpentAmount) : item.dentalAccountSummary && item.dentalAccountSummary.indOopInitialAmount ?
                                                            Number(item.dentalAccountSummary.indOopInitialAmount) : "0.00")} Spent`}
                                                </Accumulator.Title>
                                            </Accumulator>
                                            <Typography.Paragraph style={{ fontSize: 14, margin: 0, position: 'relative', top: '-13px', paddingBottom: 10 }}>
                                                There is no family annual maximum for this plan.
                                                    </Typography.Paragraph>
                                            <div className="meet-dedctible-container">
                                                {(Number(item.dentalAccountSummary.indOopSpentAmount) < Number(item.dentalAccountSummary.indOopMaxAmount) - 150) &&
                                                    item.dentalAccountSummary.indOopMaxAmount !== "0.00" ?
                                                    <div className="expandable-section not-met deductible-svg">
                                                        <UtilityIcon icon={Error} className="blue-utility" />
                                                        <span className="tds-header__h6 paragraph">You have not met your annual maximum</span>
                                                        <PopupTip icon="question" placement="top" iconVariant="outlined" pointer={parseInt(pointer, 3)}
                                                        onClick = {()=> 
                                                            modalTrack("You have not met your annual maximum")}>
                                                            <Typography.Paragraph className="tds-margin-none">
                                                                The maximum amount the plan will pay for dental services for a member in the current year. Claims for dental services not covered, such as cosmetic, do not count towards the annual maximum.
                                                            </Typography.Paragraph>
                                                        </PopupTip>
                                                    </div> :
                                                    (Number(item.dentalAccountSummary.indOopSpentAmount) >= Number(item.dentalAccountSummary.indOopMaxAmount) - 150) &&
                                                        (Number(item.dentalAccountSummary.indOopSpentAmount) < Number(item.dentalAccountSummary.indOopMaxAmount)) &&
                                                        item.dentalAccountSummary.indOopMaxAmount !== "0.00" ?
                                                        <div className="expandable-section not-met deductible-svg">
                                                            <UtilityIcon icon={WarningCircle} />
                                                            <span className="tds-header__h6 paragraph">You are approaching your annual maximum
                                                            </span>
                                                            <PopupTip icon="question" placement="top"
                                                            onClick = {()=> 
                                                                modalTrack("You are approaching your annual maximum")}>
                                                                <Typography.Paragraph className="tds-margin-none">
                                                                    The maximum amount the plan will pay for dental services for a member in the current year. Claims for dental services not covered, such as cosmetic, do not count towards the annual maximum.
                                                                </Typography.Paragraph>
                                                            </PopupTip>
                                                        </div> :
                                                        (Number(item.dentalAccountSummary.indOopSpentAmount) >= Number(item.dentalAccountSummary.indOopMaxAmount)) &&
                                                            item.dentalAccountSummary.indOopMaxAmount !== "0.00" ?
                                                            <div className="expandable-section not-met">
                                                                <UtilityIcon icon={RemoveCircle} />
                                                                <span className="tds-header__h6 paragraph">You have met your annual maximum
                                                              </span>
                                                                <PopupTip icon="question" placement="top"
                                                                onClick = {()=> 
                                                                    modalTrack("You have met your annual maximum")}>
                                                                    <Typography.Paragraph className="tds-margin-none">
                                                                        The maximum amount the plan will pay for dental services for a member in the current year. Claims for dental services not covered, such as cosmetic, do not count towards the annual maximum.
                                                                </Typography.Paragraph>
                                                                </PopupTip>
                                                            </div> : null
                                                }
                                            </div>
                                        </li>
                                    </>
                                )
                            }
                            // else {
                            //     return (
                            //         apiFailErrorMsg && apiFailErrorMsg.title && apiFailErrorMsg.title[0] ?
                            //             <ApiCallError text={apiFailErrorMsg.title[0]} />
                            //             : apiErrorMessage && apiErrorMessage.title && apiErrorMessage.title[0] ?
                            //                 <ApiCallError text={apiErrorMessage.title[0]} />
                            //                 : <ApiCallError text="Sorry, we're experiencing technical difficulties. Please try again later." />
                            //     )

                            // }
                        })
                        }
                    </ul>
                </div>
            </div>
            {/* {hasError &&
                <>
                    apiFailErrorMsg && apiFailErrorMsg.title && apiFailErrorMsg.title[0] ?
                <ApiCallError text={apiFailErrorMsg.title[0]} />
                    : apiErrorMessage && apiErrorMessage.title && apiErrorMessage.title[0] ?
                    <ApiCallError text={apiErrorMessage.title[0]} />
                    : <ApiCallError text="Sorry, we're experiencing technical difficulties. Please try again later." />
                </>
            } */}
        </>
    )
}
export default DentalClaimsAccounts
const getMemberModalData = (data, callback) => {
    let dataObj = {}
    const getFormattedData = ({
        firstName,
        lastName,
        isSubscriber,
        dateOfBirth,
        relationship
    } = {}) => {
        let DOB = new Date(dateOfBirth ? dateOfBirth.replace(/-/g, '/') : '')
        DOB = DOB ? (DOB.toLocaleDateString()).replace(/(^|\D)(\d)(?!\d)/g, '$10$2') : ''

        dataObj = {
            ...dataObj,
            firstName,
            lastName,
            isSubscriber,
            dateOfBirth: DOB,
            relationship
        }
        return dataObj

    }
    const { loggedinMember, dependents } = data || {}

    let dependentsData = []
    dependents && dependents.length > 0 &&
        dependents.forEach(data => {
            dependentsData = [...dependentsData, getFormattedData(data)]
        })

    //members covered
    callback({
        loggedinMember: getFormattedData(loggedinMember),
        dependents: dependentsData
    })
}

export default getMemberModalData

import React, { createContext, useState, useEffect } from "react"
import UseBaseService from "../hooks/useBaseService"
import Constants from "../constants/constants"
import PlandetailDataFormatter from "../hooks/plandetailFormatter"
import getCookieItem from "../tools/getCookieItem"
import config from "../config"
const { plandetail_endpoint, b360_endpoint } = Constants
const { b360_globalapiservice_call, bills_base_uri } = config;
export const PlanDetailContext = createContext()

const PlanDetailContextProvider = ({ children }) => {

   const contextProfile = JSON.parse(sessionStorage.getItem("ProfileContext"));
   const { loggedinMember: { policyNumber: policyNumber, lineOfBusiness: lineOfBusiness } } = contextProfile;
   const { loggedinMember: { activeCoverages: activeCoveragesArr } } = contextProfile;

   let benefitProductId = null;

   for (const activeCoveragesObj of activeCoveragesArr) {
      const { typeCode: { coverageTypeCode: coverageTypeCodeVal } } = activeCoveragesObj
      if (coverageTypeCodeVal && (coverageTypeCodeVal.toUpperCase() === "RX" || coverageTypeCodeVal.toUpperCase() === "CRX" || coverageTypeCodeVal.toUpperCase() === "ORX")) {
         const { productID: productID } = activeCoveragesObj;
         benefitProductId = productID
      }
   }
   const [plandetail, setPlandetail] = useState({
      isPlandetailSet: false,
      hasError: false
   })

   const getDualRxAccumulators = () => {
      if (sessionStorage.getItem("isRxDualDeductible")) {
         return JSON.parse(sessionStorage.getItem("isRxDualDeductible"));
      }
      return false;   
   }

   const getrxMoopIndicator = () => {
      if (sessionStorage.getItem("isRxMoopIndicator")) {
         return JSON.parse(sessionStorage.getItem("isRxMoopIndicator"));
      }
      return false;   
   }

   const getRXMOOPShareIndicator = () => {
      let global_api_constants = {};
      if (sessionStorage.getItem("global-api-constants")) {
         global_api_constants = JSON.parse(
            sessionStorage.getItem("global-api-constants"));
      }
      const { RX_MOOP_SHARE_INDICATOR} = global_api_constants || {}
      return RX_MOOP_SHARE_INDICATOR ? RX_MOOP_SHARE_INDICATOR : "oopm";
   }

   const getRXCostShareIndicator = () => {
      let global_api_constants = {};
      if (sessionStorage.getItem("global-api-constants")) {
         global_api_constants = JSON.parse(
            sessionStorage.getItem("global-api-constants"));
      }
      const { RX_COST_SHARE_INDICATOR} = global_api_constants || {}
      return RX_COST_SHARE_INDICATOR ? RX_COST_SHARE_INDICATOR : "ded";
   }

   // Rx Dual Deductible
   const [costShareValue, setCostShareValue] = useState(getDualRxAccumulators);
   const [costRXMoopValue, setRXMoopValue] = useState(getrxMoopIndicator);
   const [isCostRxAndMoopValueSet, setIsCostRxAndMoopValueSet] = useState(false);
   const benefitPlanID = policyNumber + '_' + benefitProductId
   const todayDate = new Date().toISOString().split("T")[0]
   let preLoginLang = getCookieItem("LOGINLOCALE") || "en"
   let postLoginLang = getCookieItem("lang") || "en"
   let selectedLang = preLoginLang === "es" || postLoginLang === "es" ? "Espanish" : "English"

   let memberData = {
      dateOfService: todayDate, // today "2023-02-01"
      language: selectedLang,
      session: "dummySession",   // cookies
      userType: "Member",  // 
      benefitPlanIDs: [{ "benefitPlanID": benefitPlanID, "benefitPlanPlatform": lineOfBusiness }],
      benefits: [],
      clientId: "myuhc",
      docType: ["external"]
   }
   async function b360API() {
      if (sessionStorage.getItem("isRxDualDeductible")) {
         setCostShareValue(JSON.parse(sessionStorage.getItem("isRxDualDeductible")))
      }
      else {
         let apiUrl = b360_globalapiservice_call === "true" ? `/ifp/globalApiService?endpoint=${b360_endpoint}/v2.0` : `/ifp${b360_endpoint}${bills_base_uri}${b360_endpoint}/v2.0`
         UseBaseService("B360", "POST", "dmz", apiUrl, memberData, response => {
            const { APIError, data } = response
            if (APIError) {
               console.log("B360 benefit api error");
            } else {
               try {
                  const benefitData = data && data.data ? data.data : data ? data : {}
                  let benefitArray = benefitData.result && benefitData.result.benefit || {}
                  benefitArray.forEach(element => {
                     const hasRx = element.benefitName ?.trim().toLowerCase().includes("pharmacy service") 
                     if (hasRx) {
                        console.log("networkLanguageDescription *****",element.benefitNetworkSection[0]?.networkLanguageDescription.toLowerCase())
                        console.log("RXCostShareIndicator *****",getRXCostShareIndicator())
                        console.log("hasCostShare *****",element.benefitNetworkSection[0]?.networkLanguageDescription.toLowerCase().includes(getRXCostShareIndicator()))
                        console.log("RXMOOPShareIndicator *****",getRXMOOPShareIndicator())
                        console.log("isRxMoopIndicator *****",element.benefitNetworkSection[0]?.networkLanguageDescription.toLowerCase().includes(getRXMOOPShareIndicator()))
                        const hasCostShare = element.benefitNetworkSection &&
                           element.benefitNetworkSection[0]?.networkLanguageDescription.toLowerCase().includes(getRXCostShareIndicator()) ? true : false;
                        sessionStorage.setItem("isRxDualDeductible", hasCostShare)
                        
                        const hasRxMoopShare = element.benefitNetworkSection &&
                           element.benefitNetworkSection[0]?.networkLanguageDescription.toLowerCase().includes(getRXMOOPShareIndicator()) ? true : false;
                        sessionStorage.setItem("isRxMoopIndicator", hasRxMoopShare)
                        
                        setCostShareValue(hasCostShare);
                        setRXMoopValue(hasRxMoopShare);
                        setIsCostRxAndMoopValueSet(true);
                     }
                  });
               }
               catch (error) {
                  console.log("B360 benefits api catch error")
               }
            }
         })
         // console.log("******isRxDualDeductible******", costShareValue)
      }
   }
   /* Get Recent Plan Detail (Medical Accounts) */
   let familymembers = []
   let familybenefits = []

   useEffect(() => {
      // if (sessionStorage.getItem("PlanDetailContext")) {
      //    setPlandetail(JSON.parse(sessionStorage.getItem("PlanDetailContext")))
      // }
      // else {
      b360API()
      if (sessionStorage.getItem("ProfileContext")) {
         const profileData = JSON.parse(sessionStorage.getItem("ProfileContext"))
         const { loggedinMember, dependents } = profileData || {}
         const isSubscriber = loggedinMember && loggedinMember['isSubscriber'] ? loggedinMember.isSubscriber : ""
         let { dobFormatted } = loggedinMember || {}
         const pediatric = Math.floor((new Date() - new Date(dobFormatted)) / 31557600000) < 19
         //loggedIn member's dependent sequence number
         let loggedInMember =
         {
            "dependentSeqNbr": loggedinMember && loggedinMember['dependentSeqNbr'] ? loggedinMember.dependentSeqNbr : " ",
            "firstName": loggedinMember && loggedinMember['firstName'] ? loggedinMember.firstName : '',
            "pediatric": pediatric
         }
         familymembers.push(loggedInMember)
         // loggedMember.push(loggedInMember)
         //Dependent sequence number if available
         let dependentsDetail = isSubscriber && dependents ? dependents.map(dependent => {
            if (dependent !== null) {
               let dependentdetail =
               {
                  "dependentSeqNbr": dependent && dependent['dependentSeqNbr'] ? dependent.dependentSeqNbr : " ",
                  "firstName": dependent && dependent['firstName'] ? dependent.firstName : '',
                  "pediatric": Math.floor((new Date() - new Date(dependent.dobFormatted && dependent.dobFormatted)) / 31557600000) < 19
               }
               return familymembers.push(dependentdetail);
               // dependentMembers.push(dependentdetail);
            } return null
         }) : null

         //get family members data from profile context -end
         //plandetail api call for each member
         if (familymembers != null && plandetail_endpoint.indexOf('offline') < 0) {
            Promise.all(
               familymembers.map(async (familymember) => {
                  const plandetail_endpoint_final = `${plandetail_endpoint}?dependentSeqNbr=${familymember.dependentSeqNbr}&dualRxAccumulators=${costShareValue}&rxMoopIndicator=${costRXMoopValue}`
                  //  await UseBaseService("Plan Detail", "GET", "dmz", `/plandetail.json?dependentSeqNbr=${familymember.dependentSeqNbr}&dualRxAccumulators=${costShareValue}`,"", response => {
                  await UseBaseService("plandetail", "GET", "dmz", plandetail_endpoint_final, '', response => {
                     try {
                        if (response) {
                           const { data, APIError } = response
                           if (APIError) {
                              setPlandetail({ hasError: true, isPlandetailSet: false })
                           } else {
                              const member = data && data.member && data.member[0]
                              const hasBenefitSet = member && member.eligibility && member.eligibility.benefitSet &&
                                 member.eligibility.benefitSet.length > 0
                              const planDetailErrorCode = member && member.error && member.error.responseError &&
                                 member.error.responseError.map(errCode => errCode !== null ? errCode.errorCode : '')
                              const planDetailError = !hasBenefitSet && planDetailErrorCode ? true : false

                              let benefitData = PlandetailDataFormatter(member)
                              benefitData['firstName'] = familymember.firstName
                              benefitData['dependentSeqNbr'] = familymember.dependentSeqNbr
                              benefitData['pediatric'] = familymember.pediatric
                              familybenefits.push(benefitData)

                              //set session in state
                              // console.log(familybenefits)
                              // sessionStorage.setItem(
                              //    "PlanDetailContext",
                              //    JSON.stringify({
                              //       familybenefits,
                              //       hasError: planDetailError,
                              //       isPlandetailSet: true
                              //    })
                              // )
                              setPlandetail({
                                 familybenefits,
                                 isPlandetailSet: true,
                                 hasError: planDetailError
                              })
                           }
                        }

                     } catch (error) {
                        setPlandetail({ hasError: true, isPlandetailSet: false })
                     }
                  })


               }
               )
            )
         }
         else if (familymembers != null && plandetail_endpoint.indexOf('offline') > 0) {
            Promise.all(
               familymembers.map(async (familymember) => {
                  const plandetail_endpoint_final = `${plandetail_endpoint}?dependentSeqNbr=${familymember.dependentSeqNbr}&dualRxAccumulators=${costShareValue}&rxMoopIndicator=${costRXMoopValue}`
                  UseBaseService("Plan Detail", "GET", "dmz", plandetail_endpoint_final, "", response => {
                     const { data, APIError } = response || {};
                     if (APIError) {
                        console.log("PlanDetail API Error: Check api call!")
                        setPlandetail({ hasError: true, isPlandetailSet: false })
                     } else {
                        try {
                           const member = data && data.member && data.member[0]
                           const hasBenefitSet = member && member.eligibility && member.eligibility.benefitSet &&
                              member.eligibility.benefitSet.length > 0
                           const planDetailErrorCode = member && member.error && member.error.responseError &&
                              member.error.responseError.map(errCode => errCode !== null ? errCode.errorCode : '')
                           const planDetailError = !hasBenefitSet && planDetailErrorCode ? true : false

                           let benefitData = PlandetailDataFormatter(member)
                           benefitData['firstName'] = familymember.firstName
                           benefitData['dependentSeqNbr'] = familymember.dependentSeqNbr
                           benefitData['pediatric'] = familymember.pediatric
                           familybenefits.push(benefitData)
                           setPlandetail({
                              familybenefits: familybenefits,
                              isPlandetailSet: true,
                              hasError: planDetailError
                           })

                        } catch (error) {
                           setPlandetail({ hasError: true, isPlandetailSet: false })
                        }
                     }
                  })


               })
            )
         }
         // }
      }
   }, [isCostRxAndMoopValueSet])

   return (
      <PlanDetailContext.Provider value={{ plandetail }}>
         {children}
      </PlanDetailContext.Provider>
   )
}

export default PlanDetailContextProvider
import React, { useState, useEffect } from 'react'
import { Typography, Button } from '@uhc-tempo/components'
import styles from './galileoHeroImage.module.css'
import Config from '../../../../../config'
import { Markup } from 'interweave';
import GalileoHeroQuery from './galileoHeroQuery'
import UseGraphqlApi from "../../../../../hooks/useGraphqlApi";
import getFilteredContentList from "../../../../../tools/getFilteredContentList";
import clickTrack from '../../../../../hooks/clickTrack';

const GalileoHeroImage = () => {

  const { legacy_url, rally_url, rally_vc_url, aem_content_url } = Config
  let newUrl = ''
  function renderUrl(urlType) {
    if (urlType === "pageUrl" || urlType === "default" || urlType === "externalUrl") {
      newUrl = url
    }
    else if (urlType === "legacyUrl") {
      newUrl = `${legacy_url}` + url
    }
    else if (urlType === "rallyUrl") {
      newUrl = `${rally_url}` + url
    }
    else if (urlType === "rallySSO") {
      newUrl = `${rally_vc_url}` + url
    }
    return newUrl
  }

  // GraphQl query implementation starts
  const [galieoHeroImage, setGalieoHeroImage] = useState([]);
  const [hiLoading, setHILoading] = useState(true);
  const [hiError, setHIError] = useState(false);
  const HIQuery = GalileoHeroQuery()
  const domain = window.location.origin + window.location.pathname

  useEffect(() => {
    UseGraphqlApi(HIQuery, postHeroImageHandler);
  }, []);

  const postHeroImageHandler = (response) => {
    if (response && !response.isError) {
      if (response.data) {
        if (response.data.listTileList.items.length > 0) {
          const listWithDates = getFilteredContentList(response.data?.listTileList?.items)
          if (listWithDates.length > 0) {
            setGalieoHeroImage(
              listWithDates[0]
            );
          }
        }
      }
    } else {
      setHIError(true);
    }
    setHILoading(false);
  };
  const { title, subTitle, description, bodySubtext, image, linkText, urlType, url, icon1, icon2 } = galieoHeroImage || {}
  // GraphQl query implementation ends

  return (
    <div style={{ marginTop: '-100px', borderBottom: '1px solid #d8d8d8' }}>
      <div className={styles.container}>
        <div className={styles.wrap}>
          <div className={styles.content}>
            {icon1 && icon1._path ? <img src={`${aem_content_url}${icon1._path}`} alt="app icon" className={styles.icon} /> : null}
            <div className={styles.logoWrap}>
              {icon2 && icon2._path ? <img src={`${aem_content_url}${icon2._path}`} alt="logo" className={styles.type} /> : null}
              {title ? <Typography.Paragraph bodyStyle={2} className={styles.header}>
                {title}
              </Typography.Paragraph> : null}
            </div>
            <div className={styles.body}>
              <Typography.Paragraph bodyStyle={2}>
                <Markup allowAttributes={true} content={description && description.html ? description.html : ''} />
              </Typography.Paragraph>
              {bodySubtext && bodySubtext[0] ? <Typography.Paragraph bodyStyle={2} style={{ fontWeight: 600 }}>
                {bodySubtext[0]}
              </Typography.Paragraph> : null}
            </div>
            {linkText ? <Button 
            buttonType="secondary-one" 
            href={renderUrl(urlType)} 
            target={urlType === "externalUrl" ? '_blank' : ''} 
            newTabWindow={urlType === "externalUrl"} 
            className={styles.btnUrlHeight}
            onClick={()=>{
              clickTrack(
                linkText, 
                "main-body", 
                "button",
                urlType === "externalUrl" ? "external" : "internal", 
                domain + renderUrl(urlType)
                )}}>
              {linkText}
            </Button> : null}
          </div>
          <div className={styles.phoneContainer}>
            {image && image._path ? <img src={`${aem_content_url}${image._path}`} alt="logo" className={styles.phone} /> : null}
          </div>
        </div>

        {subTitle && <div className="notification-container" style={{ margin: '20px 0' }}>
          <div className="hi-notification notification-info flex-info" style={{ backgroundColor: '#e5f8fb' }}>
            <div style={{ display: "flex" }}>
              <Typography.Paragraph style={{ padding: '10px 20px 0 20px' }}>
                <span> {subTitle}</span>
              </Typography.Paragraph>
            </div>
          </div>
        </div>}

      </div>
    </div>
  )
}
export default GalileoHeroImage
import React from "react"
import { Card } from "@uhc/pattern-library"
import { Link } from "@uhc-tempo/components"
import config from "../../../../config"
import clickTrack from "../../../../hooks/clickTrack"
const { aem_content_url } = config

const JourneyCardComponent = props => {
   const { title, btnText, clicked, imgPath } = props

   return (
      <Card style={{ height: 297, cursor: 'pointer' }} onClick={clicked} tabIndex={-1}>
         <Card.Content style={{ textAlign: "center", padding: "45px 0px 50px 0px" }}>
            <img src={`${imgPath}`} alt='' />
            <p style={{ fontSize: '21px' }}>
               <span>&#8220;</span>
               {title}
               <span>&#8221;</span>
            </p>
            <p>
               <Link style={{ fontWeight: 600 }} 
               onClick={()=>{ clicked()
                  clickTrack(btnText, "main-body", "text", "internal", "")
               }}
               tabIndex={0} onClickTrackingData={{
                  clickName: { btnText },
               }}>
                  {btnText}
               </Link>
            </p>
         </Card.Content>
      </Card>
   )
}

JourneyCardComponent.defaultProps = {
   imgPath: `${aem_content_url}/content/dam/myuhc/consumer/assets/myuhc/magnify-icon.svg`
}

export default JourneyCardComponent

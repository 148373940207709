import React from "react";
const modalTrack = (modalName) => {
  let global_api_constants = {};
  if (sessionStorage.getItem("global-api-constants")) {
    global_api_constants = JSON.parse(
      sessionStorage.getItem("global-api-constants")
    );
  }
  const { adobeAnalyticsNew } = global_api_constants || {};
  if (adobeAnalyticsNew === "true" &&
  window.location.pathname.includes("ifp")) {
    window.adobeDataLayer = window.adobeDataLayer || [];
    window.adobeDataLayer.push({
      event: "modal track",

      modal: {
        name: modalName, //if PII or no text value exists please request an alternate value
      },
    });
  }
};
export default modalTrack;

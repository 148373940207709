import React, { useContext, useEffect, useState } from 'react'
import { Typography, UtilityIcon, Link, Button } from '@uhc-tempo/components'
import Check from '@material-ui/icons/Check'
import config from '../../../../config'
import styled from 'styled-components'
import healthResourcesQuery from './healthResourcesQuery'
import UseGraphqlApi from '../../../../hooks/useGraphqlApi'
import { Markup } from 'interweave';
import getFilteredContentList from "../../../../tools/getFilteredContentList";
import clickTrack from '../../../../hooks/clickTrack'
import {ProfileContext} from '../../../../contexts/profileContext'
import getAge from '../../../../tools/getAge'

const StyledContainer = styled.div`
  padding: 0 27px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1180px;
  width: 100%;
`
const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    text-align: right;
  }
`
const BannerWrap = styled.div`
  background-color: #002677;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 27px;
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
  }
`

const ImageCol = styled.div`
  @media (min-width: 768px) {
    flex-basis: 50%;
  }
`
const StyledImg = styled.img`
  width: 100%;
  margin-top: 5px;
`

const ContentCol = styled.div`
  padding: 25px;
  @media (min-width: 768px) {
    flex-basis: 50%;
    padding: 50px;
  }
  svg {
    fill: #fff;
    color: #fff;
    margin-right: 7px;
  }
  li:not(:last-of-type) {
    line-height:2;
    // margin-bottom: 15px;
  }
  h2 {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 1.75rem;
  }
  p {
    margin-top: 0;
  }
  h4 {
    display: flex;
    align-items: center;
    margin: 0;
  }
`
const { aem_content_url } = config

const HealthResources = (props) => {
  const {
    coverageStatus,
    healthResourceTitle,
    healthResourceTermed,
    shopForPlanButton,
    isGalileo,
    isNMuser,
    isNMPayExempt
  } = props;


  const { profile } = useContext(ProfileContext) || {}
  const { loggedinMember } = profile || {}
  const { coverages, dobFormatted } = loggedinMember || {}
  const { medical } = coverages || {}
  const { situsState } = medical;
  const isMemberUnder18 = getAge(dobFormatted) < 18 ? true : false
  const isActive = coverageStatus === 'active'
  const isPreEffective = coverageStatus === 'pre-effective' 
  const isSubscriber = loggedinMember.isSubscriber || loggedinMember?.isRelationship === "Subscriber"

  // GraphQl query implementation starts
  const [hrContent, setHrContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasError, setError] = useState(false);
  const HRQuery = healthResourcesQuery()
  const domain = window.location.origin + window.location.pathname
  const isNJuser = situsState === 'NJ' ? true : false
  useEffect(() => {
    UseGraphqlApi(HRQuery, postHealthResourcesHandler);
  }, []);

  const postHealthResourcesHandler = (response) => {
    if (response && !response.isError) {
      if (response.data) {
        if (response.data.listTileList.items.length > 0) {
          const listWithDates = getFilteredContentList(response.data?.listTileList?.items)
          if (listWithDates.length > 0) {
            setHrContent(
              listWithDates[0]
            );
          }
          else {
            setHrContent(
              response.data.listTileByPath && response.data.listTileByPath.item ?
                response.data.listTileByPath.item : null
            )
          }
        }
        else if (response.data.listTileByPath.item) {
          setHrContent(
            response.data.listTileByPath && response.data.listTileByPath.item ?
              response.data.listTileByPath.item : null
          )
        }
      }
    } else {
      setError(true);
    }
    setLoading(false);
  };
  const { title, subTitle, description, linkText, url, linkText2, url2, } = hrContent || {}
  let {bodySubtext} =  hrContent || {}

  const earnCardDescExists = (desc) => {
    return desc?.toLowerCase().includes("card") && desc?.toLowerCase().includes("earn a");
  }

  const autoPayDescExists = (desc) => {
    return desc?.indexOf("Set up your auto-pay") === -1 && desc?.indexOf("Set up auto-pay") === -1 && desc?.indexOf("Set up Autopay") === -1 && desc?.indexOf("Set up autopay") === -1;
  }

  const referalDescExists = (desc) => {
    return isActive ? desc?.indexOf("Get a referral before seeing a specialist.") === -1 : true;
  }

  const removeAutoPayFromDesc = () => {
    bodySubtext = bodySubtext.filter((desc) => {
      if (autoPayDescExists(desc)) {
        return desc
      }
    })
  }

  const removeRestrictedDescriptionForUnder18 = () => {
    if (isPreEffective) {
      bodySubtext = bodySubtext.filter((desc) => {
        if(!earnCardDescExists(desc)) {
          return desc
        }
      }) 
      if (isSubscriber) {
        removeAutoPayFromDesc()
      }
    }

    if (isActive && (isNMuser || isNJuser)) {
      bodySubtext = bodySubtext.filter((desc) => {
        if(!earnCardDescExists(desc)) {
          return desc
        }
      })
      removeAutoPayFromDesc()
    }
  }

  if (bodySubtext !== undefined ) {
    if (!isSubscriber) {
      bodySubtext = bodySubtext.filter((desc) => {
        if (autoPayDescExists(desc)) {
          return desc
        }
      })
    }
    if (isMemberUnder18) {
      removeRestrictedDescriptionForUnder18()
    }
  }

  if (bodySubtext !== undefined && (isNJuser || isNMuser)){
    if(isPreEffective || isActive) {
      bodySubtext = bodySubtext.filter((desc) => {
        if(isNMPayExempt ? (autoPayDescExists(desc) && !earnCardDescExists(desc) && referalDescExists(desc)) :
          (isNMuser || isNJuser) && !earnCardDescExists(desc) && referalDescExists(desc)) {
            if (!isSubscriber && autoPayDescExists(desc) && isActive) {
              return desc
            }
            return desc
        }
      })
    }
  }

  // GraphQl query implementation ends
  return (
    <>
      <div style={coverageStatus !== 'pre-effective' && !isGalileo ? { borderTop: '1px solid rgb(224, 224, 224)', paddingTop: 30 } :
        coverageStatus === 'active' && isGalileo ? { borderTop: '1px solid rgb(224, 224, 224)', marginTop: 60 } :
          coverageStatus === 'active' ? { borderTop: '1px solid rgb(224, 224, 224)', marginTop: '-20px', paddingTop: 30 } :
            coverageStatus === 'termed' ? { marginTop: '-40px' }
              : null}>
        <StyledContainer style={isGalileo ? { marginTop: 20 } : {}}>
          <TitleWrap>
            {coverageStatus === 'pre-effective' && title ?
              <Typography.H2 className="tds-header__h3"> {title}</Typography.H2> :
              coverageStatus === 'active' ?
                <Typography.H2 style={{ fontSize: '1.5rem', lineHeight: 1.3 }}> {title}</Typography.H2> : ''}
            {coverageStatus !== 'termed' && linkText2 ? <Link href={url2} size="medium" text={linkText2}
            onClick={()=>{
              clickTrack(linkText2, "main-body", "text", 'internal', domain + url2)
           }} /> : ''}
          </TitleWrap>
          <BannerWrap>
            {healthResourceTitle && healthResourceTitle.imgPath ? <ImageCol>
              <StyledImg src={`${aem_content_url}${healthResourceTitle.imgPath}`} alt="" />
            </ImageCol> : ''}

            {healthResourceTermed && healthResourceTermed.fileReference ? <ImageCol>
              <StyledImg src={`${aem_content_url}${healthResourceTermed.fileReference}`} alt="" />
            </ImageCol> : ''}

            <ContentCol>
              {coverageStatus !== 'termed' ?
                <>
                  {subTitle && <Typography.H2 useLight>{subTitle}</Typography.H2>}
                  {description && <Typography.Paragraph useLight>
                    <Markup allowAttributes={true} content={description && description.html} />
                  </Typography.Paragraph>}
                </> : null}

              {healthResourceTermed && healthResourceTermed.text ?
                <Typography.H2 useLight style={{ fontSize: 36, width: '70%' }}>{healthResourceTermed.text}</Typography.H2> : ''}
              {healthResourceTermed && healthResourceTermed.longText ? <Typography.Paragraph useLight>
                {healthResourceTermed.longText}
              </Typography.Paragraph> : ''}

              {coverageStatus !== 'termed' ? <ul>
                {bodySubtext && bodySubtext.map((data, i) => {
                  return (
                    <>
                      <li key={i.toString()}>
                        <Typography.H3 className="tds-header__h4--light flex-heading flex-heading-list">
                          <UtilityIcon icon={Check} />
                          {data}
                        </Typography.H3>
                      </li>
                    </>
                  )
                })}
              </ul> : null}
              {coverageStatus === 'termed' && shopForPlanButton && shopForPlanButton.text ?
                <Button
                  id="shop-for-plan"
                  buttonType="primary-two"
                  className="uhc-tempo-margin-xsm-horizontal shop-plan-button"
                  disabledBorder={false}
                  externalLink
                  href={shopForPlanButton.url}
                  target='_blank'
                  loading={false}
                  size="md"
                  onClick = {() => 
                    clickTrack(
                    shopForPlanButton.text, 
                    "main-body",
                    "button",
                    'external',
                    domain + shopForPlanButton.url
                    )}
                >
                  {shopForPlanButton.text}
                </Button> :
                linkText && <Button
                  buttonType="primary-two"
                  disabledBorder={false}
                  text={linkText}
                  href={url}
                  onClick = {() => 
                    clickTrack(
                    linkText, 
                    "main-body",
                    "button",
                    'internal',
                    domain + url
                    )}
                />}
            </ContentCol>
          </BannerWrap>
        </StyledContainer>
      </div>
    </>
  )
}

export default HealthResources